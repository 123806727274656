import { Account } from 'models/account';
import { toastAutoHideDuration } from 'constants/toasts';
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';
import {
  createIntegration,
  getIntegration,
  listIntegrations,
} from 'api/frontend';
import {
  CreateIntegrationRequest,
  Integration,
  IntegrationType,
} from 'api/models';
import * as Sentry from '@sentry/browser';

export function getInstallState(
  integrations: Integration[],
): 'installed' | 'requested' | undefined {
  if (integrations.some((i) => i.status === 'complete')) {
    return 'installed';
  } else if (integrations.some((i) => i.status === 'requested')) {
    return 'requested';
  } else {
    return undefined;
  }
}

export async function getOrCreateIntegration(
  account: Account,
  defaultIntegrationName: string,
  integrationType: IntegrationType,
  integrationInstallEndpoint: string,
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject,
  ) => SnackbarKey,
): Promise<string | undefined> {
  const headers = { Authorization: `Bearer ${account?.token}` };
  const SubmissionError = `Something went wrong while submitting your integration request. Please try again or escalate to Chkk`;

  // first figure out whether one already exists
  try {
    const response = await listIntegrations({ headers });
    const integration = response.data.find(
      (item) => item.integration_type === integrationType,
    );

    if (integration) {
      // get the integration token
      const detailResponse = await getIntegration(integration.integration_id, {
        headers,
      });
      return `${integrationInstallEndpoint}?accountId=${
        account.accountId
      }&integrationId=${
        detailResponse.integration_id
      }&token=${encodeURIComponent(detailResponse.integration_token || '')}`;
    }
  } catch ({ response }) {
    // doing nothing as we got a different plan in this case
  }

  // did not find one, so creating it
  try {
    const request: CreateIntegrationRequest = {
      integration_name: defaultIntegrationName,
      integration_type: integrationType,
      status: 'pending',
    };

    const response = await createIntegration(request, { headers });

    return `${integrationInstallEndpoint}?accountId=${
      account.accountId
    }&integrationId=${response.integration_id}&token=${encodeURIComponent(
      response.integration_token || '',
    )}`;
  } catch ({ response }) {
    enqueueSnackbar(
      response?.data?.message || response?.data || SubmissionError,
      {
        variant: 'error',
        autoHideDuration: toastAutoHideDuration,
      },
    );
    Sentry.captureException(
      response?.data?.message || response?.data || SubmissionError,
      { level: 'log' },
    );
  }
  return undefined;
}
