import Page from 'components/Page';
import { KBAPage } from './kba/index';

const ClusterResolveLAR = () => {
  return (
    <Page title="LAR Details" sx={{ height: 1 }}>
      <KBAPage />
    </Page>
  );
};

export default ClusterResolveLAR;
