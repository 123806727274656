import React, { useState } from 'react';
import useUserAccountState from 'hooks/useUserAccountState';
import { TextField } from 'subframe/components/TextField';
import { LearnMoreAccordion } from 'subframe/components/LearnMoreAccordion';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import FormProvider from 'components/FormProvider';
import { isEmpty } from 'lodash';
import { Button } from 'subframe/index';
import { Loader } from 'subframe/components/Loader';

interface ClustersSettingProps {
  view: string;
}
const AuditLog: React.FC<ClustersSettingProps> = ({ view }) => {
  const { account } = useUserAccountState();
  const { logEvent } = AnalyticsEventLogger();
  const { enqueueSnackbar } = useSnackbar();
  const [accordionState, setAccordionState] = useState(true);
  const [submitState, setSubmitState] = useState(false);

  const validationSchema = Yup.object().shape({
    startDate: Yup.date()
      .typeError('Start date is either missing or invalid')
      .required('Start date is required'),
    endDate: Yup.date()
      .typeError('End date is either missing or invalid')
      .required('End date is required')
      .min(Yup.ref('startDate'), 'End date cannot be before Start date'),
  });

  interface SchemaFields {
    startDate: string;
    endDate: string;
  }

  const methods = useForm<SchemaFields>({
    resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit: onSubmit,
    formState: { isSubmitting, errors },
  } = methods;

  const sendRequest = async (data: SchemaFields) => {
    logEvent('audit-log-requested', {
      startDate: new Date(data.startDate),
      endDate: new Date(data.endDate),
    });
    setSubmitState(true);
    enqueueSnackbar('Audit Log Requested Successfully', {
      variant: 'success',
    });
  };

  return (
    <FormProvider methods={methods} onSubmit={onSubmit(sendRequest)}>
      <div className="flex w-full flex-col items-center justify-center gap-6">
        <div className="flex h-full w-full max-w-[1280px] grow shrink-0 basis-0 flex-col items-center justify-center gap-6">
          <div className="flex w-full flex-col items-start justify-center gap-4 p-4">
            <LearnMoreAccordion
              title="What's included in Chkk Audit Logs?"
              open={accordionState}
              onOpenChange={(open) => setAccordionState(open)}
            >
              <span className="text-body-bold font-body-bold text-default-font">
                Chkk Audit Logs capture all actions performed by users and
                systems that have access to Chkk.
              </span>
              <span className="text-body-bold font-body-bold text-default-font">
                All Audit Logs are timestamped, but not available in real-time.
              </span>
            </LearnMoreAccordion>
            <div className="flex w-192 flex-col items-start justify-center gap-4">
              <div className="flex w-full items-center justify-between pt-1 pb-1">
                <span className="text-subheader font-subheader text-default-font">
                  Select Dates for Export
                </span>
              </div>
              <div className="flex w-full items-center gap-2">
                <span className="w-48 flex-none text-body-bold font-body-bold text-default-font">
                  Start Date
                </span>
                <TextField
                  className="h-auto w-full grow shrink-0 basis-0"
                  error={!isEmpty(errors?.startDate?.message)}
                  helpText={errors?.startDate?.message}
                >
                  <TextField.Input
                    {...methods.register('startDate')}
                    placeholder="DD-MM-YYYY"
                    className="box-border relative w-full py-3 border-[none]"
                    type={'date'}
                  />
                </TextField>
              </div>
              <div className="flex w-full items-center gap-2">
                <span className="w-48 flex-none text-body-bold font-body-bold text-default-font">
                  End Date
                </span>
                <TextField
                  className="h-auto w-full grow shrink-0 basis-0"
                  error={!isEmpty(errors?.endDate?.message)}
                  helpText={errors?.endDate?.message}
                >
                  <TextField.Input
                    {...methods.register('endDate')}
                    className="box-border relative w-full py-3 border-[none]"
                    placeholder="DD-MM-YYYY"
                    type={'date'}
                  />
                </TextField>
              </div>
              <div className="flex h-full w-full grow shrink-0 basis-0 items-center gap-2">
                <Button
                  icon="FeatherDownload"
                  type="submit"
                  disabled={submitState}
                  data-cy="request-audit-log"
                >
                  Request Audit Log
                </Button>
              </div>
              {submitState && (
                <div className="flex w-full items-center gap-2">
                  <span className="text-subheader font-subheader text-default-font">
                    Exporting Audit Log
                  </span>
                  <Loader />
                  <span className="text-label font-label text-subtext-color">
                    This step can take up to 48 hours
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </FormProvider>
  );
};

export default AuditLog;
