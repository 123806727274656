import { axiosInstance } from 'api/axios-instance';
import { clustersApi } from 'api/constants';
import { useGetCluster, useListClusters } from 'api/frontend';
import { ListClustersResponse, RescanClusterRequest } from 'api/models';
import useSWR, { SWRConfiguration } from 'swr';
import { CommonError } from 'utils/CommonError';

const ClusterHelper = (token: string) => {
  const rescanRequest: RescanClusterRequest = { rescan_status: 'pending' };
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  };
  const getClusters = (
    swrOptions: SWRConfiguration = {
      revalidateOnFocus: true,
    },
  ) => {
    const { data, isValidating, error, mutate } = useListClusters(
      { filter: ['status:active'] },
      {
        request: { headers: { Authorization: `Bearer ${token}` } },
        swr: swrOptions,
      },
    );
    return {
      // empty array when no active clusters, undefined when data from api is loading (not available yet)
      data: data ? (data as ListClustersResponse).data : undefined,
      isLoading: isValidating,
      isError: error,
      mutateClusters: mutate,
    };
  };

  const getClusterById = (clusterId: string) => {
    if (!clusterId) {
      return { data: null, isLoading: true, isError: true };
    }
    const { data, isValidating, error } = useGetCluster(clusterId, {
      request: { headers: { authorization: 'Bearer ' + token } },
      swr: { revalidateOnFocus: false },
    });
    return { data: data, isLoading: isValidating, isError: error };
  };

  const getScanByClusterId = (
    clusterId: string,
    swrOptions?: SWRConfiguration,
    limit = 2,
  ) => {
    const fetcher = async (url: string) => {
      const res = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (!res.ok) {
        const info = await res.json();
        const error = new CommonError(
          'An error occurred while fetching the cluster scan.',
          info,
          res.status,
        );
        throw error;
      }
      return res.json();
    };
    const url = `${clustersApi}/${clusterId}/scans?page_size=${limit}`;
    const { data, error, isValidating } = useSWR(url, fetcher, swrOptions);
    return { data: data, isLoading: isValidating, isError: error };
  };

  const rescan = (clusterId: string) => {
    const url = `${clustersApi}/${clusterId}/rescan`;
    return axiosInstance.post(url, rescanRequest, {
      headers,
    });
  };

  const triggerRescan = async (clusterId: string) => {
    if (!clusterId) {
      throw Error('Cluster id must not be null');
    }
    return await rescan(clusterId);
  };
  return { getClusters, getClusterById, getScanByClusterId, triggerRescan };
};

export default ClusterHelper;
