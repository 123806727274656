// components
import TeamsCard from 'components/TeamsCard';

//hooks
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { RouterLink } from 'components/RouterLink';
import useUserAccountState from 'hooks/useUserAccountState';
import { Button } from 'subframe/index';
import { InviteTeamMemberDialog } from 'components/dialogs/InviteTeamMemberDialog';
import useDialog from 'hooks/useDialog';
import { useGetAccountTeam, useListTeamInvitations } from 'api/frontend';

const TeamsSetting = () => {
  const { account } = useUserAccountState();

  const {
    data: membersListToShow,
    isValidating,
    mutate: refetchMembers,
  } = useGetAccountTeam('default', 'default', {
    request: { headers: { Authorization: `Bearer ${account.token}` } },
  });

  const {
    data: pendingMembersList,
    isValidating: validatingPendingMembers,
    mutate: refetchPendingMembers,
  } = useListTeamInvitations(
    'default',
    'default',
    {},
    {
      request: { headers: { Authorization: `Bearer ${account.token}` } },
    },
  );
  const path = useAccountIdRoute('/orgs/:orgId/accounts/:accountId/teams');

  const { logEvent } = AnalyticsEventLogger();

  const { setDialogType, currentDialog } = useDialog();

  const loading = isValidating || validatingPendingMembers;

  return (
    <div className="flex w-full flex-col items-center justify-center gap-6">
      <div className="flex w-full max-w-xl flex-col items-center justify-center gap-6">
        <div className="flex w-full items-center justify-between px-0 py-1">
          <span className="text-subheader text-default-font">Your Team</span>
          <Button
            data-cy="invite-new-member-button"
            variant="brand-primary"
            icon="FeatherPlus"
            onClick={(event) => {
              if (event?.stopPropagation) {
                event.stopPropagation();
                event.preventDefault();
                setDialogType('invite-member-to-team');
                logEvent('invite-new-member');
              }
            }}
          >
            Invite Team Member
          </Button>
        </div>

        <div className="flex w-full flex-col items-center justify-center gap-6">
          <RouterLink
            to={path}
            className="contents"
            onClick={() => {
              logEvent('teams-card-click');
            }}
          >
            <TeamsCard
              members={membersListToShow?.members}
              pendingInvites={pendingMembersList?.data}
              isLoading={loading}
            />
          </RouterLink>
        </div>
        <div
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
          }}
        >
          <InviteTeamMemberDialog
            handleOpenChange={(open) => {
              if (!open) {
                refetchPendingMembers();
                refetchMembers();
                setDialogType('');
              }
            }}
            open={currentDialog === 'invite-member-to-team'}
          />
        </div>
      </div>
    </div>
  );
};

export default TeamsSetting;
