import { yupResolver } from '@hookform/resolvers/yup';
import * as Sentry from '@sentry/browser';
import * as SubframeCore from '@subframe/core';
import { updateCluster } from 'api/frontend';
import FormProvider from 'components/FormProvider';
import { AtomicTooltip } from 'components/design-system';
import { somethingWentWrong, toastAutoHideDuration } from 'constants/toasts';
import useUserAccountState from 'hooks/useUserAccountState';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button } from 'subframe/components/Button';
import { Dialog } from 'subframe/components/Dialog';
import { IconButton } from 'subframe/components/IconButton';
import { RadioGroup } from 'subframe/components/RadioGroup';
import { TextField } from 'subframe/components/TextField';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import * as Yup from 'yup';
import styles from './ClustersNameYourClusterDialog.module.scss';

export interface ChangeClusterNameDialogProps {
  open: boolean;
  clusterDetails: {
    id: string;
    name: string;
    environment: string;
  };
  allowNameChange: boolean;
  allowEnvironmentChange: boolean;
  onClose: () => void;
  onClusterNameEnvironmentChange: (
    newName: string,
    newEnvironment: string,
  ) => void;
}
export const errorAgentConfigAlreadySet =
  'Cluster name and environment are set in Chkk Operator IaC config; Leave it blank, or modify it directly in your IaC';

type ClusterEnvironmentEnum = 'Prod' | 'Staging' | 'Dev' | 'Custom';

function getClusterEnvironmentFromString(
  value: string,
): ClusterEnvironmentEnum {
  if (value === '' || value.toLowerCase() === 'prod') {
    return 'Prod';
  }
  if (value.toLowerCase() === 'staging') {
    return 'Staging';
  }
  if (value.toLowerCase() === 'dev') {
    return 'Dev';
  }
  return 'Custom';
}

const validationSchema = Yup.object().shape({
  clusterName: Yup.string()
    .required('Please provide a name for your cluster')
    .max(50, 'Cluster name is too long. Max 50 characters.'),
  clusterEnvironment: Yup.string()
    .oneOf(
      ['Prod', 'Staging', 'Dev', 'Custom'],
      'Please select an environment or enter custom.',
    )
    .required(),
  clusterEnvironmentCustom: Yup.string().when('clusterEnvironment', {
    is: 'Custom',
    then: Yup.string()
      .required("Please provide a custom name for your cluster's environment.")
      .max(50, 'Environment name is too long. Max 50 characters.'),
    otherwise: Yup.string().optional(),
  }),
});

const getDefaultValues = (clusterDetails: {
  name: string;
  environment: string;
}) => {
  return {
    clusterName: clusterDetails.name,
    clusterEnvironment: getClusterEnvironmentFromString(
      clusterDetails.environment,
    ),
    clusterEnvironmentCustom:
      getClusterEnvironmentFromString(clusterDetails.environment) === 'Custom'
        ? clusterDetails.environment
        : '',
  };
};

export const ChangeClusterNameEnvironmentDialog = ({
  clusterDetails,
  open,
  onClose,
  allowNameChange = true,
  allowEnvironmentChange = true,
  onClusterNameEnvironmentChange,
}: ChangeClusterNameDialogProps) => {
  const { account } = useUserAccountState();
  const { logEvent } = AnalyticsEventLogger();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: getDefaultValues(clusterDetails),
  });

  const {
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { isSubmitting, errors },
  } = methods;

  const customEnvironmentInputRef = useRef<HTMLElement>(null);
  const radioRef = useRef<HTMLInputElement>(null);

  const onSubmit: SubmitHandler<Record<string, any>> = async (data) => {
    setLoading(true);
    try {
      const { clusterName, clusterEnvironment, clusterEnvironmentCustom } =
        data;
      let updatedFields = [];
      let newEnvironment: string = clusterEnvironment;
      if (newEnvironment.toLowerCase() === 'custom') {
        newEnvironment = clusterEnvironmentCustom;
      }
      const payload: { name?: string; environment?: string } = {};
      if (allowNameChange) {
        payload.name = clusterName;
        updatedFields.push('name');
      }
      if (allowEnvironmentChange) {
        payload.environment = newEnvironment;
        updatedFields.push('environment');
      }
      if (!allowNameChange && !allowEnvironmentChange) {
        enqueueSnackbar(errorAgentConfigAlreadySet, {
          variant: 'warning',
          autoHideDuration: toastAutoHideDuration,
        });
        handleClose();
        return;
      }

      await updateCluster(clusterDetails.id, payload, {
        headers: {
          Authorization: `Bearer ${account?.token}`,
        },
      });

      onClusterNameEnvironmentChange(clusterName, newEnvironment);
      logEvent('cluster-name-environment-change', {
        clusterId: clusterDetails.id,
        clusterName: payload?.name,
        clusterEnvironmentName: payload?.environment,
      });
      let successMessage = 'Cluster ';
      if (updatedFields.length === 1) {
        successMessage += updatedFields[0] + ' updated successfully';
      } else if (updatedFields.length === 2) {
        successMessage += 'name and environment updated successfully';
      }
      enqueueSnackbar(successMessage, {
        variant: 'success',
        autoHideDuration: toastAutoHideDuration,
      });
      onClose();
    } catch (error) {
      enqueueSnackbar(
        somethingWentWrong.replace('<action>', 'updating this Cluster'),
        {
          variant: 'error',
          autoHideDuration: toastAutoHideDuration,
        },
      );
      Sentry.captureException(error, { level: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  const onDialogOpen = () => {
    if (open) {
      const { clusterName, clusterEnvironment, clusterEnvironmentCustom } =
        getDefaultValues(clusterDetails);
      if (clusterDetails?.name !== methods.getValues('clusterName')) {
        //If the cluster name is different in clusterDetails and form, reset the form to clusterDetails
        methods.setValue('clusterName', clusterName);
      }

      methods.setValue('clusterEnvironmentCustom', clusterEnvironmentCustom);
      methods.setValue('clusterEnvironment', clusterEnvironment);
    } else {
      handleClose();
    }
  };

  useEffect(() => {
    if (open) onDialogOpen();
  }, [open]);

  return (
    <Dialog open={open} onOpenChange={() => handleClose()}>
      <Dialog.Content
        className="h-auto w-144 flex-none"
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
        }}
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles['stack-e4dd84e4']}>
            <div className={styles['stack-1d080f2d']}>
              <div className={styles['stack']}>
                <div className={styles['stack-361fcaa5']}>
                  <SubframeCore.Icon
                    className={styles['bodyText']}
                    name="FeatherEdit2"
                  />
                  <span className={styles['subheaderText']}>
                    Customize your cluster
                  </span>
                </div>
              </div>
              <IconButton
                id="close-cluster-name-dialog"
                icon="FeatherX"
                size="medium"
                onClick={(event) => {
                  event.stopPropagation();
                  handleClose();
                }}
              />
            </div>
            <AtomicTooltip
              align="start"
              tooltipContent={
                allowNameChange
                  ? 'Cluster name'
                  : 'Set the cluster name in the Chkk Operator config within IaC. Leave it blank to enable UI overrides, or modify it directly in your IaC'
              }
            >
              <TextField
                id="cluster-name-input"
                helpText={errors.clusterName?.message}
                className="h-auto w-full flex-none"
                label="Cluster Name"
                error={methods.getFieldState('clusterName').invalid}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <TextField.Input
                  disabled={!allowNameChange}
                  {...methods.register('clusterName')}
                />
              </TextField>
            </AtomicTooltip>
            <div className={styles['stack-ff32b275']}>
              <span className={styles['text-b276728f']}>Environment</span>
              <AtomicTooltip
                align="center"
                tooltipContent={
                  allowEnvironmentChange
                    ? 'Environment name'
                    : 'Set the cluster environment in the Chkk Operator config within IaC. Leave it blank to enable UI overrides, or modify it directly in your IaC'
                }
              >
                <RadioGroup
                  name={'clusterEnvironment'}
                  defaultValue={getClusterEnvironmentFromString(
                    clusterDetails?.environment,
                  )}
                  onValueChange={(val: string) => {
                    setValue(
                      'clusterEnvironment',
                      getClusterEnvironmentFromString(val),
                      {
                        shouldValidate: true,
                      },
                    );
                  }}
                >
                  <div className="flex h-full items-center justify-center gap-2">
                    <RadioGroup.Option
                      className="h-auto w-16 flex-none"
                      label="Prod"
                      value="Prod"
                      disabled={!allowEnvironmentChange}
                    />
                    <RadioGroup.Option
                      className="h-auto w-20 flex-none"
                      label="Staging"
                      value="Staging"
                      disabled={!allowEnvironmentChange}
                    />
                    <RadioGroup.Option
                      className="h-auto w-24 flex-none"
                      label="Dev / Test"
                      value="Dev"
                      disabled={!allowEnvironmentChange}
                    />
                    <RadioGroup.Option
                      className="h-auto w-20 flex-none"
                      label="Custom"
                      value="Custom"
                      ref={radioRef}
                      disabled={!allowEnvironmentChange}
                      onClick={() => {
                        setTimeout(
                          () => customEnvironmentInputRef.current?.focus(),
                          1,
                        );
                      }}
                    />
                    <TextField label="" ref={customEnvironmentInputRef}>
                      <TextField.Input
                        {...methods.register('clusterEnvironmentCustom')}
                        onFocus={() => {
                          setTimeout(() => radioRef.current?.click(), 1);
                        }}
                        disabled={!allowEnvironmentChange}
                      />
                    </TextField>
                  </div>
                </RadioGroup>
              </AtomicTooltip>

              <span className={styles['text-e69058c3']}>
                {getValues('clusterEnvironment')?.toLowerCase() == 'custom'
                  ? errors?.clusterEnvironmentCustom?.message
                  : errors?.clusterEnvironment?.message}
              </span>
            </div>
            <div className={styles['stack-4bb28803']}>
              <div className={styles['stack-c42cc68c']}>
                <Button
                  size="large"
                  disabled={loading || isSubmitting}
                  loading={loading}
                  variant="brand-primary"
                  icon="FeatherCornerDownRight"
                  type="submit"
                  id="save-cluster-details"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleSubmit(onSubmit);
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </FormProvider>
      </Dialog.Content>
    </Dialog>
  );
};
