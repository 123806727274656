import { useState } from 'react';
import Page from 'components/Page';
import { TableOfContents } from 'components/docs';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import useDialog from 'hooks/useDialog';
import Image from '../assets/images/SOC2.png';
import { Breadcrumbs } from 'subframe/index';
import { LinkButton } from 'subframe/components/LinkButton';
import { AskK8SExpertsDialog } from 'components/dialogs';

// ----------------------------------------------------------------------
interface DocumentSectionType {
  id: string;
  titleText: string; //for the page indexing
  title?: JSX.Element; //the title heading could have html tags
  definition: JSX.Element;
}
export default function ProductSecurity() {
  const { logEvent } = AnalyticsEventLogger();
  const { setDialogType, currentDialog } = useDialog();
  const [dialogOpen, setDialogOpen] = useState(false);

  const documentSectionsSecurity: DocumentSectionType[] = [
    {
      id: 'chkk-sec-infra',
      titleText: 'Security infrastructure',
      definition: (
        <span className="text-body font-body text-default-font">
          {`Chkk's infrastructure incorporates multiple layers of security measures to safeguard your data during transmission, storage, and processing. These measures include encryption, restricted access, periodic access reviews, and secure software development practices.`}
        </span>
      ),
    },
    {
      id: 'chkk-ope-sec',
      titleText: 'Operational security ',
      definition: (
        <span className="text-body font-body text-default-font">
          Our information security team continuously implements new security
          controls and monitors Chkk for malicious activity across cloud
          infrastructure, networks, and IT devices.
        </span>
      ),
    },
    {
      id: 'chkk-product-sec',
      titleText: 'Product security',
      definition: (
        <span className="text-body font-body text-default-font">
          Chkk provides a robust set of in-product data protection and admin
          controls for greater visibility and control over your data. All the
          API and Dashboard interactions are secured by Access Tokens. Chkk
          supports an elaborate Identity Model and Permissions per Organization,
          Account, Team and User.
        </span>
      ),
    },
    {
      id: 'chkk-product-sso',
      titleText: 'SSO Support',
      definition: (
        <span className="text-body font-body text-default-font">
          Admins can securely deploy Chkk to their organizations with SSO.
          Employees use their existing corporate login when accessing the Chkk
          Dashboard, removing the need for further credential management. This
          includes provisioning and deprovisioning of accounts as people join
          and leave your organization. Chkk supports SAML and OIDC.
        </span>
      ),
    },
  ];

  const documentSectionsReliability: DocumentSectionType[] = [
    {
      id: 'chkk-uptime-availability',
      titleText: 'Uptime and availability',
      definition: (
        <div className="w-full grow shrink-0 basis-0 flex-col items-start gap-2 inline-block">
          <span className="text-body font-body text-default-font">
            Chkk offers 24/7 priority chat and phone support with a 99.9% uptime
            commitment to Enterprise customers, and provides transparency into
            real-time and{' '}
          </span>
          <LinkButton
            className="inline"
            variant="brand"
            onClick={() => {
              logEvent('security-chkk-status-link', {
                link: 'https://status.chkk.io',
                label: 'Chkk Status',
              });
              window.open('https://status.chkk.io', '_blank');
            }}
          >
            historical service status
          </LinkButton>
        </div>
      ),
    },
    {
      id: 'chkk-business-continuity',
      titleText: 'Business continuity ',
      definition: (
        <span className="text-body font-body text-default-font">
          Chkk’s infrastructure provides daily backups, regional backups, and
          recovery procedures for restoring services in the event of unavoidable
          failures.
        </span>
      ),
    },
  ];

  const contentsSecurity = documentSectionsSecurity.map((section) => {
    return { label: section.titleText, link: section.id };
  });

  const contentsReliability = documentSectionsReliability.map((section) => {
    return { label: section.titleText, link: section.id };
  });

  enum mainHeadings {
    security = 'Security',
    reliability = 'Reliability',
    privacy = 'Privacy',
    compliance = 'Compliance',
  }

  const contents = [
    {
      head: mainHeadings.security,
      id: 'chkk-security',
      subheads: contentsSecurity,
    },
    {
      head: mainHeadings.reliability,
      id: 'chkk-reliability',
      subheads: contentsReliability,
    },
    {
      head: mainHeadings.privacy,
      id: 'chkk-privacy',
      subheads: [],
    },
    {
      head: mainHeadings.compliance,
      id: 'chkk-compliance',
      subheads: [],
    },
    {
      head: 'What else would you want to see?',
      id: 'chkk-see-more',
      subheads: [],
    },
  ];

  return (
    <Page title="Security">
      <div className="flex h-full w-[calc(100%-64px)] flex-col items-start gap-6 pt-8 pb-0 px-3 bg-default-background ml-8">
        <Breadcrumbs>
          <Breadcrumbs.Item>Explore</Breadcrumbs.Item>
          <Breadcrumbs.Divider />
          <Breadcrumbs.Item active={true}>Security</Breadcrumbs.Item>
        </Breadcrumbs>
        {/* Dialogs Starts */}
        <AskK8SExpertsDialog
          open={dialogOpen && currentDialog === 'feedback'}
          handleOpenChange={(status) => {
            setDialogOpen(status);
          }}
        />
        {/* Dialogs End */}
        <div className="flex w-full items-center justify-between">
          <span className="text-section-header font-section-header text-default-font">
            Security
          </span>
        </div>
        <div className="flex w-full grow shrink-0 basis-0 items-start gap-6">
          <div className="flex grow shrink-0 basis-0 flex-col items-start gap-4 self-stretch">
            <div className="flex w-full flex-col items-start gap-2 border border-solid border-neutral-border pt-2 pr-2 pb-2 pl-2">
              <span
                className="text-subheader font-subheader text-default-font font-extrabold pt-[45px] mt-[-45px]"
                id="chkk-security"
              >
                {mainHeadings.security}
              </span>
              {documentSectionsSecurity.map((section) => {
                return (
                  <div
                    className="flex w-full flex-col items-start gap-1 pt-2 pr-2 pb-2 pl-2"
                    key={section.id}
                  >
                    <span
                      id={section.id}
                      className="text-body-bold font-body-bold text-default-font pt-[45px] mt-[-45px]"
                    >
                      {section.titleText}
                    </span>
                    {section.definition}
                  </div>
                );
              })}
            </div>

            <div className="flex w-full flex-col items-start gap-2 border border-solid border-neutral-border pt-2 pr-2 pb-2 pl-2">
              <span
                className="text-subheader font-subheader text-default-font font-extrabold pt-[45px] mt-[-45px]"
                id="chkk-reliability"
              >
                {mainHeadings.reliability}
              </span>
              <span className="text-body font-body text-default-font">
                We offer full transparency into system status and performance.
              </span>
              {documentSectionsReliability.map((section) => {
                return (
                  <div
                    className="flex w-full flex-col items-start gap-1 pt-2 pr-2 pb-2 pl-2"
                    key={section.id}
                  >
                    <span
                      className="text-body-bold font-body-bold text-default-font pt-[45px] mt-[-45px]"
                      id={section.id}
                    >
                      {section.titleText}
                    </span>
                    {section.definition}
                  </div>
                );
              })}
            </div>

            <div className="flex w-full flex-col items-start gap-2 border border-solid border-neutral-border pt-2 pr-2 pb-2 pl-2">
              <span
                className="text-subheader font-subheader text-default-font font-extrabold pt-[45px] mt-[-45px]"
                id="chkk-privacy"
              >
                {mainHeadings.privacy}
              </span>
              <div className="w-full grow shrink-0 basis-0 flex-col items-start gap-2 inline-block">
                <span className="text-body font-body text-default-font">
                  We have a comprehensive privacy compliance program that aligns
                  our practices with regulations such as the General Data
                  Protection Regulation and California Consumer Privacy Act.
                  Read our{' '}
                </span>
                <LinkButton
                  className="inline"
                  variant="brand"
                  onClick={() => {
                    logEvent('security-privacy-policy-link', {
                      link: 'https://www.chkk.io/privacy-policy',
                      label: 'Privacy Policy',
                    });
                    window.open('https://www.chkk.io/privacy-policy', '_blank');
                  }}
                >
                  Privacy Policy
                </LinkButton>
                <span className="text-body font-body text-default-font">
                  {' '}
                  for details.
                </span>
              </div>
            </div>

            <div className="flex w-full flex-col items-start gap-2 border border-solid border-neutral-border pt-2 pr-2 pb-2 pl-2">
              <span
                className="text-subheader font-subheader text-default-font font-extrabold pt-[45px] mt-[-45px]"
                id="chkk-compliance"
              >
                {mainHeadings.compliance}
              </span>
              <div className="w-full grow shrink-0 basis-0 flex-col items-start gap-2 inline-block">
                <span className="text-body font-body text-default-font">
                  Chkk adheres to global privacy laws and security standards
                  with measures in place to help you meet your compliance
                  obligations.
                </span>
              </div>
              <img className="w-36 flex-none" src={Image} />
            </div>

            <div className="flex w-full flex-col items-start gap-2 pt-2 pr-2 pb-2 pl-2">
              <span
                className="text-body-bold font-body-bold text-default-font font-extrabold pt-[45px] mt-[-45px]"
                id="chkk-see-more"
              >
                What else would you want to see?
              </span>
              <div className="w-full grow shrink-0 basis-0 flex-col items-start gap-2 inline-block">
                <LinkButton
                  className="inline"
                  variant="brand"
                  onClick={() => {
                    setDialogType('feedback');
                    setDialogOpen(true);
                  }}
                >
                  Click here
                </LinkButton>
                <span
                  className="text-body font-body text-default-font"
                  id="security-feedback"
                >
                  {' '}
                  to get more information about Chkk&#39;s security,
                  reliability, privacy and compliance.
                </span>
              </div>
            </div>
          </div>
          <TableOfContents
            pageName="Security"
            contents={contents}
            subhead={true}
          />
        </div>
      </div>
    </Page>
  );
}
