import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const BreadCrumHeaderContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
    margin: 0,
  },
}));

export const ClusterLARHeaderBadgeContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginTop: '4px',
  marginLeft: '6px',
  [theme.breakpoints.down('md')]: {
    marginLeft: 0,
  },
}));

export const Badge = styled(Box)(() => ({
  '& span': {
    borderRadius: '24px',
    fontFamily: 'SFProText',
    textTransform: 'capitalize',
    fontWeight: 400,
  },
  margin: '0 2px',
}));
