import { LarIgnoreReason } from 'api/models';

type LarIgnoreReasonValues = {
  [key in LarIgnoreReason]: string;
};

export const markAsReasonDisplay: LarIgnoreReasonValues = {
  FalsePositive: 'False positive',
  ByDesign: 'By design',
  DevTestCluster: 'Dev/test Cluster',
  Other: 'Other',
};
