import { useListDirectoryAddonsInfinite } from 'api/frontend-infinite';
import { flattenPages } from 'utils/arrays';

export function useAddonIdToNameMap({
  token,
}: {
  token: string;
}): Map<string, string> {
  const { data: addonData } = useListDirectoryAddonsInfinite(
    {},
    {
      request: { headers: { Authorization: `Bearer ${token}` } },
    },
  );
  return new Map(
    (flattenPages(addonData) || []).map((c) => [c.id, c.name || '']),
  );
}

export function useAddonIdToDetailsMap({
  token,
}: {
  token: string;
}): Map<string, { name: string; eolSource: string | undefined }> {
  const { data: addonData } = useListDirectoryAddonsInfinite(
    {},
    {
      request: { headers: { Authorization: `Bearer ${token}` } },
    },
  );

  return new Map(
    (flattenPages(addonData) || []).map((c) => [
      c.id,
      { name: c.name || '', eolSource: c.eol_source },
    ]),
  );
}
