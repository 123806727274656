import Centered from 'components/Centered';
import { LoaderContainer } from 'components/Loader';
import Page from 'components/Page';
import { Navigate } from 'react-router';
import useUserAccountState from 'hooks/useUserAccountState';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import { createSubscription } from 'api/frontend';
import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useSearchParams } from 'react-router-dom';

export default function MarketplaceAwsSignUp() {
  const { account, currentOrganization } = useUserAccountState();
  const [searchParams, _] = useSearchParams();
  const redirectUrl = useAccountIdRoute('/orgs/:orgId/subscriptions');
  const [loading, setLoading] = useState(true);

  const awsRegistrationToken = (searchParams.get('token') || '').replace(
    / /g,
    '+',
  );

  useEffect(() => {
    (async () => {
      try {
        await createSubscription(
          currentOrganization.slug,
          {
            subscription_type: 'AWSMarketplace',
            aws_registration_token: awsRegistrationToken || '',
          },
          { headers: { authorization: 'Bearer ' + account?.token } },
        );
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [awsRegistrationToken]);

  if (loading) {
    return (
      <Page title="AWS Marketplace SignUp">
        <Centered>
          <LoaderContainer />
        </Centered>
      </Page>
    );
  } else {
    return <Navigate to={redirectUrl} />;
  }
}
