// @mui
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function PageNotFoundIllustration({ ...other }: BoxProps) {
  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARKER = theme.palette.primary.darker;

  return (
    <Box {...other}>
      <svg xmlns="http://www.w3.org/2000/svg" width="333" height="125">
        <rect
          id="backgroundrect"
          width="100%"
          height="100%"
          x="0"
          y="0"
          fill="none"
          stroke="none"
        />
        <defs>
          <linearGradient
            id="BG"
            x1="19.496%"
            x2="77.479%"
            y1="71.822%"
            y2="16.69%"
          >
            <stop offset="0%" stopColor={PRIMARY_MAIN} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="linearGradient-2"
            x1="-50%"
            x2="50%"
            y1="50%"
            y2="150.001%"
          >
            <stop offset="0%" stopColor="#FFE16A" />
            <stop offset="100%" stopColor="#B78103" />
          </linearGradient>
        </defs>

        <g>
          <title>Layer 1</title>
          <g
            fill="none"
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
            id="svg_1"
          >
            <g fillRule="nonzero" id="svg_2">
              <path
                fill={PRIMARY_DARKER}
                d="M166.67961905288695,9.155273517080761e-7 c34.559,0 62.575,28.016 62.575,62.576 c0,34.559 -28.016,62.575 -62.575,62.575 c-34.56,0 -62.576,-28.016 -62.576,-62.575 c0,-34.56 28.016,-62.576 62.576,-62.576 zm0,23.186 c-21.754,0 -39.389,17.635 -39.389,39.39 c0,21.754 17.635,39.389 39.389,39.389 s39.389,-17.635 39.389,-39.389 c0,-21.755 -17.635,-39.39 -39.389,-39.39 z"
                id="svg_3"
              />
              <path
                fill={PRIMARY_MAIN}
                d="M96.76661905288697,83.13200091552733 c0,-4.133 -1.492,-5.625 -5.6370000000000005,-5.625 h-11.373 v-66.611 c0,-4.476 -1.492,-5.6370000000000005 -5.638,-5.6370000000000005 h-9.172 a9.867,9.867 0 0 0 -7.948,3.974 l-55.03,68.274 a11.006,11.006 0 0 0 -1.957,6.787 v5.968 c0,4.145 1.492,5.6370000000000005 5.625,5.6370000000000005 h54.676 v21.707 c0,4.133 1.492,5.625 5.625,5.625 h8.12 c4.146,0 5.638,-1.492 5.638,-5.625 v-21.707 h11.434 c4.414,0 5.6370000000000005,-1.492 5.6370000000000005,-5.6370000000000005 v-7.13 zm-72.42,-5.625 l35.966,-44.415 v44.415 h-35.966 zM333.408619052887,83.13200091552733 c0,-4.133 -1.492,-5.625 -5.638,-5.625 h-11.421 v-66.611 c0,-4.476 -1.492,-5.6370000000000005 -5.638,-5.6370000000000005 h-9.11 a9.869,9.869 0 0 0 -7.949,3.974 l-55.03,68.274 a10.998,10.998 0 0 0 -1.9809999999999999,6.787 v5.968 c0,4.145 1.491,5.6370000000000005 5.625,5.6370000000000005 h54.688 v21.707 c0,4.133 1.491,5.625 5.625,5.625 h8.12 c4.145,0 5.6370000000000005,-1.492 5.6370000000000005,-5.625 v-21.707 h11.434 c4.476,0 5.638,-1.492 5.638,-5.6370000000000005 v-7.13 zm-72.42,-5.625 l35.966,-44.415 v44.415 h-35.966 z"
                id="svg_4"
              />
            </g>
          </g>
        </g>
      </svg>
    </Box>
  );
}
