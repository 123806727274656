import { getCloudConnection, useGetCloudConnection } from 'api/frontend';
import { CloudType } from 'api/models';
import { ConsoleLoader } from 'components/Loader';
import useUserAccountState from 'hooks/useUserAccountState';
import { useParams } from 'react-router';
import CloudAccountsAddNewEditAWS from './edit_aws';
import CloudAccountsAddNewEditGCP from './edit_gcp';
import CloudAccountsAddNewEditAzure from './edit_azure';

export default function CloudAccountsEdit() {
  const { id } = useParams();
  const { account } = useUserAccountState();

  const { data } = useGetCloudConnection(id || '', {
    request: {
      headers: { Authorization: `Bearer ${account?.token}` },
    },
  });

  if (data === undefined) {
    return <ConsoleLoader />;
  } else if (data.cloud_type === CloudType.AWS) {
    return <CloudAccountsAddNewEditAWS />;
  } else if (data.cloud_type === CloudType.GCP) {
    return <CloudAccountsAddNewEditGCP />;
  } else if (data.cloud_type === CloudType.AZURE) {
    return <CloudAccountsAddNewEditAzure />;
  } else {
    return <></>;
  }
}
