'use client';

import React from 'react';
import { IconButton } from 'subframe/components/IconButton';
import { Button } from 'subframe/components/Button';
import { Dialog } from 'subframe/components/Dialog';
import { SetStateFunction } from 'models/react';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';

function UpgradesInstantiateVersionMismatchDialog({
  open,
  setOpen,
  onConfirm,
}: {
  open: boolean;
  setOpen: SetStateFunction<boolean>;
  onConfirm: () => void;
}) {
  const { logEvent } = AnalyticsEventLogger();

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <Dialog.Content className="h-auto w-144 flex-none">
        <div className="flex w-full flex-col items-start gap-6 pt-6 pr-6 pb-6 pl-6">
          <div className="flex w-full items-center justify-between">
            <span className="text-subheader font-subheader text-default-font">
              Instantiate Upgrade Plan ?
            </span>
            <IconButton
              size="medium"
              icon="FeatherX"
              onClick={() => {
                setOpen(false);
              }}
            />
          </div>
          <div className="flex w-full flex-col items-start gap-4">
            <span className="text-body font-body text-default-font">
              Are you sure you want to Instantiate this upgrade plan?
            </span>
            <span className="whitespace-pre-wrap text-body font-body text-default-font">
              {
                'The cluster version doesn\'t match the upgrade template. It is recommended to select a cluster that has the same version as the "From" version in the template.'
              }
            </span>
          </div>
          <div className="flex w-full items-end justify-end gap-4">
            <Button
              variant="neutral-tertiary"
              size="large"
              icon="FeatherX"
              onClick={() => {
                logEvent('upgrade-plan-instantiation-cancelled', {
                  from: 'versionMismatchConfirmationDialog',
                  versionMismatch: true,
                });
                setOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              size="large"
              icon="FeatherPlus"
              onClick={() => {
                logEvent('upgrade-plan-instantiation-click', {
                  from: 'versionMismatchConfirmationDialog',
                  versionMismatch: true,
                });
                onConfirm();
                setOpen(false);
              }}
            >
              Instantiate Upgrade Plan
            </Button>
          </div>
        </div>
      </Dialog.Content>
    </Dialog>
  );
}

export default UpgradesInstantiateVersionMismatchDialog;
