import { useListUpgradeTemplates } from 'api/frontend';
import { ListUpgradeTemplatesResponse } from 'api/models';
import { UPGRADE_TEMPLATES } from 'src/data/upgrades';

export function useTemplateIdToNameMap({
  token,
  useExampleData,
}: {
  token: string;
  useExampleData: boolean;
}): Map<string, string> {
  const { data: templatesData } = useListUpgradeTemplates(
    {},
    {
      request: { headers: { Authorization: `Bearer ${token}` } },
      swr: {
        enabled: !!token,
      },
    },
  );

  return new Map(
    (useExampleData ? UPGRADE_TEMPLATES : templatesData?.data || []).map(
      (t) => [t.id, t.name || ''],
    ),
  );
}
