import { CHKK_K8S_CONNECTOR_VERSION } from 'constants/chkk-version';
import semver from 'semver';

export const K8sConnectorVersion = () => {
  const check = (version: string) => {
    if (!version || version.startsWith('v1')) {
      // All users running v1.x.x should be prompted to upgrade to v0.1.3
      return true;
    } else {
      // Remove the prepatch or prerelease tags from the versions before comparing
      const cleanVersion = version.replace('v', '').split('-')[0];
      const cleanChkkVersion = CHKK_K8S_CONNECTOR_VERSION.replace(
        'v',
        '',
      ).split('-')[0];

      return semver.lt(cleanVersion, cleanChkkVersion);
    }
  };

  return {
    check,
  };
};
