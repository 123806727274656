import Page from 'components/Page';
import * as SubframeCore from '@subframe/core';
import { CircularProgress } from '@mui/material';
import useAccountIdRoute from 'hooks/useAccountIdRoute';

import { updateCloudConnection, useListCloudConnections } from 'api/frontend';
import { CloudConnection, CloudConnectionStatus, CloudType } from 'api/models';
import { Breadcrumbs, Loader } from 'src/subframe/index';
import { Button } from 'src/subframe/components/Button';
import { DropdownMenu } from 'src/subframe/components/DropdownMenu';
import { InformationalHeader } from 'src/subframe/components/InformationalHeader';
import { Badge } from 'src/subframe/components/Badge';
import { Tooltip } from 'src/subframe/components/Tooltip';
import { formatDistanceToNow } from 'date-fns';
import { CloudAccountsDeleteDialog } from './helpers/CloudAccountsDeleteDialog';
import { RouterLink } from 'components/RouterLink';
import useUserAccountState from 'hooks/useUserAccountState';
import BaseTable from 'components/design-system/Table/BaseTable';
import { useGate } from 'statsig-react';
import { useState } from 'react';
import { somethingWentWrong, toastAutoHideDuration } from 'constants/toasts';
import * as Sentry from '@sentry/browser';
import { useSnackbar } from 'notistack';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { EditCloudAccountNameDialog } from 'pages/cloud_accounts/helpers/EditCloudAccountNameDialog';

export default function CloudAccountsIndex() {
  const { account } = useUserAccountState();
  const basePath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/cloud_accounts/',
  );
  const [openAddAccountDropdown, setOpenAddAccountDropdown] = useState(false);
  // fetch data

  const { enqueueSnackbar } = useSnackbar();
  const { logEvent } = AnalyticsEventLogger();

  const { isValidating, data, mutate } = useListCloudConnections(
    {},
    { request: { headers: { Authorization: `Bearer ${account.token}` } } },
  );
  if (isValidating) {
    return (
      <Page title="cloud accounts">
        <div className="flex bg-default-background pl-3 pr-3 py-8 flex-col gap-6 items-start ml-8 w-[calc(100%-64px)]">
          <Breadcrumbs>
            <Breadcrumbs.Item>Configure</Breadcrumbs.Item>
            <Breadcrumbs.Divider name="FeatherChevronRight" />
            <Breadcrumbs.Item active={true}>Cloud Accounts</Breadcrumbs.Item>
          </Breadcrumbs>
          <div className="flex flex-col gap-6 items-start self-stretch">
            <div className="flex pl-0 pr-0 py-0 justify-between items-start self-stretch">
              <div className="flex flex-col gap-2 items-start">
                <span className="text-default-font text-section-header">
                  Cloud Accounts
                </span>
                <span className="text-subtext-color text-label">
                  List of all Cloud Accounts connected to Chkk
                </span>
              </div>
              <SubframeCore.DropdownMenu.Root>
                <SubframeCore.DropdownMenu.Trigger asChild={true}>
                  <Button
                    size="large"
                    id="cloudAccountsAddButton"
                    icon="FeatherPlus"
                  >
                    Add Cloud Account
                  </Button>
                </SubframeCore.DropdownMenu.Trigger>
                <SubframeCore.DropdownMenu.Portal>
                  <SubframeCore.DropdownMenu.Content
                    asChild={true}
                    side="bottom"
                    align="center"
                    sideOffset={8}
                  >
                    <DropdownMenu>
                      <RouterLink
                        className="self-stretch"
                        id="cloudAccountsAddAws"
                        to={`${basePath}new_aws`}
                      >
                        <DropdownMenu.DropdownItem icon="FeatherCloud">
                          AWS
                        </DropdownMenu.DropdownItem>
                      </RouterLink>

                      <RouterLink
                        className="self-stretch"
                        id="cloudAccountsAddGcp"
                        to={`${basePath}new_gcp`}
                      >
                        <DropdownMenu.DropdownItem icon="FeatherCloud">
                          GCP
                        </DropdownMenu.DropdownItem>
                      </RouterLink>
                      <RouterLink
                        className="self-stretch"
                        id="cloudAccountsAddAzure"
                        to={`${basePath}new_azure`}
                      >
                        <DropdownMenu.DropdownItem icon="FeatherCloud">
                          Azure
                        </DropdownMenu.DropdownItem>
                      </RouterLink>
                    </DropdownMenu>
                  </SubframeCore.DropdownMenu.Content>
                </SubframeCore.DropdownMenu.Portal>
              </SubframeCore.DropdownMenu.Root>
            </div>
          </div>
          <div className="flex self-stretch flex-col gap-2 items-center">
            <Loader size="large" />
          </div>
        </div>
      </Page>
    );
  }

  const connectedCount = data?.data.filter(
    (e) => e.status === 'connected',
  ).length;
  const pendingCount = data?.data.filter((e) => e.status === 'pending').length;
  const disconnectedCount = data?.data.filter(
    (e) => e.status === 'disconnected',
  ).length;
  const failureCount = data?.data.filter((e) => e.status === 'failed').length;

  return (
    <Page title="Cloud Accounts">
      <div className="flex bg-default-background pl-3 pr-3 py-8 flex-col gap-6 items-start ml-8">
        <Breadcrumbs>
          <Breadcrumbs.Item>Configure</Breadcrumbs.Item>
          <Breadcrumbs.Divider name="FeatherChevronRight" />
          <Breadcrumbs.Item active={true}>Cloud Accounts</Breadcrumbs.Item>
        </Breadcrumbs>
        <div className="flex flex-col gap-6 items-start self-stretch">
          <div className="flex pl-0 pr-0 py-0 justify-between items-start self-stretch">
            <div className="flex flex-col gap-2 items-start">
              <span className="text-default-font text-section-header">
                Cloud Accounts
              </span>
              <span className="text-label text-subtext-color">
                List of all Cloud Accounts connected to Chkk
              </span>
            </div>
            <SubframeCore.DropdownMenu.Root
              open={openAddAccountDropdown}
              onOpenChange={setOpenAddAccountDropdown}
            >
              <SubframeCore.DropdownMenu.Trigger asChild={true}>
                <Button
                  size="large"
                  id="cloudAccountsAddButton"
                  icon="FeatherPlus"
                >
                  Add Cloud Account
                </Button>
              </SubframeCore.DropdownMenu.Trigger>
              <SubframeCore.DropdownMenu.Portal>
                <SubframeCore.DropdownMenu.Content
                  asChild={true}
                  side="bottom"
                  align="center"
                  sideOffset={8}
                >
                  <DropdownMenu data-cy="cloud_accounts_add-provider-dropdown">
                    <RouterLink
                      className="self-stretch"
                      id="cloudAccountsAddAws"
                      to={`${basePath}new_aws`}
                    >
                      <DropdownMenu.DropdownItem icon="FeatherCloud">
                        AWS
                      </DropdownMenu.DropdownItem>
                    </RouterLink>

                    <RouterLink
                      className="self-stretch"
                      id="cloudAccountsAddGcp"
                      to={`${basePath}new_gcp`}
                    >
                      <DropdownMenu.DropdownItem icon="FeatherCloud">
                        GCP
                      </DropdownMenu.DropdownItem>
                    </RouterLink>
                    <RouterLink
                      className="self-stretch"
                      id="cloudAccountsAddAzure"
                      to={`${basePath}new_azure`}
                    >
                      <DropdownMenu.DropdownItem icon="FeatherCloud">
                        Azure
                      </DropdownMenu.DropdownItem>
                    </RouterLink>
                  </DropdownMenu>
                </SubframeCore.DropdownMenu.Content>
              </SubframeCore.DropdownMenu.Portal>
            </SubframeCore.DropdownMenu.Root>
          </div>
        </div>
        <div className="w-full flex flex-col gap-2 items-start">
          <InformationalHeader>
            <InformationalHeader.Item
              title="Connected"
              icon={
                <SubframeCore.Icon
                  className="text-success-600 text-label-bold"
                  name="FeatherCircle"
                />
              }
              bodySlot={
                <span className="text-body-bold font-body-bold text-default-font">
                  {connectedCount}
                </span>
              }
            />
            <InformationalHeader.Item
              title="Pending"
              icon={
                <SubframeCore.Icon
                  className="text-brand-600 text-label-bold"
                  name="FeatherCircle"
                />
              }
              bodySlot={
                <span className="text-body-bold font-body-bold text-default-font">
                  {pendingCount}
                </span>
              }
            />

            <InformationalHeader.Item
              title="Failed"
              icon={
                <SubframeCore.Icon
                  className="text-error-600 text-label-bold"
                  name="FeatherCircle"
                />
              }
              bodySlot={
                <span className="text-body-bold font-body-bold text-default-font">
                  {failureCount}
                </span>
              }
            />

            <InformationalHeader.Item
              title="Disconnected"
              icon={
                <SubframeCore.Icon
                  className="text-subtext-color text-label-bold"
                  name="FeatherCircle"
                />
              }
              bodySlot={
                <span className="text-body-bold font-body-bold text-default-font">
                  {disconnectedCount}
                </span>
              }
            />
          </InformationalHeader>
          <BaseTable
            settings={{
              localStorageKey: 'cloud_accounts_accounts_table',
              dataCyPrefix: 'cloud_accounts_accounts_table',
            }}
            data={data?.data || []}
            rowLink={(row) => `${basePath}${row.id}`}
            emtpyState={
              <span
                className="text-default-font text-body cursor-pointer"
                onClick={() => setOpenAddAccountDropdown(true)}
              >
                ➕ Add a Cloud Account that Chkk should scan
              </span>
            }
            columns={[
              {
                id: 'account',
                title: 'Account ID',
                titleStyle: { width: '25%' },
                cellType: 'text-cell',
                cellProperties: {
                  primary: true,
                },
                render: (row: CloudConnection) => {
                  if (row.cloud_type === CloudType.AWS) {
                    return row.aws?.aws_account_id || '';
                  } else if (row.cloud_type === CloudType.GCP) {
                    return row.gcp?.project_id || '';
                  } else if (row.cloud_type === CloudType.AZURE) {
                    return row.cloud_connection_name || '';
                  } else {
                    return 'n/a';
                  }
                },
              },
              {
                id: 'cloud_connection_name',
                title: 'Account Name',
                titleStyle: { width: '30%' },
                cellType: 'text-cell',
                render: (row: CloudConnection) => {
                  if (row.cloud_connection_name) {
                    return row.cloud_connection_name;
                  } else {
                    if (row.cloud_type === CloudType.AWS) {
                      return row.aws?.aws_account_id || '';
                    } else if (row.cloud_type === CloudType.GCP) {
                      return row.gcp?.project_id || '';
                    } else {
                      return 'n/a';
                    }
                  }
                },
              },
              {
                id: 'cloud_provider',
                title: 'Cloud Provider',
                titleStyle: { width: '20%' },
                cellType: 'text-cell',
                render: (row: CloudConnection) => {
                  return row.cloud_type === 'AZURE' ? 'Azure' : row.cloud_type; // Show AWS/GCP/Azure (Azure not capitalized)
                },
              },
              {
                id: 'status',
                title: 'Status',
                titleStyle: { width: '30%' },
                cellType: 'cell',
                render: (row: CloudConnection) => {
                  return (
                    <SubframeCore.Tooltip.Provider>
                      <SubframeCore.Tooltip.Root>
                        <SubframeCore.Tooltip.Trigger asChild={true}>
                          <Badge variant={getBadgeVariante(row.status)}>
                            {getUserVisibleStatus(row.status)}
                          </Badge>
                        </SubframeCore.Tooltip.Trigger>
                        <SubframeCore.Tooltip.Portal>
                          <SubframeCore.Tooltip.Content
                            asChild={true}
                            side="bottom"
                            align="center"
                            sideOffset={8}
                          >
                            <Tooltip>
                              {`Account ${
                                row.status === 'connected'
                                  ? 'connected'
                                  : 'added'
                              } ${formatDistanceToNow(
                                new Date(row.created * 1000),
                              )} ago`}
                            </Tooltip>
                          </SubframeCore.Tooltip.Content>
                        </SubframeCore.Tooltip.Portal>
                      </SubframeCore.Tooltip.Root>
                    </SubframeCore.Tooltip.Provider>
                  );
                },
              },
            ]}
            actions={[
              {
                id: 'edit',
                render: (entry: CloudConnection) => {
                  return (
                    <div
                      className="flex gap-4 items-start"
                      onClick={(e) => e.preventDefault()}
                      onContextMenu={(e) => e.preventDefault()}
                    >
                      <EditCloudAccountNameDialog
                        name={((entry) => {
                          if (entry.cloud_connection_name) {
                            return entry.cloud_connection_name;
                          } else if (entry.cloud_type === CloudType.AWS) {
                            return entry.aws?.aws_account_id || '';
                          } else if (entry.cloud_type === CloudType.GCP) {
                            return entry.gcp?.project_id || '';
                          } else {
                            return '';
                          }
                        })(entry)}
                        onConfirm={async (newName) => {
                          try {
                            await updateCloudConnection(
                              entry.id,
                              { cloud_connection_name: newName },
                              {
                                headers: {
                                  Authorization: `Bearer ${account.token}`,
                                },
                              },
                            );
                            logEvent('cloud-account-connection-name-change', {
                              id: entry.id,
                              name: newName,
                            });
                            mutate();
                            enqueueSnackbar(
                              'Successfully edited requested properties of the Cloud Account',
                              {
                                variant: 'success',
                                autoHideDuration: toastAutoHideDuration,
                              },
                            );
                          } catch (err) {
                            enqueueSnackbar(
                              somethingWentWrong.replace(
                                '<action>',
                                'Updating requested properties of the Cloud Account',
                              ),
                              {
                                variant: 'error',
                                autoHideDuration: toastAutoHideDuration,
                              },
                            );
                            Sentry.captureException(err);
                          }
                        }}
                      />
                    </div>
                  );
                },
              },
              {
                id: 'delete',
                render: (entry: CloudConnection) => {
                  return (
                    <div
                      className="flex gap-4 items-start"
                      onClick={(e) => e.preventDefault()}
                      onContextMenu={(e) => e.preventDefault()}
                    >
                      <CloudAccountsDeleteDialog
                        account={account}
                        cloudAccountId={entry.id}
                        mutate={mutate}
                      />
                    </div>
                  );
                },
              },
              {
                id: 'next',
                render: () => {
                  return (
                    <SubframeCore.Icon
                      className="text-default-font text-body"
                      name="FeatherChevronRight"
                    />
                  );
                },
              },
            ]}
          />
        </div>
      </div>
    </Page>
  );
}

function getBadgeVariante(
  fieldValue: CloudConnectionStatus,
): 'brand' | 'neutral' | 'error' | 'warning' | 'success' {
  if (fieldValue == 'connected') {
    return 'success';
  } else if (fieldValue == 'pending') {
    return 'warning';
  } else if (fieldValue == 'failed') {
    return 'error';
  } else {
    return 'neutral';
  }
}

function getUserVisibleStatus(fieldValue: CloudConnectionStatus): string {
  switch (fieldValue) {
    case 'connected':
      return 'Connected';
    case 'failed':
      return 'Failed';
    case 'pending':
      return 'Pending Connection';
    case 'disconnected':
      return 'Disconnected';
  }
}
