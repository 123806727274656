'use client';

import * as Sentry from '@sentry/react';
import { useGetAccountStats } from 'api/frontend';
import { useListClustersInfinite } from 'api/frontend-infinite';
import { Cluster } from 'api/models';
import Centered from 'components/Centered';
import MessageBox from 'components/MessageBox';
import Page from 'components/Page';
import { RouterLink } from 'components/RouterLink';
import { AtomicTooltip } from 'components/design-system';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import useLocalStorage from 'hooks/useLocalStorage';
import useUserAccountState from 'hooks/useUserAccountState';
import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'react-use';

import { Alert } from 'subframe/components/Alert';
import { Breadcrumbs } from 'subframe/components/Breadcrumbs';
import { Button } from 'subframe/components/Button';
import { Switch } from 'subframe/components/Switch';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { flattenPages } from 'utils/arrays';
import CountHeader from './components/CountHeader';
import RiskLedgerActionHeader from './components/RiskLedgerActionHeader';
import {
  ClusterFilters,
  ClusterViewsTooltip,
  ClustersViewLayout,
  ClustersViewLayoutValues,
} from './models';
import {
  getRealAndExampleClusters,
  objectToKeyValueArray,
} from './utils/clusterOperations';

function ClustersView() {
  const { account } = useUserAccountState();
  const { logEvent } = AnalyticsEventLogger();

  const [initialLoading, setInitialLoading] = useState<boolean>(true); // for initial Data
  const [filters, setFilters] = useState<ClusterFilters | undefined>(undefined);

  const [searchClusterName, setSearchClusterName] = useState('');

  const [clustersToShow, setClustersToShow] = useState<Cluster[] | undefined>(
    [],
  );
  const [clustersToShowLoading, setClustersToShowLoading] =
    useState<boolean>(true);
  const [clustersToShowFilterLoading, setClustersToShowFilterLoading] =
    useState<boolean>(false);

  const [exampleClustersToShow, setExampleClustersToShow] = useState<Cluster[]>(
    [],
  );
  const [showExampleData, setShowExampleData] = useLocalStorage<boolean>(
    'example-data',
    true,
  );

  const [totalClusters, setTotalClusters] = useState<Cluster[]>([]);
  const { data: accountStats, isLoading: isLoadingAccountStats } =
    useGetAccountStats(
      'default',
      {},
      {
        request: { headers: { Authorization: `Bearer ${account.token}` } },
        swr: {
          refreshInterval: 10 * 1000,
          revalidateOnFocus: true,
        },
      },
    );

  const {
    data: clustersList,
    isLoading: isLoadingClustersList,
    isValidating: isValidatingClustersList,
    error: isErrorClustersList,
    mutate: mutateClustersList,
    setSize: setSizeClustersList,
    size: sizeClustersList,
  } = useListClustersInfinite(
    account?.token,
    filters && objectToKeyValueArray(filters).length > 0
      ? { status: 'active', filter: objectToKeyValueArray(filters) }
      : { status: 'active' },

    {
      request: { headers: { Authorization: `Bearer ${account?.token}` } },
      swr: {
        keepPreviousData: false,
      },
    },
    {
      initialSize: 1,
      revalidateFirstPage: false,
    },
  );

  const {
    //this is for loading total data for the first time
    data: initialClustersListData,
    isLoading: isLoadingInitialClustersList,
    isValidating: isValidatingInitialClustersList,
    error: errorInitialClustersList,
    setSize,
    mutate: mutateInitialClustersList,
    size,
  } = useListClustersInfinite(
    account?.token,
    { status: 'active' },
    {
      request: { headers: { Authorization: `Bearer ${account?.token}` } },
      swr: {
        keepPreviousData: false,
      },
    },
    {
      initialSize: 1,
      revalidateFirstPage: false,
    },
  );

  if (errorInitialClustersList && initialLoading) {
    Sentry.captureException(errorInitialClustersList);
    setInitialLoading(false);
  }

  useEffect(() => {
    if (
      !isLoadingInitialClustersList &&
      initialClustersListData &&
      initialClustersListData[initialClustersListData?.length - 1]
        ?.continuation_token
    ) {
      setSize(size + 1);
      const initialPages = flattenPages(initialClustersListData || []) || [];
      setTotalClusters(initialPages);
    } else if (
      !isLoadingInitialClustersList &&
      ((initialClustersListData &&
        !initialClustersListData[initialClustersListData?.length - 1]
          ?.continuation_token) ||
        initialClustersListData?.length === 0)
    ) {
      setInitialLoading(false);
      const initialPages = flattenPages(initialClustersListData || []) || [];
      setTotalClusters(initialPages);
    }
  }, [initialClustersListData, isValidatingInitialClustersList]); // this is for loading total data

  useEffect(() => {
    if (
      !isLoadingClustersList &&
      clustersList &&
      clustersList[clustersList?.length - 1]?.continuation_token
    ) {
      setSizeClustersList(sizeClustersList + 1);
      const pages = flattenPages(clustersList || []) || [];
      const { realClusterData } = getRealAndExampleClusters(pages || [], false);
      setClustersToShow(realClusterData);
      !clustersToShowFilterLoading && setClustersToShowFilterLoading(true);
      setClustersToShowLoading(false);
    } else if (
      !isLoadingClustersList &&
      ((clustersList &&
        !clustersList[clustersList?.length - 1]?.continuation_token) ||
        clustersList?.data?.length === 0)
    ) {
      const pages = flattenPages(clustersList || []) || [];
      const { realClusterData } = getRealAndExampleClusters(pages || [], false);
      setClustersToShow(realClusterData);
      clustersToShowFilterLoading && setClustersToShowFilterLoading(false);
      clustersToShowLoading && setClustersToShowLoading(false);
    } else {
      !clustersToShowLoading && setClustersToShowLoading(true);
      !clustersToShowFilterLoading && setClustersToShowFilterLoading(true);
    }
  }, [clustersList, isValidatingClustersList]);

  useEffect(() => {
    setClustersToShow(undefined);
    setClustersToShowLoading(true);
    setInitialLoading(true);

    setFilters({ environments: [], providers: [], versions: [] });
    sizeClustersList > 1 && setSizeClustersList(1);
    mutateClustersList();
  }, [account?.token]); //When org changes or cluster count change we'll need to refetch the data

  useEffect(() => {
    const { exampleClusterData } = getRealAndExampleClusters(
      totalClusters || [],
      false,
    );

    setExampleClustersToShow(exampleClusterData);
  }, [totalClusters]); // this is for loading total data, and setting example cluster data

  useEffect(() => {
    if (!isLoadingInitialClustersList && !isValidatingClustersList) {
      mutateInitialClustersList();
    }
    if (!isLoadingClustersList && !isValidatingClustersList) {
      mutateClustersList();
    }
    if (accountStats && localStorage.getItem('example-data') === null) {
      setShowExampleData(!accountStats?.clusters_count);
    }
  }, [accountStats?.clusters_count]); // this is for loading total data, and setting example cluster data

  const [clusterViewLayout, setClusterViewLayout] =
    useLocalStorage<ClustersViewLayoutValues>(
      'clusters_view_layout_preference',
      ClustersViewLayout.GRID,
    );

  const handleAlignment = (layout: ClustersViewLayoutValues) => {
    setClusterViewLayout(layout);
    logEvent(`clusters-${layout}-view`);
  };

  const addClusterPath = useAccountIdRoute(
    `/orgs/:orgId/accounts/:accountId/clusters/new`,
  );

  const handleOnChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: string,
  ) => {
    setSearchClusterName(newValue);
  };

  useDebounce(
    () => {
      if (searchClusterName) {
        logEvent('search-your-clusters');
      }
    },
    1000,
    [searchClusterName],
  );

  const memoizedClusters = useMemo(() => {
    if (clustersToShow === undefined) {
      return [];
    } else if (
      showExampleData
        ? !!exampleClustersToShow && !initialLoading
        : !!clustersToShow && !clustersToShowLoading
    ) {
      if (searchClusterName !== '') {
        const found = (
          showExampleData ? exampleClustersToShow : clustersToShow
        ).filter((cluster) => cluster.name?.includes(searchClusterName));
        if (found) {
          return found;
        }
      }
      clustersToShowLoading && setClustersToShowLoading(false);
      return showExampleData ? exampleClustersToShow : clustersToShow;
    } else {
      return [];
    }
  }, [
    clustersToShow,
    exampleClustersToShow,
    searchClusterName,
    showExampleData,
  ]);

  const noClustersFound =
    !clustersToShowLoading &&
    !clustersToShowFilterLoading &&
    !isLoadingInitialClustersList &&
    ((showExampleData &&
      (!exampleClustersToShow?.length ||
        (searchClusterName !== '' && !memoizedClusters?.length))) ||
      (!showExampleData && !memoizedClusters?.length));

  let noMatchingClustersFound;
  if (!isErrorClustersList && noClustersFound) {
    if (searchClusterName !== '') {
      //search result is empty
      noMatchingClustersFound = true;
    } else if (
      !showExampleData &&
      filters &&
      objectToKeyValueArray(filters).length > 0
    ) {
      //filters result is empty and is not example data (example data should not be affected by filters)
      noMatchingClustersFound = true;
    } else {
      noMatchingClustersFound = false;
    }
  } else {
    noMatchingClustersFound = false;
  }

  return (
    <Page title={'Clusters'} className="ml-8">
      <div className="container max-w-none flex h-full w-full flex-col items-start gap-[24px] bg-default-background pt-8 pr-3 pl-3">
        <Breadcrumbs>
          <Breadcrumbs.Item>Risk Ledger</Breadcrumbs.Item>
          <Breadcrumbs.Divider />
          <Breadcrumbs.Item active={true}>Clusters</Breadcrumbs.Item>
        </Breadcrumbs>
        <div className="flex w-full items-center justify-between">
          <CountHeader
            accountStats={accountStats}
            isLoadingAccountStats={isLoadingAccountStats}
            totalClusters={
              showExampleData ? exampleClustersToShow : totalClusters
            }
            isLoadingTotalClusters={
              showExampleData ? isLoadingClustersList : initialLoading
            }
            showExampleData={showExampleData}
          />
          <div className="flex w-44 flex-none flex-col items-end justify-center gap-4">
            <RouterLink to={addClusterPath}>
              <Button
                disabled={false}
                size="large"
                icon="FeatherPlus"
                id="add-cluster-button"
              >
                Add Cluster
              </Button>
            </RouterLink>
            <div className="flex w-full items-center justify-between">
              <div className="flex w-full grow shrink-0 basis-0 items-center justify-end gap-2">
                <span className="text-body font-body text-subtext-color">
                  Show example data
                </span>
                <AtomicTooltip
                  tooltipContent={
                    ClusterViewsTooltip[showExampleData ? 'EXAMPLE' : 'REAL']
                  }
                  data-cy="example-data-tooltip"
                >
                  <Switch
                    data-cy="switch"
                    checked={showExampleData}
                    onClick={(event) => {
                      setShowExampleData(!showExampleData);
                      handleOnChange(event, '');
                    }}
                  />
                </AtomicTooltip>
              </div>
            </div>
          </div>
        </div>
        {showExampleData && exampleClustersToShow.length ? (
          <Alert
            variant="warning"
            title="You are currently viewing example data"
            description={`To view your onboarded clusters, toggle the "Show example data" button`}
          />
        ) : (
          <></>
        )}
        {!errorInitialClustersList &&
          !isErrorClustersList &&
          noClustersFound &&
          !noMatchingClustersFound && (
            <>
              {showExampleData && (
                <>
                  <Alert
                    variant="warning"
                    title="No Example Clusters to show"
                    description={
                      <>
                        <RouterLink
                          className="hoverable-link text-label font-label"
                          to={useAccountIdRoute(
                            `/orgs/:orgId/accounts/:accountId/settings?page=clusters-tab&clusters=deactivated`,
                          )}
                        >
                          Re-activate Example Cluster
                        </RouterLink>
                      </>
                    }
                    style={{ marginTop: '8px' }}
                  />
                  <div className="flex h-36 w-full flex-none flex-col items-center justify-center gap-2 pt-6 pr-6 pb-6 pl-6">
                    <span className="text-body font-body text-default-font">
                      No example clusters found
                    </span>
                  </div>
                </>
              )}
              {!showExampleData &&
                !(filters && objectToKeyValueArray(filters).length > 0) && (
                  <div className="flex w-full items-center gap-2">
                    <span className="text-body font-body text-default-font">
                      No clusters onboarded so far. Click here to onboard your
                      first cluster
                    </span>
                    <RouterLink to={addClusterPath}>
                      <Button
                        size="small"
                        icon="FeatherPlus"
                        data-cy="add-cluster"
                      >
                        Add Cluster
                      </Button>
                    </RouterLink>
                  </div>
                )}
            </>
          )}
        {errorInitialClustersList ||
          (isErrorClustersList && (
            <Centered>
              <MessageBox mode={'error'} message={'Error fetching clusters'} />
            </Centered>
          ))}

        <RiskLedgerActionHeader
          accountStats={accountStats}
          isLoadingAccountStats={isLoadingAccountStats}
          clusters={memoizedClusters}
          isLoadingClusters={clustersToShowLoading} //isLoadingClustersList}
          showExampleData={showExampleData}
          setFilters={setFilters}
          filters={filters}
          handleOnChange={handleOnChange}
          mutateClusters={mutateClustersList}
          clusterViewLayout={clusterViewLayout || ClustersViewLayout.LIST}
          handleAlignment={handleAlignment}
          isTotalClustersLoading={initialLoading}
          clustersToShowFilterLoading={clustersToShowFilterLoading}
          noClustersFound={
            noClustersFound &&
            !(filters && objectToKeyValueArray(filters).length > 0)
          }
          noMatchingClustersFound={noMatchingClustersFound}
        />
        {noMatchingClustersFound && (
          <div className="flex h-36 w-full flex-none flex-col items-center justify-center gap-2 pt-6 pr-6 pb-6 pl-6">
            <span className="text-body font-body text-default-font">
              No matching clusters found
            </span>
          </div>
        )}
      </div>
    </Page>
  );
}

export default ClustersView;
