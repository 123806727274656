import { useListClusters } from 'api/frontend';
import { ListClustersResponse } from 'api/models';
import { CLUSTERS } from 'src/data/version_register_cp';

export function useClusterIdToNameMap({
  token,
  useExampleData,
}: {
  token: string;
  useExampleData: boolean;
}): Map<string, string> {
  const { data: clusterData } = useListClusters(
    {},
    {
      request: { headers: { Authorization: `Bearer ${token}` } },
    },
  );

  return new Map(
    (useExampleData
      ? CLUSTERS
      : (clusterData as ListClustersResponse)?.data || []
    )
      .filter((c) => c.internal_k8s_ref !== 'example-cluster-ref')
      .map((c) => [c.id, c.name || '']),
  );
}
