import React, { useState } from 'react';

// components
import Page from 'components/Page';

import TeamsSetting from './TeamsSetting';
import ClustersSetting from './ClustersSetting';
import { useSearchParam } from 'react-use';
import { Breadcrumbs } from 'subframe/index';
import { TabsWithContent } from 'components/design-system/Tabs';
import SecuritySetting from 'pages/Settings/Security';
import AuditLog from 'pages/Settings/AuditLog';
import Tokens from './tokens';
import { useGate } from 'statsig-react';

export type SettingsTabs = 'teams-tab' | 'clusters-tab' | 'security-tab';

interface SettingsProps {}
const Settings: React.FC<SettingsProps> = () => {
  const currentTabQS = useSearchParam('page') as SettingsTabs;
  const clustersSettingsView = useSearchParam('clusters');
  const securitySettingsView = useSearchParam('security');
  const showAuditLogTab = useGate('show_audit_log_view');

  const [currentTab, setCurrentTab] = useState(currentTabQS || 'teams-tab');

  const SETTINGS_TABS = [
    {
      id: 'teams-tab',
      title: 'Teams',
      icon: 'akar-icons:people-group',
      content: <TeamsSetting />,
    },
    {
      id: 'clusters-tab',
      title: 'Clusters',
      icon: 'ri:home-3-line',
      content: (
        <ClustersSetting
          view={clustersSettingsView ? clustersSettingsView : 'active'}
        />
      ),
    },
    {
      id: 'security-tab',
      title: 'Security',
      icon: 'ri:home-3-line',
      content: <SecuritySetting view={'okta'} />,
    },
    {
      id: 'token-tab',
      title: 'Tokens',
      content: <Tokens />,
    },
  ];

  if (showAuditLogTab?.value) {
    SETTINGS_TABS.push({
      id: 'audit-log-tab',
      title: 'Audit Logs',
      icon: 'ri:home-3-line',
      content: <AuditLog view={'audit-log'} />,
    });
  }

  return (
    <Page title="Settings">
      <div className="flex h-full w-[calc(100%-64px)] flex-col items-start gap-6 pt-8 pb-0 px-3 bg-default-background ml-8">
        <div className="flex w-full flex-col items-start gap-6">
          <Breadcrumbs>
            <Breadcrumbs.Item>Configure</Breadcrumbs.Item>
            <Breadcrumbs.Divider />
            <Breadcrumbs.Item active={true}>Settings</Breadcrumbs.Item>
          </Breadcrumbs>
          <div className="flex w-full flex-col items-start gap-6">
            <TabsWithContent
              initialTabId={currentTab || undefined}
              onTabChange={(newTabId) => {
                setCurrentTab(newTabId as SettingsTabs);
                const url = new URL(`${window.location}`);
                url.searchParams.set('page', newTabId);
                window.history.pushState(null, '', url.toString());
              }}
              tabs={SETTINGS_TABS}
            />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Settings;
