// components
import Page from '../components/Page';
import { useState } from 'react';
import { UpdateHelmK8sConnector } from 'components/UpdateClusterAgent/UpdateHelmK8sConnector';
import { useGetCluster } from 'api/frontend';
import { Container, Stack } from 'components/utils';
import styles from './UpdateK8sConnector.module.scss';
import { Text } from '@subframe/core';
import { LearnMoreAccordion } from 'subframe/index';
import useUserAccountState from 'hooks/useUserAccountState';
import { Divider } from 'components/design-system';
import { UpdateK8sConnectorBreadcrumbsHeader } from 'components/BreadCrumbsHeaders';
import { useParams } from 'react-router-dom';
import { Alert } from 'subframe/index';
import { ChkkOperatorInfo } from './add_cluster/tabs/ChkkOperatorInfo';
// ----------------------------------------------------------------------

export default function UpdateK8sConnector() {
  const { account } = useUserAccountState();
  const [showInfo, setShowInfo] = useState(false);

  const { clusterId } = useParams<{
    clusterId: string;
  }>();

  const {
    data: cluster,
    error: clusterError,
    mutate: clusterMutate,
  } = useGetCluster(clusterId!, {
    request: { headers: { authorization: 'Bearer ' + account?.token } },
    swr: { refreshInterval: 5 * 1000 },
  });
  return (
    <Page id="update-K8s-connector-page" title="Update K8s Connector">
      <Container className={styles['page-contents']}>
        <UpdateK8sConnectorBreadcrumbsHeader
          clusterId={clusterId!}
          clusterName={cluster?.name!}
        />
        <Stack className="mt-4" />
        <Stack>
          <Alert
            variant="warning"
            title="K8s Connector can now be deployed using Chkk Operator only"
            description={`By using the Operator, you can use a single Custom Resource
            Definition (CRD) to deploy the Chkk K8s Connector and its
            configurations. You will need to remove the existing Chkk K8s
            Connector deployment and install the Chkk Operator to deploy
            the updated Chkk K8s Connector.`}
          />

          <LearnMoreAccordion
            title={'Learn more about Chkk Operator'}
            onClick={() => setShowInfo(!showInfo)}
            data-cy="chkk-operator-info-button"
          >
            <ChkkOperatorInfo />
          </LearnMoreAccordion>
          <Divider variant="neutral" className={styles['divider']} />
          <UpdateHelmK8sConnector />
        </Stack>
      </Container>
    </Page>
  );
}
