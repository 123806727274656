// import { useEffect, useState, useMemo } from 'react';
import { Box, Grid, Stack, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { format } from 'date-fns';

import Page from 'components/Page';
import RHFTextField from 'components/RHFTextField';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { useSnackbar } from 'notistack';

import * as Sentry from '@sentry/browser';
import TokenService from '@services/TokenService';
import { updateOrganization } from 'api/frontend';
import FormProvider from 'components/FormProvider';
import { Spacer } from 'components/Spacer';
import { somethingWentWrong, toastAutoHideDuration } from 'constants/toasts';
import useDialog from 'hooks/useDialog';
import useUserAccountState from 'hooks/useUserAccountState';
import { Button } from 'subframe/index';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';

interface OrganizationsToTeamFields {
  name: string;
  slug: string;
}

const Card = ({
  title,
  subtitle,
  isLast,
  value,
  name,
}: {
  title: string;
  subtitle: string;
  value?: string;
  name?: string;
  isLast?: boolean;
}) => {
  const theme = useTheme();

  return (
    <Grid
      container
      justifyContent={'space-between'}
      alignItems="center"
      sx={{
        borderBottom: isLast ? '' : `1px solid ${theme.palette.grey[500]}`,
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(4),
      }}
    >
      <Stack>
        <Typography sx={{ paddingBottom: theme.spacing(0.5) }} variant="h3">
          {title}
        </Typography>
        <Typography sx={{ color: theme.palette.grey[600] }} variant="body2">
          {subtitle}
        </Typography>
      </Stack>
      {name ? (
        <RHFTextField
          sx={{ width: '60%' }}
          variant="outlined"
          defaultValue={value}
          className="ph-no-capture"
          name={name}
        />
      ) : (
        <TextField
          sx={{ width: '60%' }}
          variant="outlined"
          defaultValue={value}
          disabled={true}
        />
      )}
    </Grid>
  );
};

export default function OrganizationSettings() {
  const { account, currentOrganization } = useUserAccountState();
  const { refreshOrganizations } = TokenService();

  const theme = useTheme();
  const { setDialogType } = useDialog();
  const { logEvent } = AnalyticsEventLogger();
  const { enqueueSnackbar } = useSnackbar();
  const OrganizationToTeamSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    slug: Yup.string().required('Slug is required'),
  });

  const defaultValues: OrganizationsToTeamFields = {
    name: currentOrganization?.name || '',
    slug: currentOrganization?.slug || '',
  };

  const methods = useForm<OrganizationsToTeamFields>({
    resolver: yupResolver(OrganizationToTeamSchema),
    defaultValues: defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: OrganizationsToTeamFields) => {
    logEvent('organization-settings-save-changes', data);
    if (!currentOrganization?.slug) {
      enqueueSnackbar(
        somethingWentWrong.replace('<action>', 'updating this organization'),
        {
          variant: 'error',
          autoHideDuration: toastAutoHideDuration,
        },
      );
      Sentry.captureException(
        somethingWentWrong.replace('<action>', 'updating this organization'),
        { level: 'log' },
      );
      return;
    }
    try {
      const reqBody = {
        name: data.name,
        slug: data.slug,
      };
      const headers = {
        Authorization: `Bearer ${account?.token}`,
      };

      await updateOrganization(currentOrganization?.slug || '', reqBody, {
        headers,
      });
      await refreshOrganizations();
      enqueueSnackbar('Organization name updated successfully', {
        variant: 'success',
        autoHideDuration: toastAutoHideDuration,
      });
    } catch (error) {
      enqueueSnackbar(
        somethingWentWrong.replace('<action>', 'updating this organization'),
        {
          variant: 'error',
          autoHideDuration: toastAutoHideDuration,
        },
      );
      Sentry.captureException(error, { level: 'log' });
    }
  };

  return (
    <Page id="organization-settings" title="Organization Settings">
      <Stack sx={{ height: '100%', padding: theme.spacing(4) }}>
        <Box
          sx={{
            borderBottom: `1px solid ${theme.palette.grey[800]}`,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingBottom: 2,
            marginBottom: 4,
          }}
        >
          <Typography variant="h1">Organization Settings</Typography>
        </Box>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack
            sx={{
              border: `1px solid ${theme.palette.grey[500]}`,
              borderRadius: '6px',
              padding: theme.spacing(0, 4),
            }}
          >
            <Card
              title={'Organization Slug'}
              subtitle={'A unique ID used to identify this organization'}
              value={currentOrganization?.slug}
            />

            <Card
              title={'Display Name'}
              subtitle={'A human-friendly name for the organization'}
              name="name"
            />
            <Card
              isLast
              title={'Created Time'}
              subtitle={'When was the organization created?'}
              value={
                currentOrganization?.created
                  ? format(currentOrganization?.created * 1000, 'MMMM do, yyyy')
                  : ''
              }
            />
          </Stack>
          <Spacer value={24} />
          <Button
            type="submit"
            size="large"
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Save changes
          </Button>
        </FormProvider>
      </Stack>
    </Page>
  );
}
