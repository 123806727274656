import {
  useGetUpgradeTemplate,
  useListUpgradeTemplateStages,
} from 'api/frontend';
import { Breadcrumbs } from 'subframe/index';
import { ConsoleLoader } from 'components/Loader';
import Page from 'components/Page';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import { useNavigate, useParams } from 'react-router';
import { UpgradesViewComponent } from '../UpgradesView';
import styles from '../UpgradesView.module.scss';
import { RouterLink } from 'components/RouterLink';
import useUserAccountState from 'hooks/useUserAccountState';
import { useSnackbar } from 'notistack';
import { toastAutoHideDuration } from 'constants/toasts';
import { useSWRConfig } from 'swr';
import { UPGRADE_STAGES, UPGRADE_TEMPLATES } from 'src/data/upgrades';
import useLocalStorage from 'hooks/useLocalStorage';

export default function UpgradesTemplateView() {
  const { templateId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const { account } = useUserAccountState();
  const basePath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/upgrades/templates',
  );
  const navigate = useNavigate();
  const { mutate: globalMutate } = useSWRConfig();

  // Check if the currently open Upgrade Template is an example or real
  const exampleData =
    templateId && UPGRADE_TEMPLATES.map((upgr) => upgr.id).includes(templateId)
      ? true
      : false;
  const [showExampleData, setShowExampleData] = useLocalStorage<boolean>(
    'example-data',
    true,
  );
  // update view in localStorage if it doesn't match the currently open Upgrade Template
  if (exampleData !== showExampleData) {
    setShowExampleData(exampleData);
  }

  const {
    data: upgradeTemplateData,
    error: upgradeTemplateError,
    mutate: mutateUpgradeTemplate,
  } = useGetUpgradeTemplate(templateId || '', {
    request: { headers: { Authorization: `Bearer ${account.token}` } },
    swr: {
      enabled: !exampleData,
    },
  });
  const { data: upgradeTemplateStagesData, error: upgradeTemplateStagesError } =
    useListUpgradeTemplateStages(
      templateId || '',
      { page_size: 100 },
      {
        request: { headers: { Authorization: `Bearer ${account.token}` } },
        swr: {
          enabled: !exampleData,
        },
      },
    );
  //redirect to the upgrade templates page if there is an error in displaying stage data
  if (upgradeTemplateData && upgradeTemplateStagesError) {
    navigate(basePath);
    if (
      upgradeTemplateStagesError?.response?.status === 404 &&
      upgradeTemplateData?.status === 'cancelled'
    ) {
      enqueueSnackbar('No data available for the cancelled Upgrade Template', {
        variant: 'warning',
        autoHideDuration: toastAutoHideDuration,
      });
    } else if (
      upgradeTemplateStagesError?.response?.status === 400 &&
      upgradeTemplateData?.status === 'pending'
    ) {
      enqueueSnackbar(
        "This Upgrade Template is being generated. You'll be notified when it's published",
        {
          variant: 'warning',
          autoHideDuration: toastAutoHideDuration,
        },
      );
    } else {
      enqueueSnackbar('No data available for the Upgrade Template', {
        variant: 'error',
        autoHideDuration: toastAutoHideDuration,
      });
    }
  }

  const upgradeTemplate = exampleData
    ? UPGRADE_TEMPLATES.find((u) => u.id === templateId)
    : upgradeTemplateData;
  const upgradeTemplateStages = exampleData
    ? UPGRADE_STAGES.get(templateId || '')
    : upgradeTemplateStagesData?.data;

  if (upgradeTemplate === undefined || upgradeTemplateStages === undefined) {
    return (
      <Page title={'Upgrade Template Details'}>
        <div
          className={styles['pageContents']}
          style={{ marginLeft: '32px', width: 'calc(100% - 64px)' }}
        >
          <Breadcrumbs>
            <Breadcrumbs.Item>Upgrade Copilot</Breadcrumbs.Item>
            <Breadcrumbs.Divider name="FeatherChevronRight" />
            <RouterLink to={basePath}>
              <Breadcrumbs.Item>Upgrades Templates</Breadcrumbs.Item>
            </RouterLink>
            <Breadcrumbs.Divider name="FeatherChevronRight" />
            <Breadcrumbs.Item active={true}>
              {upgradeTemplate?.name || templateId}
            </Breadcrumbs.Item>
          </Breadcrumbs>
          <ConsoleLoader />
        </div>
      </Page>
    );
  } else if (
    !exampleData &&
    (upgradeTemplateError !== undefined ||
      upgradeTemplateStagesError !== undefined)
  ) {
    return (
      <Page title={'Upgrade Template Details'}>
        <div
          className={styles['pageContents']}
          style={{ marginLeft: '32px', width: 'calc(100% - 32px)' }}
        >
          <Breadcrumbs>
            <Breadcrumbs.Item>Upgrade Copilot</Breadcrumbs.Item>
            <Breadcrumbs.Divider name="FeatherChevronRight" />
            <RouterLink to={basePath}>
              <Breadcrumbs.Item>Upgrade Templates</Breadcrumbs.Item>
            </RouterLink>
            <Breadcrumbs.Divider name="FeatherChevronRight" />
            <Breadcrumbs.Item active={true}>
              {upgradeTemplate?.name || templateId}
            </Breadcrumbs.Item>
          </Breadcrumbs>
          <>Upgrade Template ID not found</>
        </div>
      </Page>
    );
  } else {
    return (
      <UpgradesViewComponent
        isTemplate={true}
        account={account}
        upgradePlan={upgradeTemplate}
        stages={upgradeTemplateStages}
        navigate={navigate}
        basePath={basePath}
        breadcrumb={'Upgrade Templates'}
        onChange={(mutateStage = true, mutatePlan = false) => {
          mutatePlan && mutateUpgradeTemplate();
          mutateStage &&
            globalMutate((key) => {
              if (Array.isArray(key)) {
                if (
                  (key[0] as string).startsWith(
                    `/upgrade_templates/${upgradeTemplate.id}/`,
                  )
                ) {
                  return true;
                }
              }
              return false;
            });
        }}
      />
    );
  }
}
