import {
  ClusterDetectedLarAffectedResource,
  LarAffectedResource,
} from 'api/models';
import { useCallback, useMemo, useState } from 'react';

type SortOrder = 'asc' | 'desc';
type ColumnName = 'kind' | 'namespace' | 'name';

type TableData = LarAffectedResource[] | ClusterDetectedLarAffectedResource[];

export default function useAffectedResourcesTableSort(
  data: TableData,
  order: SortOrder,
  orderByColumnName: ColumnName,
) {
  if (!data)
    return {
      sortOrder: () => {},
      orderBy: null,
      handleRequestSort: () => {},
      sortedData: null,
      setSortOrder: () => {},
      loadingSort: true,
    };
  const [sortOrder, setSortOrder] = useState<SortOrder>(order);
  const [orderBy, setOrderBy] = useState<ColumnName>(orderByColumnName);

  const handleRequestSort = (property: ColumnName) => {
    const isAsc = orderBy === property && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const namesComparator = useCallback(
    (
      a: LarAffectedResource | ClusterDetectedLarAffectedResource,
      b: LarAffectedResource | ClusterDetectedLarAffectedResource,
    ) => {
      if (sortOrder === 'asc') {
        return a.name.localeCompare(b.name);
      } else {
        return b.name.localeCompare(a.name);
      }
    },
    [sortOrder],
  );

  const namespacesComparator = useCallback(
    (
      a: LarAffectedResource | ClusterDetectedLarAffectedResource,
      b: LarAffectedResource | ClusterDetectedLarAffectedResource,
    ) => {
      if (sortOrder === 'asc') {
        return a.namespace.localeCompare(b.namespace);
      } else {
        return b.namespace.localeCompare(a.namespace);
      }
    },
    [sortOrder],
  );

  const getComparator = useCallback(
    (order: SortOrder, orderBy: ColumnName) => {
      switch (orderBy) {
        case 'name':
          return namesComparator;
        case 'namespace':
          return namespacesComparator;
        default:
          return namesComparator;
      }
    },
    [namesComparator, namespacesComparator],
  );

  const stableSort = (
    array: LarAffectedResource[] | ClusterDetectedLarAffectedResource[],
    comparator: (
      a: LarAffectedResource | ClusterDetectedLarAffectedResource,
      b: LarAffectedResource | ClusterDetectedLarAffectedResource,
    ) => number,
  ): (LarAffectedResource | ClusterDetectedLarAffectedResource | number)[] => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort(
      (
        a: (
          | LarAffectedResource
          | ClusterDetectedLarAffectedResource
          | number
        )[],
        b: (
          | LarAffectedResource
          | ClusterDetectedLarAffectedResource
          | number
        )[],
      ) => {
        const order = comparator(a[0] as any, b[0] as any);
        if (order !== 0) {
          return order;
        }
        return 0;
      },
    );
    return stabilizedThis.map((el) => el[0]);
  };

  const sortedData = useMemo(() => {
    const comparator = getComparator(sortOrder, orderBy);
    return stableSort(data, comparator);
  }, [data, getComparator, sortOrder, orderBy]);

  return {
    sortOrder,
    orderBy,
    handleRequestSort,
    sortedData,
    setSortOrder,
  };
}
