import {
  formatDistanceToNow,
  fromUnixTime,
  format,
  addMinutes,
} from 'date-fns';

export const getTimeRemaining = (timestamp: number | undefined) => {
  const dateNow = new Date(Date.now());
  const deprecationDate = new Date(timestamp ? timestamp * 1000 : 0);
  const date_formatted = format(
    addMinutes(deprecationDate, deprecationDate.getTimezoneOffset()),
    'LLL yyyy',
  );

  const daysDiff = () => {
    let diff = deprecationDate.getTime() - dateNow.getTime();
    diff = diff / (1000 * 3600 * 24);
    return diff;
  };
  const timeRemaining =
    !timestamp || timestamp === 0
      ? '-'
      : formatDistanceToNow(fromUnixTime(timestamp), {
          addSuffix: true,
        });

  const eosMessage = (
    version: string,
    support_level_name: string,
    showInstructions = false,
  ) => {
    let message = [
      `${version} ${
        daysDiff() > 0 ? 'will reach' : 'has reached'
      } end of ${support_level_name} ${timeRemaining} (${date_formatted})`,
    ];

    if (showInstructions) {
      message.push('Upgrade Now');
    }
    return message;
  };

  return {
    date_formatted,
    daysDiff,
    timeRemaining,
    eosMessage,
  };
};
