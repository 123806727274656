'use client';
import * as SubframeCore from '@subframe/core';

import styles from '../UpgradeTemplatesLandingPage.module.scss';

import {
  Alert,
  Badge,
  Breadcrumbs,
  Button,
  InformationalHeader,
  InfoTooltip,
  Switch,
  Table,
} from 'subframe/index';
import Page from 'components/Page';
import {
  updateUpgradeTemplate,
  useListClusters,
  useListSubscriptions,
  useListUpgradeTemplates,
} from 'api/frontend';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { useSnackbar } from 'notistack';
import useUserAccountState from 'hooks/useUserAccountState';
import {
  ListClustersResponse,
  ResolvedEntitlementFeaturesItem,
} from 'api/models';
import { ConsoleLoader } from 'components/Loader';
import { getHumanReadableDate } from 'utils/subscription_plan';
import BaseTable from 'components/design-system/Table/BaseTable';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import { AtomicTooltip } from 'components/design-system';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import { RouterLink } from 'components/RouterLink';
import {
  getTooltipForUpgradeRequestedBy,
  showCancel,
  UpgradeTemplateStatusBadge,
} from '../helpers/UpgradesHelpers';
import { ChangeNameDialog } from '../helpers/ChangeNameDialog';
import { somethingWentWrong, toastAutoHideDuration } from 'constants/toasts';
import UpgradesCancelDialog from '../helpers/CancelEntryDialog';
import { LockingWrapper } from 'components/LockingWrapper';
import useLocalStorage from 'hooks/useLocalStorage';
import { UPGRADE_TEMPLATES } from 'src/data/upgrades';
import { Searchbar } from 'components/design-system/Searchbar';
import classNames from 'classnames';
import { CommentTooltip } from 'components/design-system/CommentTooltip';
import MarkUpgradeTemplateAsEnvironmentUpgraded from '../components/MarkUpgradeTemplateAsEnvironmentUpgraded';

function UpgradeTemplatesLandingPage() {
  const { logEvent } = AnalyticsEventLogger();
  const { enqueueSnackbar } = useSnackbar();
  const { account, currentOrganization } = useUserAccountState();
  const [showExampleData, setShowExampleData] = useLocalStorage<boolean>(
    'example-data',
    true,
  );

  const upgradesTemplatesBasePath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/upgrades/templates',
  );
  const clustersUpgradesBasePath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/upgrades/clusters',
  );
  const clustersBasePath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/clusters',
  );

  useEffect(() => {
    logEvent('upgrades-clusters-page-view', {
      showExampleData,
    });
  }, [showExampleData]);

  //Subscriptions
  const { data: Subscriptions } = useListSubscriptions(
    currentOrganization?.slug,
    {},
    {
      request: { headers: { Authorization: `Bearer ${account?.token}` } },
    },
  );

  // Clusters
  const { data: clusters } = useListClusters(
    { filter: ['status:active'] },
    {
      request: {
        headers: { Authorization: `Bearer ${account.token}` },
      },
    },
  );

  const realClusters =
    clusters === undefined
      ? []
      : ((clusters as ListClustersResponse).data || []).filter(
          (c) => c.internal_k8s_ref !== 'example-cluster-ref',
        );

  useEffect(() => {
    if (clusters && localStorage.getItem('example-data') === null) {
      setShowExampleData(!realClusters.length);
    }
  }, [clusters]);

  // Upgrade Templates
  const { data: upgradeTemplatesData, mutate } = useListUpgradeTemplates(
    {},
    {
      request: {
        headers: { Authorization: `Bearer ${account.token}` },
      },
    },
  );
  const exampleTemplates = UPGRADE_TEMPLATES;
  const upgradeTemplates = showExampleData
    ? exampleTemplates
    : upgradeTemplatesData
    ? upgradeTemplatesData.data.sort((a, b) => {
        return a.created > b.created ? -1 : 1; // Latest requested Upgrade Template should appear on top
      })
    : [];
  const [searchTemplate, setSearchTemplate] = useState('');
  const [templatesToShow, setTemplatesToShow] = useState(upgradeTemplates);
  const handleOnChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: string,
  ) => {
    setSearchTemplate(newValue);
  };
  useDebounce(
    () => {
      if (searchTemplate) {
        logEvent('search-upgrade-templates', { searchTemplate });
      }
    },
    1000,
    [searchTemplate],
  );
  useEffect(() => {
    if (searchTemplate !== '') {
      const found = upgradeTemplates.filter((item) =>
        item?.name?.toLowerCase().includes(searchTemplate.toLowerCase()),
      );
      setTemplatesToShow(found);
    } else {
      setTemplatesToShow(upgradeTemplates);
    }
  }, [upgradeTemplatesData, searchTemplate, showExampleData]);

  const showCreateExampleTemplateToast = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    const exampleTemplateCreateToast =
      'No new Upgrade Templates can be created from example data';
    event.stopPropagation();
    event.preventDefault();
    enqueueSnackbar(exampleTemplateCreateToast, {
      variant: 'warning',
      autoHideDuration: toastAutoHideDuration,
    });
  };

  const showCreateExamplePlanToast = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    const examplePlanCreateToast =
      'No new Upgrade Plans can be instantiated from example data';
    event.stopPropagation();
    event.preventDefault();
    enqueueSnackbar(examplePlanCreateToast, {
      variant: 'warning',
      autoHideDuration: toastAutoHideDuration,
    });
  };
  // custom environments
  const customEnvironmentTemplates = upgradeTemplates.filter(
    (u) =>
      u.environment &&
      !['prod', 'staging', 'dev'].includes(u.environment?.toLowerCase()),
  );
  let otherEnvsTooltipData: { [key: string]: number } = {
    total: 0,
  };
  if (customEnvironmentTemplates) {
    customEnvironmentTemplates.map((template) => {
      otherEnvsTooltipData[template.environment] = otherEnvsTooltipData[
        template.environment
      ]
        ? otherEnvsTooltipData[template.environment] + 1
        : 1;

      otherEnvsTooltipData.total += 1;
    });
  }

  // by default show lock is true
  const showUpgradePlanLock = Subscriptions
    ? !Subscriptions?.total_entitlement?.features?.includes(
        'request_upgrade_plan',
      )
    : true;

  const showTemplateLock = Subscriptions
    ? !Subscriptions?.total_entitlement?.features?.includes(
        'request_upgrade_template',
      )
    : true;

  return (
    <Page title="Cluster Upgrade Templates">
      <div
        style={{ marginLeft: '32px', width: 'calc(100% - 32px' }}
        className={styles['pageContents']}
      >
        <Breadcrumbs>
          <Breadcrumbs.Item>Upgrade Copilot</Breadcrumbs.Item>
          <Breadcrumbs.Divider />
          <Breadcrumbs.Item active={true}>Upgrade Templates</Breadcrumbs.Item>
        </Breadcrumbs>
        <div className={styles['header-c674606e']}>
          <div className={styles['stack-d75a4bd4']}>
            <span className={styles['sectionHeaderText']}>
              Cluster Upgrade Templates
            </span>
            {realClusters.length ? (
              <RouterLink to={`${upgradesTemplatesBasePath}/new`}>
                <LockingWrapper
                  feature={
                    ResolvedEntitlementFeaturesItem.request_upgrade_template
                  }
                  showLock={showTemplateLock}
                  tooltipText={
                    showTemplateLock
                      ? undefined //default, handled in LockingWrapper
                      : 'Request Preverified Upgrade Template'
                  }
                >
                  <Button
                    disabled={false}
                    size="large"
                    icon="FeatherPlus"
                    iconRight={showTemplateLock ? 'FeatherLock' : null}
                  >
                    Request Preverified Upgrade Template
                  </Button>
                </LockingWrapper>
              </RouterLink>
            ) : (
              <LockingWrapper
                feature={
                  ResolvedEntitlementFeaturesItem.request_upgrade_template
                }
                tooltipText="Add a cluster to get Preverified Upgrade Template"
                showLock={showTemplateLock}
              >
                <Button
                  size="large"
                  icon="FeatherPlus"
                  disabled={true}
                  iconRight={showTemplateLock ? 'FeatherLock' : null}
                >
                  Request Preverified Upgrade Template
                </Button>
              </LockingWrapper>
            )}
          </div>
          <div className={styles['stack-0ba32313']}>
            <span className={styles['text-a9f8771b']}>
              Overview of all requested Cluster Upgrade Templates
            </span>
            <div className={styles['stack-6d118439']}>
              <span className={styles['text-2285b617']}>Show example data</span>
              <AtomicTooltip
                data-cy="show-example-data-tooltip"
                tooltipContent={
                  showExampleData
                    ? 'Show results from your onboarded clusters'
                    : 'Show example data'
                }
              >
                <Switch
                  data-cy="switch"
                  checked={showExampleData}
                  onCheckedChange={() => {
                    setShowExampleData(!showExampleData);
                    setSearchTemplate('');
                  }}
                />
              </AtomicTooltip>
            </div>
          </div>
        </div>
        {showExampleData && (
          <Alert
            variant="warning"
            title="You are currently viewing example data"
            description={`To view results from your onboarded clusters, toggle the "Show example data" button`}
          />
        )}
        {!showExampleData &&
        (clusters === undefined || upgradeTemplatesData === undefined) ? (
          <ConsoleLoader />
        ) : (
          <>
            {showExampleData ||
            realClusters.length ||
            upgradeTemplates.length ? (
              <div className={styles['stack-21aef114']}>
                <span className={styles['subheaderText']}>
                  Current Templates
                </span>

                <>
                  {upgradeTemplates.length ? (
                    <>
                      <div className={styles['selectionHeader']}>
                        <InformationalHeader className="flex-[1_0_0] h-full w-full">
                          <InformationalHeader.Item
                            title="Total"
                            headingSlot={
                              <InfoTooltip tooltipText="Total Upgrade Templates created so far" />
                            }
                            bodySlot={
                              <span className="text-body-bold font-body-bold text-default-font">
                                {upgradeTemplates.length || 0}
                              </span>
                            }
                          />
                          {upgradeTemplates.filter(
                            (u) => u.environment?.toLowerCase() === 'prod',
                          ).length ? (
                            <InformationalHeader.Item
                              title="Prod"
                              headingSlot={
                                <InfoTooltip tooltipText="Upgrade Templates for Prod clusters" />
                              }
                              bodySlot={
                                <span className="text-body-bold font-body-bold text-default-font">
                                  {
                                    upgradeTemplates.filter(
                                      (u) =>
                                        u.environment?.toLowerCase() === 'prod',
                                    ).length
                                  }
                                </span>
                              }
                            />
                          ) : (
                            <></>
                          )}

                          {upgradeTemplates.filter(
                            (u) => u.environment?.toLowerCase() === 'staging',
                          ).length ? (
                            <InformationalHeader.Item
                              title="Staging"
                              headingSlot={
                                <InfoTooltip tooltipText="Upgrade Templates for Staging clusters" />
                              }
                              bodySlot={
                                <span className="text-body-bold font-body-bold text-default-font">
                                  {
                                    upgradeTemplates.filter(
                                      (u) =>
                                        u.environment?.toLowerCase() ===
                                        'staging',
                                    ).length
                                  }
                                </span>
                              }
                            />
                          ) : (
                            <></>
                          )}
                          {upgradeTemplates.filter(
                            (u) => u.environment?.toLowerCase() === 'dev',
                          ).length ? (
                            <InformationalHeader.Item
                              title="Dev/Test"
                              headingSlot={
                                <InfoTooltip tooltipText="Upgrade Templates for Dev/test clusters" />
                              }
                              bodySlot={
                                <span className="text-body-bold font-body-bold text-default-font">
                                  {
                                    upgradeTemplates.filter(
                                      (u) =>
                                        u.environment?.toLowerCase() === 'dev',
                                    ).length
                                  }
                                </span>
                              }
                            />
                          ) : (
                            <></>
                          )}
                          {customEnvironmentTemplates.length ? (
                            <InformationalHeader.Item
                              title="Other"
                              headingSlot={
                                <SubframeCore.HoverCard.Root>
                                  <SubframeCore.HoverCard.Trigger
                                    asChild={true}
                                  >
                                    <SubframeCore.Icon
                                      className={styles['icon-94f4ea2d']}
                                      name="FeatherInfo"
                                    />
                                  </SubframeCore.HoverCard.Trigger>
                                  <SubframeCore.HoverCard.Portal>
                                    <SubframeCore.HoverCard.Content
                                      side="bottom"
                                      align="center"
                                      sideOffset={4}
                                      asChild={true}
                                      style={{
                                        zIndex: 9999,
                                      }}
                                    >
                                      <div className="flex flex-col items-start gap-1 rounded bg-default-font pt-1 pr-1 pb-1 pl-1 shadow-large">
                                        <Table
                                          header={
                                            <Table.HeaderRow>
                                              <Table.HeaderCell>
                                                Environment
                                              </Table.HeaderCell>
                                              <Table.HeaderCell>
                                                Upgrade Templates
                                              </Table.HeaderCell>
                                            </Table.HeaderRow>
                                          }
                                        >
                                          {Object.keys(
                                            otherEnvsTooltipData,
                                          ).map((key, index) => {
                                            if (index < 6 && key !== 'total') {
                                              return (
                                                <Table.Row
                                                  key={key}
                                                  className="border-t border-solid border-neutral-700"
                                                >
                                                  <Table.Cell className="h-7 grow shrink-0 basis-0">
                                                    <span className="whitespace-nowrap text-body font-body text-black">
                                                      {key}
                                                    </span>
                                                  </Table.Cell>
                                                  <Table.Cell className="h-7 grow shrink-0 basis-0">
                                                    <span className="whitespace-nowrap text-body font-body text-black">
                                                      {
                                                        otherEnvsTooltipData[
                                                          key
                                                        ]
                                                      }
                                                    </span>
                                                  </Table.Cell>
                                                </Table.Row>
                                              );
                                            } else {
                                              return <></>;
                                            }
                                          })}

                                          {Object.keys(otherEnvsTooltipData)
                                            .length > 6 ? (
                                            <Table.Row className="border-t border-solid border-neutral-700">
                                              <Table.Cell className="h-7 grow shrink-0 basis-0">
                                                <span className="whitespace-nowrap text-body font-body text-black">
                                                  ...
                                                </span>
                                              </Table.Cell>
                                              <Table.Cell className="h-7 grow shrink-0 basis-0">
                                                <span className="whitespace-nowrap text-body font-body text-black">
                                                  ...
                                                </span>
                                              </Table.Cell>
                                            </Table.Row>
                                          ) : (
                                            <></>
                                          )}
                                          <Table.Row className="border-y border-solid border-neutral-700">
                                            <Table.Cell className="h-7 grow shrink-0 basis-0">
                                              <span className="whitespace-nowrap text-body-bold font-body-bold text-black">
                                                Total
                                              </span>
                                            </Table.Cell>
                                            <Table.Cell className="h-7 grow shrink-0 basis-0">
                                              <span className="whitespace-nowrap text-body-bold font-body-bold text-black">
                                                {otherEnvsTooltipData.total}
                                              </span>
                                            </Table.Cell>
                                          </Table.Row>
                                        </Table>
                                      </div>
                                    </SubframeCore.HoverCard.Content>
                                  </SubframeCore.HoverCard.Portal>
                                </SubframeCore.HoverCard.Root>
                              }
                              bodySlot={
                                <span className={styles['bodyBoldText']}>
                                  {otherEnvsTooltipData.total}
                                </span>
                              }
                            />
                          ) : (
                            <></>
                          )}
                        </InformationalHeader>

                        <Searchbar
                          onChange={handleOnChange}
                          view={showExampleData ? 'example' : 'real'}
                        />
                      </div>
                      <div className={styles['stack-92c2e65f']}>
                        <BaseTable
                          settings={{
                            localStorageKey: 'clusters_upgrade_templates_table',
                            dataCyPrefix: 'upgrades-templates-table',
                          }}
                          data={templatesToShow}
                          noMatchingData={Boolean(
                            upgradeTemplates.length && !templatesToShow.length,
                          )}
                          rowLink={(template) =>
                            showExampleData &&
                            template.id !==
                              'upgtmpl_ef7aff6e-aa2d-11ee-a506-0242ac120002'
                              ? ''
                              : `${upgradesTemplatesBasePath}/${template.id}`
                          }
                          rowSettings={{
                            rowTooltip: (template) =>
                              template.status === 'pending'
                                ? UpgradeTemplateStatusBadge[template.status]
                                    .tooltip || ''
                                : '',
                            preventClick: (template) =>
                              template.status === 'pending' ||
                              (showExampleData &&
                                template.id !==
                                  'upgtmpl_ef7aff6e-aa2d-11ee-a506-0242ac120002'),
                            preventClickToastMessage: showExampleData
                              ? 'This is a demonstration view only at this point - only some Upgrade Templates are available'
                              : "This Upgrade Template is being generated. You'll be notified when it's published",
                          }}
                          columns={[
                            {
                              id: 'template',
                              title: 'Template',
                              cellStyle: {
                                width: '30%',
                                height: '90px',
                                flex: '0 0 auto',
                              },
                              cellType: 'cell',
                              render: (template) => {
                                return (
                                  <div className="flex items-start gap-2 ">
                                    <div className={styles['stack-8e849415']}>
                                      <AtomicTooltip
                                        tooltipContent={template.name}
                                      >
                                        <div className="truncate-ellipsis ">
                                          <span
                                            className={styles['bodyBoldText']}
                                          >
                                            {template.name}
                                          </span>
                                        </div>
                                      </AtomicTooltip>
                                      {template.current_version ? (
                                        <span
                                          className={styles['text-0d750d04']}
                                        >
                                          From: {template.current_version}
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                      {template.desired_version ? (
                                        <span
                                          className={styles['text-c3480cc5']}
                                        >
                                          To: {template.desired_version}
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    {template.status === 'completed' &&
                                      template.status_comment && (
                                        <CommentTooltip
                                          userName={
                                            template?.completed_by?.name
                                          }
                                          picture={
                                            template?.completed_by?.picture
                                          }
                                          time={
                                            template?.updated
                                              ? template?.updated / 1000
                                              : undefined
                                          }
                                          comment={template?.status_comment}
                                        />
                                      )}
                                  </div>
                                );
                              },
                            },
                            {
                              id: 'status',
                              title: 'Status',
                              cellType: 'cell',
                              cellStyle: {
                                width: '10%',
                              },
                              render: (template) => {
                                return (
                                  <AtomicTooltip
                                    tooltipContent={
                                      UpgradeTemplateStatusBadge[
                                        template.status
                                      ].tooltip ||
                                      UpgradeTemplateStatusBadge[
                                        template.status
                                      ].title
                                    }
                                  >
                                    <Badge
                                      variant={
                                        UpgradeTemplateStatusBadge[
                                          template.status
                                        ].variant
                                      }
                                      style={{
                                        maxWidth: '100px',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                      }}
                                    >
                                      {
                                        UpgradeTemplateStatusBadge[
                                          template.status
                                        ].title
                                      }
                                    </Badge>
                                  </AtomicTooltip>
                                );
                              },
                            },
                            {
                              id: 'environment',
                              title: 'Environment',
                              cellType: 'cell',
                              cellStyle: {
                                width: '10%',
                              },
                              render: (template) => {
                                return (
                                  <AtomicTooltip
                                    tooltipContent={template.environment}
                                  >
                                    <Badge
                                      variant="neutral"
                                      style={{
                                        maxWidth: '100px',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                      }}
                                    >
                                      {template.environment}
                                    </Badge>
                                  </AtomicTooltip>
                                );
                              },
                            },
                            {
                              id: 'preverified',
                              title: 'Preverified on',
                              cellType: 'cell',
                              cellStyle: {
                                width: '5%',
                              },
                              render: (template) => {
                                return (
                                  <span className={styles['text-3e2c7813']}>
                                    {template.last_validated
                                      ? `${getHumanReadableDate(
                                          template.last_validated,
                                        )}`
                                      : ' '}
                                  </span>
                                );
                              },
                            },
                            {
                              id: 'updated',
                              title: 'Last Updated',
                              cellType: 'cell',
                              cellStyle: {
                                width: '5%',
                              },
                              render: (template) => {
                                return (
                                  <span className={styles['text-3e2c7813']}>
                                    {template.last_validated
                                      ? getHumanReadableDate(
                                          template.updated
                                            ? template.updated
                                            : template.last_validated,
                                        )
                                      : ''}
                                  </span>
                                );
                              },
                            },
                            {
                              id: 'requested-by',
                              title: 'Requested by',
                              cellType: 'cell',
                              cellStyle: {
                                width: '10%',
                              },
                              render: (template) => {
                                return (
                                  <AtomicTooltip
                                    className="ph-no-capture"
                                    tooltipContent={getTooltipForUpgradeRequestedBy(
                                      template.requested_by?.name,
                                      template.created,
                                      true,
                                    )}
                                  >
                                    <span
                                      className={classNames(
                                        styles['bodyText'],
                                        'ph-no-capture',
                                      )}
                                    >
                                      {template.requested_by?.name}
                                    </span>
                                  </AtomicTooltip>
                                );
                              },
                            },
                          ]}
                          actions={[
                            {
                              id: 'actions',
                              render: (template) => {
                                return (
                                  <div
                                    className={styles['stack-f5e45396']}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                    }}
                                    onContextMenu={(e) => {
                                      e.preventDefault();
                                    }}
                                  >
                                    {[
                                      'in-progress',
                                      'outdated',
                                      'update-in-progress',
                                    ].includes(template.status) && (
                                      <RouterLink
                                        to={`${clustersUpgradesBasePath}/new?template_id=${template.id}`}
                                      >
                                        <LockingWrapper
                                          feature={
                                            ResolvedEntitlementFeaturesItem.request_upgrade_plan
                                          }
                                          tooltipText={
                                            showUpgradePlanLock
                                              ? ''
                                              : 'Instantiate Upgrade Plan'
                                          }
                                          showLock={showUpgradePlanLock}
                                          showExampleData={showExampleData}
                                          defaultAction={(event) => {
                                            if (showExampleData) {
                                              showCreateExamplePlanToast(event);
                                            }
                                          }}
                                        >
                                          <div
                                            className={styles['stack-9871cca3']}
                                          >
                                            <Button
                                              disabled={false}
                                              variant="brand-secondary"
                                              size="medium"
                                              icon="FeatherPlus"
                                              iconRight={
                                                showUpgradePlanLock &&
                                                !showExampleData
                                                  ? 'FeatherLock'
                                                  : null
                                              }
                                            >
                                              Instantiate Plan
                                            </Button>
                                          </div>
                                        </LockingWrapper>
                                      </RouterLink>
                                    )}
                                    {template.status === 'in-progress' &&
                                      !showExampleData && (
                                        <MarkUpgradeTemplateAsEnvironmentUpgraded
                                          templateId={template.id}
                                          mutateTemplate={mutate}
                                        />
                                      )}
                                    <ChangeNameDialog
                                      isTemplate={true}
                                      name={template.name}
                                      onConfirm={async (newName) => {
                                        if (showExampleData) {
                                          enqueueSnackbar(
                                            'Example templates cannot be updated',
                                            {
                                              variant: 'warning',
                                              autoHideDuration:
                                                toastAutoHideDuration,
                                            },
                                          );
                                        } else {
                                          try {
                                            await updateUpgradeTemplate(
                                              template.id,
                                              { name: newName },
                                              {
                                                headers: {
                                                  Authorization: `Bearer ${account.token}`,
                                                },
                                              },
                                            );
                                            logEvent(
                                              'upgrades-addons-plan-name-change',
                                              {
                                                template: template.id,
                                                name: newName,
                                              },
                                            );
                                            mutate();
                                            enqueueSnackbar(
                                              'Successfully edited requested properties of the Upgrade Template',
                                              {
                                                variant: 'success',
                                                autoHideDuration:
                                                  toastAutoHideDuration,
                                              },
                                            );
                                          } catch (err) {
                                            enqueueSnackbar(
                                              somethingWentWrong.replace(
                                                '<action>',
                                                'updating requested properties of the Upgrade Template',
                                              ),
                                              {
                                                variant: 'error',
                                                autoHideDuration:
                                                  toastAutoHideDuration,
                                              },
                                            );
                                          }
                                        }
                                      }}
                                    />
                                    <UpgradesCancelDialog
                                      isTemplate={true}
                                      enabled={showCancel(template)}
                                      onConfirm={async () => {
                                        if (showExampleData) {
                                          enqueueSnackbar(
                                            'Example templates cannot be cancelled',
                                            {
                                              variant: 'warning',
                                              autoHideDuration:
                                                toastAutoHideDuration,
                                            },
                                          );
                                        } else {
                                          try {
                                            await updateUpgradeTemplate(
                                              template.id,
                                              { status: 'cancelled' },
                                              {
                                                headers: {
                                                  Authorization: `Bearer ${account.token}`,
                                                },
                                              },
                                            );
                                            logEvent(
                                              'upgrades-addons-plan-cancel',
                                              {
                                                templateId: template.id,
                                                name: template.name,
                                              },
                                            );
                                            mutate();
                                            enqueueSnackbar(
                                              'Successfully cancelled Upgrade Template',
                                              {
                                                variant: 'success',
                                                autoHideDuration:
                                                  toastAutoHideDuration,
                                              },
                                            );
                                          } catch (err) {
                                            enqueueSnackbar(
                                              somethingWentWrong.replace(
                                                '<action>',
                                                'cancelling this Upgrade Template',
                                              ),
                                              {
                                                variant: 'error',
                                                autoHideDuration:
                                                  toastAutoHideDuration,
                                              },
                                            );
                                          }
                                        }
                                      }}
                                    />
                                    <RouterLink
                                      to={`${upgradesTemplatesBasePath}/${template.id}`}
                                    >
                                      <SubframeCore.Icon
                                        className={
                                          template.status === 'pending'
                                            ? styles['icon-ef8c001b']
                                            : styles['bodyText']
                                        }
                                        name="FeatherChevronRight"
                                      />
                                    </RouterLink>
                                  </div>
                                );
                              },
                            },
                          ]}
                        />
                      </div>
                    </>
                  ) : (
                    <div className={styles['stack-4070173c']}>
                      <span className={styles['bodyText']}>
                        No Upgrade Templates requested.
                      </span>
                      <RouterLink to={`${upgradesTemplatesBasePath}/new`}>
                        <LockingWrapper
                          feature={
                            ResolvedEntitlementFeaturesItem.request_upgrade_template
                          }
                          showLock={showTemplateLock}
                          showExampleData={showExampleData}
                          defaultAction={(event) => {
                            if (showExampleData) {
                              showCreateExampleTemplateToast(event);
                            }
                          }}
                          tooltipText={
                            showTemplateLock
                              ? ''
                              : 'Request Preverified Upgrade Template'
                          }
                        >
                          <Button
                            disabled={false}
                            size="medium"
                            icon="FeatherPlus"
                            iconRight={
                              showTemplateLock && !showExampleData
                                ? 'FeatherLock'
                                : null
                            }
                          >
                            Request Preverified Upgrade Template
                          </Button>
                        </LockingWrapper>
                      </RouterLink>
                    </div>
                  )}
                </>
              </div>
            ) : (
              <div className={styles['stack-fd90eb5e']}>
                <span className={styles['bodyText']}>
                  No clusters onboarded so far. Click here to onboard your first
                  cluster
                </span>
                <RouterLink to={`${clustersBasePath}/new`}>
                  <Button
                    size="small"
                    icon="FeatherPlus"
                    onClick={() =>
                      logEvent('upgrades-templates-onboard-cluster-btn-click')
                    }
                  >
                    Add Cluster
                  </Button>
                </RouterLink>
              </div>
            )}
          </>
        )}
      </div>
    </Page>
  );
}

export default UpgradeTemplatesLandingPage;
