import * as SubframeCore from '@subframe/core';
import { AccountStats, Cluster } from 'api/models';
import { AtomicTooltip } from 'components/design-system';
import { InformationalHeader } from 'subframe/components/InformationalHeader';
import { SkeletonText } from 'subframe/components/SkeletonText';
import { InfoTooltip } from 'subframe/index';
import isExampleCluster from 'utils/isExampleCluster';

interface CountHeaderProps {
  totalClusters: Cluster[];
  isLoadingTotalClusters: boolean;
  showExampleData?: boolean;
  accountStats?: AccountStats;
  isLoadingAccountStats: boolean;
}

function CountHeader({
  totalClusters,
  isLoadingTotalClusters,
  showExampleData,
  accountStats,
  isLoadingAccountStats,
}: CountHeaderProps) {
  let clustersTooltipData: { [key: string]: Cluster[] } = {
    total: [],
  };
  let nodesTooltipData: { [key: string]: number } = {
    total: 0,
  };
  const getClustersTooltipData = () => {
    const x = totalClusters?.map((c: Cluster) => {
      if (c.cloud_provider) {
        if (showExampleData ? isExampleCluster(c) : !isExampleCluster(c)) {
          clustersTooltipData[c.cloud_provider] &&
          clustersTooltipData[c.cloud_provider].length
            ? clustersTooltipData[c.cloud_provider].push(c)
            : (clustersTooltipData[c.cloud_provider] = [c]);
          nodesTooltipData[c.cloud_provider] = nodesTooltipData[
            c.cloud_provider
          ]
            ? nodesTooltipData[c.cloud_provider] + c.node_count
            : c.node_count;
          clustersTooltipData.total.push(c);
          nodesTooltipData.total += c.node_count;
        }
      }
    });
  };

  if (!isLoadingTotalClusters) getClustersTooltipData();
  else if (isLoadingTotalClusters) {
    clustersTooltipData = {
      total: [],
    };
    nodesTooltipData = {
      total: 0,
    };
  }

  return (
    <div className="flex shrink-0 basis-0 items-start gap-6">
      <InformationalHeader className="h-auto w-auto flex-none">
        <InformationalHeader.Item
          title="Clusters"
          iconName={null}
          headingSlot={
            <SubframeCore.HoverCard.Root>
              <SubframeCore.HoverCard.Trigger asChild={true}>
                <AtomicTooltip tooltipContent="Total Clusters onboarded">
                  <SubframeCore.Icon
                    className={
                      'group/58466bc5 text-body font-body text-subtext-color'
                    }
                    name="FeatherInfo"
                  />
                </AtomicTooltip>
              </SubframeCore.HoverCard.Trigger>
            </SubframeCore.HoverCard.Root>
          }
          bodySlot={
            <>
              {showExampleData ? (
                <span className="text-body-bold font-body-bold text-default-font">
                  {isLoadingTotalClusters ? (
                    <SkeletonText className={'w-5'} />
                  ) : (
                    totalClusters.length
                  )}
                </span>
              ) : (
                <span className="text-body-bold font-body-bold text-default-font">
                  {isLoadingAccountStats ? (
                    <SkeletonText className={'w-5'} />
                  ) : (
                    accountStats && accountStats?.clusters_count
                  )}
                </span>
              )}
            </>
          }
        />

        {!isLoadingTotalClusters ? (
          <>
            {Object.keys(clustersTooltipData).map((key) => {
              return key !== 'total' ? (
                <InformationalHeader.Item
                  key={key}
                  title={key}
                  iconName={null}
                  headingSlot={<></>}
                  bodySlot={
                    <span className="text-body-bold font-body-bold text-default-font">
                      {`${clustersTooltipData[key]?.length}`}
                    </span>
                  }
                />
              ) : (
                <></>
              );
            })}
          </>
        ) : (
          <></>
        )}
      </InformationalHeader>
      <div style={{ display: 'contents' }}>
        <div className="flex h-12 w-0 flex-none flex-col items-start gap-2 border border-solid border-neutral-border" />

        <InformationalHeader className="h-auto w-auto flex-none">
          <InformationalHeader.Item
            title="Nodes"
            iconName={null}
            headingSlot={
              <SubframeCore.HoverCard.Root>
                <SubframeCore.HoverCard.Trigger asChild={true}>
                  <InfoTooltip tooltipText="Node count from last scan" />
                </SubframeCore.HoverCard.Trigger>
              </SubframeCore.HoverCard.Root>
            }
            bodySlot={
              <span className="text-body-bold font-body-bold text-default-font">
                {showExampleData ? (
                  <span className="text-body-bold font-body-bold text-default-font">
                    {isLoadingTotalClusters ? (
                      <SkeletonText className={'w-5'} />
                    ) : (
                      nodesTooltipData?.total
                    )}
                  </span>
                ) : (
                  <span className="text-body-bold font-body-bold text-default-font">
                    {isLoadingAccountStats ? (
                      <SkeletonText className={'w-5'} />
                    ) : (
                      accountStats && accountStats?.average_nodes_count
                    )}
                  </span>
                )}
              </span>
            }
          />
          {!isLoadingTotalClusters && nodesTooltipData.total ? (
            <>
              {Object.keys(clustersTooltipData).map((key) => {
                return (
                  key !== 'total' && (
                    <InformationalHeader.Item
                      key={key}
                      title={key}
                      iconName={null}
                      headingSlot={<></>}
                      bodySlot={
                        <span className="text-body-bold font-body-bold text-default-font">
                          {nodesTooltipData[key]}
                        </span>
                      }
                    />
                  )
                );
              })}
            </>
          ) : (
            <></>
          )}
        </InformationalHeader>
      </div>
    </div>
  );
}

export default CountHeader;
