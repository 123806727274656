import { differenceInDays } from 'date-fns';

export interface UpcomingUpgradeInstance {
  Id: string;
  Name: string;
  Environment?: string;
  CurrentVersions: string[];
  ClusterIds?: string[];
  Provider?: string;
  Region?: string;
  DueDate: number;
}

export function getUpcomingUpgradeState(
  instance: UpcomingUpgradeInstance,
): 'default' | 'warning' | 'error' {
  const dayDifference = differenceInDays(
    new Date(instance.DueDate * 1000),
    new Date(),
  );
  if (dayDifference < 0) {
    return 'error';
  } else if (dayDifference < 90) {
    return 'warning';
  } else {
    return 'default';
  }
}
