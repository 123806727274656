'use client';

import React, { useState } from 'react';
import { IconButton } from 'subframe/components/IconButton';
import { Button } from 'subframe/components/Button';
import { Dialog } from 'subframe/components/Dialog';
import { deleteAccountSSOConfiguration } from 'api/frontend';
import { SSOProvider } from 'api/models';
import * as Sentry from '@sentry/browser';
import { toastAutoHideDuration } from 'constants/toasts';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { useSnackbar } from 'notistack';

interface Props {
  authToken: string;
  onDelete?: () => void;
}

const DeleteSSOConnection: React.FC<Props> = ({
  authToken,
  onDelete,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authToken}`,
  };
  const { enqueueSnackbar } = useSnackbar();
  const { logEvent } = AnalyticsEventLogger();
  const deleteError =
    'Something went wrong while deleting Okta SSO connection. Please try again or escalate to Chkk';
  const deleteSSOConfig = async () => {
    try {
      setLoading(true);
      await deleteAccountSSOConfiguration('DEFAULT', { headers });
      logEvent('security-settings-sso-connection-deleted', {
        provider: SSOProvider['okta-saml'],
      });
    } catch (error) {
      Sentry.captureException({ error }, { level: 'log' });
      enqueueSnackbar(deleteError, {
        variant: 'error',
        autoHideDuration: toastAutoHideDuration,
      });
    } finally {
      setLoading(false);
      setOpen(false);
      onDelete && onDelete();
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      {open && (
        <Dialog open={open} onOpenChange={onClose}>
          <Dialog.Content className="h-auto w-auto flex-none">
            <div className="flex w-full min-w-[384px] max-w-[448px] flex-col items-start gap-6 p-6">
              <div className="flex w-full items-center justify-between">
                <span className="text-subheader font-subheader text-default-font">
                  Delete SSO Connection
                </span>
                <IconButton size="medium" icon="FeatherX" onClick={onClose} />
              </div>
              <span className="whitespace-pre-wrap text-body font-body text-default-font">
                {`Are you sure you want to delete your SSO connection?`}
              </span>
              <div className="flex w-full items-end justify-end gap-4">
                <Button
                  variant="neutral-tertiary"
                  size="large"
                  icon="FeatherX"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="destructive-primary"
                  size="large"
                  icon="FeatherTrash2"
                  onClick={deleteSSOConfig}
                >
                  Remove
                </Button>
              </div>
            </div>
          </Dialog.Content>
        </Dialog>
      )}
      <div className="flex w-full flex-col items-start gap-4">
        <Button
          variant={'brand-tertiary'}
          loading={loading}
          disabled={loading}
          icon="FeatherTrash"
          onClick={() => setOpen(true)}
        >
          Delete SSO Connection
        </Button>
      </div>
    </>
  );
};

export default DeleteSSOConnection;
