import { Container, Divider, Stack, Typography } from '@mui/material';
import Page from 'components/Page';
import { Spacer } from 'components/Spacer';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import RHFTextField from 'components/RHFTextField';
import FormProvider from 'components/FormProvider';
import { useSnackbar } from 'notistack';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { Button } from 'subframe/index';
import useUserAccountState from 'hooks/useUserAccountState';
import { somethingWentWrong, toastAutoHideDuration } from 'constants/toasts';
import * as Sentry from '@sentry/browser';
import { SubmitFeedbackRequest } from 'api/models';
import { submitFeedback } from 'api/frontend';

interface RAR {
  description: string;
  availabilityImpact: string;
  triggerConditions: string;
  mitigation: string;
  remediation: string;
  reproduction: string;
}

const ReportAvailabilityRisk = () => {
  const { user, account } = useUserAccountState();

  const { enqueueSnackbar } = useSnackbar();
  const { logEvent } = AnalyticsEventLogger();
  const SubmissionError =
    'Something went wrong while reporting the risk. Please try again or escalate to Chkk';

  const RARSchema = Yup.object().shape({
    description: Yup.string().required('Description is required'),
    availabilityImpact: Yup.string(),
    triggerConditions: Yup.string(),
    mitigation: Yup.string(),
    remediation: Yup.string(),
    reproduction: Yup.string(),
  });

  const defaultValues: RAR = {
    description: '',
    availabilityImpact: '',
    triggerConditions: '',
    mitigation: '',
    remediation: '',
    reproduction: '',
  };

  const methods = useForm<RAR>({
    resolver: yupResolver(RARSchema),
    defaultValues: defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: RAR) => {
    try {
      logEvent('report-risk-submit');
      const feedbackRequest: SubmitFeedbackRequest = {
        title: 'ReportAvailabilityRiskRequest',
        details: JSON.stringify(data),
        user_email: user?.email || '',
      };
      await submitFeedback(feedbackRequest, {
        headers: {
          Authorization: account?.token,
        },
      });
      enqueueSnackbar('Risk submitted successfully', {
        variant: 'success',
        autoHideDuration: toastAutoHideDuration,
      });
    } catch (error) {
      enqueueSnackbar(
        somethingWentWrong.replace('<action>', 'reporting the risk'),
        {
          variant: 'error',
          autoHideDuration: toastAutoHideDuration,
        },
      );
      Sentry.captureException(error, { level: 'log' });
    }
  };

  return (
    <Page title="Report Risk">
      <Container maxWidth={'xl'}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Typography
            id="report-availability-risk-title"
            sx={{ fontSize: '1.25rem' }}
          >
            Report Risk
          </Typography>
          <Spacer value={30} />
          <Divider />
          <Spacer value={40} />
          <Stack spacing={2} alignItems={'center'}>
            <RHFTextField
              name="description"
              label="Description"
              placeholder="What happened?"
              multiline
              minRows={2}
              maxRows={2}
            />
            <RHFTextField
              name="availabilityImpact"
              label="Availability Impact"
              placeholder="What was the Availability Impact and how severe was it?"
              multiline
              minRows={2}
              maxRows={2}
            />
            <RHFTextField
              name="triggerConditions"
              label="Trigger Conditions"
              placeholder="What triggered it?"
              multiline
              minRows={2}
              maxRows={2}
            />
            <RHFTextField
              name="mitigation"
              label="Mitigation"
              placeholder="Were you able to mitigate it? If yes, what was the work-around?"
              multiline
              minRows={2}
              maxRows={2}
            />
            <RHFTextField
              name="remediation"
              label="Remediation"
              placeholder="Did you find the root cause? Were you able to remediate it? If yes, what was the fix?"
              multiline
              minRows={2}
              maxRows={2}
            />
            <RHFTextField
              name="reproduction"
              label="Reproduction"
              placeholder="How can we reproduce this Availability Risk?"
              multiline
              minRows={5}
              maxRows={5}
            />

            <Button
              size="large"
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              Submit
            </Button>
          </Stack>
        </FormProvider>
      </Container>
    </Page>
  );
};

export default ReportAvailabilityRisk;
