import { Container, Link, Stack, styled, Typography } from '@mui/material';
import { Tracker } from 'components/tracker';
import { AlertDialog } from 'components/dialogs';
import useDialog from '../hooks/useDialog';
import { useNavigate } from 'react-router-dom';
import { Button } from 'subframe/index';
import TokenService from '@services/TokenService';
import { SignupRequestDialog } from 'components/dialogs/SignupRequestDialog';

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

const Heading = styled(Typography)(() => ({
  fontSize: '2rem',
  fontWeight: '600',
  lineHeight: '140%',
}));

const NotListPrivateBetaPage = () => {
  const { currentDialog, setDialogType, alertDialogDetails } = useDialog();
  const navigate = useNavigate();
  const { logout } = TokenService();

  return (
    <RootStyle>
      <Container>
        {/* Dialogs Start */}
        <SignupRequestDialog
          open={currentDialog === 'signup-request'}
          handleOpenChange={(status) => {
            if (!status) {
              setDialogType('');
            }
          }}
        />
        <AlertDialog
          message={alertDialogDetails.message}
          type={alertDialogDetails.type}
          open={currentDialog === 'alert'}
          handleClose={() => {
            navigate('/');
          }}
        />
        {/* Dialogs Ends */}
        <Stack sx={{ textAlign: 'center' }} alignItems="center" spacing={5}>
          <Heading>
            Oops! Looks like you&apos;re not allowlisted on Chkk.
          </Heading>
          <Stack spacing={2} width="auto">
            <Tracker event="request-signup">
              <Button
                size="large"
                onClick={() => setDialogType('signup-request')}
              >
                Sign up
              </Button>
            </Tracker>
          </Stack>

          <Typography variant="subtitle2">
            Click{' '}
            <Link
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                logout();
                navigate('/login');
              }}
            >
              here
            </Link>{' '}
            to login using a different email or if you are already allowlisted
          </Typography>
        </Stack>
      </Container>
    </RootStyle>
  );
};
export default NotListPrivateBetaPage;
