import { m } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Container } from '@mui/material';
// components
import Page from '../components/Page';
import { MotionContainer, varBounce } from '../components/animate';
// assets
import { PageNotFoundIllustration } from '../assets';
import * as Sentry from '@sentry/browser';
import { Button } from 'subframe/index';
import { Stack } from 'components/utils';
import { Text } from '@subframe/core';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

export default function Page404({ isDeadRoute }: { isDeadRoute?: boolean }) {
  const navigate = useNavigate();

  if (isDeadRoute) {
    Sentry.captureMessage('Route does not exist anymore.');
  }

  return (
    <Page title="404 Page Not Found" sx={{ height: 1 }}>
      <RootStyle>
        <Container component={MotionContainer}>
          <Stack alignItems="center">
            <m.div variants={varBounce().in}>
              <Text variant="section-header">Sorry, page not found!</Text>
            </m.div>

            <m.div variants={varBounce().in}>
              <PageNotFoundIllustration sx={{ my: { xs: 5, sm: 10 } }} />
            </m.div>
            <Button size="large" onClick={() => navigate(-1)}>
              Go back
            </Button>
          </Stack>
        </Container>
      </RootStyle>
    </Page>
  );
}
