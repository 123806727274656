'use client';

import { TemplateCard } from 'subframe/components/TemplateCard';
import { Button } from 'subframe/components/Button';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import { Icon } from '@subframe/core';
import { getHumanReadableDate } from 'utils/subscription_plan';
import { StepBase } from 'subframe/index';

interface SuccessScreenProps {
  lastUpdated: number | undefined;
}
function SuccessScreen({ lastUpdated }: SuccessScreenProps) {
  const path = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/integrations/',
  );

  return (
    <div className="flex w-full flex-col items-start">
      <div className="flex w-full items-center gap-2 px-0 py-1;">
        <Icon name="FeatherSlack" />
        <span className="text-subheader text-default-font">Chkk Slack App</span>
      </div>
      <TemplateCard
        image="https://res.cloudinary.com/subframe/image/upload/v1716127112/uploads/2/bl1enjfohqp7t7afju7b.png"
        title="Chkk App notifies you about Availability Risks & Upgrades"
        description={`Installed: ${getHumanReadableDate(lastUpdated)}`}
        tag="Installed"
        className="pointer-events-none"
      />
      <StepBase
        stepTitle="Instructions to uninstall"
        stepBody=""
        lastStep={true}
        stepNumber="1"
        actionButtons={
          <>
            <Button
              className="hidden"
              disabled={false}
              variant="brand-secondary"
              size="medium"
              icon="FeatherCornerDownRight"
              loading={false}
            >
              Mark as Done
            </Button>
            <Button
              className="hidden"
              disabled={false}
              variant="brand-secondary"
              size="medium"
              icon="FeatherRedo"
              loading={false}
            >
              Revert
            </Button>
          </>
        }
        defaultOpen={false}
      >
        <span className="text-body text-default-font">
          You can uninstall the Chkk app by removing it from your Slack
          workspace.
        </span>
        <span className="text-body text-default-font">
          You can also engage Chkk support (
          <a
            href="mailto:support@chkk.io"
            className="hoverable-link text-body"
            rel="noopener noreferrer"
          >
            support@chkk.io
          </a>
          ) to remove this integration.
        </span>
      </StepBase>
    </div>
  );
}

export default SuccessScreen;
