import { OktaOIDCConfig, OktaSAMLConfig } from 'api/models';
import { IconWithBackground } from 'subframe/components/IconWithBackground';
import * as SubframeCore from '@subframe/core';
import { Loader } from '@subframe/core';
import React from 'react';
import { format } from 'date-fns';
import { TextField } from 'subframe/components/TextField';
import { SSOConfigurationStatusEnum } from 'pages/Settings/Security';
import { Tabs } from 'subframe/components/Tabs';
import { AtomicTooltip } from 'components/design-system';

export const OktaSAMLConnection = ({
  created,
  config,
  status,
}: {
  created: number;
  config: OktaSAMLConfig;
  status: SSOConfigurationStatusEnum;
}) => {
  return (
    <>
      <Tabs>
        <AtomicTooltip tooltipContent="SSO configuration disabled. Only one SSO connection allowed.">
          <Tabs.Item
            id="okta-oidc"
            key="okta-oidc"
            disabled={true}
            active={false}
          >
            Okta - OIDC
          </Tabs.Item>
        </AtomicTooltip>
        <Tabs.Item
          id="okta-saml"
          key="okta-saml"
          title="Okta - SAML (SP Initiated SSO)"
          disabled={false}
          active={true}
        >
          Okta - SAML (SP Initiated SSO)
        </Tabs.Item>
      </Tabs>
      {status === SSOConfigurationStatusEnum.ACTIVE && <SSOConnectionActive />}
      {[
        SSOConfigurationStatusEnum.PENDING,
        SSOConfigurationStatusEnum.DISABLED,
        SSOConfigurationStatusEnum.INACTIVE,
      ].includes(status) && <SSOConnectionPending />}
      {status === SSOConfigurationStatusEnum.PENDING_DELETE && (
        <SSOConnectionPendingDelete />
      )}
      {created && (
        <div className="flex w-full items-center justify-between">
          <span className="w-48 flex-none text-body-bold font-body-bold text-default-font">
            Created
          </span>
          <span className="grow shrink-0 basis-0 text-body font-body text-subtext-color">
            {format(created * 1000, 'MMMM d, yyyy')}
          </span>
        </div>
      )}
      <div className="flex w-full items-center">
        <span className="w-48 flex-none text-body-bold font-body-bold text-default-font">
          IdP Single Sign-On URL
        </span>
        <TextField
          className="h-auto grow shrink-0 basis-0"
          disabled={true}
          label=""
          helpText=""
        >
          <TextField.Input
            style={{ width: '100%' }}
            disabled
            readOnly
            value={config?.sso_url}
          />
        </TextField>
      </div>
      <div className="flex w-full items-center">
        <span className="w-48 flex-none text-body-bold font-body-bold text-default-font">
          IdP Issuer
        </span>
        <TextField className="h-auto grow shrink-0 basis-0" disabled={true}>
          <TextField.Input
            style={{ width: '100%' }}
            disabled
            readOnly
            value={config?.idp_issuer}
          />
        </TextField>
      </div>
      <div className="flex w-full items-start">
        <span className="w-48 flex-none text-body-bold font-body-bold text-default-font">
          X.509 Certificate
        </span>
        <TextField className="h-auto grow shrink-0 basis-0" variant="filled">
          <TextField.Input
            style={{ width: '100%' }}
            disabled
            readOnly
            value="For security purposes, we don't show the certificate content."
          />
        </TextField>
      </div>
      <div className="flex w-full items-start">
        <span className="w-48 flex-none text-body-bold font-body-bold text-default-font">
          IDP metadata
        </span>
        <TextField className="h-auto grow shrink-0 basis-0" variant="filled">
          <TextField.Input
            style={{ width: '100%' }}
            disabled
            readOnly
            value="For security purposes, we don't show the IDP metadata content."
          />
        </TextField>
      </div>
    </>
  );
};

export const OktaOIDCConnection = ({
  created,
  config,
  status,
}: {
  created: number;
  config: OktaOIDCConfig;
  status: SSOConfigurationStatusEnum;
}) => {
  return (
    <>
      <Tabs>
        <Tabs.Item
          id="okta-oidc"
          key="okta-oidc"
          disabled={false}
          active={true}
        >
          Okta - OIDC
        </Tabs.Item>
        <AtomicTooltip tooltipContent="SSO configuration disabled. Only one SSO connection allowed.">
          <Tabs.Item
            id="okta-saml"
            key="okta-saml"
            title="Okta - SAML (SP Initiated SSO)"
            disabled={true}
            active={false}
          >
            Okta - SAML (SP Initiated SSO)
          </Tabs.Item>
        </AtomicTooltip>
      </Tabs>
      {status === SSOConfigurationStatusEnum.ACTIVE && <SSOConnectionActive />}
      {[
        SSOConfigurationStatusEnum.PENDING,
        SSOConfigurationStatusEnum.DISABLED,
        SSOConfigurationStatusEnum.INACTIVE,
      ].includes(status) && <SSOConnectionPending />}
      {status === SSOConfigurationStatusEnum.PENDING_DELETE && (
        <SSOConnectionPendingDelete />
      )}
      {created && (
        <div className="flex w-full items-center justify-between">
          <span className="w-48 flex-none text-body-bold font-body-bold text-default-font">
            Created
          </span>
          <span className="grow shrink-0 basis-0 text-body font-body text-subtext-color">
            {format(created * 1000, 'MMMM d, yyyy')}
          </span>
        </div>
      )}
      <div className="flex w-full items-center">
        <span className="w-48 flex-none text-body-bold font-body-bold text-default-font">
          Client ID
        </span>
        <TextField
          className="h-auto grow shrink-0 basis-0"
          disabled={true}
          label=""
          helpText=""
        >
          <TextField.Input
            style={{ width: '100%' }}
            disabled
            readOnly
            value={config?.client_id}
          />
        </TextField>
      </div>
      <div className="flex w-full items-center">
        <span className="w-48 flex-none text-body-bold font-body-bold text-default-font">
          Client Secret
        </span>
        <TextField className="h-auto grow shrink-0 basis-0" disabled={true}>
          <TextField.Input
            style={{ width: '100%' }}
            disabled
            readOnly
            value="For security purposes, we don't show your existing Client Secret."
          />
        </TextField>
      </div>
      <div className="flex w-full items-center">
        <span className="w-48 flex-none text-body-bold font-body-bold text-default-font">
          Okta Domain
        </span>
        <TextField className="h-auto grow shrink-0 basis-0" variant="filled">
          <TextField.Input
            style={{ width: '100%' }}
            disabled
            readOnly
            value={config?.okta_domain}
          />
        </TextField>
      </div>
      <div className="flex w-full items-center">
        <span className="w-48 flex-none text-body-bold font-body-bold text-default-font">
          Domain Aliases
        </span>
        <TextField className="h-auto grow shrink-0 basis-0" variant="filled">
          <TextField.Input
            style={{ width: '100%' }}
            disabled
            readOnly
            value={config?.domain_aliases}
          />
        </TextField>
      </div>
    </>
  );
};
export const SSOConnectionActive = () => {
  return (
    <>
      <div className="flex w-full items-start">
        <span className="w-48 flex-none text-body-bold font-body-bold text-default-font">
          Status
        </span>
        <div className="flex grow shrink-0 basis-0 items-center gap-2">
          <IconWithBackground variant="success" />
          <span className="text-body-bold font-body-bold text-default-font">
            Connection succeeded
          </span>
          <span className="text-label font-label text-subtext-color">
            SSO configuration will be available for review on this page.
          </span>
        </div>
      </div>
    </>
  );
};
export const SSOConnectionError = () => {
  return (
    <div className="flex w-full items-center justify-between">
      <div className="flex grow shrink-0 basis-0 items-center gap-2">
        <SubframeCore.Icon
          className="text-subheader font-subheader text-error-700"
          name="FeatherAlertTriangle"
        />
        <span className="text-body-bold font-body-bold text-default-font">
          Something went wrong
        </span>
        <span className="text-label font-label text-subtext-color">
          SAML connection failed. Check the configuration and try again.
        </span>
      </div>
    </div>
  );
};
export const SSOConnectionPendingDelete = () => {
  return (
    <>
      <div className="flex w-full items-center justify-between">
        <span className="w-48 flex-none text-body-bold font-body-bold text-default-font">
          Status
        </span>
        <div className="flex grow shrink-0 basis-0 items-center gap-2">
          <SubframeCore.Icon
            className="text-subheader font-subheader text-error-700"
            name="FeatherTrash2"
          />
          <span className="text-body-bold font-body-bold text-default-font">
            Pending Delete
          </span>
          <Loader />
          <span className="text-label font-label text-subtext-color">
            This step can take up to 48 hours
          </span>
        </div>
      </div>
    </>
  );
};
export const SSOConnectionPending = () => {
  return (
    <>
      <div className="flex w-full items-center justify-between">
        <span className="w-48 flex-none text-body-bold font-body-bold text-default-font">
          Status
        </span>
        <div className="flex grow shrink-0 basis-0 items-center gap-2">
          <Loader />
          <span className="text-body-bold font-body-bold text-default-font">
            Setting up &amp; validating connection
          </span>
          <span className="text-label font-label text-subtext-color">
            This step can take up to 48 hours
          </span>
        </div>
      </div>
    </>
  );
};
