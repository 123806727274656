import { useState, useEffect } from 'react';
import useFetch, { CachePolicies } from 'use-http';
import { clustersApi } from 'api/constants';
import { Cluster, ListClusterScansResponseV2 } from 'api/models';

export const useGetClusterAndScan = (
  clusterId: string | undefined,
  token: string,
) => {
  const [loading, setLoading] = useState(false);

  const {
    loading: clusterLoading,
    data: cluster,
    error: clusterError,
    response: clusterRes,
    get: getCluster,
  } = useFetch<Cluster>(`${clustersApi}/${clusterId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: 'Bearer ' + token,
    } as any,
    credentials: 'omit',
    cachePolicy: CachePolicies.NO_CACHE,
  });

  const {
    loading: scanLoading,
    data: scan,
    error: scanError,
    response: scanRes,
    get: getScan,
  } = useFetch<ListClusterScansResponseV2>(
    `${clustersApi}/${clusterId}/scans?page_size=2`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + token,
      } as any,
      credentials: 'omit',
      cachePolicy: CachePolicies.NO_CACHE,
    },
  );

  useEffect(() => {
    if (clusterId) {
      getCluster();
    }
  }, [clusterId, getCluster]);

  useEffect(() => {
    setLoading(clusterLoading || scanLoading);
  }, [clusterLoading, scanLoading]);

  useEffect(() => {
    if (cluster) {
      getScan();
    }
  }, [cluster, getScan]);

  return {
    cluster,
    clusterError,
    clusterRes,
    scan,
    scanError,
    scanRes,
    getScan,
    loading,
  };
};
