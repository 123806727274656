import { useEffect, useRef } from 'react';
import Page from 'components/Page';
import Logo from 'components/Logo';
import { useParams } from 'react-router-dom';
import { useOnScreen } from 'hooks/useOnScreen';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { KBAPage } from './kba/index';
import { Stack } from 'subframe/index';
import { Divider } from 'components/design-system';

// ----------------------------------------------------------------------

export default function KBA() {
  const { signatureId } = useParams<{ signatureId: string }>();
  const ref: React.MutableRefObject<HTMLElement | undefined> = useRef();
  const isVisible = useOnScreen(ref as React.MutableRefObject<HTMLElement>);
  const { logEvent } = AnalyticsEventLogger();

  useEffect(() => {
    if (isVisible) {
      logEvent('kba-page-scroll-to-bottom', {
        signatureId,
      });
    }
  }, [isVisible, logEvent, signatureId]);

  return (
    <Page title="KBA">
      <Stack
        style={{
          width: '100%',
          padding: '8px 30px',
          background: 'none',
        }}
      >
        <Logo style={{ width: '100px' }} />
      </Stack>
      <Divider variant="neutral" />
      <Stack
        ref={ref}
        style={{
          width: '100%',
          padding: '8px 300px',
          background: 'none',
        }}
      >
        <KBAPage isStandAlone />
      </Stack>
    </Page>
  );
}
