export function sum(data: number[]): number {
  return data.reduce((partialSum, a) => partialSum + a, 0);
}

export function groupInto<T>(data: T[], count: number): T[][] {
  return data.reduce((acc, item, i) => {
    const index = Math.floor(i / count);
    if (!acc[index]) {
      acc[index] = [];
    }
    acc[index].push(item);
    return acc;
  }, [] as T[][]);
}

export function flattenPages<T>(
  pages: { data: T[] }[] | undefined,
): T[] | undefined {
  if (!pages) return undefined;
  return pages.reduce<T[]>(
    (acc, page) => page?.data && acc.concat(...page.data),
    [],
  );
}

interface ResponseType<T> {
  __status: number;
  data?: T;
}

export function clusterFlattenPages<T>(
  pages: (ResponseType<T[]> | T[])[] | undefined,
): T[] | undefined {
  if (!pages) return undefined;

  return pages.reduce<T[]>((acc, page) => {
    if (Array.isArray(page)) {
      // If the page is an array, concat it directly
      return acc.concat(page);
    } else if ('data' in page && Array.isArray(page.data)) {
      // If the page is an object with a data property, concat the data array
      return acc.concat(page.data);
    }
    return acc;
  }, []);
}
