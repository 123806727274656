import semver from 'semver';

export function extractMajorMinor(versionString: string): string {
  const parsedVersion = semver.parse(semver.coerce(versionString));
  if (parsedVersion) {
    const major = parsedVersion.major;
    const minor = parsedVersion.minor;
    return `v${major}.${minor}`;
  } else {
    return '';
  }
}
