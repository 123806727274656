import { mdast } from '02strich-markdown';
import { useEffect, useMemo, useState } from 'react';
import '../../../node_modules/02strich-markdown/dist/main.css';
import './markdown.css';
import MessageBox from 'components/MessageBox';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { ParsedKBA } from './ParsedKBA';
import styles from './LatestPageKbaDetailsTab.module.scss';
import { Button } from 'subframe/components/Button';
import styles_howToFix from './LatestPageKbaHowToFixTab.module.scss';
import { SkeletonText } from 'subframe/components/SkeletonText';
import { Stack } from 'components/utils/Stack';
import { getTimeDiff, isClusterInactive } from 'utils/clusterStates';
import { captureException } from '@sentry/react';
import isExampleCluster from 'utils/isExampleCluster';
import { Cluster } from 'api/models';

interface KBARendererProps {
  details?: boolean;
  howToFix?: boolean;
  kbaDetails: {
    title: string;
    bodyData: string;
    showNotFound: boolean;
    loading: boolean;
  };
  rescanHandler: any;
  rescanDisabled: any;
  rescanComplete: boolean;
  isStandAlone?: boolean;
  lastScan?: number;
  cluster?: Cluster;
}

const KBASkeleton = () => {
  return (
    <Stack spacing={8}>
      <Stack spacing={4}>
        <SkeletonText className="h-[22px] w-[40%]" />

        <SkeletonText className="h-4 w-[70%]" />
      </Stack>
      <Stack spacing={4}>
        <SkeletonText className="h-[22px] w-[40%]" />

        <SkeletonText className="h-4 w-[70%]" />
      </Stack>
    </Stack>
  );
};

type howToFixStringsType =
  | '(Mitigation)'
  | '(Remediation)'
  | 'Short-term fix'
  | 'Long-term fix';

/**
 * It Extracts and Restructure KBA Object nodes aligned with headings containing specified 'howToFixStrings' and separates
 * content into 'How to Fix' and 'Details' tabs based on these 'strings' presence/absence respectively. It also restructures
 * the content into a structured array of nodes grouped into heading and paragraph.
 * @param tree The KBA tree to process
 * @param howToFixStrings List of Strings to match against heading content
 * @param fix Flag to indicate processing for 'How to Fix'(howToFixStrings) tab
 * @returns A Structured array of nodes grouped into heading and paragraph structure filtered out for the respective fix/details tab content.
 */
async function extractAndRestructureTabNodes(
  tree,
  howToFixStrings: howToFixStringsType[],
  fix = true,
) {
  try {
    let nodes2 = [];
    let idx = 0;
    for (let i = 0; i < tree.children.length; i++) {
      idx = i;
      let node = tree.children[i];
      if (node.type === 'heading') {
        // we don't want to render Title in the Body
        if (node.children[0].value == 'Title') {
          continue;
        }
        let substringAvailable = howToFixStrings.some((text) =>
          node.children[0].value.includes(text),
        );
        if (
          node.depth === 2 && fix ? substringAvailable : !substringAvailable
        ) {
          const headingObj = [node];
          idx++;
          let currentNode = tree.children[idx];
          while (currentNode && currentNode.type !== 'heading') {
            headingObj.push(currentNode);
            idx++;
            currentNode = tree.children[idx];
          }

          nodes2.push(...headingObj);
        }
      }
    }
    return nodes2;
  } catch (error) {
    captureException('KBA markdown Parsing error: ' + error);
  }
}
export const KBARenderer = ({
  howToFix,
  kbaDetails,
  rescanHandler,
  rescanDisabled,
  rescanComplete,
  isStandAlone,
  lastScan,
  cluster,
}: KBARendererProps) => {
  const { logEvent } = AnalyticsEventLogger();
  const [body, setBody] = useState<any[]>([]);
  const howToFixStrings: howToFixStringsType[] = [
    '(Mitigation)',
    '(Remediation)',
    'Short-term fix',
    'Long-term fix',
  ];
  useEffect(() => {
    async function fetchParsedNodes() {
      const tempbody = await extractAndRestructureTabNodes(
        mdast(kbaDetails.bodyData),
        howToFixStrings,
        howToFix,
      );
      setBody(tempbody || []);
    }
    if (!kbaDetails.loading) {
      if (!kbaDetails.showNotFound) {
        fetchParsedNodes();
      }
    }
  }, [kbaDetails.loading]);

  const timestamp = lastScan ? Number(lastScan) : undefined;

  const lastScanDiff = useMemo(() => {
    if (timestamp) {
      return getTimeDiff(timestamp);
    } else {
      return 0;
    }
  }, [timestamp]);

  const showRescanButton = useMemo(() => {
    if (cluster && lastScanDiff) {
      return (
        !isClusterInactive(lastScanDiff, cluster) &&
        !isExampleCluster(cluster) &&
        cluster.status.toLowerCase() === 'active'
      );
    } else {
      return false;
    }
  }, [cluster, lastScanDiff]);

  return (
    <div style={{ width: '100%' }}>
      {!kbaDetails.loading ? (
        <>
          {!kbaDetails.showNotFound && (
            <>
              {!!body && (
                <div className={styles['verifyYourFixButtonIconGroup']}>
                  {body.map((kbaData, idx: number) => (
                    <>
                      {kbaData.type === 'heading' && idx != 0 && (
                        <div
                          className={
                            styles_howToFix['verifyYourFixButtonGroup']
                          }
                          style={{
                            marginTop: '12px',
                            marginBottom: '12px',
                          }}
                        />
                      )}
                      <div
                        className={styles['stack-a340236f']}
                        key={idx}
                        style={{ width: '100%', marginTop: '8px' }}
                      >
                        <ParsedKBA mdastObj={kbaData} />
                      </div>
                    </>
                  ))}

                  {!isStandAlone && howToFix && (
                    <>
                      <div
                        className={styles_howToFix['verifyYourFixButtonGroup']}
                        style={{
                          marginTop: '24px',
                          marginBottom: '24px',
                        }}
                      />
                      <div
                        className={
                          styles_howToFix['verifyYourFixButtonRowGroup']
                        }
                      >
                        <div
                          className={
                            styles_howToFix['verifyYourFixButtonIconGroup']
                          }
                        >
                          <span className={styles_howToFix['subheaderText']}>
                            Verify your fix
                          </span>
                          <span className={styles_howToFix['bodyText']}>
                            Rescan the cluster to see if we still detect this
                            issue.
                          </span>
                        </div>
                        {showRescanButton ? (
                          <Button
                            variant="brand-tertiary"
                            icon="FeatherRotateCw"
                            size="medium"
                            onClick={(event) => {
                              if (event?.stopPropagation) {
                                event.stopPropagation();
                                rescanHandler();
                              }
                              logEvent('kba-rescan-button-clicked', {
                                signatureId: kbaDetails,
                              });
                            }}
                            loading={rescanComplete === false}
                            disabled={rescanComplete === false}
                          >
                            Rescan cluster
                          </Button>
                        ) : (
                          <></>
                        )}
                      </div>
                    </>
                  )}
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <KBASkeleton />
      )}

      {!kbaDetails.loading && kbaDetails.showNotFound && (
        <Stack alignItems="center" justifyContent="center" height="fill">
          <MessageBox mode={'info'} message={'KBA not found'} />
        </Stack>
      )}
    </div>
  );
};
