/*
 * Documentation:
 * [Draft] - Teams Member Details — https://app.subframe.com/library?component=%5BDraft%5D+-+Teams+Member+Details_121fdbf9-a8db-4b46-ab75-0b69fdef0045
 * Dropdown Menu — https://app.subframe.com/library?component=Dropdown+Menu_99951515-459b-4286-919e-a89e7549b43b
 * Avatar Menu + org — https://app.subframe.com/library?component=Avatar+Menu+%2B+org_dcc4f957-4f38-4f6d-970b-87a09d510b8e
 * Top Navbar — https://app.subframe.com/library?component=Top+Navbar_f3d2630e-a5fc-4fcb-81fb-095957b87fae
 * Breadcrumb — https://app.subframe.com/library?component=Breadcrumb_44b6fbd0-acf9-4944-8c86-0343ed858677
 * Avatar Image — https://app.subframe.com/library?component=Avatar+Image_ce9959fe-701f-440f-913c-132cdebc1dd5
 * Informational Header — https://app.subframe.com/library?component=Informational+Header_4cf9a88e-9d62-46e2-bdb7-c24b54eff2d7
 */

import * as SubframeCore from '@subframe/core';
import { useGetAccountTeam } from 'api/frontend';
import { TeamMember } from 'api/models';
import Page from 'components/Page';
import { RouterLink } from 'components/RouterLink';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import useUserAccountState from 'hooks/useUserAccountState';
import { useMemo } from 'react';
import { useParams } from 'react-router';
import { InformationalHeader } from 'subframe/components/InformationalHeader';
import {
  Avatar,
  Breadcrumbs,
  SkeletonCircle,
  SkeletonText,
  Loader,
} from 'subframe/index';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { getHumanReadableDate } from 'utils/subscription_plan';

function TeamsMemberDetails() {
  const { account, currentOrganization } = useUserAccountState();
  const { memberId } = useParams<{ memberId: string }>();
  const { logEvent } = AnalyticsEventLogger();
  const path = useAccountIdRoute('/orgs/:orgId/accounts/:accountId/teams');

  const {
    data: membersListToShow,
    isValidating,
    error,
    mutate: refetchMembers,
  } = useGetAccountTeam('default', 'default', {
    request: { headers: { Authorization: `Bearer ${account.token}` } },
  });

  const findMember = membersListToShow?.members?.find(
    (member: TeamMember) => member.user_id === memberId,
  );

  const targetMember: TeamMember | undefined = useMemo(() => {
    return membersListToShow?.members?.find(
      (member: TeamMember) => member.user_id === memberId,
    );
  }, [membersListToShow, memberId]);

  const memberExists = membersListToShow && targetMember && !error;

  const isLoading = isValidating && !membersListToShow && !error;

  return (
    <Page title="Member Details">
      <div className="w-[calc(100%-64px)] h-full flex-col gap-6 items-start flex pl-0 pr-3 pt-8 pb-0 bg-default-background ml-8">
        <div className="flex flex-col gap-6 items-start w-full pl-3 pr-0 py-0">
          <div className="w-full flex-col items-start flex">
            <div className="w-full flex-col gap-6 items-start flex">
              <Breadcrumbs>
                <Breadcrumbs.Item>Configure</Breadcrumbs.Item>
                <Breadcrumbs.Divider />
                <RouterLink
                  to={path}
                  onClick={() => logEvent('breadcrumbs-teams-navigation')}
                >
                  <Breadcrumbs.Item active={true}>Teams</Breadcrumbs.Item>
                </RouterLink>
                <Breadcrumbs.Divider />
                <Breadcrumbs.Item active={true} className="ph-no-capture">
                  {isLoading ? (
                    <SkeletonText className="h-6 w-[100px]" />
                  ) : (
                    `${memberExists ? targetMember?.user_name : 'Not Found'}`
                  )}
                </Breadcrumbs.Item>
              </Breadcrumbs>
            </div>
          </div>
          <div className="flex w-full items-start gap-4 rounded border border-solid border-neutral-border bg-default-background pt-4 pr-4 pb-4 pl-4 shadow">
            <div className="flex-[1_0_0] w-full gap-4 items-end justify-end flex">
              <div className="flex-[1_0_0] w-full gap-6 items-center flex">
                {!isLoading ? (
                  <>
                    {targetMember?.user_picture ? (
                      <Avatar
                        size="x-large"
                        image={targetMember?.user_picture}
                        className="ph-no-capture"
                      />
                    ) : (
                      <Avatar variant="brand" size="x-small">
                        {targetMember?.user_name?.charAt(0)?.toUpperCase()}
                      </Avatar>
                    )}
                  </>
                ) : (
                  <SkeletonCircle
                    size="default"
                    className="h-[64px] w-[64px]"
                  />
                )}
                <div className="flex-[1_0_0] w-full flex-col gap-2 items-start flex">
                  <div className="w-full gap-2 items-center flex">
                    <div className="flex-[1_0_0] w-full gap-2 items-end flex">
                      {isLoading ? (
                        <div className="w-[15%]">
                          <SkeletonText size="subheader" />
                        </div>
                      ) : (
                        <span className="flex-[1_0_0] w-full text-default-font text-subheader ph-no-capture">
                          {targetMember ? targetMember.user_name : 'Not Found'}
                        </span>
                      )}
                    </div>
                  </div>
                  {isLoading ? (
                    <div className="w-[20%]">
                      <SkeletonText />
                    </div>
                  ) : (
                    <span className="text-subtext-color text-body ph-no-capture">
                      {targetMember ? targetMember.user_email : 'Not Found'}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <InformationalHeader>
            <InformationalHeader.Item
              title="Current Org"
              bodySlot={
                <>
                  {isLoading ? (
                    <div className="h-6 w-24">
                      <SkeletonText />
                    </div>
                  ) : (
                    <span className="text-body-bold font-body-bold text-default-font">
                      {currentOrganization?.name}
                    </span>
                  )}
                </>
              }
            />
            <InformationalHeader.Item
              title="Added"
              bodySlot={
                <>
                  {isLoading ? (
                    <div className="h-6 w-24">
                      <SkeletonText />
                    </div>
                  ) : (
                    <span className="text-default-font text-body-bold">
                      {targetMember
                        ? getHumanReadableDate(targetMember.date_added)
                        : 'Not Found'}
                    </span>
                  )}
                </>
              }
            />
          </InformationalHeader>
          {isLoading ? (
            <Loader size="large" className="flex self-center" />
          ) : (
            <div className="w-full rounded flex-col shadow-shadow items-start flex">
              <div className="w-full flex-col gap-2 items-start flex pl-0 pr-2 py-2">
                <span className="w-full text-default-font overflow-hidden line-clamp-1">
                  Role
                </span>
              </div>
              <div className="w-full flex-col gap-4 items-start flex">
                <div className="w-full rounded gap-4 items-center flex p-4 bg-neutral-50">
                  <div className="h-8 items-center justify-center flex p-2">
                    <SubframeCore.Icon
                      className="text-subtext-color text-subheader"
                      name="FeatherUserCog"
                    />
                  </div>
                  <div className="flex-[1_0_0] w-full flex-col gap-1 items-start flex">
                    {targetMember ? (
                      <>
                        <span className="w-full text-default-font text-body-bold">
                          Admin
                        </span>
                        <span className="flex-[1_0_0] h-full text-subtext-color text-label">
                          Admin privileges on any teams of which they are a
                          member. They have read and write access to all
                          resources in the dashboard. Additionally, they can
                          manage memberships of teams that they are members of.
                        </span>
                      </>
                    ) : (
                      <span className="w-full text-default-font text-body-bold">
                        Member not found
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Page>
  );
}

export default TeamsMemberDetails;
