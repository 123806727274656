import { useListLARsStatus } from 'api/frontend';
import {
  useListClusterScanLARsInfinite,
  useListClusterScanSignaturesInfinite,
} from 'api/frontend-infinite';
import {
  ClusterDetectedLar,
  ClusterScan,
  LARStatus,
  ListClusterScansResponseV2,
} from 'api/models';
import { flattenPages } from 'utils/arrays';
import {
  Category,
  filterLARs,
  filterLARsOnCategory,
  filterSignatures,
  filterSignaturesOnCategory,
} from 'utils/filters';

type IgnoredStatusType = {
  [key: string]: boolean;
};

export const useGetClusterLARsStatus = (
  clusterId = '',
  scan: ClusterScan | undefined,
  scans: ListClusterScansResponseV2 | undefined,
  token: string,
  lastScanSummaryAvailable: boolean,
) => {
  const isScanValid =
    Boolean(scans?.data?.some((s) => s?.id === scan?.id)) || false;

  const { data: rawLarsData, isLoading: rawLarsDataLoading } =
    useListClusterScanLARsInfinite(
      clusterId,
      scan?.id || '',
      { page_size: 1000 },
      {
        swr: {
          enabled: !lastScanSummaryAvailable && isScanValid,
        },
        request: { headers: { authorization: `Bearer ${token}` } },
      },
    );

  const larsData = flattenPages(rawLarsData || [])?.sort((a, b) =>
    a.signature_id.localeCompare(b.signature_id),
  );

  const { data: rawSigsData, isLoading: rawSigsDataLoading } =
    useListClusterScanSignaturesInfinite(
      clusterId,
      scan?.id || '',
      { page_size: 1000 },
      {
        swr: {
          enabled: isScanValid && !lastScanSummaryAvailable,
        },
        request: { headers: { authorization: `Bearer ${token}` } },
      },
    );
  const sigsData = flattenPages(rawSigsData || [])?.sort((a, b) =>
    a.signature_id.localeCompare(b.signature_id),
  );

  const { data, mutate: larsStatusMutate } = useListLARsStatus(clusterId!, {
    request: { headers: { authorization: `Bearer ${token}` } },
    swr: {
      enabled: isScanValid && !lastScanSummaryAvailable,
      refreshInterval: 5 * 1000,
    },
  });
  const statusList: LARStatus[] = data?.data || [];

  const lars = filterLARs(larsData);
  const signatures = filterSignatures(sigsData);
  const undetectedSignaturesCount =
    signatures === undefined || lars === undefined
      ? undefined
      : signatures.length - lars.length;

  //ARSig Categories
  const defects = filterSignaturesOnCategory(sigsData, Category.DEFECTS);
  const misconfigurations = filterSignaturesOnCategory(
    sigsData,
    Category.MISCONFIGURATIONS,
  );
  const systemRequirements = filterSignaturesOnCategory(
    sigsData,
    Category.SYSTEM_REQUIREMENTS,
  );
  const deprecations = filterSignaturesOnCategory(
    sigsData,
    Category.DEPRECATIONS,
  );
  const unsupportedVersions = filterSignaturesOnCategory(
    sigsData,
    Category.UNSUPPORTED_VERSIONS,
  );
  const versionIncompatibilities = filterSignaturesOnCategory(
    sigsData,
    Category.VERSION_INCOMPATABILITIES,
  );
  const bestPractices = filterSignaturesOnCategory(
    sigsData,
    Category.BEST_PRACTICES,
  );

  // LAR Categories
  const defectsLARs = filterLARsOnCategory(larsData, Category.DEFECTS);
  const misconfigurationsLARs = filterLARsOnCategory(
    larsData,
    Category.MISCONFIGURATIONS,
  );
  const systemRequirementsLARs = filterLARsOnCategory(
    larsData,
    Category.SYSTEM_REQUIREMENTS,
  );
  const deprecationsLARs = filterLARsOnCategory(
    larsData,
    Category.DEPRECATIONS,
  );
  const unsupportedVersionsLARs = filterLARsOnCategory(
    larsData,
    Category.UNSUPPORTED_VERSIONS,
  );
  const versionIncompatibilitiesLARs = filterLARsOnCategory(
    larsData,
    Category.VERSION_INCOMPATABILITIES,
  );
  const bestPracticesLARs = filterLARsOnCategory(
    larsData,
    Category.BEST_PRACTICES,
  );

  const getIgnoredStatus = (statusList: LARStatus[]) => {
    const ret: IgnoredStatusType = {};
    if (statusList) {
      statusList.forEach((status) => {
        // add as lowercase to be used by filterStatusSignatures & filterStatusLARs
        ret[status.lar_id.toLowerCase()] = status.ignore;
      });
    }
    return ret;
  };
  const ignoredStatus: IgnoredStatusType = getIgnoredStatus(statusList);

  //Filters Ignored & Unignored LARs
  const filterStatusLARs = (lars: ClusterDetectedLar[] | undefined) => {
    const unignoredLars = lars?.filter(
      (lar) => !ignoredStatus[lar.signature_id.toLowerCase()],
    );
    const ignoredLars = lars?.filter(
      (lar) => ignoredStatus[lar.signature_id.toLowerCase()],
    );
    return {
      ignoredLars,
      unignoredLars,
    };
  };

  const { unignoredLars, ignoredLars } = filterStatusLARs(lars);

  //Defects
  const { unignoredLars: unignoredDefects, ignoredLars: ignoredDefects } =
    filterStatusLARs(defectsLARs);
  const undetectedDefects = defects?.filter((sig) => !sig.detected);
  const undetectedDefectsCount = undetectedDefects?.length;
  //Misconfigurations
  const {
    unignoredLars: unignoredMisconfigurations,
    ignoredLars: ignoredMisconfigurations,
  } = filterStatusLARs(misconfigurationsLARs);
  const undetectedMisconfigurations = misconfigurations?.filter(
    (sig) => !sig.detected,
  );
  const undetectedMisconfigurationsCount = undetectedMisconfigurations?.length;

  //System Requirements
  const {
    unignoredLars: unignoredSystemRequirements,
    ignoredLars: ignoredSystemRequirements,
  } = filterStatusLARs(systemRequirementsLARs);
  const undetectedSystemRequirements = systemRequirements?.filter(
    (sig) => !sig.detected,
  );
  const undetectedSystemRequirementsCount =
    undetectedSystemRequirements?.length;

  //Deprecations
  const {
    unignoredLars: unignoredDeprecations,
    ignoredLars: ignoredDeprecations,
  } = filterStatusLARs(deprecationsLARs);
  const undetectedDeprecations = deprecations?.filter((sig) => !sig.detected);
  const undetectedDeprecationsCount = undetectedDeprecations?.length;

  //Unsupported Versions
  const {
    unignoredLars: unignoredUnsupportedVersions,
    ignoredLars: ignoredUnsupportedVersions,
  } = filterStatusLARs(unsupportedVersionsLARs);
  const undetectedUnsupportedVersions = unsupportedVersions?.filter(
    (sig) => !sig.detected,
  );
  const undetectedUnsupportedVersionsCount =
    undetectedUnsupportedVersions?.length;

  //Version Incompatibilities
  const {
    unignoredLars: unignoredVersionIncompatibilities,
    ignoredLars: ignoredVersionIncompatibilities,
  } = filterStatusLARs(versionIncompatibilitiesLARs);
  const undetectedVersionIncompatibilities = versionIncompatibilities?.filter(
    (sig) => !sig.detected,
  );
  const undetectedVersionIncompatibilitiesCount =
    undetectedVersionIncompatibilities?.length;

  //Best Practices
  const {
    unignoredLars: unignoredBestPractices,
    ignoredLars: ignoredBestPractices,
  } = filterStatusLARs(bestPracticesLARs);

  return {
    rawLarsDataLoading,
    rawSigsDataLoading,
    larsStatusMutate,
    statusList,
    lars,
    undetectedSignaturesCount,
    unignoredLars,
    ignoredLars,
    defects,
    defectsLARs,
    unignoredDefects,
    ignoredDefects,
    undetectedDefectsCount,
    misconfigurations,
    misconfigurationsLARs,
    unignoredMisconfigurations,
    ignoredMisconfigurations,
    undetectedMisconfigurationsCount,
    systemRequirements,
    systemRequirementsLARs,
    unignoredSystemRequirements,
    ignoredSystemRequirements,
    undetectedSystemRequirementsCount,
    deprecations,
    deprecationsLARs,
    unignoredDeprecations,
    ignoredDeprecations,
    undetectedDeprecationsCount,
    unsupportedVersions,
    unsupportedVersionsLARs,
    unignoredUnsupportedVersions,
    ignoredUnsupportedVersions,
    undetectedUnsupportedVersionsCount,
    versionIncompatibilities,
    versionIncompatibilitiesLARs,
    unignoredVersionIncompatibilities,
    ignoredVersionIncompatibilities,
    undetectedVersionIncompatibilitiesCount,
    bestPractices,
    bestPracticesLARs,
    unignoredBestPractices,
    ignoredBestPractices,
  };
};
