import { ActivityFeedbackFrame } from 'subframe/components/ActivityFeedbackFrame';
import { Account } from 'models/account';
import { useState } from 'react';
import * as Sentry from '@sentry/browser';
import { isPlanActionDisabled } from './UpgradesHelpers';
import { UpgradeStatus } from 'api/models/upgradeStatus';
import { AtomicTooltip } from 'components/design-system';
import { UPGRADES } from 'src/data/upgrades';
import { UpdateUpgradeStageStepRequest } from 'api/models';
import { updateUpgradePlanStageStep } from 'api/frontend';
import { Button } from 'subframe/index';
import * as SubframeCore from '@subframe/core';

// copied from subframe/components/Button, not exportable
interface ButtonRootProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?:
    | 'brand-primary'
    | 'brand-secondary'
    | 'brand-tertiary'
    | 'neutral-primary'
    | 'neutral-secondary'
    | 'neutral-tertiary'
    | 'destructive-primary'
    | 'destructive-secondary'
    | 'destructive-tertiary'
    | 'inverse';
  size?: 'large' | 'medium' | 'small';
  children?: string;
  icon?: SubframeCore.IconName;
  iconRight?: SubframeCore.IconName;
  loading?: boolean;
  className?: string;
}
interface StepActionButtonProps extends ButtonRootProps {
  account: Account;
  upgradeId: string;
  stageNumber: number;
  stepNumber: number;
  update: UpdateUpgradeStageStepRequest;
  onSuccess: () => void;
  upgradeStatus?: UpgradeStatus;
  isTemplate?: boolean;
  children: string;
}
export function StepActionButton({
  account,
  upgradeId,
  stageNumber,
  stepNumber,
  update,
  children,
  onSuccess,
  upgradeStatus,
  isTemplate,
  ...otherProps
}: StepActionButtonProps): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [variant, setVariant] = useState<'default' | 'success' | 'error'>(
    'default',
  );
  const showExampleData = UPGRADES.map((upgr) => upgr.id).includes(upgradeId)
    ? true
    : false;

  return (
    <ActivityFeedbackFrame variant={variant}>
      {isPlanActionDisabled(upgradeStatus) || isTemplate ? (
        <AtomicTooltip
          tooltipContent={
            isTemplate
              ? 'Action not allowed on Upgrade Template'
              : `Action not allowed on ${upgradeStatus} Upgrade Plan`
          }
        >
          <Button size="large" {...otherProps} disabled={true}>
            {children}
          </Button>
        </AtomicTooltip>
      ) : (
        <Button
          size="large"
          loading={loading}
          onClick={async () => {
            if (!showExampleData) {
              setLoading(true);
              try {
                await updateUpgradePlanStageStep(
                  upgradeId,
                  stageNumber.toString(),
                  stepNumber.toString(),
                  update,
                  {
                    headers: { Authorization: `Bearer ${account.token}` },
                  },
                );

                // we'll wait here for a sec to give the backend some time
                await new Promise((f) => setTimeout(f, 3000));

                onSuccess();
                setVariant('success');
              } catch (err) {
                Sentry.captureException(err);
                setVariant('error');
              }
              setLoading(false);
              setTimeout(() => setVariant('default'), 3000);
            }
          }}
          {...otherProps}
        >
          {children}
        </Button>
      )}
    </ActivityFeedbackFrame>
  );
}
