/*
 * Documentation:
 * [Draft] - Teams — https://app.subframe.com/library?component=%5BDraft%5D+-+Teams_ca614274-cfaf-41d4-a25c-bf54bb16c764
 * Dropdown Menu — https://app.subframe.com/library?component=Dropdown+Menu_99951515-459b-4286-919e-a89e7549b43b
 * Avatar Menu + org — https://app.subframe.com/library?component=Avatar+Menu+%2B+org_dcc4f957-4f38-4f6d-970b-87a09d510b8e
 * Top Navbar — https://app.subframe.com/library?component=Top+Navbar_f3d2630e-a5fc-4fcb-81fb-095957b87fae
 * Breadcrumb — https://app.subframe.com/library?component=Breadcrumb_44b6fbd0-acf9-4944-8c86-0343ed858677
 * Tabs — https://app.subframe.com/library?component=Tabs_e1ad5091-8ad8-4319-b1f7-3e47f0256c20
 * Button — https://app.subframe.com/library?component=Button_3b777358-b86b-40af-9327-891efc6826fe
 * Text Input — https://app.subframe.com/library?component=Text+Input_be48ca43-f8e7-4c0e-8870-d219ea11abfe
 * Avatar Image — https://app.subframe.com/library?component=Avatar+Image_ce9959fe-701f-440f-913c-132cdebc1dd5
 * Table — https://app.subframe.com/library?component=Table_142dfde7-d0cc-48a1-a04c-a08ab2252633
 * Tooltip — https://app.subframe.com/library?component=Tooltip_ccebd1e9-f6ac-4737-8376-0dfacd90c9f3
 * Icon Button — https://app.subframe.com/library?component=Icon+Button_af9405b1-8c54-4e01-9786-5aad308224f6
 * Avatar — https://app.subframe.com/library?component=Avatar_bec25ae6-5010-4485-b46b-cf79e3943ab2
 */

import * as SubframeCore from '@subframe/core';
import { useState } from 'react';
import { Breadcrumbs } from 'subframe/index';
import { Tabs } from 'subframe/components/Tabs';
import { Button } from 'subframe/components/Button';
import useUserAccountState from 'hooks/useUserAccountState';
import { useGetAccountTeam, useListTeamInvitations } from 'api/frontend';
import TeamsMembers from './components/TeamMembers';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import TeamsPendingMembers from './components/TeamPendingMembers';
import Page from 'components/Page';
import { Alert, SkeletonText } from 'subframe/index';
import { InviteTeamMemberDialog } from 'components/dialogs';
import useDialog from 'hooks/useDialog';
import { CircularProgress } from '@mui/material';
import { useGate } from 'statsig-react';
import { useSnackbar } from 'notistack';

function Teams() {
  const { account } = useUserAccountState();
  const { logEvent } = AnalyticsEventLogger();
  const { setDialogType, currentDialog } = useDialog();
  const { enqueueSnackbar } = useSnackbar();
  const showSsoBanner = useGate('teams_sso_banner');

  type TabTypes = 'members' | 'pending';

  const {
    data: membersListToShow,
    isValidating,
    mutate: refetchMembers,
  } = useGetAccountTeam('default', 'default', {
    request: { headers: { Authorization: `Bearer ${account.token}` } },
  });

  const {
    data: pendingMembersList,
    isValidating: validatingPendingMembers,
    mutate: refetchPendingMembers,
  } = useListTeamInvitations(
    'default',
    'default',
    {},
    {
      request: { headers: { Authorization: `Bearer ${account.token}` } },
    },
  );

  const [currentTab, setCurrentTab] = useState<TabTypes>('members');
  const isCurrentTabLoading =
    currentTab === 'members'
      ? isValidating && !membersListToShow
      : validatingPendingMembers && !pendingMembersList;

  const TEAM_TABS = [
    {
      key: 'members',
      id: 'members-tab',
      title: `Members ${
        membersListToShow ? `(${membersListToShow?.members?.length})` : ''
      }`,
      icon: 'FeatherUsers' as SubframeCore.IconName,
      trackerEvent: 'team-members-tab',
      component: !isCurrentTabLoading && (
        <TeamsMembers
          members={membersListToShow?.members || []}
          refetchMembers={refetchMembers}
        />
      ),
    },
    {
      key: 'pending',
      id: 'pending-members-tab',
      title: `Pending (${pendingMembersList?.data?.length || 0})`,
      icon: 'FeatherUserMinus2',
      trackerEvent: 'team-pending-tab',
      component: !isCurrentTabLoading && (
        <TeamsPendingMembers
          pendingMembers={pendingMembersList?.data || []}
          refetchPendingMembers={refetchPendingMembers}
        />
      ),
    },
  ];
  return (
    <Page title="Teams">
      <InviteTeamMemberDialog
        handleOpenChange={(open) => {
          if (!open) {
            refetchMembers();
            refetchPendingMembers();
            setDialogType('');
          }
        }}
        open={currentDialog === 'invite-member-to-team'}
      />
      <div className="h-full flex-col gap-6 items-start flex pl-0 pr-3 pt-8 pb-0 bg-default-background ml-8 w-[calc(100%-64px)]">
        <div className="w-full flex-col items-start flex">
          <div className="w-full flex-col gap-6 items-start flex pl-3 pr-0 py-0">
            <div className="w-full flex-col gap-6 items-start flex">
              <Breadcrumbs>
                <Breadcrumbs.Item>Configure</Breadcrumbs.Item>
                <Breadcrumbs.Divider />
                <Breadcrumbs.Item active={true}>Teams</Breadcrumbs.Item>
              </Breadcrumbs>
              <div className="w-full flex-col gap-6 items-start flex">
                {showSsoBanner.value && (
                  <Alert
                    variant="warning"
                    title={
                      'Setup SSO Integration to reduce management overhead!'
                    }
                    description={
                      'Admins can securely deploy Chkk to their organizations with SSO. Employees use their existing ' +
                      'corporate login when accessing the Chkk Dashboard, removing the need for further credential management. ' +
                      'This includes provisioning and deprovisioning of accounts as people join and leave your organization. ' +
                      'Chkk supports SAML and OIDC. Click on this banner to get started with setting up the integration.'
                    }
                    className="cursor-pointer"
                    onClick={() => {
                      logEvent('teams-sso-banner-click');
                      enqueueSnackbar(
                        "Thank you for the interest in getting SSO setup. A member of Chkk's support team will be in touch soon.",
                        {
                          variant: 'success',
                        },
                      );
                    }}
                  />
                )}
                <div className="w-full justify-between items-center flex">
                  <Tabs
                    id="team-details-tabs"
                    className="h-auto w-auto flex-none"
                  >
                    {TEAM_TABS.map((tab) => (
                      <Tabs.Item
                        id={tab.id}
                        key={tab.key}
                        active={tab.key === currentTab}
                        onClick={() => {
                          logEvent(tab.trackerEvent);
                          setCurrentTab(tab.key as TabTypes);
                        }}
                        icon={tab.icon as SubframeCore.IconName}
                      >
                        {isCurrentTabLoading ? (
                          <SkeletonText className="h-6 w-[100px]" />
                        ) : (
                          tab.title
                        )}
                      </Tabs.Item>
                    ))}
                  </Tabs>
                  <Button
                    icon="FeatherPlus"
                    size="large"
                    onClick={() => {
                      setDialogType('invite-member-to-team');
                      logEvent('invite-new-member');
                    }}
                    data-cy="invite-new-member-button"
                  >
                    Invite Team Member
                  </Button>
                </div>
                <div className="w-full flex-col gap-3 items-start flex">
                  {TEAM_TABS.map((tab) => {
                    const isMatched = tab.key === currentTab;
                    return (
                      isMatched && (
                        <>
                          {isCurrentTabLoading ? (
                            <div className="flex self-center">
                              <CircularProgress />
                            </div>
                          ) : (
                            tab.component
                          )}
                        </>
                      )
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default Teams;
