'use client';

import React, { useState } from 'react';
import { IconButton } from 'subframe/components/IconButton';
import { TextArea } from 'subframe/components/TextArea';
import { Button } from 'subframe/components/Button';
import { Dialog } from 'subframe/components/Dialog';
import useUserAccountState from 'hooks/useUserAccountState';
import { useSnackbar } from 'notistack';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { updateUpgradeTemplate } from 'api/frontend';
import { AtomicTooltip } from 'components/design-system';
import FormProvider from 'components/FormProvider';
import * as Sentry from '@sentry/react';

interface MarkUpgradeTemplateAsEnvironmentUpgradedProps {
  templateId: string;
  mutateTemplate: () => void;
}

function MarkUpgradeTemplateAsEnvironmentUpgraded({
  templateId,
  mutateTemplate,
}: MarkUpgradeTemplateAsEnvironmentUpgradedProps) {
  const { account, currentOrganization } = useUserAccountState();
  const { enqueueSnackbar } = useSnackbar();
  const { logEvent } = AnalyticsEventLogger();
  const [open, setOpen] = useState(false);

  const markAsCompletedSchema = Yup.object().shape({
    comment: Yup.string().max(500).optional(),
  });

  const defaultValues: { comment: string } = {
    comment: '',
  };

  const methods = useForm<{ comment: string }>({
    resolver: yupResolver(markAsCompletedSchema),
    defaultValues: defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    clearErrors,
    formState: { isSubmitting, isValid, errors },
  } = methods;

  const resetToDefault = () => {
    setValue('comment', '');
  };

  const onSubmit = async (data: { comment: string }) => {
    try {
      const response = await updateUpgradeTemplate(
        templateId,
        { status: 'completed', status_comment: data.comment },
        {
          headers: {
            Authorization: `Bearer ${account.token}`,
          },
        },
      );

      if (response?.__status === 200) {
        enqueueSnackbar('Upgrade plan marked as Environment Upgraded', {
          variant: 'success',
        });
        logEvent('upgrade_plan_completed', {
          upgrade_id: templateId,
          organization_id: currentOrganization.id,
        });
        mutateTemplate();
      } else {
        enqueueSnackbar('Failed to mark upgrade plan as Environment Upgraded', {
          variant: 'error',
        });
        Sentry.captureException({
          data: { response, templateId, data },
          error: 'Failed to mark upgrade plan as Environment Upgraded',
        });
      }
    } catch (e) {
      Sentry.captureException(e);
    }

    setOpen(false);
    resetToDefault();
  };

  return (
    <div style={{ display: 'contents' }}>
      <AtomicTooltip
        tooltipContent={`Mark the Upgrade Template as Environment Upgraded.`}
      >
        <IconButton
          icon="FeatherCheck"
          size="medium"
          onClick={() => setOpen(true)}
        />
      </AtomicTooltip>
      <Dialog
        open={open}
        onOpenChange={(openStatus) => {
          setOpen(openStatus);
          resetToDefault();
        }}
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Dialog.Content className="h-auto w-auto flex-none">
            <div className="flex w-full min-w-[384px] max-w-[448px] flex-col items-start gap-6 pt-6 pr-6 pb-6 pl-6">
              <div className="flex w-full items-start justify-between">
                <span className="text-subheader font-subheader text-default-font">
                  Ready to mark this Upgrade Template as Environment Upgraded?
                </span>
                <IconButton
                  size="medium"
                  icon="FeatherX"
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    setOpen(false);
                  }}
                />
              </div>
              <span className="text-body font-body text-default-font">
                The Upgrade Template will become read-only once marked as
                Environment Upgraded.
              </span>
              <div className="flex w-full flex-col items-start gap-2">
                <span className="text-body-bold font-body-bold text-subtext-color">
                  Comments (Optional)
                </span>
                <TextArea
                  className="h-auto w-full flex-none"
                  helpText={errors.comment?.message}
                  error={methods.getFieldState('comment').invalid}
                >
                  <TextArea.Input
                    className="h-auto min-h-[96px] w-full flex-none"
                    placeholder=""
                    {...methods.register('comment')}
                  />
                </TextArea>
              </div>
              <div className="flex w-full items-end justify-end gap-4">
                <Button
                  variant="brand-primary"
                  size="large"
                  icon="FeatherCheck"
                  onClick={handleSubmit(onSubmit)}
                  disabled={isSubmitting}
                  loading={isSubmitting}
                >
                  Mark as Environment Upgraded
                </Button>
              </div>
            </div>
          </Dialog.Content>
        </FormProvider>
      </Dialog>
    </div>
  );
}

export default MarkUpgradeTemplateAsEnvironmentUpgraded;
