import * as SubframeCore from '@subframe/core';
import { AccountAddon, AddonInstance, Cluster } from 'api/models';
import Page from 'components/Page';
import { RouterLink } from 'components/RouterLink';
import { AtomicTooltip } from 'components/design-system';
import BaseTable from 'components/design-system/Table/BaseTable';
import { Stack } from 'components/utils';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import { useAddonIdToNameMap } from 'hooks/useAddonIdToNameMap';
import useLocalStorage from 'hooks/useLocalStorage';
import useUserAccountState from 'hooks/useUserAccountState';
import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'react-use';
import {
  ADDONS,
  getAddonExpiryInWords,
  getAddonExpiryState,
  getEarliestAddonExpiryInDays,
  getHumanReadableImageTag,
} from 'src/data/version_register_addons';
import { CLUSTERS } from 'src/data/version_register_cp';
import {
  Alert,
  Breadcrumbs,
  Button,
  InfoTooltip,
  InformationalHeader,
  SkeletonText,
  Switch,
} from 'subframe/index';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { clusterFlattenPages, flattenPages } from 'utils/arrays';
import styles from './AcmeVersionRegisterAddOnsLandingPage.module.scss';
import { Searchbar } from 'components/design-system/Searchbar';
import { Select } from 'subframe/components/Select';
import { groupBy } from 'lodash';
import { moreTooltipContent } from 'components/design-system/AtomicTooltip';
import {
  useListAddonInstancesInfinite,
  useListAddonsInfinite,
  useListClustersInfinite,
} from 'api/frontend-infinite';
import { AddonsInfoHeader } from './model';
import { formatDistanceToNow, fromUnixTime } from 'date-fns';
import * as semver from 'semver';

export default function VersionRegisterAddonsIndex() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { logEvent } = AnalyticsEventLogger();
  const { account } = useUserAccountState();
  const basePath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/artifact_register/addons',
  );
  const explorePath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/explore/supported?tab=addons',
  );

  const [filterCluster, setFilterCluster] = useState<string | undefined>(
    searchParams.get('cluster') || undefined,
  );

  const [showExampleData, setShowExampleData] = useLocalStorage<boolean>(
    'example-data',
    true,
  );

  const { data: accountAddons, isLoading: isLoadingAccountAddons } =
    useListAddonsInfinite(
      {},
      {
        swr: {
          revalidateOnFocus: true,
          revalidateOnMount: true,
        },
        request: { headers: { Authorization: `Bearer ${account.token}` } },
      },
      {
        revalidateFirstPage: true,
      },
    );
  const totalAccountAddons = flattenPages(accountAddons || []);

  const {
    data: addonInstanceByCluster,
    isLoading: isLoadingAddonInstanceByCluster,
    size,
  } = useListAddonInstancesInfinite(
    {
      filter: ['status:active'],
      cluster_id: filterCluster,
    },
    {
      request: { headers: { Authorization: `Bearer ${account.token}` } },
    },
  );

  const addonIdToNameMap = useAddonIdToNameMap({ token: account.token });

  const { data: clustersList, isLoading: isLoadingClustersList } =
    useListClustersInfinite(
      '',
      { status: 'active' },
      {
        request: { headers: { Authorization: `Bearer ${account?.token}` } },
      },
      {
        initialSize: 100,
        revalidateFirstPage: true,
      },
    );

  const listClustersResponse = clusterFlattenPages(clustersList || []) || [];

  const realClusterData =
    listClustersResponse && !isLoadingClustersList
      ? listClustersResponse?.filter(
          (c) => c.internal_k8s_ref !== 'example-cluster-ref',
        )
      : [];

  const isFirstGlobalScanPending =
    !isLoadingClustersList &&
    realClusterData?.length > 0 &&
    !isLoadingAccountAddons &&
    !totalAccountAddons?.length;

  useEffect(() => {
    if (listClustersResponse && localStorage.getItem('example-data') === null) {
      setShowExampleData(!realClusterData.length);
    }
  }, [listClustersResponse]);

  useEffect(() => {
    logEvent('version-register-addons-listing-viewed', {
      showExampleData,
    });
    setSearchParams({});
  }, [showExampleData]);

  const filterAndExampleData: AddonInstance[] | undefined = showExampleData
    ? ADDONS
    : filterCluster
    ? flattenPages(addonInstanceByCluster || [])
    : undefined;

  const instancesByAddonId: { [addonId: string]: AddonInstance[] } =
    useMemo(() => {
      return groupBy(
        filterAndExampleData?.filter(
          (instance) =>
            filterCluster === undefined ||
            instance.cluster_id === filterCluster,
        ) || [],
        (instance) => instance.addon_id,
      );
    }, [addonInstanceByCluster, showExampleData]);

  const clusterData: Cluster[] = showExampleData ? CLUSTERS : realClusterData;

  const tableData = useMemo(() => {
    if (showExampleData || filterCluster) {
      return Object.entries(instancesByAddonId)
        .filter(
          (
            item, // filter out disabled addons
          ) => addonIdToNameMap.get(item[0]),
        )
        .sort((a, b) => {
          const expiryA = getEarliestAddonExpiryInDays(a[1]);
          const expiryB = getEarliestAddonExpiryInDays(b[1]);
          if (expiryA === undefined && expiryB === undefined) {
            return 0;
          } else if (expiryA === undefined) {
            return 1;
          } else if (expiryB === undefined) {
            return -1;
          } else {
            return expiryA - expiryB;
          }
        });
    } else if (!filterCluster) {
      return totalAccountAddons?.sort((a, b) => {
        const expiryA = getEarliestAddonExpiryInDays([a]);
        const expiryB = getEarliestAddonExpiryInDays([b]);
        if (expiryA === undefined && expiryB === undefined) {
          return 0;
        } else if (expiryA === undefined) {
          return 1;
        } else if (expiryB === undefined) {
          return -1;
        } else {
          return expiryA - expiryB;
        }
      });
    }
  }, [instancesByAddonId, totalAccountAddons, showExampleData]);

  const [searchAddon, setSearchAddon] = useState('');
  const handleOnChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: string,
  ) => {
    setSearchAddon(newValue);
  };

  useDebounce(
    () => {
      if (searchAddon) {
        logEvent('search-addon', { searchAddon });
      }
    },
    1000,
    [searchAddon],
  );

  const addonsToShow = useMemo(() => {
    if (tableData) {
      if (filterCluster || showExampleData) {
        return searchAddon
          ? (tableData as [string, AddonInstance[]][]).filter((item) => {
              return addonIdToNameMap
                .get(typeof item[0] == 'string' ? item[0] : '')
                ?.toLowerCase()
                .includes(searchAddon.toLowerCase());
            })
          : tableData;
      } else if (!filterCluster) {
        return searchAddon
          ? tableData?.filter(
              (item) =>
                'earliest_eol_date' in item &&
                item?.name.toLowerCase().includes(searchAddon.toLowerCase()),
            )
          : tableData;
      }
    }
  }, [searchAddon, tableData, showExampleData]);

  const isTableLoading =
    (!showExampleData &&
      ((!filterCluster && isLoadingAccountAddons) ||
        (filterCluster && isLoadingAddonInstanceByCluster))) ||
    (showExampleData && !addonIdToNameMap?.size);

  const addonInfoHeaderData: AddonsInfoHeader = useMemo(() => {
    const lastUpdated =
      !isLoadingAccountAddons && accountAddons?.length
        ? formatDistanceToNow(
            fromUnixTime(accountAddons[0]?.data[0]?.created || 0),
            {
              addSuffix: true,
            },
          ).replace('about', '~')
        : '';
    if (showExampleData || filterCluster) {
      let data = tableData as [string, AddonInstance[]][];
      return {
        total_addons_count: data?.length,
        eol_in_90_days_count: data?.filter(
          ([, instances]) => getAddonExpiryState(instances) === 'warning',
        ).length,
        eol_count: data.filter(
          ([, instances]) => getAddonExpiryState(instances) === 'error',
        ).length,
        last_updated: lastUpdated,
      };
    } else {
      let data = tableData as AccountAddon[];

      return {
        total_addons_count: data?.length,
        eol_in_90_days_count: data?.filter(
          (instance) => getAddonExpiryState([instance]) === 'warning',
        )?.length,
        eol_count: data?.filter(
          (instance) => getAddonExpiryState([instance]) === 'error',
        ).length,
        last_updated: lastUpdated,
      };
    }
  }, [showExampleData, addonsToShow]);

  const isInformationalHeaderLoading =
    (!showExampleData && isTableLoading) ||
    (showExampleData && !addonIdToNameMap?.size);

  const newClusterPath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/clusters/new',
  );
  const isClusterOboarded =
    realClusterData?.length &&
    !isLoadingClustersList &&
    !isLoadingAccountAddons;

  return (
    <Page title="Add-on Register">
      <div
        className={styles['pageContents']}
        style={{ marginLeft: '32px', width: 'calc(100% - 32px' }}
      >
        <Breadcrumbs>
          <Breadcrumbs.Item>Artifact Register</Breadcrumbs.Item>
          <Breadcrumbs.Divider name="FeatherChevronRight" />
          <Breadcrumbs.Item active={true}>Add-ons</Breadcrumbs.Item>
        </Breadcrumbs>

        <div className="flex w-full flex-col items-start gap-2">
          <div className="flex w-full items-center justify-between">
            <span className="text-section-header font-section-header text-default-font">
              Add-On Register
            </span>
          </div>
          <div className="flex w-full items-center gap-2">
            <div className="flex flex-col items-start gap-2">
              <span className="text-body font-body text-subtext-color">
                Add-ons running in your Infrastructure
              </span>
            </div>
            <div className="flex grow shrink-0 basis-0 items-center justify-end gap-2">
              <span className="text-label font-label text-subtext-color">
                Show example data
              </span>
              <SubframeCore.Tooltip.Provider>
                <SubframeCore.Tooltip.Root>
                  <SubframeCore.Tooltip.Trigger asChild={true}>
                    <AtomicTooltip
                      tooltipContent={
                        showExampleData
                          ? 'Show results from your onboarded clusters'
                          : 'Show example data'
                      }
                    >
                      <Switch
                        data-cy="switch"
                        checked={showExampleData}
                        onClick={() => setShowExampleData(!showExampleData)}
                      />
                    </AtomicTooltip>
                  </SubframeCore.Tooltip.Trigger>
                  <SubframeCore.Tooltip.Portal>
                    <SubframeCore.Tooltip.Content
                      side="bottom"
                      align="center"
                      sideOffset={8}
                      asChild={true}
                    >
                      <AtomicTooltip
                        tooltipContent={'Turn example data on/off'}
                      />
                    </SubframeCore.Tooltip.Content>
                  </SubframeCore.Tooltip.Portal>
                </SubframeCore.Tooltip.Root>
              </SubframeCore.Tooltip.Provider>
            </div>
          </div>
        </div>

        {showExampleData && (
          <Alert
            variant="warning"
            title="You are currently viewing example data"
            description={`To view results from your onboarded clusters, toggle the "Show example data" button`}
          />
        )}
        {addonIdToNameMap.size > 0 && (
          <RouterLink
            to={explorePath}
            style={{
              width: '100%',
            }}
            onClick={() => logEvent('version-register-addons-explore-clicked')}
          >
            <Alert
              className={styles.fullWidth}
              variant="neutral"
              title={`Chkk covers ${addonIdToNameMap.size} K8s Add-ons`}
              description="Check out the Clouds & Add-ons page for details of what Chkk covers"
              icon="FeatherCloudy"
            />
          </RouterLink>
        )}
        {!showExampleData &&
        (isLoadingClustersList || isLoadingAccountAddons) ? (
          <SubframeCore.Loader />
        ) : (
          <>
            {!showExampleData && !isClusterOboarded && (
              <div className={styles['stack-807f0c5d']}>
                <span className={styles['bodyText']}>
                  No clusters onboarded so far. Click here to onboard your first
                  cluster
                </span>
                <RouterLink to={newClusterPath}>
                  <Button size="small" icon="FeatherPlus" data-cy="add-cluster">
                    Add Cluster
                  </Button>
                </RouterLink>
              </div>
            )}
            {!showExampleData && isFirstGlobalScanPending && (
              <div className={styles['stack-b5750724']}>
                <span className={styles['bodyText']}>
                  The Add-On register update is currently in progress and may
                  take up to 15 minutes to complete.
                </span>
              </div>
            )}
          </>
        )}
        {showExampleData || (isClusterOboarded && !isFirstGlobalScanPending) ? (
          <div className={styles['data']}>
            <>
              <div
                className={styles['stack-e8f7d8c7']}
                data-cy="vr-addon-header"
              >
                <InformationalHeader className="flex-[0_0_auto] h-auto w-auto">
                  <InformationalHeader.Item
                    data-cy={
                      !isInformationalHeaderLoading
                        ? 'total-addons'
                        : 'loading-total-addons'
                    }
                    title="Total"
                    headingSlot={
                      <InfoTooltip tooltipText="Covered Add-ons detected across all the clusters" />
                    }
                    bodySlot={
                      <span className="text-body-bold font-body-bold text-default-font">
                        {!isInformationalHeaderLoading ? (
                          addonInfoHeaderData?.total_addons_count
                        ) : (
                          <SkeletonText className="h-[20px] w-[20px]" />
                        )}
                      </span>
                    }
                  />

                  <InformationalHeader.Item
                    data-cy={
                      !isInformationalHeaderLoading
                        ? 'eos-count'
                        : 'loading-eos-count'
                    }
                    title="EOL in 90d"
                    headingSlot={
                      <InfoTooltip tooltipText="Count of Add-ons with at least one version that will hit EOL in the next 90d" />
                    }
                    icon={
                      <SubframeCore.Icon
                        data-cy="yellow-clock"
                        className={styles['icon-306cfbd0']}
                        name="FeatherClock"
                      />
                    }
                    bodySlot={
                      <span className="text-body-bold font-body-bold text-default-font">
                        {!isInformationalHeaderLoading ? (
                          addonInfoHeaderData?.eol_in_90_days_count
                        ) : (
                          <SkeletonText className="h-[20px] w-[20px]" />
                        )}
                      </span>
                    }
                  />

                  <InformationalHeader.Item
                    data-cy={
                      !isInformationalHeaderLoading
                        ? 'eol-count'
                        : 'loading-eol-count'
                    }
                    title="EOL"
                    headingSlot={
                      <InfoTooltip tooltipText="Count of Add-ons with at least one version that has already hit EOL" />
                    }
                    icon={
                      <SubframeCore.Icon
                        data-cy="red-clock"
                        className={styles['icon']}
                        name="FeatherClock"
                      />
                    }
                    bodySlot={
                      <span className="text-body-bold font-body-bold text-default-font">
                        {!isInformationalHeaderLoading ? (
                          addonInfoHeaderData?.eol_count
                        ) : (
                          <SkeletonText className="h-[20px] w-[20px]" />
                        )}
                      </span>
                    }
                  />

                  {accountAddons && accountAddons[0]?.data[0]?.created && (
                    <InformationalHeader.Item
                      data-cy={
                        !isInformationalHeaderLoading
                          ? 'last-updated'
                          : 'loading-last-updated'
                      }
                      title="Last Updated"
                      headingSlot={
                        <InfoTooltip tooltipText="Timestamp when the Artifact Register was last updated." />
                      }
                      bodySlot={
                        <span className="text-body-bold font-body-bold text-default-font">
                          {!isLoadingAccountAddons &&
                          accountAddons &&
                          addonInfoHeaderData?.last_updated ? (
                            addonInfoHeaderData?.last_updated
                          ) : (
                            <SkeletonText className="h-[20px] w-[20px]" />
                          )}
                        </span>
                      }
                    />
                  )}
                </InformationalHeader>
                <div className={styles['stack-12eeb243']}>
                  {totalAccountAddons?.length && addonIdToNameMap ? (
                    <Searchbar
                      onChange={handleOnChange}
                      view={showExampleData ? 'example' : 'real'}
                    />
                  ) : (
                    <div style={{ height: '32px' }} />
                  )}
                  {!isLoadingClustersList || showExampleData ? (
                    <Select
                      data-cy="dropdown-button"
                      icon={null}
                      placeholder={
                        filterCluster === undefined
                          ? 'All Clusters'
                          : clusterData.find((f) => f.id === filterCluster)
                              ?.name || 'Unknown'
                      }
                      onValueChange={(value) => {
                        if (value === 'all') {
                          setSearchParams({});
                          setFilterCluster(undefined);
                        } else {
                          setSearchParams({ cluster: value });
                          setFilterCluster(value);
                        }
                      }}
                    >
                      <Select.Item key="all" value="all">
                        All Clusters
                      </Select.Item>
                      {clusterData?.map((cluster) => (
                        <Select.Item
                          key={cluster.id}
                          value={cluster.id}
                          data-cy="dropdown-cluster-names"
                        >
                          {cluster?.name || 'Unknown'}
                        </Select.Item>
                      ))}
                    </Select>
                  ) : (
                    <SkeletonText className="h-[30px] w-[150px] flex-none" />
                  )}
                </div>
              </div>
            </>
            {!isTableLoading ? (
              <>
                {!filterCluster &&
                  !showExampleData &&
                  !isLoadingAccountAddons &&
                  totalAccountAddons &&
                  totalAccountAddons?.length && (
                    <div className={styles['stack-7722959e']}>
                      <BaseTable
                        settings={{
                          localStorageKey:
                            'artifact_register_addons_index_table',
                          dataCyPrefix: 'vr-addon',
                        }}
                        data={
                          addonsToShow && typeof addonsToShow[0] !== 'string'
                            ? (addonsToShow as AccountAddon[])
                            : []
                        }
                        rowLink={(instances) =>
                          `${basePath}/${instances?.id}${
                            filterCluster === undefined
                              ? ''
                              : '?cluster=' + filterCluster
                          }`
                        }
                        columns={[
                          {
                            id: '1',
                            title: 'Name',
                            titleStyle: { width: '40%' },
                            cellType: 'cell',
                            render: (instances) => {
                              const addonExpiryState = getAddonExpiryState([
                                instances,
                              ]);

                              return (
                                <>
                                  {addonExpiryState === 'error' && (
                                    <AtomicTooltip
                                      tooltipContent={`End of Life/Support: ${getAddonExpiryInWords(
                                        [instances],
                                      )}`}
                                    >
                                      <SubframeCore.Icon
                                        data-cy="red-icon"
                                        className={styles['icon-3a04925b']}
                                        name="FeatherClock"
                                      />
                                    </AtomicTooltip>
                                  )}
                                  {addonExpiryState === 'warning' && (
                                    <AtomicTooltip
                                      tooltipContent={`End of Life/Support: ${getAddonExpiryInWords(
                                        [instances],
                                      )}`}
                                    >
                                      <SubframeCore.Icon
                                        data-cy="yellow-icon"
                                        className={styles['icon-5f938ed1']}
                                        name="FeatherClock"
                                      />
                                    </AtomicTooltip>
                                  )}
                                  <span className={styles['bodyText']}>
                                    {instances?.name}
                                  </span>
                                </>
                              );
                            },
                          },
                          {
                            id: '2',
                            title: (
                              <Stack direction="row" spacing={1}>
                                <>Tags</>
                                <InfoTooltip tooltipText="All Tags that have been locally applied to an Add-on's container images" />
                              </Stack>
                            ),
                            titleStyle: { width: '40%' },
                            cellType: 'cell',
                            render: (instances) => {
                              const sortedCommonTags = instances?.common_tags
                                ? Object.entries(instances?.common_tags).sort(
                                    (a, b) => {
                                      // Strip 'v' prefix if present, as semver doesn't compare with the 'v'
                                      const versionA = a[0].startsWith('v')
                                        ? a[0].substring(1)
                                        : a[0];
                                      const versionB = b[0].startsWith('v')
                                        ? b[0].substring(1)
                                        : b[0];

                                      // Validate if the versions are semver compliant
                                      const isValidVersionA =
                                        semver.valid(versionA);
                                      const isValidVersionB =
                                        semver.valid(versionB);

                                      // If one version is invalid, consider it smaller for sorting purposes
                                      if (!isValidVersionA && !isValidVersionB)
                                        return 0; // both are invalid
                                      if (!isValidVersionA) return -1; // versionA is invalid, so it comes first
                                      if (!isValidVersionB) return 1; // versionB is invalid, so it comes first

                                      // Use semver.compare to sort valid versions
                                      return semver.compare(versionA, versionB);
                                    },
                                  )
                                : [];
                              const lowestCommonTag = sortedCommonTags?.length
                                ? sortedCommonTags[0][0]
                                : '';

                              const tags =
                                instances && instances?.common_tags
                                  ? Object.keys(instances?.common_tags)?.filter(
                                      (key) => key != lowestCommonTag,
                                    )
                                  : [];
                              return (
                                <AtomicTooltip
                                  tooltipContent={moreTooltipContent(tags, 0)}
                                >
                                  <div className={styles['stack-20840211']}>
                                    <span
                                      className={styles['bodyText']}
                                      data-cy="addon-version"
                                    >
                                      {sortedCommonTags[0]}
                                    </span>
                                    {instances?.unique_tag_count > 1 && (
                                      <span
                                        className={styles['text-838a1344']}
                                        data-cy="tag-col-more"
                                      >
                                        +{instances?.unique_tag_count - 1} more
                                      </span>
                                    )}
                                  </div>
                                </AtomicTooltip>
                              );
                            },
                          },
                          {
                            id: '3',
                            title: 'Instances',
                            titleStyle: { width: '20%' },
                            cellType: 'cell',
                            render: (instances) => {
                              return (
                                <div className={styles['stack-df5bc67a']}>
                                  <span
                                    className={styles['bodyText']}
                                    data-cy="instances"
                                  >
                                    {instances?.instance_count}
                                  </span>
                                </div>
                              );
                            },
                          },
                        ]}
                        actions={[
                          {
                            id: 'navigate',
                            render: () => {
                              return (
                                <div className={styles['stack-83b62c08']}>
                                  <SubframeCore.Icon
                                    className={styles['bodyText']}
                                    name="FeatherChevronRight"
                                  />
                                </div>
                              );
                            },
                          },
                        ]}
                      />
                    </div>
                  )}

                {(filterCluster || showExampleData) &&
                  addonsToShow &&
                  !isLoadingAddonInstanceByCluster && (
                    <div className={styles['stack-7722959e']}>
                      <BaseTable
                        settings={{
                          localStorageKey:
                            'artifact_register_addons_index_table',
                          dataCyPrefix: 'vr-addon',
                        }}
                        data={
                          addonsToShow && typeof addonsToShow[0] !== 'string'
                            ? (addonsToShow as [string, AddonInstance[]][])
                            : []
                        }
                        rowLink={([addonId, instances]) =>
                          `${basePath}/${addonId}${
                            filterCluster === undefined
                              ? ''
                              : '?cluster=' + filterCluster
                          }`
                        }
                        columns={[
                          {
                            id: '1',
                            title: 'Name',
                            titleStyle: { width: '40%' },
                            cellType: 'cell',
                            render: ([_, instances]) => {
                              const addonInstance = !(
                                'earliest_eol_date' in instances
                              )
                                ? instances
                                : [];
                              const addonExpiryState =
                                getAddonExpiryState(addonInstance);
                              return (
                                <>
                                  {addonExpiryState === 'error' && (
                                    <AtomicTooltip
                                      tooltipContent={`End of Life/Support: ${getAddonExpiryInWords(
                                        addonInstance,
                                      )}`}
                                    >
                                      <SubframeCore.Icon
                                        data-cy="red-icon"
                                        className={styles['icon-3a04925b']}
                                        name="FeatherClock"
                                      />
                                    </AtomicTooltip>
                                  )}
                                  {addonExpiryState === 'warning' && (
                                    <AtomicTooltip
                                      tooltipContent={`End of Life/Support: ${getAddonExpiryInWords(
                                        addonInstance,
                                      )}`}
                                    >
                                      <SubframeCore.Icon
                                        data-cy="yellow-icon"
                                        className={styles['icon-5f938ed1']}
                                        name="FeatherClock"
                                      />
                                    </AtomicTooltip>
                                  )}
                                  <span className={styles['bodyText']}>
                                    {addonIdToNameMap.get(
                                      addonInstance[0]?.addon_id,
                                    ) || addonInstance[0]?.addon_id}
                                  </span>
                                </>
                              );
                            },
                          },
                          {
                            id: '2',
                            title: (
                              <Stack direction="row" spacing={1}>
                                <>Tags</>
                                <InfoTooltip tooltipText="All Tags that have been locally applied to an Add-on's container images" />
                              </Stack>
                            ),
                            titleStyle: { width: '40%' },
                            cellType: 'cell',
                            render: ([_, instances]) => {
                              const tags = Array.from(
                                new Set(
                                  instances.map((instance) =>
                                    getHumanReadableImageTag(
                                      instance.primary_component?.image,
                                    ),
                                  ),
                                ).values(),
                              ).sort();

                              // make sure to push unknown back
                              if (tags.length > 0 && tags[0] === 'Unknown') {
                                tags.shift();
                                tags.push('Unknown');
                              }

                              return (
                                <AtomicTooltip
                                  tooltipContent={moreTooltipContent(tags, 0)}
                                >
                                  <div className={styles['stack-20840211']}>
                                    <span
                                      className={styles['bodyText']}
                                      data-cy="addon-version"
                                    >
                                      {tags[0]}
                                    </span>
                                    {tags.length > 1 && (
                                      <span
                                        className={styles['text-838a1344']}
                                        data-cy="tag-col-more"
                                      >
                                        +{tags.length - 1} more
                                      </span>
                                    )}
                                  </div>
                                </AtomicTooltip>
                              );
                            },
                          },
                          {
                            id: '3',
                            title: 'Instances',
                            titleStyle: { width: '20%' },
                            cellType: 'cell',
                            render: ([_, instances]) => {
                              return (
                                <div className={styles['stack-df5bc67a']}>
                                  <span
                                    className={styles['bodyText']}
                                    data-cy="instances"
                                  >
                                    {instances.length}
                                  </span>
                                </div>
                              );
                            },
                          },
                        ]}
                        actions={[
                          {
                            id: 'navigate',
                            render: () => {
                              return (
                                <div className={styles['stack-83b62c08']}>
                                  <SubframeCore.Icon
                                    className={styles['bodyText']}
                                    name="FeatherChevronRight"
                                  />
                                </div>
                              );
                            },
                          },
                        ]}
                      />
                    </div>
                  )}
              </>
            ) : (
              <BaseTable
                settings={{
                  localStorageKey: 'artifact_register_addons_index_table',
                  dataCyPrefix: 'loading-vr-addon',
                }}
                data={[
                  <SkeletonText key="1" className={'h-[30px] w-[50px]'} />,
                ]}
                columns={[
                  {
                    id: '1',
                    title: 'Name',
                    titleStyle: { width: '40%' },
                    cellType: 'cell',
                    render: (skeletonLoading) => {
                      return skeletonLoading;
                    },
                  },
                  {
                    id: '2',
                    title: (
                      <Stack direction="row" spacing={1}>
                        <>Tags</>
                        <InfoTooltip tooltipText="All Tags that have been locally applied to an Add-on's container images" />
                      </Stack>
                    ),
                    titleStyle: { width: '40%' },
                    cellType: 'cell',
                    render: (skeletonLoading) => {
                      return skeletonLoading;
                    },
                  },
                  {
                    id: '3',
                    title: 'Instances',
                    titleStyle: { width: '20%' },
                    cellType: 'cell',
                    render: (skeletonLoading) => {
                      return skeletonLoading;
                    },
                  },
                ]}
                actions={[]}
              />
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </Page>
  );
}
