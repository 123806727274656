//hooks
import useSettings from 'hooks/useSettings';
import { useTheme } from '@mui/material/styles';
import { useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { useGetClusterAndScan } from 'hooks/useGetClusterAndScan';
import { useRescan } from 'hooks/useRescan';

//components
import ClusterProperties from 'components/ClusterProperties';
import {
  Box,
  Container,
  Grid,
  Link,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';

import Page from 'components/Page';
// import ClusterOverview from 'components/ClusterOverview';
import ClusterScannedSignatures from 'components/ClusterScannedSignatures';
import { ConsoleLoader } from 'components/Loader';
import Centered from 'components/Centered';
import MessageBox from 'components/MessageBox';
import ClusterDetailsSectionHeader from 'layouts/dashboard/ClusterDetailsSectionHeader';
import {
  ClusterDetailsTab,
  ClusterDetailsTabProvider,
} from 'contexts/ClusterDetailsTabContext';

//constants
import { formatDistanceToNow, fromUnixTime } from 'date-fns';

// helpers
import isExampleCluster from 'utils/isExampleCluster';
import {
  getClusterExpiryStateBySupportLevel,
  getCurrentlyApplicableSupportLevel,
} from 'utils/clusterSupportLevelAndEol';
import { extractMajorMinor } from 'utils/extractMajorMinor';
import { getTimeRemaining } from 'utils/getTimeRemaining';
import { CLUSTER_TABS_TYPE } from 'layouts/dashboard/ClusterDetailsSectionHeader/ClusterDetailsSectionHeader';
import ClusterARSigsOverview from 'components/ClusterARSigsOverview';
import { useGetClusterLARsStatus } from 'hooks/useGetClusterLARsStatus';
import ARSigsTabSkeleton from 'components/Skeletons/ARSigsTabSkeleton';
import ClusterPropertiesSkeleton from 'components/Skeletons/ClusterPropertiesSkeleton';
import Iconify from 'components/Iconify';
import { K8sConnectorVersion } from 'utils/K8sConnectorVersion';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import { useGetCluster } from 'api/frontend';
import useUserAccountState from 'hooks/useUserAccountState';
import { Alert } from 'subframe/index';
import { ClusterScan } from 'api/models';
import * as Sentry from '@sentry/browser';
import { EnumEolState } from 'utils/types';
import { useGate } from 'statsig-react';

// ---------------------------
function FirstScanProcessing() {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      height="20rem"
    >
      <Grid item>
        <ConsoleLoader />
      </Grid>
      <Grid item>
        <Typography variant="h1">Scan In Progress...</Typography>
      </Grid>
    </Grid>
  );
}

export function ClusterDetails() {
  const { account } = useUserAccountState();

  const [currentTab, setCurrentTab] = useState('arsigs-overview');
  const { themeStretch } = useSettings();
  const theme = useTheme();

  const { clusterId } = useParams<{
    clusterId: string;
  }>();
  const showSystemRequirements = useGate('show_system_requirements');

  const [lastScanTime, setLastScanTime] = useState<string>('');
  const [lastScan, setLastScan] = useState<ClusterScan>();

  const location = useLocation();

  useEffect(() => {
    // Extract the query parameter from the location object
    const searchParams = new URLSearchParams(location.search);
    const paramValue = searchParams.get('view');

    // Change Tab to the selected category based on the query parameter
    setCurrentTab(paramValue ? paramValue : 'arsigs-overview');
  }, [location]);

  const { scan, scanError, scanRes, getScan, loading } = useGetClusterAndScan(
    clusterId!,
    account?.token,
  );

  const {
    data: cluster,
    error: clusterError,
    mutate: clusterMutate,
  } = useGetCluster(clusterId!, {
    request: { headers: { authorization: 'Bearer ' + account?.token } },
    swr: { refreshInterval: 5 * 1000 },
  });

  useEffect(() => {
    if (scan?.data?.length) {
      const latestScan = scan.data[0];
      const secondLatestScan = scan.data[1];
      if (latestScan.status === 'complete') {
        setLastScan(latestScan);
      } else if (secondLatestScan) {
        if (secondLatestScan.status === 'complete') {
          setLastScan(secondLatestScan);
        } else {
          Sentry.captureException(new Error('Multiple scans in pending'), {
            extra: {
              clusterId: clusterId,
              accountId: account?.accountId,
              latestScanId: latestScan?.id,
              secondLatestScanId: secondLatestScan.id,
            },
          });
        }
      }
    }
  }, [scan]);
  const { logEvent } = AnalyticsEventLogger();
  const { triggerRescan, rescanComplete } = useRescan(
    clusterId,
    account?.token,
  );

  const [getCluster, setCluster] = useState({} as any);

  const onClusterNameEnvironmentChange = (
    name: string,
    environment: string,
  ) => {
    setCluster({ ...getCluster, name, environment });
  };

  useEffect(() => {
    setCluster(cluster);
  }, [cluster]);

  const {
    statusList,
    unignoredLars,
    defects,
    defectsLARs,
    unignoredDefects,
    ignoredDefects,
    misconfigurations,
    misconfigurationsLARs,
    unignoredMisconfigurations,
    ignoredMisconfigurations,
    systemRequirements,
    systemRequirementsLARs,
    unignoredSystemRequirements,
    ignoredSystemRequirements,
    deprecations,
    deprecationsLARs,
    unignoredDeprecations,
    ignoredDeprecations,
    unsupportedVersions,
    unsupportedVersionsLARs,
    unignoredUnsupportedVersions,
    ignoredUnsupportedVersions,
    versionIncompatibilities,
    versionIncompatibilitiesLARs,
    unignoredVersionIncompatibilities,
    ignoredVersionIncompatibilities,
    bestPractices,
    bestPracticesLARs,
    unignoredBestPractices,
    ignoredBestPractices,
    rawLarsDataLoading,
    rawSigsDataLoading,
  } = useGetClusterLARsStatus(clusterId, lastScan, scan, account?.token);

  useEffect(() => {
    if (rescanComplete !== null) {
      if (rescanComplete) {
        getScan();
      }
    }
  }, [rescanComplete, getScan]);

  useEffect(() => {
    if (scan?.data?.length && lastScan) {
      const s = lastScan;
      const t = Number(s?.created);

      const m =
        t === 0
          ? '-'
          : formatDistanceToNow(fromUnixTime(t), {
              addSuffix: true,
            });
      setLastScanTime(m);
    }
  }, [scan, lastScan]);

  const updateTabValue = (tab: ClusterDetailsTab) => {
    setCurrentTab(tab);
    const url = new URL(`${window.location}`);
    url.searchParams.set('view', tab);
    window.history.pushState(null, '', url.toString());
  };

  const versionToShow = extractMajorMinor(cluster?.version || '');

  const guideLink = useAccountIdRoute(
    `/orgs/:orgId/accounts/:accountId/upgrades/templates/new?cluster_id=${clusterId}`,
  );

  const clusterAgentUpdatePath = useAccountIdRoute(
    `/orgs/:orgId/accounts/:accountId/clusters/${clusterId}/update-k8s-connector`,
  );

  const currSupportLevel = getCurrentlyApplicableSupportLevel(cluster);
  const timeLeft = getTimeRemaining(currSupportLevel?.end_date);
  let isClusterAgentOutdated = false;
  if (cluster && cluster.internal_k8s_ref != 'example-cluster-ref') {
    isClusterAgentOutdated = K8sConnectorVersion().check(
      cluster.chkk_metadata.version,
    );
  }
  const showLoading = () =>
    loading ||
    !scan ||
    unignoredDefects === undefined ||
    rawLarsDataLoading ||
    rawSigsDataLoading;
  const showNotFound = () =>
    !showLoading() &&
    ((clusterError && clusterError?.response?.status === 404) ||
      scanRes.status === 404);
  const showError = () =>
    !showLoading() && !showNotFound() && (clusterError || scanError);
  const showClusterDetails = () => !showNotFound() && !showError();

  const loadingState = showLoading();

  const CLUSTER_TABS: CLUSTER_TABS_TYPE = [
    {
      key: 'arsigs-overview',
      id: 'arsigs-overview-tab',
      title: 'Overview',
      icon: 'ic:trending-up',
      trackerEvent: 'cluster-arsigs-overview-tab',
      component: (
        <>
          {!loading && (!getCluster || !scan || !lastScan) ? (
            <FirstScanProcessing />
          ) : (
            <ClusterARSigsOverview
              DefectsCount={defectsLARs?.length}
              MisconfigurationsCount={misconfigurationsLARs?.length}
              SystemRequirementsCount={systemRequirementsLARs?.length}
              DeprecationsCount={deprecationsLARs?.length}
              UnsupportedVersionsCount={unsupportedVersionsLARs?.length}
              VersionIncompatibilitiesCount={
                versionIncompatibilitiesLARs?.length
              }
              BestPracticesCount={bestPracticesLARs?.length}
              UnignoredDefects={unignoredDefects}
              UnignoredMisconfigurations={unignoredMisconfigurations}
              UnignoredSystemRequirements={unignoredSystemRequirements}
              UnignoredDeprecations={unignoredDeprecations}
              UnignoredUnsupportedVersions={unignoredUnsupportedVersions}
              UnignoredVersionIncompatibilities={
                unignoredVersionIncompatibilities
              }
              UnignoredBestPractices={unignoredBestPractices}
              loading={loadingState}
            />
          )}
        </>
      ),
      skeleton: <></>,
    },
    {
      key: 'unsupported-versions',
      id: 'unsupported-versions-tab',
      title: 'Unsupported Versions',
      icon: 'mdi:layers-remove',
      trackerEvent: 'cluster-unsupported-versions-tab',
      component: (
        <>
          {getCluster && scan && lastScan && (
            <ClusterScannedSignatures
              scan={lastScan}
              cluster={getCluster}
              ARSigs={unsupportedVersions}
              unignoredLARs={unignoredUnsupportedVersions}
              ignoredLARs={ignoredUnsupportedVersions}
              title={'Unsupported Versions'}
              hideSeverity={true}
              larStatus={statusList}
            />
          )}
          {(!getCluster || !scan || !lastScan) && <FirstScanProcessing />}
        </>
      ),
      skeleton: <ARSigsTabSkeleton title={'Unsupported Versions'} />,
    },
    {
      key: 'defects',
      id: 'defects-tab',
      title: 'Defects',
      icon: 'fluent-mdl2:defect-solid',
      trackerEvent: 'cluster-defects-tab',
      component: (
        <>
          {getCluster && scan && lastScan && (
            <ClusterScannedSignatures
              cluster={getCluster}
              scan={lastScan}
              ARSigs={defects}
              unignoredLARs={unignoredDefects}
              ignoredLARs={ignoredDefects}
              title={'Defects'}
              larStatus={statusList}
            />
          )}
          {(!getCluster || !scan || !lastScan) && <FirstScanProcessing />}
        </>
      ),
      skeleton: <ARSigsTabSkeleton title={'Defects'} />,
    },
    {
      key: 'deprecations',
      id: 'deprecations-tab',
      title: 'Deprecations',
      icon: 'carbon:in-progress-error',
      trackerEvent: 'cluster-deprecations-tab',
      component: (
        <>
          {getCluster && scan && lastScan && (
            <ClusterScannedSignatures
              scan={lastScan}
              cluster={getCluster}
              ARSigs={deprecations}
              unignoredLARs={unignoredDeprecations}
              ignoredLARs={ignoredDeprecations}
              title={'Deprecations'}
              hideSeverity={true}
              larStatus={statusList}
            />
          )}
          {(!getCluster || !scan || !lastScan) && <FirstScanProcessing />}
        </>
      ),
      skeleton: <ARSigsTabSkeleton title={'Deprecations'} />,
    },
    {
      key: 'version-incompatibilities',
      id: 'version-incompatibilities-tab',
      title: 'Version Incompatibilities',
      icon: 'teenyicons:layers-difference-solid',
      trackerEvent: 'cluster-version-incompatibilities-tab',
      component: (
        <>
          {getCluster && scan && lastScan && (
            <ClusterScannedSignatures
              scan={lastScan}
              cluster={getCluster}
              ARSigs={versionIncompatibilities}
              unignoredLARs={unignoredVersionIncompatibilities}
              ignoredLARs={ignoredVersionIncompatibilities}
              title={'Version Incompatibilities'}
              hideSeverity={true}
              larStatus={statusList}
            />
          )}
          {(!getCluster || !scan || !lastScan) && <FirstScanProcessing />}
        </>
      ),
      skeleton: (
        <ARSigsTabSkeleton
          title={'Version Incompatibilities'}
          hideSeverity={true}
        />
      ),
    },
    {
      key: 'misconfigurations',
      id: 'misconfigurations-tab',
      title: 'Misconfigurations',
      icon: 'fluent:error-circle-settings-20-regular',
      trackerEvent: 'cluster-misconfigurations-tab',
      component: (
        <>
          {getCluster && scan && lastScan && (
            <ClusterScannedSignatures
              cluster={getCluster}
              scan={lastScan}
              ARSigs={misconfigurations}
              unignoredLARs={unignoredMisconfigurations}
              ignoredLARs={ignoredMisconfigurations}
              title={'Misconfigurations'}
              larStatus={statusList}
            />
          )}
          {(!getCluster || !scan || !lastScan) && <FirstScanProcessing />}
        </>
      ),
      skeleton: <ARSigsTabSkeleton title={'Misconfigurations'} />,
    },
    ...(showSystemRequirements?.value
      ? [
          {
            key: 'system-requirements',
            id: 'system-requirements-tab',
            title: 'System Requirements',
            icon: 'pajamas:requirements',
            trackerEvent: 'cluster-system-requirements-tab',
            component: (
              <>
                {getCluster && scan && lastScan && (
                  <ClusterScannedSignatures
                    cluster={getCluster}
                    scan={lastScan}
                    ARSigs={systemRequirements}
                    unignoredLARs={unignoredSystemRequirements}
                    ignoredLARs={ignoredSystemRequirements}
                    title={'System Requirements'}
                    hideSeverity={true}
                    larStatus={statusList}
                  />
                )}
                {(!getCluster || !scan || !lastScan) && <FirstScanProcessing />}
              </>
            ),
            skeleton: (
              <ARSigsTabSkeleton
                title={'System Requirements'}
                hideSeverity={true}
              />
            ),
          },
        ]
      : []),
    {
      key: 'guardrails',
      id: 'guardrails-tab',
      title: 'Guardrails',
      icon: 'mingcute:certificate-fill',
      trackerEvent: 'cluster-guardrails-tab',
      component: (
        <>
          {getCluster && scan && lastScan && (
            <ClusterScannedSignatures
              cluster={getCluster}
              scan={lastScan}
              ARSigs={bestPractices}
              unignoredLARs={unignoredBestPractices}
              ignoredLARs={ignoredBestPractices}
              title={'Guardrails'}
              hideSeverity={true}
              larStatus={statusList}
            />
          )}
          {(!getCluster || !scan || !lastScan) && <FirstScanProcessing />}
        </>
      ),
      skeleton: <ARSigsTabSkeleton title={'Guardrails'} hideSeverity={true} />,
    },
    {
      key: 'properties',
      id: 'properties-tab',
      title: 'Properties',
      icon: 'heroicons-outline:cog',
      trackerEvent: 'cluster-properties-tab',
      component: (
        <ClusterProperties
          properties={getCluster}
          scan={lastScan}
          filteredLARs={unignoredLars}
          onClusterNameEnvironmentChange={onClusterNameEnvironmentChange}
          mutateCluster={clusterMutate}
        />
      ),
      skeleton: <ClusterPropertiesSkeleton />,
    },
  ];

  const rescanDisabled = useMemo(
    () =>
      !lastScan ||
      rescanComplete === false ||
      (getCluster && isExampleCluster(getCluster)),
    [rescanComplete, getCluster, lastScan],
  );

  return (
    <Page title="Cluster">
      <Container
        sx={{ height: '100%' }}
        maxWidth={themeStretch ? false : '100%'}
      >
        {showNotFound() && (
          <Centered>
            <MessageBox mode={'info'} message={'Cluster not found'} />
          </Centered>
        )}

        {showError() && (
          <Centered>
            <MessageBox
              mode={'error'}
              message={'Error fetching cluster details'}
            />
          </Centered>
        )}

        {showClusterDetails() && (
          <Stack
            sx={{
              marginTop: '16px',
              minHeight: '90vh',
              backgroundColor: theme.palette.grey[900],
              borderRadius: 'var(--rounded)',
              paddingBottom: '20px',
            }}
          >
            <ClusterDetailsSectionHeader
              isRescanButtonDisabled={rescanDisabled as boolean}
              title={getCluster?.name}
              clusterId={cluster?.id || ''}
              lastScanText={lastScanTime ? `Last scan ${lastScanTime}` : ''}
              rescanComplete={rescanComplete}
              triggerRescan={triggerRescan}
              setCurrentTab={setCurrentTab}
              clusterStatus={cluster?.status || ''}
            />
            {cluster && isExampleCluster(cluster) && (
              <Alert
                variant="warning"
                title="You are currently viewing example data"
                description=""
                style={{ marginTop: '8px' }}
              />
            )}
            {cluster &&
              !isExampleCluster(cluster) &&
              (getClusterExpiryStateBySupportLevel(cluster) ===
                EnumEolState.Warning ||
                getClusterExpiryStateBySupportLevel(cluster) ===
                  EnumEolState.Error) &&
              !showLoading() && (
                <div style={{ padding: '8px 8px 0px 8px' }}>
                  <Alert
                    variant={
                      getClusterExpiryStateBySupportLevel(cluster) ===
                      EnumEolState.Warning
                        ? 'warning'
                        : 'error'
                    }
                    icon={'FeatherAlertTriangle'}
                    title={
                      timeLeft.eosMessage(
                        versionToShow,
                        currSupportLevel?.name || '',
                      )[0]
                    }
                    description={
                      <span
                        id="chkk-cluster-agent-update-banner"
                        className="text-label font-label"
                      >
                        {
                          <Link
                            to={guideLink}
                            component={RouterLink}
                            className="text-label font-label"
                          >
                            {
                              timeLeft.eosMessage(
                                versionToShow,
                                currSupportLevel?.name || '',
                                true,
                              )[1]
                            }
                          </Link>
                        }
                      </span>
                    }
                  />
                </div>
              )}
            {isClusterAgentOutdated && (
              <div style={{ padding: '8px 8px 0px 8px' }}>
                <Alert
                  variant="neutral"
                  title="You are using an outdated version of the K8s Connector"
                  description={
                    <span
                      id="chkk-cluster-agent-update-banner"
                      className="text-label font-label"
                    >
                      Please follow the instructions{' '}
                      {
                        <Link
                          to={clusterAgentUpdatePath}
                          component={RouterLink}
                          className="text-label font-label"
                        >
                          here
                        </Link>
                      }{' '}
                      to upgrade to the latest supported version
                    </span>
                  }
                />
              </div>
            )}
            <Grid
              sx={{
                backgroundColor: 'rgba(41, 37, 36, 0.4)',
                minHeight: '250px',
                alignItems: 'baseline',
                marginLeft: theme.spacing(2),
                marginTop: '24px',
                paddingBottom: '20px',
                borderRadius: 'var(--rounded)',
              }}
              direction="column"
            >
              <Grid item width="100%" sx={{ marginLeft: '-10px' }}>
                <Tabs
                  id="cluster-details-tabs"
                  value={currentTab}
                  scrollButtons="auto"
                  variant="scrollable"
                  allowScrollButtonsMobile
                  onChange={(e, value) => updateTabValue(value)}
                  sx={{
                    marginLeft: `-${theme.spacing(1)}`,
                    width: '99%',
                    [theme.breakpoints.up(1710)]: {
                      marginLeft: `${theme.spacing(4)}`,
                    },
                  }}
                >
                  {CLUSTER_TABS.map((tab) => (
                    <Tab
                      id={tab.id}
                      key={tab.key}
                      value={tab.key}
                      disableRipple
                      label={tab.title}
                      onClick={() => logEvent(tab.trackerEvent)}
                      icon={
                        <Iconify
                          height={18}
                          width={18}
                          icon={tab.icon}
                          sx={{
                            textDecoration: 'underline',
                            textUnderlinePosition: 'under',
                            textUnderlineOffset: 15,
                          }}
                        />
                      }
                      sx={{
                        fontSize: '14px',
                        marginRight: '20px !important',
                        color: theme.palette.grey[400],
                        borderBottom: `2px solid ${theme.palette.grey[400]}`,
                      }}
                    />
                  ))}
                </Tabs>
              </Grid>
              <Grid item width="100%" sx={{ marginLeft: '-10px' }}>
                {CLUSTER_TABS.map((tab) => {
                  const isMatched = tab.key === currentTab;
                  return (
                    isMatched && (
                      <Box key={tab.key} width="100%">
                        {currentTab === 'arsigs-overview' ? (
                          tab.component
                        ) : (
                          <>{showLoading() ? tab.skeleton : tab.component}</>
                        )}
                      </Box>
                    )
                  );
                })}
              </Grid>
            </Grid>
          </Stack>
        )}
      </Container>
    </Page>
  );
}

export default function ClusterDetailsWithContext() {
  return (
    <ClusterDetailsTabProvider>
      <ClusterDetails />
    </ClusterDetailsTabProvider>
  );
}
