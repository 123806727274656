import { Badge } from 'subframe/index';

export function ExpiryBadge(props: {
  expiryState: 'default' | 'warning' | 'error';
  eolSource: string | undefined;
  expiryInWords: string | undefined;
}): JSX.Element {
  const badge = (
    <Badge
      variant={
        props.expiryState === 'default'
          ? 'neutral'
          : props.expiryState === 'error'
          ? 'error'
          : 'warning'
      }
    >{`End of Life/Support: ${props.expiryInWords}`}</Badge>
  );

  return badge;
}
