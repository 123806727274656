import { useState } from 'react';
import { Table } from 'subframe/components/Table';
import { featureComparison, pricingComparison } from '../model';
import { TabsWithContent } from 'components/design-system/Tabs';
import { ResolvedSubscription, SubscriptionPlan } from 'api/models';
import { ProductPlanContactUsButton } from 'components/ProductPlanContactUsButton';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { ProductPlanDemo } from 'components/ProductPlanDemo';
import { Icon } from '@subframe/core';
import { PricingPlanCardChkkCopy } from 'subframe/index';

interface Props {
  subscription: ResolvedSubscription | undefined;
}

export const ProductPlans = ({ subscription }: Props) => {
  const { logEvent } = AnalyticsEventLogger();

  const [featureComparisonTab, setFeatureComparisonTab] = useState('');

  const buttonVisibility = (() => {
    let plan = subscription?.plan;
    if (plan === 'Business') {
      return { starter: false, business: false, enterprise: true };
    } else if (plan === 'Enterprise') {
      return { starter: false, business: false, enterprise: false };
    } else {
      return { starter: true, business: true, enterprise: true };
    }
  })();

  const isCurrentPlan = (plan: SubscriptionPlan) => {
    return plan === subscription?.plan;
  };

  const isDemo = subscription?.plan === 'Demo';

  return (
    <div className="flex-[1_0_0] h-full w-full flex-col gap-[24px] items-start flex max-w-[1400px]">
      <div className="flex h-full w-full flex-[1_0_0] flex-col items-start gap-[24px]">
        <div className="flex w-full items-start justify-center gap-[16px]">
          <PricingPlanCardChkkCopy
            className="h-144 w-112 flex-none"
            currentPlan={isCurrentPlan('Business')}
            planName="Business"
            description="For startups and scaleups"
            button={
              buttonVisibility.business ? (
                <ProductPlanContactUsButton
                  size="large"
                  className="flex-[0_0_auto] h-[40px] w-full self-center"
                  plan={'Business'}
                  view={'Subscription Plan'}
                />
              ) : null
            }
            renewalFrequency=""
          >
            {pricingComparison[0].points.map((item, idx) => {
              return (
                <PricingPlanCardChkkCopy.PlanFeatureItem
                  key={`feature-${idx}`}
                  className="h-auto w-full flex-none"
                >
                  {item}
                </PricingPlanCardChkkCopy.PlanFeatureItem>
              );
            })}
          </PricingPlanCardChkkCopy>
          <PricingPlanCardChkkCopy
            className="h-144 w-112 flex-none"
            currentPlan={isCurrentPlan('Enterprise')}
            planName="Enterprise"
            description="For enterprises running mission critical apps on Kubernetes"
            button={
              buttonVisibility.enterprise ? (
                <ProductPlanContactUsButton
                  size="large"
                  className="flex-[0_0_auto] h-[40px] w-full self-center"
                  plan={'Enterprise'}
                  view={'Subscription Plan'}
                />
              ) : null
            }
            renewalFrequency=""
          >
            <span>Everything in the Business plan, plus:</span>
            {pricingComparison[1].points.map((item, idx) => {
              return (
                <PricingPlanCardChkkCopy.PlanFeatureItem
                  key={`feature-${idx}`}
                  className="h-auto w-full flex-none"
                >
                  {item}
                </PricingPlanCardChkkCopy.PlanFeatureItem>
              );
            })}
          </PricingPlanCardChkkCopy>
        </div>
        <div className="flex-[1_0_0] h-full w-full gap-[8px] items-center flex">
          {isDemo ? (
            <ProductPlanDemo view={'Subscription Plan'} />
          ) : (
            <>
              <span className="text-default-font text-body-bold">
                To purchase additional Nodes or Upgrade Templates:{' '}
              </span>
              <ProductPlanContactUsButton
                variant="brand-secondary"
                size="small"
                plan="AdditionalQuota"
                view="Subscription Plan"
              />
            </>
          )}
        </div>
      </div>
      <div className="flex-[0_0_auto] h-px w-full flex-col gap-[8px] items-center flex bg-neutral-300" />

      <div className="w-full flex-col gap-[24px] items-start flex">
        <span className="text-default-font text-subheader">
          Feature Comparison
        </span>
        <div className="w-full flex-col gap-[24px] items-start flex">
          <TabsWithContent
            initialTabId={featureComparisonTab || undefined}
            onTabChange={(newTabId) => {
              setFeatureComparisonTab(newTabId);
              logEvent('feaure-comparison-tab', {
                section: newTabId,
              });
            }}
            tabs={featureComparison.map((feature) => ({
              id: `${feature.feature}-comparison`,
              title: `${feature.feature}`,
              content: (
                <div className="min-h-[532px] w-full">
                  <Table
                    header={
                      <Table.HeaderRow>
                        <Table.HeaderCell style={{ width: '30%' }}>
                          Feature
                        </Table.HeaderCell>

                        <Table.HeaderCell style={{ width: '35%' }}>
                          Business
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ width: '35%' }}>
                          Enterprise
                        </Table.HeaderCell>
                      </Table.HeaderRow>
                    }
                  >
                    {feature.tiers.map((tier) => {
                      return (
                        <Table.Row
                          className="h-auto w-full flex-none"
                          key={`${tier.feature}`}
                        >
                          <Table.Cell>
                            <span className="text-body-bold font-body-bold text-neutral-700 ">
                              {tier.feature}
                            </span>
                          </Table.Cell>

                          <Table.Cell className="h-auto grow shrink-0 basis-0">
                            <div className="flex grow shrink-0 basis-0 flex-col items-start gap-1">
                              {tier.business.split('\n').map((item, idx) => (
                                <>
                                  {item.includes('✔️') ? (
                                    <div className="flex h-4 w-4 flex-none items-center justify-end">
                                      <Icon
                                        name={'FeatherCheck'}
                                        className="text-body font-body text-subtext-color"
                                      />
                                    </div>
                                  ) : (
                                    <span className="text-body font-body text-neutral-500">
                                      {item}
                                    </span>
                                  )}
                                </>
                              ))}
                            </div>
                          </Table.Cell>
                          <Table.Cell className="h-auto grow shrink-0 basis-0">
                            <div className="flex grow shrink-0 basis-0 flex-col items-start gap-1">
                              {tier.enterprise.split('\n').map((item, idx) => (
                                <>
                                  {item.includes('✔️') ? (
                                    <div className="flex h-4 w-4 flex-none items-center justify-end">
                                      <Icon
                                        name={'FeatherCheck'}
                                        className="text-body font-body text-subtext-color"
                                      />
                                    </div>
                                  ) : (
                                    <span className="text-body font-body text-neutral-500">
                                      {item}
                                    </span>
                                  )}
                                </>
                              ))}
                            </div>
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table>
                </div>
              ),
            }))}
          />
        </div>
      </div>
    </div>
  );
};
