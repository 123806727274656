import {
  Cluster,
  ClusterSupportLevel,
  ClusterSupportLevelType,
  KubernetesProvider,
} from 'api/models';
import { EnumEolState } from 'utils/types';
import { extractMajorMinor } from 'utils/extractMajorMinor';
import {
  differenceInDays,
  formatDistanceToNowStrict,
  isBefore,
} from 'date-fns';

type ClusterCountByProviderMap = {
  [key in KubernetesProvider]?: number;
};

export interface supportLevelBadgeMetadata extends ClusterSupportLevel {
  provider: KubernetesProvider;
  clusterCountByProvider?: number; // only used when clusters belong to different providers
  badgeVariant: 'neutral' | 'warning' | 'error';
}

function getBadgeVariant(
  enumEolState: EnumEolState | undefined,
): supportLevelBadgeMetadata['badgeVariant'] {
  if (enumEolState === undefined) {
    return 'neutral';
  }
  switch (enumEolState) {
    case 'default':
      return 'neutral';
    case 'warning':
      return 'warning';
    case 'error':
      return 'error';
    default:
      return 'neutral';
  }
}

export function getSupportBadgeForAccumulatedClusters(
  clusters: Cluster[],
): supportLevelBadgeMetadata[] | undefined {
  if (clusters.length === 0) {
    return undefined;
  }
  //Do all clusters belong to the same Provider?
  let providerList: KubernetesProvider[] = [];
  clusters.forEach((cluster) => {
    if (!providerList.includes(cluster.cloud_provider)) {
      providerList.push(cluster.cloud_provider);
    }
  });

  if (providerList.length == 1) {
    //If all clusters belong to the same provider, then all Clusters has the same Support Level and EOL Date
    const supportLevel = getCurrentlyApplicableSupportLevel(clusters[0]);
    return supportLevel
      ? [
          {
            ...supportLevel,
            provider: providerList[0],
            badgeVariant: getBadgeVariant(
              getEolBadgeStateBySupportLevel(supportLevel, clusters[0]),
            ),
          },
        ].filter((sl) =>
          Object.values(sl).every((value) => value !== undefined),
        )
      : undefined;
  }

  let clusterCountByProvider: ClusterCountByProviderMap = {};
  let providers: KubernetesProvider[] = [];

  const eolDates = clusters.filter((c) => {
    if (!providers.includes(c.cloud_provider)) {
      providers.push(c.cloud_provider);
      const applicableSupport = getCurrentlyApplicableSupportLevel(c);
      //Count the number of clusters per provider
      clusterCountByProvider[c.cloud_provider] = 1;

      if (
        //If the cluster's current applicable support level has reached EOL, we return it
        applicableSupport &&
        applicableSupport.end_date !== undefined
      ) {
        return c;
      }
    } else if (clusterCountByProvider[c.cloud_provider]) {
      clusterCountByProvider[c.cloud_provider] =
        (clusterCountByProvider[c.cloud_provider] || 0) + 1;
    }
  });

  if (eolDates) {
    //If clusters belong to different providers, we return currently applicable support level and its EOL date for that provider
    return eolDates
      .map((c) => {
        const supportLevelObj = getCurrentlyApplicableSupportLevel(c);
        if (
          supportLevelObj &&
          supportLevelObj.end_date &&
          supportLevelObj.type &&
          supportLevelObj.name
        ) {
          return {
            ...supportLevelObj,
            provider: c.cloud_provider,
            clusterCountByProvider:
              clusterCountByProvider[c.cloud_provider] || 0,
            badgeVariant: getBadgeVariant(
              getEolBadgeStateBySupportLevel(
                getCurrentlyApplicableSupportLevel(c),
                c,
              ),
            ),
          };
        } else {
          return {} as supportLevelBadgeMetadata;
        }
      })
      .filter((sl) => Object.values(sl).every((value) => value !== undefined))
      .sort(
        (a) =>
          a.badgeVariant === 'error'
            ? -1
            : a.badgeVariant === 'warning'
            ? 0
            : 1, //sort in the order: Error, Warning, Neutral
      );
  } else {
    return undefined;
  }
}

export function getEolDateForClusters(clusters: Cluster[]): Date | undefined {
  const dates = clusters
    .map((c) => getCurrentlyApplicableSupportLevel(c)?.end_date)
    .filter((d) => d !== undefined && d > 0) as number[];

  if (dates.length === 0) {
    return undefined;
  }

  return new Date(Math.min(...dates) * 1000);
}

export function getTooltipTextForAccumulatedClusters(
  clusters: Cluster[],
): string | undefined {
  if (clusters.length === 0) {
    return undefined;
  }

  // we need to identify the cluster with the oldest EOL date
  const oldestDate = getEolDateForClusters(clusters);
  if (oldestDate === undefined) {
    return undefined;
  }

  const oldestDateClusters = clusters.filter((c) => {
    return (
      getCurrentlyApplicableSupportLevel(c)?.end_date ===
      oldestDate.getTime() / 1000
    );
  });
  if (
    oldestDateClusters.length === 0 ||
    oldestDateClusters.length > clusters.length
  ) {
    return undefined;
  }

  // get support level
  const supportLevel = getCurrentlyApplicableSupportLevel(
    oldestDateClusters[0],
  );
  if (supportLevel === undefined) {
    return undefined;
  }

  const version = extractMajorMinor(oldestDateClusters[0].version);
  const targetDate = new Date(supportLevel.end_date * 1000);
  const doClustersHaveDifferentEOL = clusters.some((c) => {
    return (
      getCurrentlyApplicableSupportLevel(c)?.end_date !== supportLevel.end_date
    );
  });

  if (clusters.every((c) => c.cloud_provider === KubernetesProvider.EKS)) {
    if (isBefore(targetDate, new Date())) {
      return `${
        clusters.length > 1
          ? `${clusters.length} clusters`
          : `${clusters.length} cluster`
      } with ${version} reached end of ${
        supportLevel.name
      } for as long as ${formatDistanceToNowStrict(targetDate, {
        addSuffix: true,
      })}`;
    } else {
      return `${
        clusters.length > 1
          ? `${clusters.length} clusters`
          : `${clusters.length} cluster`
      } with ${version} will reach end of ${
        supportLevel.name
      }  ${formatDistanceToNowStrict(targetDate, { addSuffix: true })}`;
    }
  }

  if (clusters.length === 1 || !doClustersHaveDifferentEOL) {
    if (isBefore(targetDate, new Date())) {
      return `${version} reached end of support  ${formatDistanceToNowStrict(
        targetDate,
        { addSuffix: true },
      )}`;
    } else {
      return `${version} will reach end of support  ${formatDistanceToNowStrict(
        targetDate,
        { addSuffix: true },
      )}`;
    }
  } else {
    if (isBefore(targetDate, new Date())) {
      return `${oldestDateClusters.length} of ${
        clusters.length
      } clusters reached end of support for as long as ${formatDistanceToNowStrict(
        targetDate,
        { addSuffix: true },
      )}`;
    } else {
      return `${oldestDateClusters.length} of ${
        clusters.length
      } clusters will reach end of support  ${formatDistanceToNowStrict(
        targetDate,
        { addSuffix: true },
      )}`;
    }
  }
}

export function getCurrentlyApplicableSupportLevel(
  cluster: Cluster | undefined,
): ClusterSupportLevel | undefined {
  if (cluster === undefined || cluster.support_level === undefined) {
    return undefined;
  }

  const stsSupportLevel = cluster.support_level.filter((sl) => {
    return sl.type === ClusterSupportLevelType.GeneralSupport;
  });

  const ltsSupportLevel = cluster.support_level.filter((sl) => {
    return sl.type === ClusterSupportLevelType.LongTermSupport;
  });

  // if only sts support is present
  if (stsSupportLevel.length > 0 && ltsSupportLevel.length === 0) {
    return stsSupportLevel[0];
  }

  // if only lts support is present
  if (stsSupportLevel.length === 0 && ltsSupportLevel.length > 0) {
    return ltsSupportLevel[0];
  }

  // if neither is present
  if (stsSupportLevel.length === 0 && ltsSupportLevel.length === 0) {
    return undefined;
  }

  // if both are present
  if (stsSupportLevel.length > 0 && ltsSupportLevel.length > 0) {
    if (isBefore(new Date(stsSupportLevel[0].end_date * 1000), new Date())) {
      return ltsSupportLevel[0];
    } else {
      return stsSupportLevel[0];
    }
  }

  return undefined;
}

export function getEarliestClusterExpiryInDays(
  clusters: Cluster[],
): number | undefined {
  if (clusters.length === 0) {
    return undefined;
  }

  const eolDate = getEolDateForClusters(clusters);
  if (eolDate === undefined) {
    return undefined;
  }
  return differenceInDays(eolDate, new Date());
}

export function getAccumulatedClusterExpiryStateBySupportLevel(
  clusters: Cluster[],
): EnumEolState | undefined {
  const clustersExpiryState = clusters.map((c) => {
    return getClusterExpiryStateBySupportLevel(c);
  });

  if (clustersExpiryState.includes(EnumEolState.Error)) {
    return EnumEolState.Error;
  }

  if (clustersExpiryState.includes(EnumEolState.Warning)) {
    return EnumEolState.Warning;
  }

  if (clustersExpiryState.includes(EnumEolState.Default)) {
    return EnumEolState.Default;
  }

  return undefined;
}

function getEolBadgeStateBySupportLevel(
  support_level: ClusterSupportLevel | undefined,
  cluster: Cluster,
): EnumEolState | undefined {
  // We need this function as the eol date badges in the view page are shown
  // per support level instead of per cloud provider. In order to get the correct
  // state and consequently color/variant we need to consider
  // the current support level under consideration
  // with
  // ALL of the support levels for the cluster
  if (
    cluster === undefined ||
    cluster.support_level === undefined ||
    cluster.support_level.length === 0 ||
    support_level === undefined ||
    support_level.end_date === undefined
  ) {
    return undefined;
  }

  var ltsDateInt = 0;
  var stsDateInt = 0;

  if (
    cluster.support_level?.some((sl) => {
      return sl.type === ClusterSupportLevelType.GeneralSupport;
    })
  ) {
    stsDateInt =
      cluster.support_level?.filter((sl) => {
        return sl.type === ClusterSupportLevelType.GeneralSupport;
      })[0].end_date || 0;
  } else {
    // all clusters must have a general support level
    return undefined;
  }
  if (
    cluster.support_level?.some((sl) => {
      return sl.type === ClusterSupportLevelType.LongTermSupport;
    })
  ) {
    // if there is a long term support level in the cluster we use its end date
    ltsDateInt =
      cluster.support_level?.filter((sl) => {
        return sl.type === ClusterSupportLevelType.LongTermSupport;
      })[0].end_date || 0;
  }

  if (stsDateInt === 0) {
    return undefined;
  }

  const stsEndDate = new Date(stsDateInt * 1000);
  const ltsEndDate = new Date(ltsDateInt * 1000);

  const diffDaysSts = differenceInDays(stsEndDate, new Date());
  const diffDaysLts = differenceInDays(ltsEndDate, new Date());

  // we determine the color of the individual badge based on the support level it represents
  if (support_level.type === ClusterSupportLevelType.GeneralSupport) {
    // If end of standard support has reached we show a RED badge for standard support
    if (diffDaysSts <= 0) {
      return EnumEolState.Error;
    }

    // If the current date is less than or equal to 90 days from the end of standard support for a version, display a YELLOW badge
    if (diffDaysSts <= 90) {
      return EnumEolState.Warning;
    }

    // if end of standard support is more than 90 days away we show a GREY badge
    if (diffDaysSts > 90) {
      return EnumEolState.Default;
    }
  } else if (support_level.type === ClusterSupportLevelType.LongTermSupport) {
    if (ltsDateInt === 0) {
      return EnumEolState.Default;
    }

    // if end of extended support is more than 90 days and standard support has not ended, we show a GREY badge for extended support
    if (diffDaysLts > 90 && diffDaysSts > 0) {
      return EnumEolState.Default;
    }

    // if end of extended support is more than 90 days and standard support has ended, we show a YELLOW badge for extended support
    if (diffDaysLts > 90 && diffDaysSts <= 0) {
      return EnumEolState.Warning;
    }

    // If the current date is less than 90 days from the end of extended support for a version, display a RED badge.
    if (diffDaysLts <= 90) {
      return EnumEolState.Error;
    }
  } else {
    return undefined;
  }
}

export function getClusterExpiryStateBySupportLevel(
  cluster: Cluster,
): EnumEolState | undefined {
  if (
    cluster === undefined ||
    cluster.support_level === undefined ||
    cluster.support_level.length === 0
  ) {
    return undefined;
  }

  const currSupportLevel = getCurrentlyApplicableSupportLevel(cluster);
  if (currSupportLevel === undefined) {
    return undefined;
  }

  const diffDays = differenceInDays(
    new Date(currSupportLevel.end_date * 1000),
    new Date(),
  );

  if (currSupportLevel.type === ClusterSupportLevelType.GeneralSupport) {
    if (diffDays <= 0) {
      return EnumEolState.Error;
    }
    if (diffDays <= 90) {
      return EnumEolState.Warning;
    }
    return EnumEolState.Default;
  } else if (
    currSupportLevel.type === ClusterSupportLevelType.LongTermSupport
  ) {
    // for LTS period there is no default state
    // its either warning or error, follow same as getEolBadgeStateBySupportLevel
    if (diffDays > 90) {
      return EnumEolState.Warning;
    }
    return EnumEolState.Error;
  } else {
    return undefined;
  }
}

export function isClusterInOrPastLTSSupportPeriod(
  cluster: Cluster,
): boolean | undefined {
  if (
    cluster === undefined ||
    cluster.support_level === undefined ||
    cluster.support_level.length === 0
  ) {
    return undefined;
  }

  const supportLevel = getCurrentlyApplicableSupportLevel(cluster);
  return supportLevel?.type === ClusterSupportLevelType.LongTermSupport;
}

export function getClusterLTSPeriodBadgeTooltipText(
  cluster: Cluster,
): string | undefined {
  if (cluster.cloud_provider == KubernetesProvider.EKS) {
    return 'This cluster is in the Extended Support period. Extended Support is available for 12 months after the end of Standard Support. During this period, you can continue to run existing workloads and receive important security updates.';
  } else if (cluster.cloud_provider == KubernetesProvider.AKS) {
    return 'This cluster is in the Long Term Support period. Long Term Support is available for 12 months after the end of Standard Support. During this period, you can continue to run existing workloads and receive important security updates.';
  }

  return undefined;
}

export function getClusterLTSSupportLevelName(
  cluster: Cluster,
): string | undefined {
  if (
    cluster === undefined ||
    cluster.support_level === undefined ||
    cluster.support_level.length === 0
  ) {
    return undefined;
  }

  const supportLevelLTS = cluster.support_level.filter((sl) => {
    return sl.type === ClusterSupportLevelType.LongTermSupport;
  });

  if (supportLevelLTS.length === 0) {
    return undefined;
  }

  return supportLevelLTS[0].name;
}
