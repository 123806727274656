import {
  Box,
  Container,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import Page from 'components/Page';
import { useTheme } from '@mui/material/styles';
import { useParams } from 'react-router';

import Label from 'components/Label';
import { severityBadgeMap } from 'models/scans';
import {
  Badge,
  BreadCrumHeaderContainer,
  ClusterLARHeaderBadgeContainer,
} from 'pages/kba/elements';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useDebounce, useSearchParam } from 'react-use';

import LARDeepDiveViewTable from 'components/LARDeepDiveViewTable';
import { availabilityRisksApi } from 'api/constants';
import { LARsBreadCrumbsHeader } from 'components/BreadCrumbsHeaders';
import useSWR from 'swr';
import ClusterHelper from 'utils/ClusterHelper';
import { ARViews } from 'models/general';
import useUserAccountState from 'hooks/useUserAccountState';
import { axiosInstance } from 'api/axios-instance';
import { Searchbar } from 'components/design-system/Searchbar';

const LARClusters = (token: string, view: ARViews, larId?: string) => {
  const getLARClustersApi =
    availabilityRisksApi +
    `/${larId?.toLowerCase()}/affected_resources?filter=types:clusters&filter=need_attention:true&filter=example_cluster:${
      view === 'example'
    }
      `;
  const fetcher = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };

      return await axiosInstance.get(getLARClustersApi, { headers });
    } catch {
      //
    }
  };
  const { data, error, isValidating } = useSWR(getLARClustersApi, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  return { larClustersData: data, cLoading: isValidating };
};

const LARAddOns = (token: string, view: ARViews, larId?: string) => {
  const getLARAddOnsApi =
    availabilityRisksApi +
    `/${larId?.toLowerCase()}/affected_resources?filter=types:addons&filter=need_attention:true&filter=example_cluster:${
      view === 'example'
    }
      `;
  const fetcher = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };

      return await axiosInstance.get(getLARAddOnsApi, { headers });
    } catch {
      //
    }
  };
  const { data, error, isValidating } = useSWR(getLARAddOnsApi, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  return { larAddOnsData: data, aLoading: isValidating };
};

export default function LARDeepDiveView() {
  const { larId } = useParams<{ larId: string }>();
  const { account } = useUserAccountState();
  const theme = useTheme();
  const { logEvent } = AnalyticsEventLogger();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchClusterName, setSearchClusterName] = useState('');

  const [clusters, setClusters] = useState([]);
  const [clustersToShow, setClustersToShow] = useState([]);
  const [addOns, setAddOns] = useState([]);
  const [isTitleLoading, setIsTitleLoading] = useState(true);
  const [isClusterLoading, setIsClusterLoading] = useState(true);
  const [isAddonLoading, setIsAddonLoading] = useState(true);

  const [displayData, setDisplayData] = useState({
    title: '',
    category: '',
    severity: '',
  });

  const { getClusters } = ClusterHelper(account?.token);
  const { data: clustersInOrg, isLoading: orgClustersLoading } = getClusters();

  const currentView = useSearchParam('view') as ARViews;
  const [view, setView] = useState<ARViews>(
    currentView || (clustersInOrg?.length > 1 ? 'real' : 'example'),
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { larClustersData, cLoading } = LARClusters(
    account?.token,
    view,
    larId,
  );
  const { larAddOnsData, aLoading } = LARAddOns(account?.token, view, larId);

  const getLoadingState = () => {
    return (
      isClusterLoading ||
      isAddonLoading ||
      aLoading ||
      cLoading ||
      orgClustersLoading ||
      isTitleLoading
    );
  };

  useEffect(() => {
    if (displayData.title) {
      setIsTitleLoading(false);
    } else if (!larClustersData?.data?.availability_risk?.title) {
      setIsTitleLoading(false);
    }
  }, [displayData.title]);

  const getLARClusters = async () => {
    setIsClusterLoading(true);
    const data = larClustersData?.data;

    if (data) {
      setClusters(data.data);
      setDisplayData({
        title: data.availability_risk?.title,
        severity: data.availability_risk?.severity,
        category: data.availability_risk?.category[0] || '',
      });
    }
    setIsClusterLoading(false);
  };

  const getLARAddOns = async () => {
    setIsAddonLoading(true);

    const data = larAddOnsData?.data;
    if (data?.data) {
      setAddOns(data.data);
    }

    setIsAddonLoading(false);
  };

  useEffect(() => {
    getLARClusters();
    getLARAddOns();
  }, [account?.token, larId, larClustersData, larAddOnsData]);

  const open = Boolean(anchorEl);
  const handleOnChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: string,
  ) => {
    setSearchClusterName(newValue);
  };

  useDebounce(
    () => {
      if (searchClusterName) {
        logEvent('lar-view-search-cluster');
      }
    },
    1000,
    [searchClusterName],
  );

  useEffect(() => {
    if (searchClusterName !== '' && clusters) {
      setIsClusterLoading(true);
      const found = clusters.filter((item) =>
        item?.name?.toLowerCase().includes(searchClusterName.toLowerCase()),
      );
      if (found) {
        setClustersToShow(found);
      }
      setIsClusterLoading(false);
    } else {
      setClustersToShow(clusters);
    }
  }, [searchClusterName, clusters]);

  return (
    <Page title={larId?.toUpperCase() || ''} maxHeight={'100%'}>
      <Container maxWidth="100%">
        <BreadCrumHeaderContainer>
          <LARsBreadCrumbsHeader
            arSigId={larId?.toUpperCase() || ''}
            category={displayData.category}
            view={clustersInOrg?.length > 1 ? view : 'example'}
          />
        </BreadCrumHeaderContainer>
        <Grid direction={'column'}>
          <Box
            sx={{
              background: theme.palette.grey[900],
              borderRadius: 1,
              padding: theme.spacing(2),
              alignItems: 'center',
            }}
          >
            <Grid
              container
              direction={'row'}
              justifyContent="space-between"
              alignItems={'center'}
            >
              <Grid item>
                {getLoadingState() ? (
                  <Skeleton width={500} />
                ) : (
                  <Typography id="lar-details-title">
                    {displayData.title ? displayData.title : 'Not found.'}
                  </Typography>
                )}
              </Grid>
              <Grid item maxWidth={'50%'}>
                {isAddonLoading || isClusterLoading || aLoading || cLoading ? (
                  <ClusterLARHeaderBadgeContainer>
                    <Skeleton width={200} />
                  </ClusterLARHeaderBadgeContainer>
                ) : (
                  <ClusterLARHeaderBadgeContainer>
                    <Grid container direction="row" justifyContent={'flex-end'}>
                      <Stack
                        spacing={2}
                        justifyContent={'flex-start'}
                        marginTop={0.5}
                      >
                        {displayData.severity &&
                        (displayData.category === 'Defects' ||
                          displayData.category === 'Misconfigurations') ? (
                          <Typography sx={{ fontSize: '0.8rem' }}>
                            Severity:
                          </Typography>
                        ) : (
                          <></>
                        )}
                        {addOns.length ? (
                          <Typography sx={{ fontSize: '0.8rem' }}>
                            Affected Add-ons:
                          </Typography>
                        ) : (
                          <></>
                        )}
                      </Stack>

                      <Stack
                        spacing={1}
                        justifyContent={'flex-start'}
                        marginLeft={2}
                        maxWidth={'60%'}
                      >
                        {displayData.severity &&
                        (displayData.category === 'Defects' ||
                          displayData.category === 'Misconfigurations') ? (
                          <Badge>
                            <Label
                              variant={'ghost'}
                              color={
                                severityBadgeMap[
                                  displayData.severity.toLowerCase()
                                ] || ('info' as any)
                              }
                            >
                              {displayData.severity}
                            </Label>
                          </Badge>
                        ) : (
                          <></>
                        )}
                        {addOns.length ? (
                          <Grid container direction="row">
                            {addOns.map((addon) => (
                              <Grid item key={addon.name}>
                                <Badge>
                                  <Label>{addon.name}</Label>
                                </Badge>
                              </Grid>
                            ))}
                          </Grid>
                        ) : (
                          <></>
                        )}
                      </Stack>
                    </Grid>
                  </ClusterLARHeaderBadgeContainer>
                )}
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              background: theme.palette.grey[900],
              borderRadius: 1,
              padding: theme.spacing(2),
              alignItems: 'center',
              marginTop: theme.spacing(2),
            }}
          >
            <Grid
              container
              direction={'row'}
              justifyContent="flex-end"
              alignItems={'center'}
              spacing={2}
            >
              <Grid item>
                <Grid
                  container
                  direction={'row'}
                  justifyContent="flex-end"
                  spacing={2}
                >
                  <Grid item>
                    {clusters.length ? (
                      <Searchbar onChange={handleOnChange} />
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Box boxShadow={2} marginTop={1} sx={{ borderRadius: 1 }}>
              <LARDeepDiveViewTable
                clusters={clustersToShow}
                loading={getLoadingState()}
              />
            </Box>
          </Box>
        </Grid>
      </Container>
    </Page>
  );
}
