import { TeamInvitation } from 'api/models';
import { Searchbar } from 'components/design-system/Searchbar';
import BaseTable from 'components/design-system/Table/BaseTable';
import { SyntheticEvent, useState } from 'react';
import { useDebounce } from 'react-use';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { capitalizeString } from 'utils/formatString';
import { ExpiryDisplay, getHumanReadableDate } from 'utils/subscription_plan';
import { ReinviteMemberDialog } from './ReinviteMemberDialog';

interface TeamPendingMembersTabProps {
  pendingMembers: TeamInvitation[];
  refetchPendingMembers: VoidFunction;
}

export default function TeamsPendingMembers({
  pendingMembers,
  refetchPendingMembers,
}: TeamPendingMembersTabProps) {
  const { logEvent } = AnalyticsEventLogger();
  const [searchMember, setSearchMember] = useState<string>('');
  useDebounce(
    () => {
      if (searchMember) {
        logEvent('search-member', { searchMember });
      }
    },
    1000,
    [searchMember],
  );

  const handleOnChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: string,
  ) => {
    setSearchMember(newValue);
  };

  const membersToShow =
    searchMember !== ''
      ? pendingMembers.filter((item) =>
          item?.email.toLowerCase().includes(searchMember.toLowerCase()),
        )
      : pendingMembers;

  const membersToShowSorted = Object.values(membersToShow).sort(
    (a, b) => (b.expiry || 0) - (a.expiry || 0),
  );

  return (
    <>
      <div className="w-full items-center justify-end flex">
        {pendingMembers?.length ? (
          <Searchbar onChange={handleOnChange} />
        ) : (
          <></>
        )}
      </div>
      {pendingMembers?.length ? (
        <BaseTable
          settings={{
            localStorageKey: 'teams_pending_members_table',
            dataCyPrefix: 'pending-members',
          }}
          data={membersToShowSorted || []}
          noMatchingData={membersToShowSorted?.length === 0}
          columns={[
            {
              id: '1',
              title: 'Email',
              titleStyle: { width: '25%' },
              cellType: 'text-cell',
              hideCapture: true,
              render: (row) => {
                return `${row?.email}`;
              },
            },
            {
              id: '2',
              title: 'Inviter',
              titleStyle: { width: '25%' },
              cellType: 'text-cell',
              hideCapture: true,
              render: (row) => {
                return `${row?.inviter}`;
              },
            },
            {
              id: '3',
              title: 'Status',
              titleStyle: { width: '20%' },
              cellType: 'badge-cell',
              cellProperties: {
                variant: (row) => {
                  return ExpiryDisplay(row?.expiry) !== ''
                    ? 'error'
                    : 'neutral';
                },
              },
              render: (row) => {
                return ExpiryDisplay(row?.expiry) !== ''
                  ? 'Expired'
                  : capitalizeString(row?.status);
              },
            },
            {
              id: '4',
              title: 'Expiry',
              titleStyle: { width: '20%' },
              cellType: 'text-cell',
              render: (row) => {
                return capitalizeString(
                  getHumanReadableDate(row?.expiry) || '',
                );
              },
            },
          ]}
          actions={[
            {
              id: '4',
              render: (row) => {
                return (
                  <ReinviteMemberDialog
                    email={row.email}
                    refetchPendingMembers={refetchPendingMembers}
                  />
                );
              },
            },
          ]}
        />
      ) : (
        <div className="flex-[1_0_0] h-full w-full flex-col gap-2 items-center justify-center flex">
          <span>No Invites Pending</span>
        </div>
      )}
    </>
  );
}
