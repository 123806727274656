import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { Alert } from 'subframe/index';
import { RouterLink } from 'components/RouterLink';
import { useListSubscriptions } from 'api/frontend';
import useUserAccountState from 'hooks/useUserAccountState';
import {
  canRequestPlanExtension,
  ExpiryDisplay,
  getHeaderExpiryInDaysText,
  getMostRelevanSubscription,
  showUpdateNow,
} from 'utils/subscription_plan';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import { RequestSubscriptionPlanExtensionCTA } from 'components/RequestSubscriptionPlanExtensionCTA';

export const ExpiryAlert = () => {
  const { logEvent } = AnalyticsEventLogger();

  const { account, currentOrganization } = useUserAccountState();

  const { data: subscriptionData } = useListSubscriptions(
    currentOrganization.slug,
    {},
    {
      request: { headers: { authorization: 'Bearer ' + account?.token } },
    },
  );
  const mainSubscription = getMostRelevanSubscription(
    subscriptionData?.subscriptions,
  );
  const subscriptionPlanPath = useAccountIdRoute(
    '/orgs/:orgId/subscriptions?view=billing',
  );

  return (
    <>
      {showUpdateNow(mainSubscription?.plan) &&
      ExpiryDisplay(mainSubscription?.expiry) !== '' ? (
        <Alert
          title={`${getHeaderExpiryInDaysText(
            mainSubscription?.expiry,
            mainSubscription?.name,
          )}
          `}
          variant="error"
          description={
            <>
              <RouterLink
                className="hoverable-link text-label font-label"
                to={subscriptionPlanPath}
                onClick={() => {
                  logEvent('upgrade-now-click');
                }}
              >
                Upgrade Now
              </RouterLink>
              {mainSubscription &&
                canRequestPlanExtension(mainSubscription) && (
                  <>
                    <span className="text-label font-label"> or </span>
                    <RequestSubscriptionPlanExtensionCTA
                      clickHandler={() => {
                        logEvent('product-plans-alert-banner-click', {
                          text: 'Request Extension',
                        });
                      }}
                      CTAType={'link'}
                      description={'Request Extension'}
                      subscription={mainSubscription}
                    />
                  </>
                )}
            </>
          }
        />
      ) : (
        <></>
      )}
    </>
  );
};
