/* Release: 63880259 (Latest – unreleased) */

import classNames from 'classnames';
import { useEffect, useMemo, useRef, useState } from 'react';
import { InformationalHeader } from 'subframe/components/InformationalHeader';
import { Tabs } from 'subframe/components/Tabs';
import styles from './LatestPageKbaDetailsTab.module.scss';
import styles_affectedRes from './LatestPageKbaAffectedResourcesTab.module.scss';
import styles_howToFix from './LatestPageKbaHowToFixTab.module.scss';
import { mdast } from '02strich-markdown';

import {
  ClusterLARDetailsBreadCrumbsHeader,
  LARInClusterBreadCrumbsHeader,
} from 'components/BreadCrumbsHeaders';
import { severityBadgeMap } from 'models/scans';
import { Navigate, useParams } from 'react-router';
import { useGetClusterAndScan } from 'hooks/useGetClusterAndScan';
import useUserAccountState from 'hooks/useUserAccountState';
import {
  Category,
  findCategory,
  getCategory,
  splitStringAtUppercase,
} from 'utils/filters';
import isExampleCluster from 'utils/isExampleCluster';

import ClusterHelper from 'utils/ClusterHelper';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { ARState } from './components/ARState';
import { getLastScanTimeByTimestamp } from 'utils/formatTime';
import { KBARenderer } from './KBARenderer';
import { LARAffectedResources } from './LARAffectedResources';
import { AxiosRequestConfig } from 'axios';
import { ARViews } from 'models/general';
import Label from 'components/Label';
import { Badge, SkeletonText } from 'subframe/index';
import {
  useGetAvailabilityRiskAffectedResource,
  useGetKba,
  useGetLARStatus,
  useListClusterScanLARs,
  useListClusterScanSignatures,
} from 'api/frontend';
import { AtomicTooltip } from 'components/design-system';
import { truncateAtLength } from 'utils/formatString';
import { Stack } from 'components/utils/Stack';
import { useOnScreen } from 'hooks/useOnScreen';
import {
  Cluster,
  ClusterDetectedLar,
  ClusterScannedSignature,
  GetAvailabilityRiskAffectedResourceParams,
} from 'api/models';
import { moreTooltipContent } from 'components/design-system/AtomicTooltip';
import { capitalizeFirstLetter } from 'utils/stringOperations';
import { markAsReasonDisplay } from './models/markAs';
import { ConsoleLoader } from 'components/Loader';
import { CommentTooltip } from 'components/design-system/CommentTooltip';
import { EmptyMessage } from 'components/design-system/EmptyMessage';
import { useListClusterScanLARResourcesInfinite } from 'api/frontend-infinite';
import { flattenPages } from 'utils/arrays';
import { useSnackbar } from 'notistack';
import { somethingWentWrong, toastAutoHideDuration } from 'constants/toasts';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import * as Sentry from '@sentry/browser';

interface ClusterLARDetailsProps {
  isFromScannedSignature?: boolean;
  isFromLARView?: boolean;
  isStandAlone?: boolean;
}
export interface ExtendedLAR extends ClusterDetectedLar {
  detected: boolean;
}

interface ClusterWithLARDetails {
  clusterName: string;
  larDetails?: ExtendedLAR;
  lastScan: number;
  firstScan: number;
}

export const KBAPage = ({
  isFromScannedSignature,
  isFromLARView,
  isStandAlone,
}: ClusterLARDetailsProps) => {
  let { clusterId } = useParams<{ clusterId: string }>();
  let { id: kbaId } = useParams<{ id: string }>();
  const { logEvent } = AnalyticsEventLogger();

  const { account } = useUserAccountState();

  const [details, setDetails] = useState<ClusterWithLARDetails | null>();
  const [mutateClusters, setMutateClusters] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { cluster, scan, clusterError, clusterRes } = isStandAlone
    ? {
        cluster: undefined,
        scan: undefined,
        clusterError: undefined,
        clusterRes: undefined,
      }
    : useGetClusterAndScan(clusterId, account?.token);

  const currentScanStatus = scan?.data[0]?.status;
  const currentScanId =
    currentScanStatus === 'complete' ? scan?.data[0]?.id : scan?.data[1]?.id;
  const { data: larsData } = useListClusterScanLARs(
    clusterId || '',
    currentScanId || '',
    { page_size: 1000 },
    {
      request: { headers: { authorization: 'Bearer ' + account?.token } },
      swr: { enabled: !isFromScannedSignature && !isStandAlone },
    },
  );
  const { data: sigsData } = useListClusterScanSignatures(
    clusterId || '',
    currentScanId || '',
    { page_size: 1000 },
    {
      request: { headers: { authorization: 'Bearer ' + account?.token } },
      swr: { enabled: isFromScannedSignature },
    },
  );
  const {
    data: affectedResources,
    isLoading,
    error: errorAffectedResources,
  } = useListClusterScanLARResourcesInfinite(
    clusterId || '',
    currentScanId || '',
    kbaId?.toUpperCase() || '',
    { page_size: 1000 },
    {
      request: { headers: { authorization: 'Bearer ' + account?.token } },
      swr: { enabled: !isStandAlone && !isFromScannedSignature },
    },
  );
  const affectedResourcesToShow = errorAffectedResources
    ? []
    : flattenPages(affectedResources);

  if (errorAffectedResources) {
    Sentry.captureException(errorAffectedResources);
    enqueueSnackbar(
      somethingWentWrong.replace('<action>', 'fetching affected resources'),
      {
        variant: 'error',
        autoHideDuration: toastAutoHideDuration,
      },
    );
  }

  const LARR = () => {
    if (isFromScannedSignature || isStandAlone) return undefined;
    let lar = {} as ExtendedLAR;
    if (larsData?.data?.length) {
      for (let i = 0; i < larsData?.data?.length; i++) {
        if (
          larsData?.data[i].signature_id.toLowerCase() === kbaId?.toLowerCase()
        ) {
          lar = { ...larsData?.data[i], detected: true };
        }
      }
      if (!lar.signature_id) {
        return undefined;
      }
    }
    return lar;
  };
  const Sigg = () => {
    if (!isFromScannedSignature) return undefined;
    let sig = {} as ClusterScannedSignature;
    if (sigsData?.data?.length) {
      for (let i = 0; i < sigsData?.data?.length; i++) {
        if (
          sigsData?.data[i].signature_id.toLowerCase() === kbaId?.toLowerCase()
        ) {
          sig = sigsData?.data[i];
        }
      }
      if (!sig.signature_id) {
        return undefined;
      }
    }
    return sig;
  };
  const [LARDetail, SetLARDetail] = useState(() => {
    const initialLAR = LARR();
    return initialLAR || undefined;
  });
  const [SignatureDetail, SetSignatureDetail] = useState(() => {
    const initialSig = Sigg();
    return initialSig || undefined;
  });

  useEffect(() => {
    SetLARDetail(() => {
      const initialLAR = LARR();
      return initialLAR || undefined;
    });
    SetSignatureDetail(() => {
      const initialSig = Sigg();
      return initialSig || undefined;
    });
  }, [scan, larsData, sigsData]);

  useEffect(() => {
    const details = {
      clusterName: cluster?.name || '',
      larDetails: isFromScannedSignature ? SignatureDetail : LARDetail,
      lastScan: 0,
      firstScan: 0,
    };
    if (scan?.data?.length) {
      const currentScanStatus = scan?.data[0]?.status;
      details.lastScan =
        currentScanStatus === 'complete'
          ? scan?.data[0]?.created
          : scan?.data[1]?.created;
      details.firstScan = scan ? scan.data[scan.data.length - 1]?.created : 0;
    }
    setDetails(details as ClusterWithLARDetails);
  }, [SignatureDetail, LARDetail]);

  const ref = useRef<HTMLElement | null>(null); // Use null as the initial value
  const isVisible = useOnScreen(ref);
  useEffect(() => {
    if (isVisible) {
      logEvent('lar-details-scroll-to-bottom', {
        signatureId: details?.larDetails?.signature_id,
        clusterId: clusterId,
      });
    }
  }, [isVisible]);

  const [kbaDetails, setKBADetails] = useState({
    title: '',
    bodyData: '',
    showNotFound: false,
    error: false,
    loading: true,
  });

  const getCurrentView = () => {
    if (cluster && isExampleCluster(cluster)) return 'example';
    else return 'real';
  };

  //cluster rescan
  const getScanStatus = (cluster: Cluster | undefined) => {
    if (cluster) {
      return cluster.rescan === 'completed' || !cluster.rescan;
    } else {
      return false;
    }
  };
  const { triggerRescan } = ClusterHelper(account?.token);
  const [rescanComplete, setRescanComplete] = useState(getScanStatus(cluster));
  useEffect(() => {
    setRescanComplete(getScanStatus(cluster));
  }, [cluster]);

  const rescanDisabled = useMemo(
    () => rescanComplete === false || getCurrentView() === 'example',
    [rescanComplete, clusterId],
  );

  const rescanHandler = async () => {
    setRescanComplete(false);
    const response = await triggerRescan(clusterId || '');
    setRescanComplete(
      response?.data.rescan === 'completed' || !response?.data.rescan,
    );
    logEvent('rescan-cluster', {
      clusterId,
    });
  };

  let KBA_TABS = [
    {
      key: 'details',
      title: 'Details',
      trackerEvent: 'kba-details-tab',
      component: (
        <KBARenderer
          howToFix={false}
          kbaDetails={kbaDetails}
          rescanHandler={rescanHandler}
          rescanDisabled={rescanDisabled}
          rescanComplete={rescanComplete}
        />
      ),
    },
    {
      key: 'how-to-fix',
      title: 'How to fix',
      trackerEvent: 'kba-how-to-fix-tab',
      component: (
        <div className={styles['tabDetails']}>
          <KBARenderer
            howToFix={true}
            kbaDetails={kbaDetails}
            rescanHandler={rescanHandler}
            rescanDisabled={rescanDisabled}
            rescanComplete={rescanComplete}
            isStandAlone={isStandAlone}
            lastScan={details?.lastScan}
            cluster={cluster}
          />
        </div>
      ),
    },
  ];

  const [currentTab, setCurrentTab] = useState('details');

  let title: string = '';
  let body: string = '';

  const config = {} as AxiosRequestConfig;
  config.headers = { Authorization: `Bearer ${account?.token}` };
  const options = {
    swr: {
      // Since signature information does not change often, we can disable revalidations
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
    request: config,
  };
  function isNotEmptyObject(obj: any) {
    return obj !== null && obj !== undefined && Object.keys(obj).length !== 0;
  }
  // Lowercasing since signature details view passes signatureId in caps
  const response: any = useGetKba(
    isStandAlone
      ? kbaId || ''
      : isNotEmptyObject(details?.larDetails)
      ? kbaId?.toLowerCase() || ''
      : '',
    options,
  );

  const fetchKBADetails = () => {
    if (response !== undefined && response?.data) {
      response.data?.title ? (title = response.data.title) : undefined;
      if (response.data?.title === '') {
        const parsed = mdast(response.data?.body);
        for (let i = 0; i < parsed.children.length; i++) {
          if (parsed.children[i].type === 'heading') {
            // if the heading is 'Title' then the next element is the title
            if (parsed.children[i].children[0].value == 'Title') {
              title = parsed.children[i + 1].children[0].value;
              break;
            }
          }
        }
      } else {
        title = response.data.title;
      }
      response.data?.body ? (body = response.data.body) : undefined;
      logEvent('view-kba-page', { kbaId });
      setKBADetails({
        error: false,
        title: title,
        bodyData: body,
        showNotFound: false,
        loading: false,
      });
    } else if (response?.error && response?.error?.response?.status >= 500) {
      logEvent('view-kba-page', { kbaId: kbaId, error: response?.error });
      setKBADetails({
        title: '',
        bodyData: '',
        showNotFound: false,
        error: true,
        loading: false,
      });
    } else {
      setKBADetails({
        title: '',
        bodyData: '',
        showNotFound: true,
        error: false,
        loading: false,
      });
    }
  };
  useEffect(() => {
    if (response?.data || response?.error) {
      fetchKBADetails();
    }
  }, [response?.data, response?.error]);

  const switchTab = (index: number) => {
    setCurrentTab(KBA_TABS[index].key);
  };

  const LARClusters = (token: string, view: ARViews, larId?: string) => {
    const params: GetAvailabilityRiskAffectedResourceParams = {
      filter: [
        'types:clusters',
        `example_cluster:${getCurrentView() === 'example'}`,
      ],
    };
    const config: AxiosRequestConfig = {};
    config.headers = { authorization: `Bearer ${token}` };

    const {
      data,
      isValidating: isMutating,
      mutate: affClustersMutate,
    } = useGetAvailabilityRiskAffectedResource(larId || '', params, {
      request: config,
      swr: {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        enabled:
          !!cluster &&
          (!isExampleCluster(cluster) || cluster.status === 'active'), //disable for deactivated example cluster
      },
    });
    return {
      larClustersData: data,
      isMutating: isMutating,
      affClustersMutate: affClustersMutate,
    };
  };
  useEffect(() => {
    if (mutateClusters) {
      if (affClustersMutate) affClustersMutate();
      setMutateClusters(false);
    }
  }, [mutateClusters]);

  const { larClustersData, affClustersMutate, isMutating } =
    isFromScannedSignature || isStandAlone
      ? {
          larClustersData: undefined,
          affClustersMutate: undefined,
          isMutating: undefined,
        }
      : LARClusters(account?.token, 'real', kbaId);
  const ARCategory = getCategory(details?.larDetails?.labels || []);

  function isClusterNotDetected(): boolean {
    return !larClustersData?.data.some((clst) => clst.name === cluster?.name);
  }

  const getClustersHeaderValue = () => {
    const moreClusters =
      larClustersData?.data && larClustersData.data.length > 1
        ? larClustersData.data.length - 1
        : 0;
    if (moreClusters && !isMutating) {
      return ` +${moreClusters} more`;
    } else if (moreClusters == undefined || isMutating) {
      return <SkeletonText className="h-6 w-[50px] ml-2" />;
    } else {
      return '';
    }
  };

  const ignoredFlag = isClusterNotDetected();
  const clusterToDisplay =
    getCurrentView() === 'example'
      ? cluster?.name
      : ignoredFlag
      ? larClustersData?.data[0]?.name || isMutating
        ? larClustersData?.data[0]?.name
        : null
      : cluster?.name;
  const getMoreClusterTooltip = () => {
    return moreTooltipContent(
      larClustersData?.data
        ?.map((clusterData) => clusterData.name)
        .filter((name, idx) => !ignoredFlag || (ignoredFlag && idx !== 0))
        .filter((target) => target !== cluster?.name) || [],
    );
  };

  const getLastDetectedHeaderValue = () => {
    const lastScan = details?.lastScan;
    if (lastScan) {
      return `${getLastScanTimeByTimestamp(+details.lastScan)}`;
    } else if (lastScan === undefined) {
      return (
        <div style={{ width: '50px' }}>
          <SkeletonText />
        </div>
      );
    } else {
      return 'Null';
    }
  };

  const hideSeverity = details?.larDetails?.labels
    ? (details?.larDetails?.labels?.length || 0) > 0
      ? findCategory(details?.larDetails?.labels, Category.BEST_PRACTICES) ||
        findCategory(
          details?.larDetails?.labels,
          Category.SYSTEM_REQUIREMENTS,
        ) ||
        findCategory(
          details?.larDetails?.labels,
          Category.VERSION_INCOMPATABILITIES,
        ) ||
        findCategory(details?.larDetails?.labels, Category.DEPRECATIONS) ||
        findCategory(details?.larDetails?.labels, Category.UNSUPPORTED_VERSIONS)
      : false
    : undefined;
  const clustersRedirectUrl = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/clusters',
  );

  const getKBANotFound = () => {
    return (
      (!kbaDetails.error && kbaDetails?.showNotFound && !kbaDetails?.loading) ||
      (!isStandAlone && !details?.larDetails) ||
      clusterError
    );
  };

  const getKBAFetchError = () => {
    return kbaDetails.error && !getKBANotFound();
  };

  const { data: dataLarStatus } = useGetLARStatus(
    cluster?.id || '',
    kbaId || '',
    {
      request: { headers: { Authorization: `Bearer ${account.token}` } },
    },
  );

  const getEmptyKBAMessage = () => {
    if (clusterError) {
      if (clusterRes?.status === 404) {
        enqueueSnackbar(
          `No Cluster with id ${clusterId} exist in this org. Please try again or escalate to Chkk`,
          {
            variant: 'error',
            autoHideDuration: toastAutoHideDuration,
          },
        );
      } else {
        enqueueSnackbar(
          somethingWentWrong.replace(
            '<action>',
            `fetching cluster with ID ${clusterId}`,
          ),
          {
            variant: 'error',
            autoHideDuration: toastAutoHideDuration,
          },
        );
      }

      return <Navigate to={clustersRedirectUrl} />;
    }
    if (isStandAlone || kbaDetails?.showNotFound) {
      return (
        <EmptyMessage
          heading={'KBA Not Found'}
          message={`No matching KBA was found with ID ${kbaId?.toUpperCase()}.`}
          iconName="FeatherAlertCircle"
          iconVariant="error"
        />
      );
    } else if (isFromScannedSignature) {
      return (
        <EmptyMessage
          heading={'Availability Risk Signature Not Found'}
          message={`Availability Risk Signature ${kbaId?.toUpperCase()} was not scanned in this cluster.`}
          iconName="FeatherAlertCircle"
          iconVariant="error"
        />
      );
    } else if (!isFromLARView && scan?.data) {
      const isScannedSig = sigsData?.data?.find(
        (scan) => scan?.signature_id?.toLowerCase() === kbaId?.toLowerCase(),
      );
      if (isScannedSig)
        return (
          <EmptyMessage
            heading={'Availability Risk Not Detected'}
            message={`🎉 Availability Risk ${kbaId?.toUpperCase()} was not detected in this cluster. Great job!`}
            iconName="FeatherCheck"
          />
        );
      else {
        return (
          <EmptyMessage
            heading={'Availability Risk Not Found'}
            message={`Availability Risk ${kbaId?.toUpperCase()} was not found in this cluster.`}
            iconName="FeatherAlertCircle"
            iconVariant="error"
          />
        );
      }
    }
  };
  const getErrorKBAMessage = () => {
    return (
      <EmptyMessage
        heading={'Error Fetching KBA'}
        message={
          <>
            It&apos;s taking longer than expected to fetch this KBA. It should
            automatically resolve shortly. You can force refresh the page to see
            the contents. In case you&apos;re unable to, reach out to us on{' '}
            <a
              href="http://slack.chkk.io"
              target="_blank"
              rel="noopener noreferrer"
            >
              Slack
            </a>{' '}
            or email us at <a href="mailto:support@chkk.io">support@chkk.io</a>{' '}
            for assistance.
          </>
        }
        iconName="FeatherAlertCircle"
        iconVariant="error"
      />
    );
  };
  return (
    <div
      className={classNames(
        styles['pageContents'],
        styles['howToFixSection-9fdafbb7'],
      )}
      style={{ marginLeft: '32px', width: 'calc(100% - 64px)' }}
      ref={ref}
    >
      <div className={styles['howToFixSection-8efa91fd']}>
        <div className={classNames(styles['howToFixSection'])}>
          <>
            <div className={styles['howToFixContent']}>
              {!isStandAlone && (
                <>
                  {isFromLARView ? (
                    <LARInClusterBreadCrumbsHeader
                      clusterName={details?.clusterName}
                      arSigId={kbaId?.toUpperCase() || ''}
                      clusterId={clusterId}
                      category={getCategory(details?.larDetails?.labels || [])}
                      view={getCurrentView()}
                    ></LARInClusterBreadCrumbsHeader>
                  ) : (
                    <ClusterLARDetailsBreadCrumbsHeader
                      clusterName={details?.clusterName}
                      arSigId={kbaId?.toUpperCase() || ''}
                      clusterId={clusterId}
                      category={getCategory(details?.larDetails?.labels || [])}
                    />
                  )}
                </>
              )}
              <div className={styles['id1142Group']}>
                {!getKBANotFound() && !getKBAFetchError() && (
                  <div
                    className={styles_howToFix['id1142DetailsGroup']}
                    data-cy="kba-summary"
                  >
                    <div className={styles_howToFix['id1142DetailsContent']}>
                      <div className={styles_howToFix['title']}>
                        {details?.larDetails?.title || kbaDetails.title ? (
                          <span
                            className={
                              styles_howToFix['unsupportedVersionText']
                            }
                          >
                            {`${
                              isStandAlone
                                ? kbaDetails.title
                                : details?.larDetails?.title
                            }`}
                          </span>
                        ) : (
                          <SkeletonText className="h-[38px] w-[55%] min-w-[500px]" />
                        )}
                        {details?.larDetails?.title &&
                          dataLarStatus?.ignore_status?.reason && (
                            <div className={styles_howToFix['stack']}>
                              {dataLarStatus?.ignore_status?.comment ? (
                                <>
                                  <Badge variant="neutral">
                                    {`Marked as: ${
                                      markAsReasonDisplay[
                                        dataLarStatus.ignore_status.reason
                                      ]
                                    }`}
                                  </Badge>

                                  <CommentTooltip
                                    userName={
                                      dataLarStatus?.ignore_status?.user?.name
                                    }
                                    picture={
                                      dataLarStatus?.ignore_status?.user
                                        ?.picture
                                    }
                                    time={dataLarStatus?.ignore_status?.updated}
                                    comment={
                                      dataLarStatus?.ignore_status?.comment
                                    }
                                  />
                                </>
                              ) : (
                                <Badge variant="neutral">
                                  {`Marked as: ${
                                    markAsReasonDisplay[
                                      dataLarStatus.ignore_status.reason
                                    ]
                                  }`}
                                </Badge>
                              )}
                            </div>
                          )}
                      </div>
                      {!isStandAlone && (
                        <>
                          {details?.larDetails?.availability_impact ? (
                            <span
                              className={styles['bodyText']}
                              style={{ width: '100%' }}
                            >
                              {details.larDetails.availability_impact}
                            </span>
                          ) : (
                            <SkeletonText className="h-5 w-[70%]" />
                          )}
                        </>
                      )}
                    </div>

                    {cluster && details?.larDetails?.detected ? (
                      <ARState
                        cluster={cluster}
                        selectedLar={details?.larDetails}
                        affClustersMutate={setMutateClusters}
                        resources={affectedResourcesToShow || []}
                      />
                    ) : (
                      !isStandAlone &&
                      !isFromScannedSignature && (
                        <SkeletonText className="h-[50px] w-[25%]" />
                      )
                    )}
                  </div>
                )}
              </div>
              {!isStandAlone && !getKBAFetchError() && !getKBANotFound() && (
                <>
                  {details?.larDetails &&
                  cluster &&
                  hideSeverity != undefined ? (
                    <InformationalHeader data-cy="kba-context">
                      <InformationalHeader.Item
                        title="Category"
                        bodySlot={
                          <span className="text-body-bold font-body-bold text-default-font">
                            {`${splitStringAtUppercase(ARCategory)}`}
                          </span>
                        }
                      />

                      {!hideSeverity && (
                        <InformationalHeader.Item
                          title="Impact"
                          bodySlot={
                            details?.larDetails?.severity ? (
                              <Label
                                variant={'ghost'}
                                color={
                                  details?.larDetails?.severity
                                    ? severityBadgeMap[
                                        details?.larDetails?.severity?.toLowerCase()
                                      ]
                                    : ('info' as any)
                                }
                              >
                                {`${capitalizeFirstLetter(
                                  details?.larDetails?.severity,
                                )}`}
                              </Label>
                            ) : (
                              <div className="w-[50px]">
                                <SkeletonText />
                              </div>
                            )
                          }
                        />
                      )}
                      <InformationalHeader.Item
                        title="Environment"
                        bodySlot={
                          <AtomicTooltip
                            tooltipContent={
                              cluster?.environment
                                ? cluster.environment
                                : 'Default'
                            }
                          >
                            <span className="text-body-bold font-body-bold text-default-font">
                              {` ${truncateAtLength(
                                cluster?.environment
                                  ? cluster.environment
                                  : 'Default',
                                18,
                              )}`}
                            </span>
                          </AtomicTooltip>
                        }
                      />

                      {!isFromScannedSignature && (
                        <>
                          <InformationalHeader.Item
                            title="Last detected"
                            bodySlot={
                              <span className="text-body-bold font-body-bold text-default-font">{` ${getLastDetectedHeaderValue()}`}</span>
                            }
                          />
                          {clusterToDisplay !== null && (
                            <InformationalHeader.Item
                              title="Clusters"
                              moreElements={
                                <AtomicTooltip
                                  tooltipContent={getMoreClusterTooltip()}
                                >
                                  <span className="text-body font-body text-subtext-color underline">
                                    {getCurrentView() === 'example'
                                      ? ''
                                      : getClustersHeaderValue()}
                                  </span>
                                </AtomicTooltip>
                              }
                              bodySlot={
                                clusterToDisplay && !isMutating ? (
                                  <AtomicTooltip
                                    tooltipContent={clusterToDisplay}
                                  >
                                    <span className="text-body-bold font-body-bold text-default-font">
                                      {` ${truncateAtLength(
                                        clusterToDisplay,
                                        24,
                                      )}${
                                        getClustersHeaderValue() !== ''
                                          ? ', '
                                          : ' '
                                      }`}
                                    </span>
                                  </AtomicTooltip>
                                ) : (
                                  <SkeletonText className="w-[50px] h-6" />
                                )
                              }
                            />
                          )}
                        </>
                      )}
                      <div className={styles['stack-daf5cd4e']}>
                        <div className={styles['stack-a758e3b6']} />
                      </div>
                    </InformationalHeader>
                  ) : (
                    <SkeletonText className="h-[52px] w-[90%]" />
                  )}
                </>
              )}
            </div>
            {!getKBANotFound() && !getKBAFetchError() && (
              <>
                {details?.larDetails?.affected_resources ? (
                  <div className={styles_affectedRes['howToFixDetailsContent']}>
                    <span className={styles_affectedRes['subheaderText']}>
                      Affected Resources
                    </span>

                    <LARAffectedResources
                      showVersion={Boolean(
                        details?.larDetails?.affected_resources?.length,
                      )}
                      resources={affectedResourcesToShow || []}
                      isLoading={isLoading}
                      clusterId={clusterId}
                      larDetails={details?.larDetails}
                    />
                  </div>
                ) : (
                  !isFromScannedSignature &&
                  !isStandAlone && (
                    <Stack alignItems="center" width="fill">
                      <ConsoleLoader />
                    </Stack>
                  )
                )}

                <div className={styles['howToFixTabs']}>
                  <div className={styles['kbaDetails']}>
                    <Tabs className="h-auto w-auto flex-none">
                      {KBA_TABS.map((tab, idx) => (
                        <Tabs.Item
                          className={styles['mainAxisHug']}
                          active={tab.key === currentTab}
                          id={tab.trackerEvent}
                          key={tab.key}
                          onClick={() => {
                            switchTab(idx);
                            logEvent(tab.trackerEvent, {
                              signatureId: details?.larDetails?.signature_id,
                              clusterId: clusterId,
                            });
                          }}
                          data-cy={tab.key}
                        >
                          {tab.title}
                        </Tabs.Item>
                      ))}
                    </Tabs>
                    <div className={styles['shortTermFixGroup']}>
                      {KBA_TABS.map((tab) => {
                        const isMatched = tab.key === currentTab;
                        return (
                          isMatched && (
                            <div style={{ width: '100%' }} key={tab.key}>
                              {tab.component}
                            </div>
                          )
                        );
                      })}
                    </div>
                  </div>
                </div>
              </>
            )}
            {getKBAFetchError() && (
              <div style={{ width: '100%', height: '80vh' }}>
                {getErrorKBAMessage()}
              </div>
            )}
            {getKBANotFound() && (
              <div style={{ width: '100%', height: '80vh' }}>
                {getEmptyKBAMessage()}
              </div>
            )}
          </>
        </div>
      </div>
    </div>
  );
};
