import { useState } from 'react';

// components
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { Cluster, LarAffectedResource } from 'api/models';
import { SetStateFunction } from 'models/react';
import { useGetLARStatus } from 'api/frontend';
import useUserAccountState from 'hooks/useUserAccountState';
import CreateJiraTicketDialog from 'components/dialogs/CreateJiraDialog';
import MarkAsIgnoreDialog from './MarkAsIgnoreDialog';
import { Button, Tooltip } from 'subframe/index';
import * as SubframeCore from '@subframe/core';
import styles from '../LatestPageKbaHowToFixTab.module.scss';
import { markAsReasonDisplay } from '../models/markAs';
import { Stack } from 'components/utils';
import Iconify from 'components/Iconify';
import { ExtendedLAR } from '..';

interface ARStateProps {
  selectedLar: ExtendedLAR;
  cluster: Cluster;
  isDialogOpen?: boolean;
  setIsDialogOpen?: SetStateFunction<boolean>;
  affClustersMutate: SetStateFunction<boolean>;
  resources: LarAffectedResource[] | undefined;
}

export const ARState = ({
  selectedLar,
  cluster,
  affClustersMutate,
  resources,
}: ARStateProps) => {
  const { logEvent } = AnalyticsEventLogger();
  const { account } = useUserAccountState();

  const [isCreateJiraTicketDialogOpen, setIsCreateJiraTicketDialogOpen] =
    useState<boolean>(false);
  const [isIgnoreDialogOpen, setIsIgnoreDialogOpen] = useState<boolean>(false);

  const {
    data: dataLarStatus,
    isValidating: isValidatingLarStatus,
    isLoading: isLoadingLarStatus,
    mutate: mutateLarStatus,
  } = useGetLARStatus(cluster?.id, selectedLar.signature_id?.toLowerCase(), {
    request: { headers: { Authorization: `Bearer ${account.token}` } },
  });

  const getMarkedStatus =
    dataLarStatus?.ignore_status?.reason ||
    (dataLarStatus?.ignore ? false : undefined);

  const getJiraState = dataLarStatus?.jira_ticket
    ? {
        jiraId: dataLarStatus.jira_ticket?.split('/').pop() || '',
        jiraLink: dataLarStatus.jira_ticket,
      }
    : {};

  const openJiraTicketLink = () => {
    window.open(getJiraState?.jiraLink);
    logEvent('navigate-jira-ticket', {
      clusterId: cluster.id,
      lar_id: selectedLar.signature_id,
    });
  };

  const createJiraTicket = () => {
    setIsCreateJiraTicketDialogOpen(!isCreateJiraTicketDialogOpen);
    logEvent('create-ticket-dialog', {
      clusterId: cluster.id,
      lar_id: selectedLar.signature_id,
    });
  };

  return (
    <div className={styles['actionButtons1']}>
      <CreateJiraTicketDialog
        lar={selectedLar}
        cluster={cluster}
        handleOpen={setIsCreateJiraTicketDialogOpen}
        isOpen={isCreateJiraTicketDialogOpen}
        mutateStatus={mutateLarStatus}
        resources={resources}
      />
      <MarkAsIgnoreDialog
        lar={selectedLar}
        cluster={cluster}
        handleOpen={setIsIgnoreDialogOpen}
        isOpen={isIgnoreDialogOpen}
        ignoreStatus={dataLarStatus?.ignore_status}
        mutateStatus={mutateLarStatus}
        mutateAffClusters={affClustersMutate}
      />

      <SubframeCore.Tooltip.Provider>
        <SubframeCore.Tooltip.Root>
          <SubframeCore.Tooltip.Trigger asChild={true}>
            <Button
              size="large"
              variant="brand-secondary"
              icon={getMarkedStatus ? 'FeatherLightbulbOff' : null}
              onClick={() => setIsIgnoreDialogOpen(true)}
              loading={isLoadingLarStatus || isValidatingLarStatus}
              disabled={isLoadingLarStatus || isValidatingLarStatus}
            >
              {getMarkedStatus ? `Unmark` : 'Mark as'}
            </Button>
          </SubframeCore.Tooltip.Trigger>
          <SubframeCore.Tooltip.Portal>
            <SubframeCore.Tooltip.Content
              side="bottom"
              align="center"
              sideOffset={8}
              asChild={true}
            >
              <Tooltip className="max-w-[200px]">
                {getMarkedStatus
                  ? `This Availability Risk was Marked as ${markAsReasonDisplay[getMarkedStatus]}. Click to unmark the Availability Risk.`
                  : `Mark the Availability Risk if it's not actionable.`}
              </Tooltip>
            </SubframeCore.Tooltip.Content>
          </SubframeCore.Tooltip.Portal>
        </SubframeCore.Tooltip.Root>
      </SubframeCore.Tooltip.Provider>
      {getJiraState && getJiraState.jiraId ? (
        <Button
          size="large"
          icon={null}
          variant="brand-primary"
          id="jira-ticket-link-button"
          onClick={async () => openJiraTicketLink()}
          loading={isLoadingLarStatus}
        >
          <Stack direction={'row'}>
            <Iconify
              sx={{ marginRight: '2px', marginTop: '4px' }}
              width={14}
              height={14}
              icon={'logos:jira'}
            />
            {`Jira ${getJiraState.jiraId}`}
          </Stack>
        </Button>
      ) : (
        <Button
          variant="brand-primary"
          icon="FeatherSend"
          size="large"
          id="create-jira-ticket-button"
          onClick={async () => createJiraTicket()}
          loading={isLoadingLarStatus}
          disabled={isLoadingLarStatus}
        >
          Create Ticket
        </Button>
      )}
    </div>
  );
};
