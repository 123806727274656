import { CardBase } from 'subframe/components/CardBase';
import { SkeletonText } from 'subframe/components/SkeletonText';
import { Badge, InfoTooltip, Table } from 'subframe/index';

export function ClusterCardSkeletonLoading() {
  return (
    <CardBase
      title={<SkeletonText className="w-28" />}
      subtitle=""
      metric="9"
      metricTitle="Need attention"
      actions={<></>}
      metric2Title="Total Scanned"
      metric2Children={
        <>
          <div className="hidden items-center gap-2">
            <span className="text-body font-body text-subtext-color">
              Total Scanned
            </span>
            <InfoTooltip tooltipText="Tooltip contents" />
          </div>
          <div className="hidden items-center gap-2">
            <span className="text-section-header font-section-header text-default-font">
              37
            </span>
            <div className="flex items-center gap-2">
              <Badge variant="neutral">92 OK</Badge>
            </div>
          </div>
        </>
      }
      metric1Children={
        <div className="flex flex-col items-start gap-2">
          <div className="flex items-center gap-2">
            <span className="text-body font-body text-subtext-color">
              Need attention
            </span>
            <SkeletonText className="h-5 w-5 flex-none" />
          </div>
          <div className="flex items-center gap-2">
            <SkeletonText className="h-8 w-4 flex-none" />
            <SkeletonText className="h-5 w-14 flex-none" />
          </div>
        </div>
      }
      metric1Label={<Badge variant="neutral">16 OK</Badge>}
      metric2Label={<Badge variant="neutral">92 OK</Badge>}
      metric1Tooltip="Tooltip contents"
      metric2Tooltip="Tooltip contents"
    />
  );
}

export function ClusterRowSkeletonLoading() {
  return (
    <Table.Row>
      <Table.Cell className="h-[66px] w-full grow shrink-0 basis-0">
        <div className="flex w-full grow shrink-0 basis-0 items-center gap-2">
          <SkeletonText />
          <div className="flex h-full w-full grow shrink-0 basis-0 items-center gap-2">
            <SkeletonText size="default" />
          </div>
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className="flex h-full w-full grow shrink-0 basis-0 items-center gap-2">
          <SkeletonText />
        </div>
      </Table.Cell>
      <Table.Cell>
        <SkeletonText />
      </Table.Cell>
    </Table.Row>
  );
}
