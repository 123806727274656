import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import {
  Breadcrumbs,
  CodeBlock,
  CtaCard,
  IconButton,
  IconWithBackground,
  InfoTooltip,
  LearnMoreAccordion,
  Loader,
  PropertiesAccordion,
  StepBase,
  Table,
  Tooltip,
} from 'src/subframe/index';
import { TextField } from 'src/subframe/components/TextField';
import Page from 'components/Page';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import { Button } from 'src/subframe/components/Button';
import { ActionType, CloudConnection, CloudType } from 'api/models';
import * as Sentry from '@sentry/browser';
import { useSnackbar } from 'notistack';
import { RouterLink } from 'components/RouterLink';
import useUserAccountState from 'hooks/useUserAccountState';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CopyToClipboard } from 'components/design-system/CopyToClipboard';
import {
  createCloudConnection,
  createCloudConnectionAction,
  useGetCloudConnection,
  useListCloudConnections,
} from 'api/frontend';
import { toastAutoHideDuration } from 'constants/toasts';
import { AtomicTooltip } from 'components/design-system';

type ProcessingState = 'pending' | 'processing' | 'completed' | 'failed';
interface WorkflowState {
  // form fields
  cloudAccountName: string;
  cloudAccountSubscriptions: { subscriptionId: string }[];
  tenantId: string;
  clientId: string;
  clientSecret: string;

  // step state/icon handling
  firstStep: ProcessingState;
  secondStep: ProcessingState;
  thirdStep: ProcessingState;
  fourthStep: ProcessingState;
  fifthStep: ProcessingState;
  sixthStep: ProcessingState;

  // steps accordion folding
  preReqOpen: boolean;
  firstOpen: boolean;
  secondOpen: boolean;
  thirdOpen: boolean;
  fourthOpen: boolean;
  fifthOpen: boolean;
  sixthOpen: boolean;
}

interface AzureSchemaFields {
  //step 1
  cloudAccountName: string;

  //step 2
  subscriptionId: string; // only verify before adding to table

  //step 5
  tenantId: string;
  clientId: string;
  clientSecret: string;
}

function creationStateToStepVariant(
  state: ProcessingState,
): 'default' | 'success' | 'loading' | 'error' {
  if (state === 'pending') {
    return 'default';
  }
  if (state === 'processing') {
    return 'loading';
  }
  if (state === 'completed') {
    return 'success';
  }
  if (state === 'failed') {
    return 'error';
  }

  return 'default';
}
function connectionStateToCreationState(
  state: 'pending' | 'failed' | 'connected' | 'disconnected',
): ProcessingState {
  if (state === 'pending') {
    return 'processing';
  }
  if (state === 'failed') {
    return 'failed';
  }
  if (state === 'connected') {
    return 'completed';
  }
  return 'pending';
}
// "pending" | "failed" | "connected" | "disconnected"

// Regular expression for validating UUID v4
const uuidV4Regex =
  /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export default function CloudAccountsAddNewEditAzure() {
  const { logEvent } = AnalyticsEventLogger();
  const { id } = useParams();
  const { account } = useUserAccountState();
  const [hideClientSecret, setHideClientSecret] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const EditRequestError =
    'Something went wrong while adding this Cloud Account. Please try again or escalate to Chkk';
  const LoadRequestError =
    'Something went wrong while fetching this Cloud Account. Please try again or escalate to Chkk';
  const basePathCloudAccounts = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/cloud_accounts/',
  );
  const basePathClusters = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/clusters/',
  );
  useEffect(() => {
    logEvent('cloud-account-add-edit-page-viewed', {
      cloud_connection_id: id,
      cloud_account_type: 'azure',
    });
  }, []);

  const { data: listCloudConnectionsData, mutate: listCloudConnectionsMutate } =
    useListCloudConnections(
      {},
      { request: { headers: { Authorization: `Bearer ${account.token}` } } },
    );
  const existingAzureConnections =
    listCloudConnectionsData?.data.filter(
      (c) => c.cloud_type === CloudType.AZURE,
    ) || [];

  const AzureSchema = yup.object().shape({
    cloudAccountName: yup
      .string()
      .required('Name is required')
      .test(
        'unique-connection-name',
        `An Azure cloud connection with this name already exists. Please enter a unique name`,
        function (value) {
          return !existingAzureConnections.some(
            (c) => c.cloud_connection_name === value,
          );
        },
      ),
    subscriptionId: yup
      .string()
      .required('Subscription ID is required')
      .matches(uuidV4Regex, 'Subscription ID must be a valid UUID version 4')
      .test(
        'unique-subscriptionId',
        'Subscription ID already added. Please enter a unique ID',
        function (value) {
          return !subscriptionRows.some((row) => row.subscriptionId === value);
        },
      ),
    tenantId: yup
      .string()
      .required('Tenant ID is required')
      .matches(uuidV4Regex, 'Tenant ID must be a valid UUID version 4'),
    clientId: yup
      .string()
      .required('Client ID is required')
      .matches(
        uuidV4Regex,
        'Client/Application ID must be a valid UUID version 4',
      ),
    clientSecret: yup.string().required('Client Secret is required'),
  });
  const defaultValues: AzureSchemaFields = {
    cloudAccountName: '',
    subscriptionId: '',
    tenantId: '',
    clientId: '',
    clientSecret: '',
  };
  const methods = useForm<AzureSchemaFields>({
    resolver: yupResolver(AzureSchema),
    defaultValues: defaultValues,
  });
  const {
    handleSubmit: handleAzureConnectionSubmit,
    formState: { isSubmitting, errors, isValid },
    trigger: validateForm,
  } = methods;

  const [connectionId, setConnectionId] = useState<string>(id || '');
  const [workflowState, setWorkflowState] = useState<WorkflowState>({
    // form fields
    cloudAccountName: '',
    cloudAccountSubscriptions: [],
    tenantId: '',
    clientId: '',
    clientSecret: '',

    // steps state/icon handling
    firstStep: 'pending',
    secondStep: 'pending',
    thirdStep: 'pending',
    fourthStep: 'pending',
    fifthStep: 'pending',
    sixthStep: 'pending',

    // steps accordion folding
    preReqOpen: true,
    firstOpen: id === undefined,
    secondOpen: false,
    thirdOpen: false,
    fourthOpen: false,
    fifthOpen: false,
    sixthOpen: false,
  });

  const [subscriptionIdInTextField, setSubscriptionIdInTextField] =
    useState<string>('');
  const [subscriptionRows, setSubscriptionRows] = useState<
    { subscriptionId: string }[]
  >(workflowState.cloudAccountSubscriptions);

  // Function to handle deleting a row
  const handleDeleteRow = (id: string) => {
    const newRows = subscriptionRows.filter((row) => row.subscriptionId !== id);
    setSubscriptionRows(newRows);
  };
  // Function to handle adding a new row
  const handleAddRow = (id: string) => {
    setSubscriptionRows([...subscriptionRows, { subscriptionId: id }]);
    setSubscriptionIdInTextField('');
  };

  const {
    data: cloudConnectionData,
    error: cloudConnectionError,
    mutate: cloudConnectionMutate,
  } = useGetCloudConnection(connectionId, {
    request: { headers: { Authorization: `Bearer ${account?.token}` } },
    swr: {
      enabled: !!connectionId,
    },
  });

  if (id && cloudConnectionData && workflowState.fifthStep !== 'completed') {
    setWorkflowState((prev) => ({
      ...prev,
      cloudAccountName: cloudConnectionData.cloud_connection_name || '',
      tenantId: cloudConnectionData.azure?.tenant_id || '',
      clientId: cloudConnectionData.azure?.app_id || '',
      clientSecret: cloudConnectionData.azure?.app_secret || '',
      cloudAccountSubscriptions:
        cloudConnectionData.azure?.scopes.map((scope) => {
          return { subscriptionId: scope.id };
        }) || [],

      firstStep: 'completed',
      secondStep: 'completed',
      thirdStep: 'completed',
      fourthStep: 'completed',
      fifthStep: 'completed',
      sixthStep: connectionStateToCreationState(cloudConnectionData.status),

      firstOpen: false,
      secondOpen: false,
      thirdOpen: false,
      fourthOpen: false,
      fifthOpen: false,
      sixthOpen: cloudConnectionData.status !== 'connected',
    }));
    setSubscriptionRows(
      cloudConnectionData.azure?.scopes.map((scope) => {
        return { subscriptionId: scope.id };
      }) || [],
    );
  } else if (cloudConnectionError && workflowState.fifthStep !== 'failed') {
    {
      enqueueSnackbar(LoadRequestError, {
        variant: 'error',
        autoHideDuration: toastAutoHideDuration,
      });
      Sentry.captureException(cloudConnectionError || LoadRequestError, {
        level: 'error',
      });
      setWorkflowState((prev) => ({ ...prev, fifthStep: 'failed' }));
    }
  }

  const validateConnection = async (connection: CloudConnection) => {
    try {
      setWorkflowState((prev) => ({
        ...prev,
        sixthStep: 'processing',
      }));

      await createCloudConnectionAction(
        connection.id,
        { action_type: ActionType.VERIFY },
        { headers: { Authorization: `Bearer ${account?.token}` } },
      );
      cloudConnectionMutate();
      if (connection?.status === 'connected') {
        setWorkflowState((prev) => ({
          ...prev,
          fifthStep: 'completed',
          sixthStep: 'completed',

          firstOpen: false,
          secondOpen: false,
          thirdOpen: false,
          fourthOpen: false,
          fifthOpen: false,
          sixthOpen: true,
        }));
        logEvent('cloud-account-verify-connection-to-chkk-marked-as-done');
      } else {
        setWorkflowState((prev) => ({
          ...prev,
          sixthStep: connectionStateToCreationState(connection.status),
        }));
      }
    } catch (error) {
      setWorkflowState((prev) => ({ ...prev, sixthStep: 'failed' }));
      Sentry.captureException(error || EditRequestError, {
        level: 'error',
      });
    }
  };

  const createHandler = async (
    cloudAccountName: string,
    subscriptions: { subscriptionId: string }[],
    tenantId: string,
    clientId: string,
    clientSecret: string,
  ) => {
    setWorkflowState((prev) => ({ ...prev, fifthStep: 'processing' }));
    try {
      const result = await createCloudConnection(
        {
          cloud_type: CloudType.AZURE,
          cloud_connection_name: cloudAccountName,
          azure: {
            is_organization: false,
            app_id: clientId,
            app_secret: clientSecret,
            tenant_id: tenantId,
            scopes: subscriptions.map((subscription) => {
              return { level: 'Subscription', id: subscription.subscriptionId };
            }),
          },
        },
        {
          headers: { Authorization: `Bearer ${account?.token}` },
        },
      );
      setConnectionId(result.id);
      cloudConnectionMutate();
      setWorkflowState((prev) => ({
        ...prev,
        fifthStep: 'completed',
        sixthStep: 'processing',
        firstOpen: false,
        secondOpen: false,
        thirdOpen: false,
        fourthOpen: false,
        fifthOpen: false,
        sixthOpen: true,
      }));
    } catch ({ response }) {
      enqueueSnackbar(EditRequestError, {
        variant: 'error',
        autoHideDuration: toastAutoHideDuration,
      });
      Sentry.captureException(response?.data || EditRequestError, {
        level: 'error',
      });
      setWorkflowState((prev) => ({ ...prev, fifthStep: 'failed' }));
    }
  };
  useEffect(() => {
    if (cloudConnectionData) {
      if (
        (cloudConnectionData.status === 'failed' &&
          workflowState.sixthStep === 'failed') ||
        (cloudConnectionData.status === 'connected' &&
          workflowState.sixthStep === 'completed')
      ) {
        return;
      }
      const interval = setInterval(async () => {
        await validateConnection(cloudConnectionData);
      }, 10000);

      return () => clearInterval(interval);
    }
  }, [cloudConnectionData, workflowState.sixthStep]);

  const scopes = workflowState.cloudAccountSubscriptions
    .map((subscription) => `/subscriptions/${subscription.subscriptionId}`)
    .join(' ');

  const [cloudConnectionSuffix, setCloudConnectionSuffix] =
    useState<string>('');

  return (
    <Page title="Cloud Accounts">
      <div className="container max-w-none flex h-full w-[calc(100%-64px)] flex-col items-start gap-6 bg-default-background pt-8 pr-3 pl-3 ml-8">
        <Breadcrumbs>
          <Breadcrumbs.Item>Configure</Breadcrumbs.Item>
          <Breadcrumbs.Divider name="FeatherChevronRight" />
          <RouterLink to={basePathCloudAccounts}>
            <Breadcrumbs.Item>Cloud Accounts</Breadcrumbs.Item>
          </RouterLink>
          <Breadcrumbs.Divider name="FeatherChevronRight" />
          {id === undefined && (
            <Breadcrumbs.Item active={true}>Add Azure Account</Breadcrumbs.Item>
          )}
          {id && <Breadcrumbs.Item active={true}>{id}</Breadcrumbs.Item>}
        </Breadcrumbs>
        <div className="flex w-full flex-col items-start gap-6">
          <div className="flex w-full items-start justify-between pb-3">
            <div className="flex grow shrink-0 basis-0 flex-col items-start gap-2 self-stretch">
              <span className="text-section-header font-section-header text-default-font">
                {id === undefined
                  ? 'Add Azure Account'
                  : `Azure Account: ${cloudConnectionData?.cloud_connection_name}`}
              </span>
              <span className="text-label font-label text-subtext-color">
                {id === undefined
                  ? 'Connect your Azure Account to Chkk'
                  : `ID: ${id}`}
              </span>
            </div>
          </div>
        </div>
        {(id !== undefined && !cloudConnectionData) ||
        !listCloudConnectionsData ? (
          <div>
            <Loader />
          </div>
        ) : (
          <div className="flex w-full flex-col items-start gap-2">
            <LearnMoreAccordion
              title="Pre-requisites"
              open={workflowState.preReqOpen}
              onOpenChange={(open) => {
                setWorkflowState((prev) => {
                  return {
                    ...prev,
                    preReqOpen: !prev.preReqOpen,
                    preReqStep: 'completed',
                  };
                });
              }}
            >
              <span className="text-body-bold font-body-bold text-default-font">
                You must have User Access Administrator or Role Based Access
                Control Administrator permissions, or higher, in your
                subscription to create the Cloud Connection.
              </span>
            </LearnMoreAccordion>
            <StepBase
              stepTitle="Name Your Connection"
              stepBody="Provide a name to identify this connection."
              stepNumber="1"
              open={workflowState.firstOpen}
              onOpenChange={(open) => {
                setWorkflowState((prev) => {
                  return {
                    ...prev,
                    firstOpen: open,
                  };
                });
                open && logEvent('cloud-account-azure-step1-opened');
              }}
              variant={creationStateToStepVariant(workflowState.firstStep)}
              actionButtons={
                <Button
                  disabled={workflowState.firstStep === 'completed'}
                  variant="brand-secondary"
                  size="medium"
                  icon="FeatherCornerDownRight"
                  loading={false}
                  onClick={async (event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    const isValid = await validateForm('cloudAccountName');
                    if (workflowState.cloudAccountName && isValid) {
                      setWorkflowState((prev) => {
                        return {
                          ...prev,
                          firstOpen: false,
                          firstStep: 'completed',
                          secondOpen: true,
                        };
                      });
                      logEvent('cloud-account-azure-step1-marked-as-done');
                    }
                  }}
                >
                  Mark as Done
                </Button>
              }
            >
              <div className="flex w-full items-center rounded shadow">
                <div className="flex w-52 flex-none items-center gap-1">
                  <span className="text-body font-body text-default-font">
                    {id === undefined ? 'Enter Account Name' : 'Account Name'}
                  </span>
                  <InfoTooltip tooltipText="Provide a name to identify this connection" />
                </div>
                <TextField
                  className="h-auto grow shrink-0 basis-0"
                  error={methods.getFieldState('cloudAccountName').invalid}
                  label=""
                  helpText={errors?.cloudAccountName?.message}
                >
                  <TextField.Input
                    {...methods.register('cloudAccountName')}
                    disabled={
                      workflowState.firstStep === 'completed' ||
                      id !== undefined
                    }
                    placeholder={
                      cloudConnectionData?.cloud_connection_name || ''
                    }
                    onChange={(event) =>
                      setWorkflowState((prev) => ({
                        ...prev,
                        cloudAccountName: event.target.value,
                      }))
                    }
                  />
                </TextField>
              </div>
            </StepBase>

            <StepBase
              stepTitle="Provide Subscription IDs"
              stepBody="Enter the Subscription IDs you wish to connect with Chkk"
              stepNumber="2"
              open={workflowState.secondOpen}
              onOpenChange={(open) => {
                setWorkflowState((prev) => {
                  return {
                    ...prev,
                    secondOpen: open,
                  };
                });
                open && logEvent('cloud-account-azure-step2-opened');
              }}
              variant={creationStateToStepVariant(workflowState.secondStep)}
              actionButtons={
                <Button
                  disabled={workflowState.secondStep === 'completed'}
                  variant="brand-secondary"
                  size="medium"
                  icon="FeatherCornerDownRight"
                  loading={false}
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    if (subscriptionRows.length) {
                      setWorkflowState((prev) => {
                        return {
                          ...prev,
                          secondOpen: false,
                          secondStep: 'completed',
                          thirdOpen: true,
                          cloudAccountSubscriptions: subscriptionRows,
                        };
                      });
                      logEvent('cloud-account-azure-step2-marked-as-done');
                      setSubscriptionIdInTextField('');
                    } else {
                      enqueueSnackbar(
                        'Please Add at least one subscription ID',
                        {
                          variant: 'error',
                          autoHideDuration: toastAutoHideDuration,
                        },
                      );
                    }
                  }}
                >
                  Mark as Done
                </Button>
              }
            >
              <div className="flex w-full items-center gap-2 rounded shadow">
                <span className="w-72 flex-none text-body font-body text-default-font">
                  Enter your Azure subscription ID(s)
                </span>
                <div className="flex grow shrink-0 basis-0 items-start justify-center gap-2">
                  <TextField
                    className="h-auto grow shrink-0 basis-0"
                    error={methods.getFieldState('subscriptionId').invalid}
                    label=""
                    helpText={errors?.subscriptionId?.message}
                  >
                    <TextField.Input
                      {...methods.register('subscriptionId')}
                      disabled={
                        workflowState.secondStep === 'completed' ||
                        id !== undefined
                      }
                      value={subscriptionIdInTextField}
                      onChange={(event) => {
                        setSubscriptionIdInTextField(event.target.value);
                      }}
                      onKeyDown={async (event) => {
                        if (event.key === 'Enter') {
                          const isValid = await validateForm('subscriptionId');
                          if (subscriptionIdInTextField && isValid) {
                            handleAddRow(subscriptionIdInTextField);
                            setSubscriptionIdInTextField('');
                            logEvent(
                              'cloud-account-azure-step2-subscription-added',
                            );
                          }
                        }
                      }}
                    />
                  </TextField>
                  <Button
                    disabled={workflowState.secondStep === 'completed'}
                    variant={
                      subscriptionIdInTextField
                        ? 'brand-primary'
                        : 'brand-tertiary'
                    }
                    icon="FeatherPlus"
                    onClick={async () => {
                      const isValid = await validateForm('subscriptionId');
                      if (subscriptionIdInTextField && isValid) {
                        handleAddRow(subscriptionIdInTextField);
                        setSubscriptionIdInTextField('');
                        logEvent(
                          'cloud-account-azure-step2-subscription-added',
                        );
                      }
                    }}
                  >
                    Add
                  </Button>
                </div>
              </div>
              <div className="flex w-full grow shrink-0 basis-0 items-start justify-between">
                <div className="flex w-72 flex-none items-start self-stretch" />
                {subscriptionRows.length ? (
                  <Table
                    header={
                      <Table.HeaderRow>
                        <Table.HeaderCell>Subscription ID</Table.HeaderCell>
                      </Table.HeaderRow>
                    }
                  >
                    {subscriptionRows.map((subscription) => {
                      return (
                        <Table.Row
                          className="h-auto w-full flex-none"
                          clickable={true}
                          key={subscription.subscriptionId}
                        >
                          <Table.Cell>
                            <span className="whitespace-nowrap text-body font-body text-neutral-700">
                              {subscription.subscriptionId}
                            </span>
                          </Table.Cell>
                          <Table.Cell>
                            <div className="flex grow shrink-0 basis-0 items-center justify-end">
                              <IconButton
                                disabled={
                                  workflowState.secondStep === 'completed'
                                }
                                icon="FeatherTrash"
                                onClick={() => {
                                  handleDeleteRow(subscription.subscriptionId);
                                }}
                              />
                            </div>
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table>
                ) : (
                  <></>
                )}
              </div>
            </StepBase>

            <StepBase
              stepTitle="Login to Azure"
              stepBody="Open a terminal and log in to your Azure account."
              stepNumber="3"
              open={workflowState.thirdOpen}
              onOpenChange={(open) => {
                setWorkflowState((prev) => {
                  return {
                    ...prev,
                    thirdOpen: open,
                  };
                });
                open && logEvent('cloud-account-azure-step3-opened');
              }}
              variant={creationStateToStepVariant(workflowState.thirdStep)}
              actionButtons={
                <Button
                  disabled={workflowState.thirdStep === 'completed'}
                  variant="brand-secondary"
                  size="medium"
                  icon="FeatherCornerDownRight"
                  loading={false}
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    if (
                      workflowState.firstStep !== 'completed' ||
                      workflowState.secondStep !== 'completed'
                    ) {
                      enqueueSnackbar('Please complete step 1 & 2 first', {
                        variant: 'error',
                        autoHideDuration: toastAutoHideDuration,
                      });
                    } else {
                      setWorkflowState((prev) => {
                        return {
                          ...prev,
                          thirdOpen: false,
                          thirdStep: 'completed',
                          fourthOpen: true,
                        };
                      });
                      logEvent('cloud-account-azure-step3-marked-as-done');
                    }
                  }}
                >
                  Mark as Done
                </Button>
              }
            >
              <div className="flex w-full flex-col items-start gap-2">
                <span className="w-full text-body font-body text-default-font">
                  Open a terminal and log in to your Azure account.
                </span>
                <CopyToClipboard
                  className="h-auto w-full flex-none"
                  text={
                    workflowState.firstStep !== 'completed' ||
                    workflowState.secondStep !== 'completed'
                      ? 'Please complete step 1 & 2 first'
                      : 'az login'
                  }
                  onCopy={() => {
                    logEvent('cloud-account-azure-step3-az-login-copied');
                  }}
                />
              </div>
            </StepBase>

            <StepBase
              stepTitle="Create a Service Principal with Reader Role"
              stepBody="Create the IAM Role for Chkk to assume"
              stepNumber="4"
              open={workflowState.fourthOpen}
              onOpenChange={(open) => {
                setWorkflowState((prev) => {
                  return {
                    ...prev,
                    fourthOpen: open,
                  };
                });
                open && logEvent('cloud-account-azure-step4-opened');
              }}
              variant={creationStateToStepVariant(workflowState.fourthStep)}
              actionButtons={
                <Button
                  disabled={workflowState.fourthStep === 'completed'}
                  variant="brand-secondary"
                  size="medium"
                  icon="FeatherCornerDownRight"
                  loading={false}
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    if (workflowState.thirdStep !== 'completed') {
                      enqueueSnackbar('Please complete step 3 first', {
                        variant: 'error',
                        autoHideDuration: toastAutoHideDuration,
                      });
                    } else {
                      setWorkflowState((prev) => {
                        return {
                          ...prev,
                          fourthOpen: false,
                          fourthStep: 'completed',
                          fifthOpen: true,
                        };
                      });
                      logEvent('cloud-account-azure-step3-marked-as-done');
                    }
                  }}
                >
                  Mark as Done
                </Button>
              }
            >
              <div className="flex w-full flex-col items-start justify-center gap-3">
                <span className="w-full text-body font-body text-default-font">
                  Create a new service principal with the Reader role. Paste the
                  following command in your terminal.
                </span>
                <CopyToClipboard
                  className="h-auto w-full flex-none"
                  text={
                    workflowState.thirdStep !== 'completed'
                      ? 'Please complete step 3 first'
                      : `az ad sp create-for-rbac --display-name "${
                          'chkk-cloud-connect-' + workflowState.cloudAccountName
                        }" --role Reader --scopes ${
                          scopes || '<SUBSCRIPTION-IDS>'
                        }`
                  }
                  onCopy={() => {
                    logEvent('cloud-account-azure-step4-az-create-sp-copied');
                  }}
                />
                <PropertiesAccordion title="Sample Output:">
                  <span className="text-body font-body text-default-font">
                    The output of the command should resemble the example below.
                    Please note that the password is displayed only once during
                    the creation of the service principal.
                  </span>
                  <CodeBlock className="h-auto w-full flex-none">
                    {workflowState.thirdStep !== 'completed'
                      ? 'Please complete step 3 first'
                      : [
                          '{',
                          '   "appId": "Client/Application ID"',
                          `   "displayName": "${
                            'chkk-cloud-connect-' +
                            workflowState.cloudAccountName
                          }"`,
                          '   "password": "Client/Application Secret"',
                          '   "tenant": "Tenant ID"',
                          '}',
                        ].join('\n')}
                  </CodeBlock>
                </PropertiesAccordion>
              </div>
            </StepBase>

            <StepBase
              stepTitle="Provide Service Principal details"
              stepBody="Create the IAM Role for Chkk to assume"
              stepNumber="5"
              open={workflowState.fifthOpen}
              onOpenChange={(open) => {
                setWorkflowState((prev) => {
                  return {
                    ...prev,
                    fifthOpen: open,
                  };
                });
                open && logEvent('cloud-account-azure-step5-opened');
              }}
              variant={creationStateToStepVariant(workflowState.fifthStep)}
              actionButtons={
                <Button
                  disabled={
                    workflowState.fifthStep === 'processing' ||
                    workflowState.fifthStep === 'completed' ||
                    id !== undefined
                  }
                  variant="brand-secondary"
                  size="medium"
                  icon="FeatherCornerDownRight"
                  loading={workflowState.fifthStep === 'processing'}
                  onClick={async (event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    const isValid =
                      (await validateForm('tenantId')) &&
                      (await validateForm('clientId')) &&
                      (await validateForm('clientSecret'));
                    if (isValid) {
                      createHandler(
                        workflowState.cloudAccountName,
                        workflowState.cloudAccountSubscriptions,
                        workflowState.tenantId,
                        workflowState.clientId,
                        workflowState.clientSecret,
                      );
                      logEvent('cloud-account-azure-step5-marked-as-done');
                    }
                  }}
                >
                  Mark as Done
                </Button>
              }
            >
              <span className="text-body font-body text-default-font">
                Enter the values from the output of the service principal
                creation command from previous step.
              </span>
              <div className="flex w-full items-center rounded shadow">
                <div className="flex w-36 flex-none items-center gap-1">
                  <span className="text-body font-body text-default-font">
                    Tenant ID
                  </span>
                </div>
                <TextField
                  className="h-auto grow shrink-0 basis-0"
                  error={methods.getFieldState('tenantId').invalid}
                  label=""
                  helpText={errors?.tenantId?.message}
                >
                  <TextField.Input
                    {...methods.register('tenantId')}
                    disabled={
                      workflowState.fifthStep === 'completed' ||
                      workflowState.fifthStep === 'processing' ||
                      id !== undefined
                    }
                    placeholder={cloudConnectionData?.azure?.tenant_id || ''}
                    onChange={(event) =>
                      setWorkflowState((prev) => ({
                        ...prev,
                        tenantId: event.target.value,
                      }))
                    }
                  />
                </TextField>
              </div>
              <div className="flex w-full items-center rounded shadow">
                <div className="flex w-36 flex-none items-center gap-1">
                  <span className="text-body font-body text-default-font">
                    Client ID
                  </span>
                </div>

                <TextField
                  className="h-auto grow shrink-0 basis-0"
                  error={methods.getFieldState('clientId').invalid}
                  label=""
                  helpText={errors?.clientId?.message}
                >
                  <TextField.Input
                    {...methods.register('clientId')}
                    disabled={
                      workflowState.fifthStep === 'completed' ||
                      workflowState.fifthStep === 'processing' ||
                      id !== undefined
                    }
                    placeholder={cloudConnectionData?.azure?.app_id || ''}
                    onChange={(event) =>
                      setWorkflowState((prev) => ({
                        ...prev,
                        clientId: event.target.value,
                      }))
                    }
                  />
                </TextField>
              </div>
              <div className="flex w-full items-center rounded shadow">
                <div className="flex w-36 flex-none items-center gap-1">
                  <span className="text-body font-body text-default-font">
                    Client Secret
                  </span>
                </div>
                <TextField
                  className="h-auto grow shrink-0 basis-0"
                  error={methods.getFieldState('clientSecret').invalid}
                  label=""
                  helpText={errors?.clientSecret?.message}
                >
                  <div className="flex w-full grow shrink-0 basis-0 items-center justify-between">
                    <TextField.Input
                      {...methods.register('clientSecret')}
                      type={hideClientSecret ? 'password' : 'text'}
                      disabled={
                        workflowState.fifthStep === 'completed' ||
                        workflowState.fifthStep === 'processing' ||
                        id !== undefined
                      }
                      placeholder={
                        workflowState.fifthStep === 'completed' ||
                        workflowState.fifthStep === 'processing' ||
                        cloudConnectionData
                          ? "For security purposes, we don't show your existing Client Secret"
                          : ''
                      }
                      onChange={(event) =>
                        setWorkflowState((prev) => ({
                          ...prev,
                          clientSecret: event.target.value,
                        }))
                      }
                    />
                    <AtomicTooltip
                      tooltipContent={
                        hideClientSecret ? 'Show Secret' : 'Hide Secret'
                      }
                    >
                      <IconButton
                        disabled={
                          workflowState.fifthStep === 'completed' ||
                          workflowState.fifthStep === 'processing' ||
                          id !== undefined
                        }
                        icon={hideClientSecret ? 'FeatherEye' : 'FeatherEyeOff'}
                        size="small"
                        onClick={() => setHideClientSecret(!hideClientSecret)}
                      />
                    </AtomicTooltip>
                  </div>
                </TextField>
              </div>
            </StepBase>

            <StepBase
              stepTitle="Verify Connection to Chkk"
              stepBody="Verify Connection to Chkk"
              lastStep={true}
              variant={creationStateToStepVariant(workflowState.sixthStep)}
              stepTitleTooltip={
                cloudConnectionData?.status === 'failed' ? (
                  <Tooltip>
                    Cloud connection failed. Please check your input and retry
                    by creating a new connection
                  </Tooltip>
                ) : null
              }
              stepNumber="6"
              actionButtons={
                cloudConnectionData?.status === 'connected' && (
                  <Button
                    disabled={workflowState.sixthStep === 'processing'}
                    variant="brand-secondary"
                    size="medium"
                    icon="FeatherCornerDownRight"
                    loading={workflowState.sixthStep === 'processing'}
                    onClick={async () => {
                      validateConnection(cloudConnectionData);
                    }}
                  >
                    Redo
                  </Button>
                )
              }
            />

            {cloudConnectionData?.status === 'connected' &&
              workflowState.sixthStep === 'completed' && (
                <RouterLink to={basePathClusters} className="w-full">
                  <CtaCard
                    className="h-auto w-full flex-none"
                    leftSlot={
                      <IconWithBackground
                        variant="success"
                        size="medium"
                        icon="FeatherCoffee"
                      />
                    }
                  >
                    <span className="text-subheader font-subheader text-default-font">
                      🎉 Your Azure cloud account is connected!
                    </span>
                    <span className="text-body font-body text-default-font">
                      Cloud metadata is being collected. Availability Risks will
                      appear in the Clusters page.
                    </span>
                  </CtaCard>
                </RouterLink>
              )}
          </div>
        )}
      </div>
    </Page>
  );
}
