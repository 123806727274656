import Page from 'components/Page';
import { Container, Stack } from 'components/utils';
import { Divider } from 'components/design-system';
import Logo from 'components/Logo';
import { CopyToClipboard } from 'src/components/design-system/CopyToClipboard';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import styles from './ChkkAgent.module.scss';
import { Text } from '@subframe/core';

let sampleYaml = `apiVersion: k8s.chkk.io/v1beta1
kind: ChkkAgent
metadata:
    name: chkk-agent
    namespace: chkk-system
spec:
# Global Chkk settings to configure the agent
global:
    credentials:
        accessToken: < TOKEN > # The access token of the ChkkAgent
        accessTokenSecret: # AccessTokenSecret uses an existing Secret which stores Chkk Access Token instead of creating a new one. If set, this parameter takes precedence over "accessToken".
            secretName: chkk-agent-access-token
            keyName: accessToken
    #  Filters sets the includes / excludes rules to filter Kubernetes Kinds or any parts of the manifests
    filter: |-
        rules:
        - include:
            - path: $.kind
                match: ^*$
        - exclude:
            - path: $.kind
                match: ^Secret|ConfigMap|ExternalSecret$
    updates: # Set the auto-update configuration for k8s connector
        auto: false
        frequency: weekly # This will be ignored if the above is set to false
# Override default configuration of the ChkkAgent
agentOverride:
    # Name of the ChkkAgent
    name: chkk-agent-custom
    # Schedule of the ChkkAgent CronJob in Cron format
    schedule: "*/5 * * * *"
    # Set CreateRbac to false to prevent automatic creation of Role/ClusterRole for this component
    createRbac: false
    # Sets the ServiceAccount used by this component. Ignored if the field CreateRbac is true.
    serviceAccountName: chkk-cluster-context-cronjob-custom
    `;

export default function ChkkAgent() {
  const { logEvent } = AnalyticsEventLogger();

  return (
    <Page title="ChkkAgent">
      <Stack className="w-full px-[30px] py-[8px] bg-none">
        <Logo style={{ width: '100px' }} />
      </Stack>
      <Divider variant="neutral" />
      <Stack className="w-full px-[300px] pt-[20px] pb-[30px] bg-none">
        <Container spacing={4}>
          <Text variant="header">ChkkAgent</Text>
          <Text variant="body">
            The ChkkAgent resource is a Kubernetes Custom Resource (CRD) that
            describes the desired state for the K8s Connector and allows you to
            configure the components that make up the K8s Connector in your
            cluster.{' '}
          </Text>
          <Text variant="body">
            The default configuration allows ChkkAgent to automatically update
            K8s Connector.
          </Text>
          <Text>
            The following example provides description for overridable
            configuration fields:
          </Text>
          <CopyToClipboard
            text={sampleYaml}
            onCopy={() =>
              logEvent('terminal-copy-chkkagent-sample', {
                command: 'chkk-agent-sample-manifest',
              })
            }
          />
          <Text variant="subheader">Bring your own Service Account</Text>
          <Text variant="body">
            ChkkAgent allows you to mount your own Service Account instead of
            creating one for you. The following example shows how you can modify
            ChkkAgent default configuration to mount your Service Account:
          </Text>
          <CopyToClipboard
            text={[
              'apiVersion: k8s.chkk.io/v1beta1',
              'kind: ChkkAgent',
              'metadata:',
              '   name: chkk-agent',
              '   namespace: chkk-system',
              'spec:',
              '   agentOverride:',
              '      createRbac: false',
              '      serviceAccountName: chkk-agent-custom-sa',
            ].join('\n')}
            onCopy={() =>
              logEvent('terminal-copy-chkkagent-sample', {
                command: 'chkk-agent-sample-manifest',
              })
            }
          />
          <Text variant="body">
            The above configuration uses the ServiceAccount
            &ldquo;chkk-agent-custom-sa&ldquo; for ChkkAgent. If you are
            planning to use a custom service account for ChkkAgent, make sure
            the associated ClusterRole has the following RBAC permissions:
          </Text>
          <CopyToClipboard
            text={[
              '- apiGroups: ["batch"]',
              '  resources: ["jobs", "cronjobs"]',
              '  verbs: ["get", "create", "list", "update"]',
              '- apiGroups: [""]',
              '  resources: ["nodes"]',
              '  verbs: ["get", "list", "watch"]',
            ].join('\n')}
            onCopy={() =>
              logEvent('terminal-copy-chkkagent-sample', {
                command: 'chkk-agent-sample-manifest',
              })
            }
          />
          <Text variant="subheader">Bring your own Secret</Text>
          <Text variant="body">
            ChkkAgent allows you to mount your own Secret instead of creating
            one for you. The following example shows how you can modify
            ChkkAgent default configuration to mount your Secret:
          </Text>
          <CopyToClipboard
            text={[
              'apiVersion: k8s.chkk.io/v1beta1',
              'kind: ChkkAgent',
              'metadata:',
              '   name: chkk-agent',
              '   namespace: chkk-system',
              'spec:',
              '   global:',
              '      credentials:',
              '        accessTokenSecret:',
              '          keyName: accessToken',
              '          secretName: chkk-credentials',
            ].join('\n')}
            onCopy={() =>
              logEvent('terminal-copy-chkkagent-sample', {
                command: 'chkk-agent-sample-manifest',
              })
            }
          />
          <Text>
            The above configuration would use the token mounted in accessToken
            key in chkk-credentials secret as the global token for communication
            with Chkk API.
          </Text>
        </Container>
      </Stack>
      <Divider variant="neutral" className={styles['bottom']} />
    </Page>
  );
}
