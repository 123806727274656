import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import useUserAccountState from 'hooks/useUserAccountState';

import { Dialog } from 'subframe/components/Dialog';
import { IconButton } from 'subframe/components/IconButton';
import { TextField } from 'subframe/components/TextField';
import { Button } from 'subframe/components/Button';
import { IngestionToken } from 'api/models';

import * as Sentry from '@sentry/browser';
import * as Yup from 'yup';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { somethingWentWrong, toastAutoHideDuration } from 'constants/toasts';
import { updateIngestionToken } from 'api/frontend';

interface EditTokenNameDialogProps {
  open: boolean;
  token: IngestionToken;
  onClose: () => void;
  callRefetch: VoidFunction;
}

export function EditTokenNameDialog({
  open,
  token,
  onClose,
  callRefetch,
}: EditTokenNameDialogProps): JSX.Element {
  const { logEvent } = AnalyticsEventLogger();
  const { enqueueSnackbar } = useSnackbar();
  const { account, currentOrganization } = useUserAccountState();
  const [updatedName, setUpdatedName] = useState(
    token.token_name || `token-${currentOrganization.name.toLowerCase()}`,
  );
  const [nameErrorText, setNameErrorText] = useState<string | undefined>(
    undefined,
  );
  const [isSubmitting, setisSubmitting] = useState(false);

  const ChangeNameSchema = Yup.object().shape({
    name: Yup.string()
      .required('Token Name is required')
      .matches(/^[a-z0-9]([-a-z0-9]*[a-z0-9])?$/, {
        message:
          'Token name must start and end with lowercase alphanumeric characters, and can be separated by hyphens.',
      }),
  });

  const handleChangeName = async () => {
    setisSubmitting(true);
    try {
      await ChangeNameSchema.validate({ name: updatedName });
      // if the name has changed, call the onConfirm callback
      if (
        updatedName &&
        updatedName.localeCompare(token.token_name || '') !== 0
      ) {
        await updateTokenNameHandler(updatedName);
      }
      onClose();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        setNameErrorText(error.message);
      } else {
        Sentry.captureException(error);
      }
    } finally {
      setisSubmitting(false);
    }
  };

  const handleOnClose = () => {
    setUpdatedName(
      token.token_name || `token-${currentOrganization.name.toLowerCase()}`,
    );
    setNameErrorText(undefined);
    onClose();
  };

  async function updateTokenNameHandler(newName: string) {
    try {
      await updateIngestionToken(
        'default',
        token.token_id,
        { token_name: newName },
        {
          headers: {
            Authorization: `Bearer ${account.token}`,
          },
        },
      );
      logEvent('token-name-change', {
        tokenID: token.token_id,
        name: newName,
      });
      callRefetch();
      enqueueSnackbar('Successfully updated Token Name', {
        variant: 'success',
        autoHideDuration: toastAutoHideDuration,
      });
    } catch (err) {
      enqueueSnackbar(
        somethingWentWrong.replace('<action>', 'Updating name for Token'),
        {
          variant: 'error',
          autoHideDuration: toastAutoHideDuration,
        },
      );
      Sentry.captureException(err);
    }
  }

  useEffect(() => {
    setUpdatedName(
      token.token_name || `token-${currentOrganization.name.toLowerCase()}`,
    );
  }, [token]);

  return (
    <div className="contents">
      <Dialog open={open} onOpenChange={handleOnClose}>
        <Dialog.Content className="h-auto w-80 flex-none">
          <div className="flex w-full flex-col items-start gap-6 pt-6 pr-6 pb-6 pl-6">
            <div className="flex w-full items-center justify-between">
              <span className="text-subheader font-subheader text-default-font">
                Edit Token Name
              </span>
              <IconButton
                size="medium"
                icon="FeatherX"
                onClick={handleOnClose}
              />
            </div>
            <div className="flex w-full flex-col items-start gap-4">
              <TextField
                className="h-auto w-full flex-none"
                helpText={nameErrorText}
                error={!!nameErrorText}
              >
                <TextField.Input
                  value={updatedName}
                  onChange={(event) => setUpdatedName(event.target.value)}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      handleChangeName();
                    }
                  }}
                />
              </TextField>
            </div>
            <div className="flex w-full grow shrink-0 basis-0 items-end justify-end gap-2">
              <Button
                variant="brand-primary"
                size="large"
                icon="FeatherSave"
                onClick={handleChangeName}
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                Save
              </Button>
            </div>
          </div>
        </Dialog.Content>
      </Dialog>
    </div>
  );
}
