import Page from 'components/Page';
import { KBAPage } from './kba/index';

const LARClusterDetails = () => {
  return (
    <Page title="LAR Details">
      <KBAPage isFromLARView />
    </Page>
  );
};

export default LARClusterDetails;
