import { Key, SWRConfiguration } from 'swr';
import useSwrInfinite, { SWRInfiniteConfiguration } from 'swr/infinite';
import { customInstance } from './axios-instance';
import {
  getListAccountAddonsKey,
  getListAddonInstancesKey,
  getListClusterScanLARResourcesKey,
  getListClusterScanLARsKey,
  getListClusterScanSignaturesKey,
  getListClustersKey,
  getListDirectoryAddonsKey,
  listAccountAddons,
  listAddonInstances,
  listClusterScanLARResources,
  listClusterScanLARs,
  listClusterScanSignatures,
  listClusters,
  listDirectoryAddons,
} from './frontend';
import {
  ListAccountAddonsParams,
  ListAddonInstancesParams,
  ListClusterScanLARResourcesParams,
  ListClusterScanLARsParams,
  ListClusterScanSignaturesParams,
  ListClustersParams,
  ListClustersResponseV2,
  ListDirectoryAddonsParams,
} from './models';

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const useListAddonInstancesInfinite = <TError = unknown>(
  params?: ListAddonInstancesParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listAddonInstances>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
  infiniteParams: {
    initialSize: number;
    revalidateFirstPage: boolean;
  } = {
    initialSize: 100,
    revalidateFirstPage: false,
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey = (
    pageIndex: number,
    previousPageData: Awaited<ReturnType<typeof listAddonInstances>> | null,
  ) => {
    if (!isEnabled) return null;

    // reached the end
    if (previousPageData && !previousPageData.continuation_token) return null;

    if (previousPageData === null) return getListAddonInstancesKey(params);

    // add the cursor to the API endpoint
    return getListAddonInstancesKey({
      ...params,
      continuation_token: previousPageData.continuation_token,
    });
  };

  const swrFn = (args: readonly [string, ListAddonInstancesParams]) => {
    return listAddonInstances(args[1], requestOptions);
  };

  const query = useSwrInfinite<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    infiniteParams,
  );

  return {
    swrKey,
    ...query,
  };
};

export const useListDirectoryAddonsInfinite = <TError = unknown>(
  params?: ListDirectoryAddonsParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listDirectoryAddons>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey = (
    pageIndex: number,
    previousPageData: Awaited<ReturnType<typeof listDirectoryAddons>> | null,
  ) => {
    if (!isEnabled) return null;

    // reached the end
    if (previousPageData && !previousPageData.continuation_token) return null;

    if (previousPageData === null) return getListDirectoryAddonsKey(params);

    // add the cursor to the API endpoint
    return getListDirectoryAddonsKey({
      ...params,
      continuation_token: previousPageData.continuation_token,
    });
  };

  const swrFn = (args: readonly [string, ListDirectoryAddonsParams]) => {
    return listDirectoryAddons(args[1], options?.request);
  };

  const query = useSwrInfinite<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    {
      revalidateFirstPage: false,
      persistSize: true,
      initialSize: 100000,
    },
  );

  return {
    swrKey,
    ...query,
  };
};

export const useListAddonsInfinite = <TError = unknown>(
  params?: ListAccountAddonsParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listAccountAddons>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
  infiniteParams: {
    initialSize?: number;
    revalidateFirstPage?: boolean;
  } = {
    initialSize: 100,
    revalidateFirstPage: false,
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey = (
    pageIndex: number,
    previousPageData: Awaited<ReturnType<typeof listAccountAddons>> | null,
  ) => {
    if (!isEnabled) return null;

    // reached the end
    if (previousPageData && !previousPageData.continuation_token) return null;

    if (previousPageData === null) return getListAccountAddonsKey(params);

    // add the cursor to the API endpoint
    return getListAccountAddonsKey({
      ...params,
      continuation_token: previousPageData.continuation_token,
    });
  };

  // Safely merge swrOptions and infiniteParams
  const mergedOptions: SWRInfiniteConfiguration<
    Awaited<ReturnType<typeof swrFn>>,
    TError
  > = {
    ...infiniteParams, // Always include infiniteParams
    ...(swrOptions ?? {}), // Merge swrOptions if it exists, otherwise use an empty object
  };

  const swrFn = (args: readonly [string, ListAddonInstancesParams]) => {
    return listAccountAddons(args[1], requestOptions);
  };

  const query = useSwrInfinite<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    mergedOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const useListClusterScanLARResourcesInfinite = <TError = unknown>(
  clusterId: string,
  scanId: string,
  larId: string,
  params?: ListClusterScanLARResourcesParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listClusterScanLARResources>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(clusterId && scanId && larId);

  const swrKey = (
    pageIndex: number,
    previousPageData: Awaited<
      ReturnType<typeof listClusterScanLARResources>
    > | null,
  ) => {
    if (!isEnabled) return null;

    // reached the end
    if (previousPageData && !previousPageData.continuation_token) return null;

    if (previousPageData === null)
      return getListClusterScanLARResourcesKey(
        clusterId,
        scanId,
        larId,
        params,
      );

    // add the cursor to the API endpoint
    return getListClusterScanLARResourcesKey(clusterId, scanId, larId, {
      ...params,
      continuation_token: previousPageData.continuation_token,
    });
  };

  const swrFn = (args: readonly [string, ListDirectoryAddonsParams]) => {
    return listClusterScanLARResources(
      clusterId,
      scanId,
      larId,
      args[1],
      requestOptions,
    );
  };

  const query = useSwrInfinite<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    {
      revalidateFirstPage: true,
      persistSize: true,
      initialSize: 1000,
    },
  );

  return {
    swrKey,
    ...query,
  };
};

export const useListClusterScanLARsInfinite = <TError = unknown>(
  clusterId: string,
  scanId: string,
  params?: ListClusterScanLARsParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listClusterScanLARs>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!(clusterId && scanId);

  const swrKey = (
    pageIndex: number,
    previousPageData: Awaited<ReturnType<typeof listClusterScanLARs>> | null,
  ) => {
    if (!isEnabled) return null;

    // reached the end
    if (previousPageData && !previousPageData.continuation_token) return null;

    if (previousPageData === null)
      return getListClusterScanLARsKey(clusterId, scanId, params);

    // add the cursor to the API endpoint
    return getListClusterScanLARsKey(clusterId, scanId, {
      ...params,
      continuation_token: previousPageData.continuation_token,
    });
  };

  const swrFn = (args: readonly [string, ListDirectoryAddonsParams]) => {
    return listClusterScanLARs(clusterId, scanId, args[1], requestOptions);
  };

  const query = useSwrInfinite<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    {
      revalidateFirstPage: false,
      persistSize: true,
      initialSize: 1000,
    },
  );

  return {
    swrKey,
    ...query,
  };
};

export const useListClusterScanSignaturesInfinite = <TError = unknown>(
  clusterId: string,
  scanId: string,
  params?: ListClusterScanSignaturesParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listClusterScanSignatures>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!(clusterId && scanId);

  const swrKey = (
    pageIndex: number,
    previousPageData: Awaited<
      ReturnType<typeof listClusterScanSignatures>
    > | null,
  ) => {
    if (!isEnabled) return null;

    // reached the end
    if (previousPageData && !previousPageData.continuation_token) return null;

    if (previousPageData === null)
      return getListClusterScanSignaturesKey(clusterId, scanId, params);

    // add the cursor to the API endpoint
    return getListClusterScanSignaturesKey(clusterId, scanId, {
      ...params,
      continuation_token: previousPageData.continuation_token,
    });
  };

  const swrFn = (args: readonly [string, ListDirectoryAddonsParams]) => {
    return listClusterScanSignatures(
      clusterId,
      scanId,
      args[1],
      requestOptions,
    );
  };

  const query = useSwrInfinite<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    {
      revalidateFirstPage: false,
      persistSize: true,
      initialSize: 1000,
    },
  );

  return {
    swrKey,
    ...query,
  };
};

export const getCustomListClustersKey = (
  params?: ListClustersParams,
  token?: string,
) => {
  const originalKey = getListClustersKey(params);
  if (token) {
    // Add custom key to the original key
    return [...originalKey, token];
  }
  return originalKey;
};
export const useListClustersInfinite = <TError = unknown>(
  token: string,
  params?: ListClustersParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof listClusters>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
    request?: SecondParameter<typeof customInstance>;
  },
  infiniteParams: {
    initialSize: number;
    revalidateFirstPage: boolean;
  } = {
    initialSize: 100,
    revalidateFirstPage: false,
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;

  const swrKey = (
    pageIndex: number,
    previousPageData: Awaited<ReturnType<typeof listClusters>> | null,
  ) => {
    if (!isEnabled) return null;

    // reached the end
    if (previousPageData && !previousPageData?.continuation_token) return null;

    if (previousPageData === null) return getListClustersKey(params);

    // add the cursor to the API endpoint
    return getCustomListClustersKey(
      {
        ...params,
        continuation_token: previousPageData?.continuation_token,
      },
      token,
    );
  };

  const swrFn = (args: readonly [string, ListClustersParams]) => {
    return listClusters(args[1], requestOptions);
  };

  const query = useSwrInfinite<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    infiniteParams,
  );

  return {
    swrKey,
    ...query,
  };
};

// Helper function to determine if an object is a ListClustersResponseV2
export function isListClustersResponseV2(
  data: any,
): data is ListClustersResponseV2 {
  return 'continuation_token' in data;
}
