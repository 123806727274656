import { UpgradePlan, UpgradeStage } from 'api/models';

export const ISTIO_PLAN: UpgradePlan = {
  id: 'upgr_2cc1bf7a-727a-11ee-b962-0242ac120002',
  name: 'istio -- eks-127-euw2-prod -- in-place',
  resources: {
    addon_instances: [
      {
        id: 'id-istio-upgrade-1',
      },
    ],
  },
  deployment_strategy: 'in-place',
  status: 'completed',
  created: new Date(2023, 9, 11).getTime(),
  updated: new Date(2023, 9, 21).getTime(),
  last_validated: new Date(2023, 9, 21).getTime(),
  requested_by: {
    email: 'katy@chkk.io',
    id: '',
    name: 'Katy Smith',
    picture: '',
  },
  completed_by: {
    email: 'roger@chkk.io',
    id: '',
    name: 'Roger Walter',
    picture: '',
  },
  header: {
    items: [
      {
        title: 'Version',
        value: '1.16.1',
      },
      {
        title: 'Recommended',
        value: '1.17.3',
      },
      {
        title: 'Envoy Version',
        value: 'v1.24.5',
      },
      {
        title: 'Recommended Envoy Version',
        value: 'v1.25.7',
      },
      {
        title: 'Deployment Tool',
        value: 'Helm',
      },
      {
        title: 'Helm Chart Version',
        value: '1.16.1',
      },
      {
        title: 'Recommended Helm Chart Version',
        value: '1.17.3',
      },
      {
        title: 'Upgrade Type',
        value: 'In-Place',
      },
    ],
  },
};

export const ISTIO_PLAN_STAGES: UpgradeStage[] = [
  {
    allowed_action_types: [],
    created: 42,
    footer_content_blocks: [
      {
        text: "Let's start preparing for your upgrade",
        title: "🎉 You are all caught up on what's changing in Istio 1.17.3",
        type: 'completionCtaCard',
      },
    ],
    header_content_blocks: [],
    name: "Learn What's Changed",
    stage_number: 1,
    status: 'completed',
    steps: [
      {
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        content_blocks: [
          {
            style: 'header',
            text: 'Notable Changes',
            type: 'text',
          },
          {
            entries: [
              {
                text: 'Fixed an issue with forward compatibility with Istio 1.18+ Kubernetes Gateway Automated Deployment. To have a seamless upgrade to 1.18+, users of this feature should first adopt this patch release',
              },
              {
                text: 'Fixed The dns_upstream_failures_total metric was mistakenly deleted in the previous release.',
              },
              {
                text: 'Fixed an issue where Istio Gateway (Envoy) would crash due to a duplicate istio_authn network filter in the Envoy filter chain',
              },
              {
                text: 'Fixed a bug that would cause unexpected behavior when applying access logging configuration based on the direction of traffic. With this fix, access logging configuration for CLIENT or SERVER will not affect each other.',
              },
              {
                text: 'Fixed an issue where auto allocated service entry IPs change on host reuse.',
              },
              {
                text: 'Fixed an issue where RBAC updates were not sent to older proxies after upgrading istiod to 1.17.',
              },
              {
                text: 'Fixed istiod not reconciling Kubernetes Gateway deployments and services when they are changed',
              },
              {
                text: 'Fixed an issue where updating service ExternalName does not take effect',
              },
              {
                text: 'Fixed a bug that caused the Namespace’s network label to have a higher priority than the Pod’s network label.',
              },
            ],
            type: 'bullet-list',
          },
          {
            links: [
              {
                text: 'Istio releases',
                url: 'https://istio.io/latest/news/releases/',
              },
            ],
            type: 'learn-more',
          },
        ],
        created: 42,
        description:
          'We recommend moving to Istio version 1.17.3 because it contains bug fixes for the following critical and high severity Defects:',
        name: 'Istio 1.16.1 -> 1.17.3',
        status: 'completed',
        step_number: 1,
        updated: 42,
        comments: {},
      },
    ],
    updated: 42,
  },
  {
    allowed_action_types: [],
    created: 42,
    footer_content_blocks: [
      {
        text: "Let's proceed to upgrade Istio",
        title: '🎉 All Preflight checks are passing',
        type: 'completionCtaCard',
      },
    ],
    header_content_blocks: [],
    name: 'Preflight Checks',
    stage_number: 2,
    status: 'completed',
    steps: [
      {
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        requirement_level: 'required',
        content_blocks: [
          {
            column_definitions: [
              {
                title: 'name',
              },
              {
                title: 'Check Status',
              },
            ],
            data: [
              [
                {
                  value: 'Ensure Compatible Kubernetes API server version',
                },
                {
                  value: 'Pass',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'Ensure Compatible Gateway API version',
                },
                {
                  value: 'Pass',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'Verify No Pods bind to the lo interface',
                },
                {
                  value: 'Pass',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
            ],
            type: 'table',
          },
        ],
        created: 42,
        description: '',
        name: 'Istio upgrade requirement checks',
        status: 'completed',
        step_number: 1,
        updated: 42,
        comments: {},
      },
      {
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        requirement_level: 'required',
        content_blocks: [
          {
            style: 'header',
            text: 'Applications',
            type: 'text',
          },
          {
            column_definitions: [
              {
                title: 'name',
              },
              {
                title: 'Health Check Status',
              },
            ],
            data: [
              [
                {
                  value: 'api',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'cartwheel',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'catalog',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'checkout-manager',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'dashboard',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'frontend',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'order-processor',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'spam-detector',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'billing-manager',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'custom-authorizer',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'dionysus-daemon',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'api-worker',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'asset-manager',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'nightly-verif',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'fujin-simulator',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
            ],
            type: 'table',
          },
        ],
        created: 42,
        description: '',
        name: 'Verify Application Health',
        status: 'completed',
        step_number: 2,
        updated: 42,
        comments: {},
      },
      {
        requirement_level: 'optional',
        allowed_action_types: [
          'mark-completed',
          'undo-mark-completed',
          'skip',
          'undo-skip',
        ],
        content_blocks: [
          {
            style: 'normal',
            text: 'Notify relevant stakeholders (business, product and application owners) about the upcoming\n upgrade and any expected impact or downtime. Communicate the timeline, expected duration, \nand potential risks associated with the upgrade to ensure everyone is well-informed.',
            type: 'text',
          },
        ],
        created: 42,
        last_status_change_details: {
          changed: new Date(2023, 9, 21).getTime() / 1000,
          comment:
            "Not applicable for the time being. Skipping this step. We'll notify the stakeholders later.",
          reason: 'Other',
          user: {
            email: '',
            id: '',
            name: 'Roger Walter',
            picture: '',
          },
        },
        description: '',
        name: 'Notify Stakeholders',
        status: 'skipped',
        step_number: 3,
        updated: 42,
        comments: {},
      },
    ],
    updated: 42,
  },
  {
    allowed_action_types: [],
    created: 42,
    footer_content_blocks: [
      {
        text: "Now let's make sure all the components are healthy",
        title: '🎉 Istio has been upgraded',
        type: 'completionCtaCard',
      },
    ],
    header_content_blocks: [],
    name: 'Upgrade',
    stage_number: 3,
    status: 'completed',
    steps: [
      {
        requirement_level: 'required',
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        content_blocks: [
          {
            style: 'normal',
            text: 'amd64.',
            type: 'text',
          },
          {
            code: 'curl -L https://istio.io/downloadIstio | ISTIO_VERSION=1.17.3 TARGET_ARCH=amd64 sh -',
            type: 'code',
          },
          {
            style: 'normal',
            text: 'arm64.',
            type: 'text',
          },
          {
            code: 'curl -L https://istio.io/downloadIstio | ISTIO_VERSION=1.17.3 TARGET_ARCH=arm64 sh -',
            type: 'code',
          },
        ],
        created: 42,
        description: 'Upgrade istioctl to version 1.17.3',
        name: 'Upgrade istioctl',
        status: 'completed',
        step_number: 1,
        updated: 42,
        comments: {},
      },
      {
        requirement_level: 'required',
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        content_blocks: [
          {
            style: 'normal',
            text: 'You’ll need to run the following command or it’s equivalent in your Helm client. If you’re using Terraform or a different Helm provider, please adjust the command accordingly.',
            type: 'text',
          },
          {
            style: 'normal',
            text: 'Upgrade the Kubernetes custom resource definitions',
            type: 'text',
          },
          {
            code: 'kubectl apply -f manifests/charts/base/crds',
            type: 'code',
          },
          {
            style: 'normal',
            text: 'helm upgrade istio-base manifests/charts/base -n istio-system --skip-crds',
            type: 'text',
          },
          {
            code: 'helm upgrade istio-base manifests/charts/base -n istio-system --skip-crds',
            type: 'code',
          },
          {
            style: 'normal',
            text: 'Upgrade the Istio discovery chart',
            type: 'text',
          },
          {
            code: 'helm upgrade istiod istio/istiod -n istio-system',
            type: 'code',
          },
          {
            style: 'normal',
            text: 'Upgrade and gateway charts installed in your cluster',
            type: 'text',
          },
          {
            code: 'helm upgrade istio-ingress istio/gateway -n istio-ingress',
            type: 'code',
          },
          {
            type: 'spacer',
          },
        ],
        created: 42,
        description:
          'If you already have an existing Infrastructure as Code(IaC) to deploy Istio, please update your IaC using the instructions below. With GitHub Connector installed, Chkk can create Pull Requests to automate this step.',
        name: 'Upgrade Istio to 1.17.3',
        status: 'completed',
        step_number: 2,
        updated: 42,
        comments: {},
      },
      {
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        content_blocks: [
          {
            style: 'normal',
            text: 'You’ll need to run the following command or it’s equivalent to rollout the recommended version of Istio sidecar.',
            type: 'text',
          },
          {
            style: 'normal',
            text: 'List all the namespaces with side injection enabled',
            type: 'text',
          },
          {
            code: 'kubectl get namespace -L istio-injection',
            type: 'code',
          },
          {
            style: 'normal',
            text: 'For each namespace, update the Istio data plane by restarting any pods with Istio sidecars',
            type: 'text',
          },
          {
            code: 'kubectl rollout restart deployment -n NAMESPACE',
            type: 'code',
          },
          {
            type: 'spacer',
          },
        ],
        requirement_level: 'required',
        created: 42,
        description:
          'Restart workloads with Envoy sidecars will result in them picking up the correct Envoy version.',
        name: 'Restart workloads with Envoy sidecars',
        status: 'completed',
        step_number: 3,
        updated: 42,
        comments: {},
      },
    ],
    updated: 42,
  },
  {
    allowed_action_types: [],
    created: 42,
    footer_content_blocks: [
      {
        text: 'You can always refer back to completed Upgrade Plans',
        title: '🎉 Istio has been successfully upgraded',
        type: 'completionCtaCard',
      },
    ],
    header_content_blocks: [],
    name: 'Postflight Checks',
    stage_number: 4,
    status: 'completed',
    steps: [
      {
        requirement_level: 'required',
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        content_blocks: [
          {
            column_definitions: [
              {
                title: 'Verification Step',
              },
              {
                title: 'Status',
              },
            ],
            data: [
              [
                {
                  value:
                    'Check for misplaced and invalid Istio annotations in Kubernetes resources',
                },
                {
                  value: 'Pass',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'Check invalid application UID',
                },
                {
                  value: 'Pass',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'Check the validity of authorization policies',
                },
                {
                  value: 'Pass',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'Check association between services and pods',
                },
                {
                  value: 'Pass',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'Check for deprecated Istio types and fields',
                },
                {
                  value: 'Pass',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value:
                    'Check if caCertificates is set when TLS mode is SIMPLE/MUTUAL',
                },
                {
                  value: 'Pass',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'Check an envoyFilters',
                },
                {
                  value: 'Pass',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'Check a gateway certificate',
                },
                {
                  value: 'Pass',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: "Check a gateway's selector, port number and hosts",
                },
                {
                  value: 'Pass',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value:
                    "Check a gateway's ports against the gateway's Kubernetes service ports",
                },
                {
                  value: 'Pass',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: "Check a gateway's referenced secrets for correctness",
                },
                {
                  value: 'Pass',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'Check conditions related to Istio sidecar injection',
                },
                {
                  value: 'Pass',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value:
                    'Check the image of auto-injection configured with the running proxies on pods',
                },
                {
                  value: 'Pass',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value:
                    'Makes sure that Pods and Deployments with `image: auto` are going to be injected',
                },
                {
                  value: 'Pass',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'Check the validity of MeshNetworks in the cluster',
                },
                {
                  value: 'Pass',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value:
                    "Run schema validation as an analyzer on 'AuthorizationPolicy' resources",
                },
                {
                  value: 'Pass',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value:
                    "Run schema validation as an analyzer on 'DestinationRule' resources",
                },
                {
                  value: 'Pass',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value:
                    "Run schema validation as an analyzer on 'EnvoyFilter' resources",
                },
                {
                  value: 'Pass',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value:
                    "Run schema validation as an analyzer on 'Gateway' resources",
                },
                {
                  value: 'Pass',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value:
                    "Run schema validation as an analyzer on 'MeshConfig' resources",
                },
                {
                  value: 'Pass',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value:
                    "Run schema validation as an analyzer on 'MeshNetworks' resources",
                },
                {
                  value: 'Pass',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value:
                    "Run schema validation as an analyzer on 'PeerAuthentication' resources",
                },
                {
                  value: 'Pass',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value:
                    "Run schema validation as an analyzer on 'ProxyConfig' resources",
                },
                {
                  value: 'Pass',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value:
                    "Run schema validation as an analyzer on 'RequestAuthentication' resources",
                },
                {
                  value: 'Pass',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value:
                    "Run schema validation as an analyzer on 'ServiceEntry' resources",
                },
                {
                  value: 'Pass',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value:
                    "Run schema validation as an analyzer on 'Sidecar' resources",
                },
                {
                  value: 'Pass',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value:
                    "Run schema validation as an analyzer on 'Telemetry' resources",
                },
                {
                  value: 'Pass',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value:
                    "Run schema validation as an analyzer on 'VirtualService' resources",
                },
                {
                  value: 'Pass',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value:
                    "Run schema validation as an analyzer on 'WasmPlugin' resources",
                },
                {
                  value: 'Pass',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value:
                    "Run schema validation as an analyzer on 'WorkloadEntry' resources",
                },
                {
                  value: 'Pass',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value:
                    "Run schema validation as an analyzer on 'WorkloadGroup' resources",
                },
                {
                  value: 'Pass',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'Check the port names associated with each service',
                },
                {
                  value: 'Pass',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
            ],
            type: 'table',
          },
        ],
        created: 42,
        description:
          "Let's analyze the Istio configuration and make sure there are no issues or errors.",
        name: 'Analyze the Istio configuration',
        status: 'completed',
        step_number: 1,
        updated: 42,
        comments: {},
      },
      {
        requirement_level: 'required',
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        content_blocks: [
          {
            style: 'header',
            text: 'Applications',
            type: 'text',
          },
          {
            column_definitions: [
              {
                title: 'name',
              },
              {
                title: 'Health Check Status',
              },
            ],
            data: [
              [
                {
                  value: 'api',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'cartwheel',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'catalog',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'checkout-manager',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'dashboard',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'frontend',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'order-processor',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'spam-detector',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'billing-manager',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'custom-authorizer',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'dionysus-daemon',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'api-worker',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'asset-manager',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'nightly-verif',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'fujin-simulator',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
            ],
            type: 'table',
          },
        ],
        created: 42,
        description: '',
        name: 'Verify Application Health',
        status: 'completed',
        step_number: 2,
        updated: 42,
        comments: {},
      },
    ],
    updated: 42,
  },
];
