import * as SubframeCore from '@subframe/core';
import { groupBy } from 'lodash';
import styles from './AcmeVersionRegisterAddOnsCertManagerAllVersions.module.scss';
import Page from 'components/Page';
import {
  Alert,
  Badge,
  Breadcrumbs,
  Button,
  InfoTooltip,
  InformationalHeader,
  SkeletonText,
  Switch,
} from 'subframe/index';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import { useParams } from 'react-router';
import useLocalStorage from 'hooks/useLocalStorage';
import {
  ADDONS,
  getAddonExpiryInWords,
  getAddonExpiryState,
  getEarliestAddonExpiryInDays,
  getHumanReadableImageTag as getHumanReadableImageTag,
} from 'src/data/version_register_addons';
import { CLUSTERS } from 'src/data/version_register_cp';
import { AtomicTooltip } from 'components/design-system';
import { RouterLink } from 'components/RouterLink';
import { useAddonIdToDetailsMap } from 'hooks/useAddonIdToNameMap';
import useUserAccountState from 'hooks/useUserAccountState';
import { AddonInstance, Cluster } from 'api/models';
import { useGate } from 'statsig-react';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { useEffect, useState } from 'react';
import { moreTooltipContent } from 'components/design-system/AtomicTooltip';
import {
  useListAddonInstancesInfinite,
  useListClustersInfinite,
} from 'api/frontend-infinite';
import { clusterFlattenPages, flattenPages } from 'utils/arrays';
import BaseTable from 'components/design-system/Table/BaseTable';
import { useSearchParams } from 'react-router-dom';
import { Select } from 'subframe/components/Select';
import classNames from 'classnames';

export default function VersionRegisterAddonsView() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { addonId } = useParams();
  const { account } = useUserAccountState();
  const basePath = useAccountIdRoute('/orgs/:orgId/accounts/:accountId');
  const versionRegisterAddonBasePath = `${basePath}/artifact_register/addons`;
  const availabilityRisksPath = `${basePath}/availability-risks`;
  const { logEvent } = AnalyticsEventLogger();
  const [showExampleData, setShowExampleData] = useLocalStorage<boolean>(
    'example-data',
    true,
  );
  const [filterCluster, setFilterCluster] = useState<string | undefined>(
    searchParams.get('cluster') || undefined,
  );

  const [clusterIDsWithAddons, setClusterIDsWithAddons] = useState<
    string[] | undefined
  >(undefined);

  const flippedArsigViewSidenavTab = useGate('flipped_arsig_view_sidenav_tab');

  useEffect(() => {
    logEvent('version-register-addons-view', {
      showExampleData,
    });
    setClusterIDsWithAddons(undefined);
  }, [showExampleData]);

  const { data: addonInstances, isLoading: isLoadingAddonInstances } =
    useListAddonInstancesInfinite(
      {
        filter: ['status:active'],
        addon_id: addonId,
        cluster_id: filterCluster,
      },
      {
        swr: {
          enabled: !!addonId,
        },
        request: { headers: { Authorization: `Bearer ${account.token}` } },
      },
    );

  const totalinstances = flattenPages(addonInstances || []) || [];

  const totalInstancesLoading = showExampleData
    ? false
    : isLoadingAddonInstances;

  const addonIdToDetailsMap = useAddonIdToDetailsMap({ token: account.token });

  const { data: clustersList, isLoading: isloadingClustersList } =
    useListClustersInfinite(
      '',
      { status: 'active' },
      {
        request: { headers: { Authorization: `Bearer ${account?.token}` } },
      },
      {
        initialSize: 100,
        revalidateFirstPage: false,
      },
    );

  const listClustersResponse = clusterFlattenPages(clustersList || []) || [];
  const realClusterData = listClustersResponse
    ? listClustersResponse.filter(
        (c) => c.internal_k8s_ref !== 'example-cluster-ref',
      )
    : [];

  useEffect(() => {
    if (listClustersResponse && localStorage.getItem('example-data') === null) {
      setShowExampleData(!realClusterData.length);
    }
  }, [listClustersResponse]);

  const data: AddonInstance[] = showExampleData ? ADDONS : totalinstances || [];
  const clusterData = showExampleData
    ? CLUSTERS
    : realClusterData.filter(
        (data) => data?.status && data.status !== 'deactivated',
      );
  const clusterIdToDetailsMap: Map<string, Cluster> = new Map(
    clusterData.map((c) => [c.id, c]),
  );
  const instances = data?.filter((a) => a.addon_id === addonId);
  const addonName = addonIdToDetailsMap.get(addonId || '')?.name || addonId;

  useEffect(() => {
    if (addonName && addonId && instances?.length) {
      logEvent('version-register-view-addon', {
        addon_id: addonId,
        addon_name: addonName,
        num_instances: instances?.length,
      });
    }
  }, []);

  const clusterIdsWithAddons: Set<string> = new Set(
    instances?.map((a) => a.cluster_id),
  );

  useEffect(() => {
    if (
      !isLoadingAddonInstances &&
      clusterIdsWithAddons &&
      clusterIDsWithAddons == undefined
    ) {
      setClusterIDsWithAddons(Array.from(clusterIdsWithAddons));
    } else if (
      showExampleData ||
      (clusterIDsWithAddons == undefined &&
        filterCluster &&
        !isloadingClustersList)
    ) {
      setClusterIDsWithAddons(clusterData?.map((c) => c.id));
    }
  }, [addonInstances, clusterData]);

  const instancesByPrimaryComponentTag = groupBy(
    instances?.filter(
      (instance) =>
        clusterIdToDetailsMap.get(instance.addon_id)?.status !==
          'deactivated' &&
        (filterCluster === undefined || instance.cluster_id === filterCluster),
    ),
    (instance) => getHumanReadableImageTag(instance.primary_component?.image),
  );
  const tableData = Object.entries(instancesByPrimaryComponentTag).sort(
    (a, b) =>
      (getEarliestAddonExpiryInDays(a[1]) || 0) -
      (getEarliestAddonExpiryInDays(b[1]) || 0),
  );

  const isDataLoading =
    !showExampleData &&
    (addonInstances === undefined ||
      listClustersResponse === undefined ||
      totalInstancesLoading);
  return (
    <Page title={`Add-ons - ${addonName}`}>
      <div
        className={styles['pageContents']}
        style={{ marginLeft: '32px', width: 'calc(100% - 32px)' }}
      >
        <Breadcrumbs>
          <Breadcrumbs.Item>Artifact Register</Breadcrumbs.Item>
          <Breadcrumbs.Divider name="FeatherChevronRight" />
          <RouterLink
            to={`${versionRegisterAddonBasePath}${
              filterCluster === undefined ? '' : '?cluster=' + filterCluster
            }`}
          >
            <Breadcrumbs.Item data-cy="breadcrumb-addon">
              Add-ons
            </Breadcrumbs.Item>
          </RouterLink>
          <Breadcrumbs.Divider name="FeatherChevronRight" />
          <Breadcrumbs.Item active={true}>
            {addonIdToDetailsMap.size > 0 ? (
              addonName
            ) : (
              <SkeletonText className={'h-[20px] w-[50px]'} />
            )}{' '}
          </Breadcrumbs.Item>
        </Breadcrumbs>

        <>
          <div className={styles['header']}>
            <div className={styles['stack-45c5e219']}>
              <span className={classNames(styles['sectionHeaderText'])}>
                <div className="flex gap-2">
                  {addonIdToDetailsMap.size > 0 ? (
                    addonName
                  ) : (
                    <SkeletonText className="h-[35px] w-[50px]" />
                  )}{' '}
                  Versions
                </div>
              </span>
              {flippedArsigViewSidenavTab.value && (
                <div className={styles['stack-21a51363']}>
                  <RouterLink
                    to={availabilityRisksPath}
                    onClick={() =>
                      logEvent(
                        'version-register-addons-view-availability-risks-btn-click',
                      )
                    }
                  >
                    <Button
                      size="large"
                      data-cy="view-availability-risks-button"
                      variant="brand-secondary"
                      icon="FeatherChevronRight"
                    >
                      View Availability Risks
                    </Button>
                  </RouterLink>
                </div>
              )}
            </div>
            <div className={styles['stack-ef4fe48b']}>
              <span
                className={classNames(styles['text-ea718189'], 'flex gap-2')}
              >
                {addonIdToDetailsMap.size > 0 ? (
                  addonName
                ) : (
                  <SkeletonText className="h-[20px] w-[50px]" />
                )}{' '}
                Versions running in your infrastructure
              </span>
              <div className={styles['stack-2a2ee34a']}>
                <span className={styles['text-2daf8024']}>
                  Show example data
                </span>
                <AtomicTooltip
                  tooltipContent={
                    showExampleData
                      ? 'Show results from your onboarded clusters'
                      : 'Show example data'
                  }
                >
                  <Switch
                    checked={showExampleData}
                    onClick={() => setShowExampleData(!showExampleData)}
                  />
                </AtomicTooltip>
              </div>
            </div>
          </div>
          {showExampleData && (
            <Alert
              variant="warning"
              title="You are currently viewing example data"
              description={`To view results from your onboarded clusters, toggle the "Show example data" button`}
            />
          )}

          <div className={styles['stack-f7091ce2']}>
            <InformationalHeader
              className="flex-[0_0_auto] h-auto w-auto"
              data-cy="vr-addon-view-header"
            >
              <InformationalHeader.Item
                data-cy={isDataLoading ? 'loading-tags' : 'tags'}
                title="Tags"
                headingSlot={
                  <InfoTooltip tooltipText="Count of unique tags across all Add-on instances" />
                }
                bodySlot={
                  <span className="text-body-bold font-body-bold text-default-font">
                    {isDataLoading ? (
                      <SkeletonText className="h-[20px] w-[20px]" />
                    ) : (
                      Object.entries(instancesByPrimaryComponentTag).length
                    )}
                  </span>
                }
              />

              <InformationalHeader.Item
                data-cy={isDataLoading ? 'loading-eos' : 'eos'}
                title="EOL in 90d"
                headingSlot={
                  <InfoTooltip tooltipText="Upgrades are due soon. Going end of life/support in the next 90d." />
                }
                icon={
                  <SubframeCore.Icon
                    className={styles['icon-662b4939']}
                    name="FeatherClock"
                  />
                }
                bodySlot={
                  <span className="text-body-bold font-body-bold text-default-font">
                    {isDataLoading ? (
                      <SkeletonText className="h-[20px] w-[20px]" />
                    ) : (
                      Object.entries(instancesByPrimaryComponentTag).filter(
                        ([, values]) =>
                          getAddonExpiryState(values) === 'warning',
                      ).length
                    )}
                  </span>
                }
              />

              <InformationalHeader.Item
                data-cy={isDataLoading ? 'loading-eol' : 'eol'}
                title="EOL"
                headingSlot={
                  <InfoTooltip tooltipText="Upgrades are due. Running some unsupported or incompatible versions." />
                }
                icon={
                  <SubframeCore.Icon
                    className={styles['icon']}
                    name="FeatherClock"
                  />
                }
                bodySlot={
                  <span className="text-body-bold font-body-bold text-default-font">
                    {isDataLoading ? (
                      <SkeletonText className="h-[20px] w-[20px]" />
                    ) : (
                      Object.entries(instancesByPrimaryComponentTag).filter(
                        ([, values]) => getAddonExpiryState(values) === 'error',
                      ).length
                    )}
                  </span>
                }
              />
              {addonIdToDetailsMap.get(addonId || '')?.eolSource !==
                undefined && (
                <InformationalHeader.Item
                  title="EOL Source"
                  bodySlot={
                    <a
                      data-cy="eol-source"
                      style={{
                        color: 'var(--subtext-color)',
                        font: 'var(--body)',
                      }}
                      href={addonIdToDetailsMap.get(addonId || '')?.eolSource}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Learn more
                    </a>
                  }
                />
              )}
            </InformationalHeader>
            <div className={styles['stack-4b52a2b8']}>
              {showExampleData || !isloadingClustersList ? (
                <Select
                  icon={null}
                  className="w-auto max-w-[400px]"
                  placeholder={
                    filterCluster === undefined
                      ? 'All Clusters'
                      : clusterIdToDetailsMap.get(filterCluster)?.name ||
                        'Unknown'
                  }
                  onValueChange={(value) => {
                    if (value === 'all') {
                      setSearchParams({});
                      setFilterCluster(undefined);
                    } else {
                      setSearchParams({ cluster: value });
                      setFilterCluster(value);
                    }
                  }}
                >
                  <Select.Item key="all" value="all">
                    All Clusters
                  </Select.Item>
                  {Array.from(clusterIDsWithAddons || []).map((clusterId) => (
                    <Select.Item key={clusterId} value={clusterId}>
                      {clusterIdToDetailsMap.get(clusterId)?.name || 'Unknown'}
                    </Select.Item>
                  ))}
                </Select>
              ) : (
                <SkeletonText className="h-[30px] w-[150px] flex-none" />
              )}
            </div>
          </div>
          <div className={styles['stack-bd1d122e']}>
            {isDataLoading ? (
              <BaseTable
                settings={{
                  localStorageKey: 'artifact_register_addons_view_table',
                  dataCyPrefix: 'vr-addon-view',
                }}
                data={[
                  <SkeletonText key="1" className={'h-[30px] w-[50px]'} />,
                ]}
                columns={[
                  {
                    id: 'tag',
                    title: 'Tag',
                    titleStyle: { width: '20%' },
                    cellType: 'cell',
                    render: (skeletonLoading) => {
                      return skeletonLoading;
                    },
                  },
                  {
                    id: 'environment',
                    title: 'Environment',
                    titleStyle: { width: '40%' },
                    cellType: 'cell',
                    render: (skeletonLoading) => {
                      return skeletonLoading;
                    },
                  },
                  {
                    id: 'clusters',
                    title: 'Clusters running this version',
                    titleStyle: { width: '40%' },
                    cellType: 'cell',
                    render: (skeletonLoading) => {
                      return skeletonLoading;
                    },
                  },
                ]}
                actions={[]}
              />
            ) : (
              <BaseTable
                settings={{
                  localStorageKey: 'artifact_register_addons_view_table',
                  dataCyPrefix: 'vr-addon-view',
                }}
                data={tableData || []}
                rowLink={([tag, instances]) =>
                  `${versionRegisterAddonBasePath}/${
                    instances[0].addon_id
                  }/version/${encodeURIComponent(tag)}${
                    filterCluster === undefined
                      ? ''
                      : '?cluster=' + filterCluster
                  }`
                }
                columns={[
                  {
                    id: 'tag',
                    title: 'Tag',
                    titleStyle: { width: '20%' },
                    cellType: 'cell',
                    render: ([tag, instances]) => {
                      const expiryState = getAddonExpiryState(instances);

                      return (
                        <>
                          {expiryState === 'warning' && (
                            <AtomicTooltip
                              tooltipContent={`End of Life/Support: ${getAddonExpiryInWords(
                                instances,
                              )}`}
                            >
                              <SubframeCore.Icon
                                data-cy="yellow-icon"
                                className={styles['icon-dc5cc178']}
                                name="FeatherClock"
                              />
                            </AtomicTooltip>
                          )}
                          {expiryState === 'error' && (
                            <AtomicTooltip
                              tooltipContent={`End of Life/Support: ${getAddonExpiryInWords(
                                instances,
                              )}`}
                            >
                              <SubframeCore.Icon
                                data-cy="red-icon"
                                className={styles['icon-566ecc71']}
                                name="FeatherClock"
                              />
                            </AtomicTooltip>
                          )}
                          <span
                            className={styles['bodyText']}
                            data-cy="view-tag"
                          >
                            {tag}
                          </span>
                        </>
                      );
                    },
                  },
                  {
                    id: 'environment',
                    title: 'Environment',
                    titleStyle: { width: '40%' },
                    cellType: 'cell',
                    render: ([_, instances]) => {
                      // Define the order of categories
                      const categoryOrder: Record<string, number> = {
                        prod: 1, // Highest priority
                        default: 3, // Default priority
                      };

                      const instancesByClusterEnvironment = Object.entries(
                        groupBy(
                          instances.filter(
                            (instance) =>
                              clusterIdToDetailsMap.get(instance.cluster_id)
                                ?.status === 'active',
                          ),
                          (instance) => {
                            const cluster = clusterIdToDetailsMap.get(
                              instance.cluster_id,
                            );
                            return cluster?.environment || 'default';
                          },
                        ),
                      ).sort((a, b) => {
                        const orderA = categoryOrder[a[0].toLowerCase()] || 2; // Default to a high number if not found
                        const orderB = categoryOrder[b[0].toLowerCase()] || 2;
                        return orderA - orderB;
                      });

                      return (
                        <div className={styles['stack-4869a732']}>
                          {instancesByClusterEnvironment
                            .slice(0, 3)
                            .map(([environment, instances]) => (
                              <Badge
                                variant="neutral"
                                key={environment}
                                data-cy="env-count"
                              >
                                {`${environment}: ${instances?.length}`}
                              </Badge>
                            ))}

                          {instancesByClusterEnvironment.length > 3 && (
                            <AtomicTooltip
                              tooltipContent={instancesByClusterEnvironment
                                .slice(3)
                                .map(
                                  ([key, value]) => `${key}: ${value.length}`,
                                )
                                .join(', ')}
                            >
                              <span
                                className={styles['text-69695e6b']}
                                data-cy="environment-more"
                              >
                                +
                                {Math.max(
                                  0,
                                  Object.entries(instancesByClusterEnvironment)
                                    .length - 3,
                                )}{' '}
                                more
                              </span>
                            </AtomicTooltip>
                          )}
                        </div>
                      );
                    },
                  },
                  {
                    id: 'clusters',
                    title: 'Clusters running this version',
                    titleStyle: { width: '40%' },
                    cellType: 'cell',
                    render: ([_, instances]) => {
                      const prodInstances = instances?.filter(
                        (instance) =>
                          clusterIdToDetailsMap
                            .get(instance.cluster_id)
                            ?.environment?.toLowerCase() === 'prod',
                      );
                      const nonProdInstances = instances?.filter(
                        (instance) =>
                          clusterIdToDetailsMap
                            .get(instance.cluster_id)
                            ?.environment?.toLowerCase() !== 'prod',
                      );

                      const totalInstances = [
                        ...prodInstances, //prod instances first
                        ...nonProdInstances,
                      ].filter(
                        (c) =>
                          clusterIdToDetailsMap.get(c.cluster_id)?.status ===
                          'active',
                      );

                      return (
                        <>
                          {clusterIdToDetailsMap.size > 0 ? (
                            <div className={styles['stack-ea7b5126']}>
                              {totalInstances?.length > 0 && (
                                <span
                                  className={styles['bodyText']}
                                  data-cy="cluster-names"
                                >
                                  {totalInstances
                                    .slice(0, 3)
                                    .map(
                                      (c) =>
                                        clusterIdToDetailsMap.get(c.cluster_id)
                                          ?.name || c.cluster_id,
                                    )
                                    .join(', ')}{' '}
                                </span>
                              )}

                              {totalInstances?.length > 3 && (
                                <AtomicTooltip
                                  tooltipContent={moreTooltipContent(
                                    totalInstances?.map(
                                      (c) =>
                                        clusterIdToDetailsMap.get(c.cluster_id)
                                          ?.name || c.cluster_id,
                                    ),
                                    3,
                                  )}
                                >
                                  <span
                                    className={styles['text-69695e6b']}
                                    data-cy="nmore-cluster"
                                  >
                                    +{Math.max(0, totalInstances?.length - 3)}{' '}
                                    more
                                  </span>
                                </AtomicTooltip>
                              )}
                            </div>
                          ) : (
                            <SkeletonText />
                          )}
                        </>
                      );
                    },
                  },
                ]}
                actions={[
                  {
                    id: 'navigate',
                    render: () => {
                      return (
                        <SubframeCore.Icon
                          className={styles['bodyText']}
                          name="FeatherChevronRight"
                        />
                      );
                    },
                  },
                ]}
              />
            )}
          </div>
        </>
      </div>
    </Page>
  );
}
