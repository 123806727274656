export class CommonError extends Error {
  info: string;
  statusCode: number;
  constructor(message: string, info: string, statusCode: number) {
    super(message);
    this.name = 'CommonError';
    this.info = info;
    this.statusCode = statusCode;
  }
}
