// config
import { InfoTooltip, SkeletonText } from 'subframe/index';
import useAffectedResourcesTableSort from 'hooks/useAffectedResourcesTableSort';
import { Stack } from 'components/utils/Stack';
import {
  ClusterDetectedLarAffectedResource,
  LarAffectedResource,
} from 'api/models';

import BaseTable from 'components/design-system/Table/BaseTable';
import { TableSortLabel, useTheme } from '@mui/material';
import { VisuallyHidden } from 'components/utils';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { useEffect } from 'react';
import { ExtendedLAR } from '.';
import { getCategory } from 'utils/filters';

interface LARAffectedResourcesProps {
  resources: LarAffectedResource[] | ClusterDetectedLarAffectedResource[];
  showVersion?: boolean;
  isLoading?: boolean;
  clusterId?: string;
  larDetails?: ExtendedLAR;
}

export const LARAffectedResources = ({
  resources,
  showVersion,
  isLoading,
  clusterId,
  larDetails,
}: LARAffectedResourcesProps) => {
  const theme = useTheme();
  const { logEvent } = AnalyticsEventLogger();

  const { sortOrder, orderBy, handleRequestSort, sortedData, loadingSort } =
    useAffectedResourcesTableSort(resources, 'asc', 'namespace');

  useEffect(() => {
    if (!loadingSort && !isLoading) {
      if (
        !resources ||
        (resources?.length === 1 &&
          resources[0]?.kind === '--' &&
          resources[0]?.name === '--' &&
          resources[0]?.namespace === '--')
      ) {
        logEvent('affected-resources-missing', {
          signatureId: larDetails?.signature_id,
          clusterId: clusterId,
          category: getCategory(larDetails?.labels || []),
          needAttention: larDetails?.need_attention,
        });
      }
    }
  }, [resources?.length]);

  return (
    <>
      {!loadingSort && !isLoading ? (
        <Stack spacing={2} className="w-full">
          <BaseTable
            settings={{
              localStorageKey: 'kba_affected_resources_table',
              dataCyPrefix: 'kba-affected-resources',
              defaultRowsPerPage: 5,
            }}
            data={sortedData || []}
            noMatchingData={!sortedData?.length}
            noDataText="No affected resources found"
            columns={[
              {
                id: 'kind-column',
                title: (
                  <TableSortLabel //#TODO: Migrate Sorting to Subframe
                    sx={{
                      '.MuiTableSortLabel-icon': {
                        color: `${theme.palette.text.secondary} !important`,
                      },
                    }}
                    direction={
                      orderBy === 'kind' ? (sortOrder as 'asc' | 'desc') : 'asc'
                    }
                    onClick={() => handleRequestSort('kind')}
                    active={orderBy === 'kind'}
                  >
                    {orderBy === 'kind' && (
                      <VisuallyHidden>
                        {sortOrder === 'asc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </VisuallyHidden>
                    )}
                    Kind
                  </TableSortLabel>
                ),
                titleStyle: { width: '250px' },
                cellType: 'text-cell',
                render: (row) => {
                  return `${row?.kind}`;
                },
              },
              {
                id: 'title-column',
                title: (
                  <TableSortLabel //#TODO: Migrate Sorting to Subframe
                    direction={
                      orderBy === 'name' ? (sortOrder as 'asc' | 'desc') : 'asc'
                    }
                    onClick={() => handleRequestSort('name')}
                    active={orderBy === 'name'}
                    sx={{
                      '.MuiTableSortLabel-icon': {
                        color: `${theme.palette.text.secondary} !important`,
                      },
                    }}
                  >
                    {orderBy === 'name' && (
                      <VisuallyHidden>
                        {sortOrder === 'asc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </VisuallyHidden>
                    )}
                    Name
                  </TableSortLabel>
                ),
                cellType: 'text-cell',
                render: (row) => {
                  return `${row?.name}`;
                },
              },
              {
                id: 'namespace-column',
                title: (
                  <TableSortLabel //#TODO: Migrate Sorting to Subframe
                    direction={
                      orderBy === 'namespace'
                        ? (sortOrder as 'asc' | 'desc')
                        : 'asc'
                    }
                    onClick={() => handleRequestSort('namespace')}
                    active={orderBy === 'namespace'}
                    sx={{
                      '.MuiTableSortLabel-icon': {
                        color: `${theme.palette.text.secondary} !important`,
                      },
                    }}
                  >
                    {orderBy === 'namespace' && (
                      <VisuallyHidden>
                        {sortOrder === 'asc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </VisuallyHidden>
                    )}
                    Namespace
                  </TableSortLabel>
                ),
                cellType: 'text-cell',

                render: (row) => {
                  return `${row?.namespace}`;
                },
              },
              {
                id: 'tags-column',
                title: (
                  <Stack direction="row" spacing={2}>
                    Tags
                    <InfoTooltip tooltipText="All Tags that have been locally applied to a resource's container images" />
                  </Stack>
                ),
                cellType: 'text-cell',

                render: (row) => {
                  return `${
                    (row?.context?.image_tag == 'Tags not applied'
                      ? undefined
                      : row?.context?.image_tag) || ''
                  }`;
                },
              },
            ].filter(
              (col) => col.id !== 'affected-resources-tags' || showVersion,
            )}
          />
        </Stack>
      ) : (
        <>
          <SkeletonText />
          <SkeletonText size={'header'} />
        </>
      )}
    </>
  );
};
