import * as SubframeCore from '@subframe/core';
import { Breadcrumbs } from 'subframe/index';
import { TabsWithContent } from 'components/design-system/Tabs';
import { Badge } from 'subframe/components/Badge';
import { CardIntegration } from 'subframe/components/CardIntegration';
import { AtomicTooltip } from 'components/design-system';
import useUserAccountState from 'hooks/useUserAccountState';
import { SimpleAddon } from 'api/models';
import { flattenPages, groupInto } from 'utils/arrays';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import { RouterLink } from 'components/RouterLink';
import RequestSupportDialog from './RequestSupportDialog';
import Page from 'components/Page';
import { useSearchParams } from 'react-router-dom';
import { Searchbar } from 'components/design-system/Searchbar';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import {
  useListAddonInstancesInfinite,
  useListDirectoryAddonsInfinite,
} from 'api/frontend-infinite';

function AddonCard(props: {
  addon: SimpleAddon;
  installed: boolean;
  urlBase: string;
  onClick: VoidFunction;
}): JSX.Element {
  let supportedFeatures: string[] = [];
  if (props.addon.support?.upgrades) {
    supportedFeatures.push('Upgrade Plans');
  }
  if (props.addon.support?.lar_detection) {
    supportedFeatures.push('Availability Risk Detection');
  }

  if (props.installed) {
    return (
      <RouterLink
        to={`${props.urlBase}artifact_register/addons/${props.addon.id}`}
        onClick={props.onClick}
      >
        <AtomicTooltip tooltipContent={props.addon.name}>
          <CardIntegration
            cardTitle={props.addon.name}
            text={`Covered: ${supportedFeatures.join(' + ')}`}
            badge={
              <>
                <Badge variant="success">Installed</Badge>
              </>
            }
          />
        </AtomicTooltip>
      </RouterLink>
    );
  } else {
    return (
      <AtomicTooltip tooltipContent={props.addon.name}>
        <CardIntegration
          cardTitle={props.addon.name}
          text={`Covered: ${supportedFeatures.join(' + ')}`}
          nonClickable={true}
        />
      </AtomicTooltip>
    );
  }
}

export default function LatestSupportedCloudsAddOns() {
  const { logEvent } = AnalyticsEventLogger();
  const { account } = useUserAccountState();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: addonData } = useListDirectoryAddonsInfinite(
    {},
    { request: { headers: { Authorization: `Bearer ${account.token}` } } },
  );
  const { data: addonInstancesDataPages } = useListAddonInstancesInfinite(
    { filter: ['status:active'] },
    { request: { headers: { Authorization: `Bearer ${account.token}` } } },
  );
  const addonInstancesData = flattenPages(addonInstancesDataPages);
  const cloudConnectionsLink = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/cloud_accounts',
  );
  const urlBase = useAccountIdRoute('/orgs/:orgId/accounts/:accountId/');

  const sortedAddons = (flattenPages(addonData) || []).sort((a, b) =>
    a.name.localeCompare(b.name),
  );

  const [searchAddon, setSearchAddon] = useState('');
  const [addonsToShow, setAddonsToShow] = useState(sortedAddons);
  const firstRow = addonsToShow.slice(0, 2);
  const otherRows = groupInto(addonsToShow.slice(2), 3);
  const handleOnChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: string,
  ) => {
    setSearchAddon(newValue);
  };

  useDebounce(
    () => {
      if (searchAddon) {
        logEvent('search-addon', { searchAddon });
      }
    },
    1000,
    [searchAddon],
  );
  useEffect(() => {
    if (searchAddon !== '') {
      const found = sortedAddons.filter((item) =>
        item?.name.toLowerCase().includes(searchAddon.toLowerCase()),
      );
      setAddonsToShow(found);
    } else {
      setAddonsToShow(sortedAddons);
    }
  }, [addonData, searchAddon]);

  useEffect(() => {
    logEvent('latest-supported-clouds-addons-viewed');
  }, []);

  return (
    <Page title="Covered Clouds & Add-ons">
      <div className="flex flex-col gap-6 items-start w-[calc(100%-32px)] h-full pt-8 pb-0 px-3 bg-default-background ml-8">
        <Breadcrumbs>
          <Breadcrumbs.Item>Explore</Breadcrumbs.Item>
          <Breadcrumbs.Divider />
          <Breadcrumbs.Item active={true}>
            Clouds &amp; Add-ons
          </Breadcrumbs.Item>
        </Breadcrumbs>
        <div className="flex flex-col gap-2 items-start w-full">
          <div className="flex flex-col gap-2 items-start w-full">
            <span className="text-default-font text-section-header">
              Covered Clouds &amp; Add-ons
            </span>
            <div className="flex gap-2 items-center w-full">
              <span className="text-body text-subtext-color">
                Clouds and Add-ons covered by Chkk
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2 items-start w-full">
          {/* TODO (laiba) replace with Subframe searchbar once available */}
          <div className="flex flex-col">
            <div className="flex flex-row min-w-[1165px] justify-end">
              {searchParams.get('tab') === 'addons' ? (
                <Searchbar onChange={handleOnChange} />
              ) : (
                <div className="h-8" />
              )}
            </div>

            <TabsWithContent
              initialTabId={searchParams.get('tab') || undefined}
              onTabChange={(newTabId) => {
                logEvent('latest-supported-clouds-addons-tab-changed', {
                  tab: newTabId,
                });
                setSearchParams({ tab: newTabId });
                setSearchAddon('');
              }}
              tabs={[
                {
                  id: 'clouds',
                  title: 'Clouds',
                  content: (
                    <div className="flex flex-col gap-6 items-start w-full">
                      <div className="flex gap-2 items-center">
                        <RequestSupportDialog
                          requestResource="Cloud / K8s distribution"
                          eventTitle={'RequestSupportCloud'}
                        />
                        <RouterLink
                          to={cloudConnectionsLink}
                          onClick={() =>
                            logEvent(
                              'latest-supported-clouds-addons-clouds-configure-clouds-click',
                              {
                                resource: 'Cloud / K8s distribution',
                              },
                            )
                          }
                        >
                          <CardIntegration
                            cardTitle="AWS"
                            text={
                              'Availability Risk Detection + Upgrade Plans for Clusters and Add-ons.'
                            }
                            badge={<></>}
                            onClick={() =>
                              logEvent(
                                'latest-supported-clouds-addons-clouds-configure-clouds-click',
                                { resource: 'AWS' },
                              )
                            }
                            selected={false}
                            logo={
                              <img
                                src="https://res.cloudinary.com/demo/image/upload/v1677449922/amazon_u8k7az.png"
                                width={36}
                                height={36}
                              />
                            }
                          />
                        </RouterLink>
                        <RouterLink
                          to={cloudConnectionsLink}
                          onClick={() =>
                            logEvent(
                              'latest-supported-clouds-addons-clouds-configure-clouds-click',
                              {
                                resource: 'Cloud / K8s distribution',
                              },
                            )
                          }
                        >
                          <CardIntegration
                            cardTitle="GCP"
                            text="Availability Risk Detection + Upgrade Plans for Clusters and Add-ons."
                            badge={<></>}
                            onClick={() =>
                              logEvent(
                                'latest-supported-clouds-addons-clouds-configure-clouds-click',
                                { resource: 'GCP' },
                              )
                            }
                            selected={false}
                            logo={
                              <img
                                src="https://res.cloudinary.com/demo/image/upload/v1679151815/gcp_logo2_fflpwr.png"
                                width={36}
                                height={36}
                              />
                            }
                          />
                        </RouterLink>
                      </div>
                      <div className="flex gap-6 items-center w-full">
                        <CardIntegration
                          cardTitle="Azure"
                          text="Availability Risk Detection + Add-on Upgrade Plans."
                          badge={
                            <Badge variant="neutral">Limited Coverage</Badge>
                          }
                          nonClickable={true}
                          selected={false}
                          logo={
                            <img
                              src="https://res.cloudinary.com/demo/image/upload/v1679154167/azure_logo_nfhff1.jpg"
                              width={36}
                              height={36}
                            />
                          }
                        />
                      </div>
                    </div>
                  ),
                },
                {
                  id: 'addons',
                  title: 'Add-ons',
                  content: (
                    <div className="flex flex-[1_0_0] h-full w-full flex-col gap-2 items-start">
                      {(addonData === undefined ||
                        addonInstancesData === undefined) && (
                        <SubframeCore.Loader />
                      )}
                      {addonData !== undefined &&
                        addonInstancesData !== undefined && (
                          <>
                            {addonsToShow.length ? (
                              <>
                                <div className="flex w-full gap-2 items-center">
                                  <RequestSupportDialog
                                    requestResource="Add-on"
                                    eventTitle={'RequestSupportAddon'}
                                  />
                                  {firstRow.map((addon) => (
                                    <AddonCard
                                      key={addon.id}
                                      addon={addon}
                                      urlBase={urlBase}
                                      installed={
                                        (addonInstancesData || []).find(
                                          (ai) => ai.addon_id === addon.id,
                                        ) !== undefined
                                      }
                                      onClick={() => {
                                        logEvent(
                                          'latest-supported-clouds-addons-addons-configure-addons-click',
                                          { resource: addon.name },
                                        );
                                      }}
                                    />
                                  ))}
                                </div>
                                {otherRows.map((row, idx) => (
                                  <div
                                    className="flex w-full gap-2 items-center"
                                    key={idx}
                                  >
                                    {row.map((addon) => (
                                      <AddonCard
                                        key={addon.id}
                                        addon={addon}
                                        urlBase={urlBase}
                                        installed={
                                          (addonInstancesData || []).find(
                                            (ai) => ai.addon_id === addon.id,
                                          ) !== undefined
                                        }
                                        onClick={() => {
                                          logEvent(
                                            'latest-supported-clouds-addons-addons-configure-addons-click',
                                            { resource: addon.name },
                                          );
                                        }}
                                      />
                                    ))}
                                  </div>
                                ))}
                              </>
                            ) : (
                              <div className="flex flex-[0_0_auto] h-96 w-full flex-col gap-6 items-center justify-center">
                                <span className="text-default-font text-subheader">
                                  {`Could not find the Add-on "${searchAddon}".`}
                                </span>
                                <span className="text-default-font text-subheader">
                                  {'Click '}
                                  <RequestSupportDialog
                                    requestResource="Add-on"
                                    text="here"
                                    requestedResourceName={searchAddon}
                                    eventTitle={'RequestSupportAddon'}
                                  />
                                  {' to request this Add-on.'}
                                </span>
                              </div>
                            )}
                          </>
                        )}
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>
    </Page>
  );
}
