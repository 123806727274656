import { IconButton } from 'subframe/components/IconButton';
import { Button } from 'subframe/components/Button';
import { Dialog } from 'subframe/components/Dialog';
import { useState } from 'react';
import { Account } from 'models/account';
import { deleteCloudConnection } from 'api/frontend';
import { somethingWentWrong, toastAutoHideDuration } from 'constants/toasts';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { useSnackbar } from 'notistack';
import { AtomicTooltip } from 'components/design-system';

export function CloudAccountsDeleteDialog(props: {
  account: Account;
  cloudAccountId: string;
  mutate: () => void;
}): JSX.Element {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { logEvent } = AnalyticsEventLogger();

  const deleteAccount = async () => {
    setLoading(true);
    try {
      await deleteCloudConnection(props.cloudAccountId, {
        headers: {
          Authorization: `Bearer ${props.account.token}`,
        },
      });
      props.mutate();
      enqueueSnackbar('Successfully deleted Cloud Account with Chkk', {
        variant: 'success',
        autoHideDuration: toastAutoHideDuration,
      });
      logEvent('cloud-account-deleted', {
        id: props.cloudAccountId,
      });
    } catch (err) {
      enqueueSnackbar(
        `${somethingWentWrong.replace(
          '<action>',
          'deleting Cloud Account with Chkk',
        )}`,
        {
          variant: 'error',
          autoHideDuration: toastAutoHideDuration,
        },
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <AtomicTooltip tooltipContent="Delete Cloud Account">
        <IconButton
          icon="FeatherTrash"
          size="medium"
          onClick={() => setOpen(true)}
        />
      </AtomicTooltip>

      <Dialog open={open} onOpenChange={() => setOpen(!open)}>
        <Dialog.Content className="h-auto w-80 flex-none">
          <div className="flex w-full flex-col items-start gap-[24px] p-[24px]">
            <div className="flex w-full items-center justify-between">
              <span className="text-subheader text-default-font">
                Delete the Cloud Account?
              </span>
              <IconButton
                icon="FeatherX"
                size="medium"
                onClick={() => setOpen(false)}
              />
            </div>
            <div className="flex w-full flex-col items-start gap-[16px]">
              <span className="text-body text-default-font">
                Are you sure that you don&#39;t want Chkk to scan this Cloud
                Account for Upgrade Plans and Availability Risks?
              </span>
              <span className="text-body text-default-font">
                This action will disconnect Chkk&#39;s connection with this
                Cloud Account. You will need to follow Add Account instructions
                to reconnect this account to Chkk.
              </span>
            </div>
            <div className="flex w-full items-end justify-end gap-[16px]">
              <Button
                size="large"
                loading={loading}
                disabled={loading}
                variant="neutral-tertiary"
                icon="FeatherX"
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
              <Button
                size="large"
                loading={loading}
                disabled={loading}
                variant="destructive-primary"
                icon="FeatherTrash2"
                onClick={() => deleteAccount()}
              >
                Delete
              </Button>
            </div>
          </div>
        </Dialog.Content>
      </Dialog>
    </>
  );
}
