import { useRef, useState } from 'react';

import { useDebounce } from 'react-use';
import { Tracker } from 'components/tracker';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { useHoverDirty } from 'hooks/useDirtyHover';
import { Account } from 'models/account';
import { useSnackbar } from 'notistack';
import { IntegrationType, ListIntegrationsResponse } from 'api/models';
import { RouterLink } from 'components/RouterLink';
import { IIntegration } from '../model';
import { getInstallState, getOrCreateIntegration } from '../utils';
import { Stack } from '@mui/material';
import { CardIntegration } from 'subframe/components/CardIntegration';
import { Badge } from 'subframe/index';
import Iconify from 'components/Iconify';
import RequestSupportDialog from 'pages/explore/RequestSupportDialog';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import { AtomicTooltip } from 'components/design-system';

interface IntegrationCardProps {
  account: Account;
  integration: IIntegration;
  id?: string;
  data: ListIntegrationsResponse | undefined;
}

const ExternalAndRouterLink = ({
  href,
  children,
}: {
  href: string;
  children: JSX.Element;
}) => {
  const isExternalLink = (path: string) => {
    try {
      new URL(path);
      return true;
    } catch {
      return false;
    }
  };

  if (isExternalLink(href)) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        style={{ display: 'contents' }}
      >
        {children}
      </a>
    );
  } else {
    return (
      <RouterLink to={useAccountIdRoute(href)} style={{ display: 'contents' }}>
        {children}
      </RouterLink>
    );
  }
};

export const IntegrationCard = ({
  account,
  integration,
  id,
  data,
}: IntegrationCardProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const isHovering = useHoverDirty(ref);
  const { logEvent } = AnalyticsEventLogger();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [infoDialogOpen, setInfoDialogOpen] = useState<boolean>(false);

  const badgeState = (() => {
    switch (integration.name) {
      case 'GitHub':
        return getInstallState(
          data?.data?.filter(
            (value) => IntegrationType.github_app === value.integration_type,
          ) || [],
        );
      case 'Jira':
        return getInstallState(
          data?.data?.filter(
            (value) => IntegrationType.jira_app === value.integration_type,
          ) || [],
        );
      case 'Slack':
        return getInstallState(
          data?.data?.filter(
            (value) =>
              IntegrationType.slack_app === value.integration_type &&
              value.slack_app_configuration !== undefined,
          ) || [],
        );
      default:
        return data ? '' : undefined;
    }
  })();

  useDebounce(
    () => {
      if (isHovering) {
        logEvent(`integration-${integration.name.toLowerCase()}-card-hovered`);
      }
    },
    1000,
    [isHovering],
  );

  const clickable =
    integration?.name === 'Slack' || //Slack card should always be clickable
    !['installed'].includes(badgeState || '') ||
    loading;

  const handleIntegrationCardClick = async () => {
    if (!clickable) {
      return;
    }
    switch (integration.name) {
      case 'Jira': {
        setLoading(true);
        const redirectLink = await getOrCreateIntegration(
          account,
          'jiraApp',
          IntegrationType.jira_app,
          `${import.meta.env.VITE_JIRA_APP_URL}/jira/install`,
          enqueueSnackbar,
        );
        if (redirectLink !== undefined) {
          window.open(redirectLink, '_blank', 'popup=yes');
        }
        setLoading(false);
        return;
      }

      case 'GitHub': {
        setLoading(true);
        const redirectLink = await getOrCreateIntegration(
          account,
          'githubApp',
          IntegrationType.github_app,
          `${import.meta.env.VITE_GITHUB_APP_URL}/chkk/install`,
          enqueueSnackbar,
        );
        if (redirectLink !== undefined) {
          window.open(redirectLink, '_blank', 'popup=yes');
        }
        setLoading(false);
        return;
      }
    }
    if (integration.popupText) {
      setInfoDialogOpen(true);
      return;
    }
  };
  let redirectLink = '';
  if (integration?.docLink) {
    redirectLink = integration?.docLink;
  }
  return (
    <Tracker event={`integration-${integration.name.toLowerCase()}-card-click`}>
      <ExternalAndRouterLink href={redirectLink}>
        {integration.name !== 'requestIntegration' ? (
          <CardIntegration
            id={id}
            ref={ref}
            logo={<Iconify icon={integration.icon} fontSize={36} />}
            cardTitle={
              integration.name.length > 13 ? (
                <AtomicTooltip tooltipContent={integration.name}>
                  <span>{integration.name}</span>
                </AtomicTooltip>
              ) : (
                integration.name
              )
            }
            text={integration.text}
            badge={getBadge(integration, badgeState)}
            selected={false}
            loading={loading}
            inactive={loading}
            onClick={handleIntegrationCardClick}
            nonClickable={!clickable}
            onContextMenu={(event) => {
              if (!clickable || !integration?.docLink) {
                event.preventDefault();
              }
            }}
          />
        ) : (
          <RequestSupportDialog
            requestResource="Integration"
            eventTitle={'RequestSupportIntegration'}
            showRequestedResourceNameToast={true}
          />
        )}
      </ExternalAndRouterLink>
    </Tracker>
  );
};

const getBadge = (
  integration: IIntegration,
  badgeState: string | undefined,
) => {
  let badges = [];

  if (badgeState === 'installed') {
    badges.push(
      <Badge key={`${integration.installState}-badge`} variant="success">
        Installed
      </Badge>,
    );
  } else if (badgeState === 'requested') {
    badges.push(
      <Badge key={`${integration.installState}-badge`} variant="warning">
        Requested
      </Badge>,
    );
  }

  return (
    <Stack direction="row" spacing={1}>
      {badges.map((badge) => badge)}
    </Stack>
  );
};
