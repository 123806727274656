import { StepBase } from 'subframe/index';
type Props = {
  showHelm?: boolean;
  showKubectl?: boolean;
};

export const SystemRequirements = ({
  showHelm = false,
  showKubectl = true,
}: Props) => {
  return (
    <div data-cy="system-requirements" style={{ display: 'contents' }}>
      <StepBase.BulletedLine>
        {'Kubernetes >= v1.19 (tested on Amazon EKS, Google GKE and Azure AKS)'}
      </StepBase.BulletedLine>
      <StepBase.BulletedLine>
        {'OS/Architecture: linux/amd64, linux/arm64'}
      </StepBase.BulletedLine>
      {showKubectl && (
        <StepBase.BulletedLine>{'kubectl: >= v1.19'}</StepBase.BulletedLine>
      )}
      {showHelm && (
        <StepBase.BulletedLine>{'Helm: >= version 2'}</StepBase.BulletedLine>
      )}
    </div>
  );
};
