// @mui

import { TeamMember } from 'api/models';
import { AtomicTooltip } from 'components/design-system';
import BaseTable from 'components/design-system/Table/BaseTable';
import { RemoveTeamMemberConfirmationDialog } from 'components/dialogs/RemoveTeamMemberConfirmationDialog';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import useUserAccountState from 'hooks/useUserAccountState';
import { Member } from 'models/teams';
import { SyntheticEvent, useState } from 'react';
import { useDebounce } from 'react-use';
import { Avatar, IconButton, Table } from 'subframe/index';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { getHumanReadableDate } from 'utils/subscription_plan';
import { Searchbar } from 'components/design-system/Searchbar';

interface TeamMembersTabProps {
  members: TeamMember[];
  refetchMembers: VoidFunction;
}

export default function TeamsMembers({
  members,
  refetchMembers,
}: TeamMembersTabProps) {
  const { logEvent } = AnalyticsEventLogger();
  const [selectedMember, setSelectedMember] = useState<TeamMember | null>(null);
  const [open, setOpen] = useState(false);
  const { user } = useUserAccountState();

  const [searchMember, setSearchMember] = useState<string>('');

  const path = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/teams/member',
  );

  useDebounce(
    () => {
      if (searchMember) {
        logEvent('search-member', { searchMember });
      }
    },
    1000,
    [searchMember],
  );

  const handleOnChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: string,
  ) => {
    setSearchMember(newValue);
  };

  const membersToShow =
    searchMember !== ''
      ? members.filter((item) =>
          item?.user_name.toLowerCase().includes(searchMember.toLowerCase()),
        )
      : members;

  return (
    <>
      <div className="w-full items-center justify-end flex">
        <Searchbar onChange={handleOnChange} />
      </div>
      <BaseTable
        settings={{
          localStorageKey: 'teams_members_table',
          dataCyPrefix: 'members',
        }}
        noMatchingData={membersToShow?.length === 0}
        data={membersToShow || []}
        rowLink={(row) => {
          return `${path}/${row?.user_id}`;
        }}
        columns={[
          {
            id: 'members-table-body',
            title: 'Name',
            titleStyle: { width: '30%' },
            cellType: 'cell',

            render: (row) => {
              return (
                <>
                  {row?.user_picture ? (
                    <Avatar
                      size="small"
                      image={row?.user_picture}
                      className="ph-no-capture"
                    />
                  ) : (
                    <Avatar variant="neutral" size="small">
                      {row?.user_name?.charAt(0)?.toUpperCase()}
                    </Avatar>
                  )}
                  <Table.Cell>
                    <span className="text-body font-body text-neutral-500 ph-no-capture">
                      {row?.user_name}
                    </span>
                  </Table.Cell>
                </>
              );
            },
          },
          {
            id: '2',
            title: 'Email',
            titleStyle: { width: '40%' },
            cellType: 'text-cell',
            hideCapture: true,
            render: (row) => {
              return `${row?.user_email}`;
            },
          },
          {
            id: '3',
            title: 'Added',
            titleStyle: { width: '30%' },
            cellType: 'text-cell',
            render: (row) => {
              return `${
                row ? getHumanReadableDate(row.date_added) : 'Not Found'
              }`;
            },
          },
        ]}
        actions={[
          {
            id: '4',
            render: (row) => {
              const disableActions = members?.length <= 1;
              return (
                <>
                  <AtomicTooltip
                    tooltipContent={
                      disableActions
                        ? 'You cannot leave this team as you are the only team owner.'
                        : user?.email === row?.user_email
                        ? 'Leave team'
                        : 'Remove team member'
                    }
                  >
                    <IconButton
                      icon={
                        user?.email === row?.user_email
                          ? 'FeatherLogOut'
                          : 'FeatherTrash'
                      }
                      onContextMenu={(event) => {
                        event.preventDefault();
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        setSelectedMember(row);
                        setOpen(true);
                      }}
                      disabled={disableActions}
                      className="bg-inherit"
                      size="medium"
                    />
                  </AtomicTooltip>
                </>
              );
            },
          },
        ]}
      />

      <RemoveTeamMemberConfirmationDialog
        member={selectedMember || ({} as Member)}
        open={open && (members?.length || 0) > 1}
        onClose={() => {
          setOpen(false);
        }}
        callRefetch={refetchMembers}
      />
    </>
  );
}
