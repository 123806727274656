import { useEffect, useMemo } from 'react';
import * as SubframeCore from '@subframe/core';
import { groupBy } from 'lodash';
import styles from './AcmeVersionRegisterControlPlaneLandingPage.module.scss';
import Page from 'components/Page';
import { EnumEolState } from 'utils/types';
import {
  Alert,
  Badge,
  Breadcrumbs,
  Button,
  InfoTooltip,
  InformationalHeader,
  Switch,
} from 'subframe/index';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import {
  CLUSTERS,
  filterClustersByVersion,
} from 'src/data/version_register_cp';
import {
  getAccumulatedClusterExpiryStateBySupportLevel,
  getEolDateForClusters,
  getSupportBadgeForAccumulatedClusters,
  getTooltipTextForAccumulatedClusters,
  supportLevelBadgeMetadata,
} from 'utils/clusterSupportLevelAndEol';
import useLocalStorage from 'hooks/useLocalStorage';
import { format, formatDistanceToNowStrict } from 'date-fns';
import { useListClusters } from 'api/frontend';
import {
  Cluster,
  ClusterSupportLevelType,
  KubernetesProvider,
} from 'api/models';
import { AtomicTooltip } from 'components/design-system';
import { RouterLink } from 'components/RouterLink';
import useUserAccountState from 'hooks/useUserAccountState';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import AtomicTooltipStyles from 'components/design-system/AtomicTooltip.module.scss';
import classNames from 'classnames';
import { moreTooltipContent } from 'components/design-system/AtomicTooltip';
import BaseTable from 'components/design-system/Table/BaseTable';

interface ClustersByVersion {
  [version: string]: Cluster[];
}
interface DataItem {
  key: string;
  version: string;
  clusters: Cluster[];
  url: string;
}

export default function FleetIndex() {
  const { logEvent } = AnalyticsEventLogger();
  const { account } = useUserAccountState();
  const basePath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/artifact_register/control_plane',
  );
  const newClusterPath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/clusters/new',
  );
  const explorePath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/explore/supported',
  );
  const [showExampleData, setShowExampleData] = useLocalStorage<boolean>(
    'example-data',
    true,
  );

  const { data: listClustersResponse } = useListClusters(
    { filter: ['status:active'] },
    {
      request: { headers: { Authorization: `Bearer ${account.token}` } },
    },
  );

  const realClusterData = listClustersResponse
    ? 'data' in listClustersResponse
      ? listClustersResponse.data.filter(
          (c) => c.internal_k8s_ref !== 'example-cluster-ref',
        )
      : []
    : [];
  useEffect(() => {
    if (listClustersResponse && localStorage.getItem('example-data') === null) {
      setShowExampleData(!realClusterData.length);
    }
  }, [listClustersResponse]);

  const data: Cluster[] = showExampleData ? CLUSTERS : realClusterData;

  const clustersByVersion: ClustersByVersion = {
    '1.31': filterClustersByVersion(data, '1.31'),
    '1.30': filterClustersByVersion(data, '1.30'),
    '1.29': filterClustersByVersion(data, '1.29'),
    '1.28': filterClustersByVersion(data, '1.28'),
    '1.27': filterClustersByVersion(data, '1.27'),
    '1.26': filterClustersByVersion(data, '1.26'),
    '1.25': filterClustersByVersion(data, '1.25'),
    '1.24': filterClustersByVersion(data, '1.24'),
    '1.23': filterClustersByVersion(data, '1.23'),
    '1.22': filterClustersByVersion(data, '1.22'),
  };
  const earlierClusters = filterClustersByVersion(data, 'earlier');

  useEffect(() => {
    logEvent('version-register-control-plane-listing-view', {
      showExampleData,
    });
  }, [showExampleData]);

  const getDisplayData = () => {
    // Combine the two lists and store them in displayData
    const combinedData: DataItem[] = [];
    if (earlierClusters && clustersByVersion) {
      if (earlierClusters.length > 0) {
        combinedData.push({
          key: 'earlier',
          version: 'v1.21 and earlier',
          clusters: earlierClusters,
          url: `${basePath}/earlier`,
        });
      }

      Object.keys(clustersByVersion)
        .reverse()
        .filter((version) => clustersByVersion[version].length > 0)
        .forEach((version) => {
          combinedData.push({
            key: version,
            version: `v${version}`,
            clusters: clustersByVersion[version],
            url: `${basePath}/${version.replace(/\./g, '_')}`,
          });
        });

      return combinedData;
    }
  };
  const displayData = useMemo(() => {
    return getDisplayData();
  }, [earlierClusters, clustersByVersion]);

  return (
    <Page title="Control Plane">
      <div
        className={styles['pageContents']}
        style={{ marginLeft: '32px', width: 'calc(100% - 32px' }}
      >
        <Breadcrumbs>
          <Breadcrumbs.Item>Artifact Register</Breadcrumbs.Item>
          <Breadcrumbs.Divider name="FeatherChevronRight" />
          <Breadcrumbs.Item active={true}>Control Plane</Breadcrumbs.Item>
        </Breadcrumbs>
        <div className={styles['header']}>
          <div className={styles['stack-03cb65f3']}>
            <div className={styles['stack-efa89bf0']}>
              <span className={styles['sectionHeaderText']}>
                Control Plane Register
              </span>
            </div>
            <div className={styles['stack-f079aabc']}>
              <span className={styles['text-0b4b7a20']}>
                K8s control plane versions running in your infrastructure
              </span>
              <div className={styles['stack-e87adb5d']}>
                <span className={styles['text-c3faf4fc']}>
                  Show example data
                </span>
                <AtomicTooltip
                  tooltipContent={
                    showExampleData
                      ? 'Show results from your onboarded clusters'
                      : 'Show example data'
                  }
                >
                  <Switch
                    data-cy="switch"
                    checked={showExampleData}
                    onClick={() => setShowExampleData(!showExampleData)}
                  />
                </AtomicTooltip>
              </div>
            </div>
          </div>
        </div>
        {showExampleData && (
          <Alert
            variant="warning"
            title="You are currently viewing example data"
            description={`To view results from your onboarded clusters, toggle the "Show example data" button`}
          />
        )}
        <RouterLink
          to={explorePath}
          style={{
            width: '100%',
          }}
        >
          <Alert
            className={styles.fullWidth}
            variant="neutral"
            title={`Chkk covers 3 Clouds`}
            description="Check out what's covered on the Clouds & Add-ons page. "
            icon="FeatherCloudy"
          />
        </RouterLink>

        {!showExampleData && listClustersResponse === undefined && (
          <SubframeCore.Loader />
        )}
        {!showExampleData &&
          listClustersResponse !== undefined &&
          data.length === 0 && (
            <div className={styles['stack-db6e6201']}>
              <span className={styles['bodyText']}>
                No clusters onboarded so far. Click here to onboard your first
                cluster
              </span>
              <RouterLink to={newClusterPath}>
                <Button size="small" icon="FeatherPlus" data-cy="add-cluster">
                  Add Cluster
                </Button>
              </RouterLink>
            </div>
          )}
        <div className={styles['data']}>
          {data.length > 0 && (
            <>
              <div className={styles['stack-9650766c']}>
                <InformationalHeader>
                  <InformationalHeader.Item
                    data-cy="total-clusters"
                    title="Total"
                    bodySlot={
                      <span className="text-body-bold font-body-bold text-default-font">
                        {data.length}
                      </span>
                    }
                  />
                  {Object.keys(clustersByVersion)
                    .filter((version) => clustersByVersion[version].length > 0)
                    .map((version) => {
                      //! this is where the informational header clock color is determined
                      const expiryState =
                        getAccumulatedClusterExpiryStateBySupportLevel(
                          clustersByVersion[version],
                        );
                      const eosDate = getEolDateForClusters(
                        clustersByVersion[version],
                      );
                      const textToShow = getTooltipTextForAccumulatedClusters(
                        clustersByVersion[version],
                      );
                      return (
                        <InformationalHeader.Item
                          data-cy="cluster-version"
                          key={version}
                          title={`v${version}`}
                          headingSlot={
                            eosDate !== undefined ? (
                              <InfoTooltip tooltipText={textToShow} />
                            ) : (
                              <></>
                            )
                          }
                          bodySlot={
                            <span className="text-body-bold font-body-bold text-default-font">
                              {clustersByVersion[version].length}
                            </span>
                          }
                          icon={
                            expiryState !== undefined &&
                            expiryState !== EnumEolState.Default ? (
                              <>
                                {expiryState === EnumEolState.Warning && (
                                  <SubframeCore.Icon
                                    data-cy="yellow-icon"
                                    className={styles['icon']}
                                    name="FeatherClock"
                                  />
                                )}
                                {expiryState === EnumEolState.Error && (
                                  <SubframeCore.Icon
                                    data-cy="red-icon"
                                    className={styles['icon-f61a883f']}
                                    name="FeatherClock"
                                  />
                                )}
                              </>
                            ) : undefined
                          }
                        />
                      );
                    })}
                  {earlierClusters.length > 0 && (
                    <InformationalHeader.Item
                      data-cy="cluster-version"
                      title="v1.21 and earlier"
                      icon={
                        <SubframeCore.Icon
                          data-cy="red-icon"
                          className={styles['icon-f61a883f']}
                          name="FeatherClock"
                        />
                      }
                      headingSlot={
                        getEolDateForClusters(earlierClusters) !== undefined ? (
                          <InfoTooltip
                            tooltipText={getTooltipTextForAccumulatedClusters(
                              earlierClusters,
                            )}
                          />
                        ) : (
                          <></>
                        )
                      }
                      bodySlot={
                        <span className="text-body-bold font-body-bold text-default-font">
                          {earlierClusters.length}
                        </span>
                      }
                    />
                  )}
                </InformationalHeader>
              </div>

              <div className={styles['stack-02ddb5ec']}>
                <BaseTable
                  settings={{
                    localStorageKey: 'artifact_register_control_plane_index',
                    dataCyPrefix: 'ar-control-plane',
                  }}
                  data={displayData || []}
                  rowLink={(row) => row.url}
                  columns={[
                    {
                      id: '1',
                      title: 'Version',
                      titleStyle: { width: '20%' },
                      cellType: 'cell',
                      render: (row) => {
                        const expiryState =
                          getAccumulatedClusterExpiryStateBySupportLevel(
                            row.clusters,
                          );
                        const eosDate = getEolDateForClusters(row.clusters);

                        const supportBadgeData =
                          expiryState == 'default' //If the state is default, then it's neither an EOL nor an Extended support
                            ? undefined
                            : getSupportBadgeForAccumulatedClusters(
                                row.clusters,
                              );
                        const getTooltipText = (
                          supportLevel: supportLevelBadgeMetadata,
                        ) => {
                          const relativeTime = `${formatDistanceToNowStrict(
                            new Date(supportLevel.end_date * 1000),
                            { addSuffix: true },
                          )} ${format(
                            new Date(supportLevel.end_date * 1000),
                            '(LLL yyyy)',
                          )}`;

                          if (!supportLevel || !supportLevel.end_date) {
                            return undefined;
                          } else if (!supportLevel.clusterCountByProvider) {
                            //When all clusters belongs to one provider only, clusterCountByProvider is undefined
                            return `${row.clusters.length} cluster${
                              row.clusters.length > 1 ? 's' : ''
                            } ${
                              supportLevel?.end_date >
                              new Date().getTime() / 1000
                                ? 'will reach '
                                : 'reached'
                            } end of ${supportLevel.name} ${relativeTime}.`;
                          } else if (supportLevel.clusterCountByProvider) {
                            //When  clusters belongs to multiple providers
                            return `${supportLevel.clusterCountByProvider} of ${
                              row.clusters.length
                            } clusters ${
                              supportLevel?.end_date >
                              new Date().getTime() / 1000
                                ? 'will reach '
                                : 'reached'
                            } end of ${supportLevel.name} ${relativeTime}.`;
                          } else {
                            return undefined;
                          }
                        };

                        return (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '4px',
                            }}
                          >
                            <div className={styles['stack-8171b00a']}>
                              {expiryState === EnumEolState.Warning &&
                                (eosDate !== undefined ? (
                                  <AtomicTooltip
                                    tooltipContent={getTooltipTextForAccumulatedClusters(
                                      row.clusters,
                                    )}
                                  >
                                    <SubframeCore.Icon
                                      data-cy="yellow-icon"
                                      className={styles['icon-ffd9b8f3']}
                                      name="FeatherClock"
                                    />
                                  </AtomicTooltip>
                                ) : (
                                  <></>
                                ))}

                              {expiryState === EnumEolState.Error &&
                                (eosDate !== undefined ? (
                                  <AtomicTooltip
                                    tooltipContent={getTooltipTextForAccumulatedClusters(
                                      row.clusters,
                                    )}
                                  >
                                    <SubframeCore.Icon
                                      data-cy="red-icon"
                                      className={styles['icon-588264cf']}
                                      name="FeatherClock"
                                    />
                                  </AtomicTooltip>
                                ) : (
                                  <></>
                                ))}

                              <span
                                className={styles['bodyText']}
                                data-cy="version-from-row"
                              >
                                {row.version}
                              </span>

                              {supportBadgeData && supportBadgeData.length && (
                                <>
                                  {supportBadgeData.map(
                                    (supportLevel, index) => {
                                      return (
                                        <>
                                          {supportLevel.badgeVariant &&
                                            supportLevel.badgeVariant !==
                                              'neutral' && (
                                              <AtomicTooltip
                                                key={
                                                  supportLevel.provider +
                                                  '-' +
                                                  index
                                                }
                                                tooltipContent={getTooltipText(
                                                  supportLevel,
                                                )}
                                              >
                                                <Badge
                                                  variant={
                                                    supportLevel.badgeVariant ||
                                                    'error'
                                                  }
                                                >
                                                  {`${
                                                    supportLevel.badgeVariant ===
                                                    'error'
                                                      ? 'End of '
                                                      : ''
                                                  }${supportLevel.name}${
                                                    supportBadgeData.length ==
                                                      1 &&
                                                    supportLevel.provider ===
                                                      KubernetesProvider.EKS &&
                                                    supportLevel.badgeVariant ===
                                                      'warning' &&
                                                    supportLevel.type ===
                                                      ClusterSupportLevelType.LongTermSupport
                                                      ? `: ${
                                                          supportLevel?.end_date >
                                                          new Date().getTime() /
                                                            1000
                                                            ? 'Ends'
                                                            : ''
                                                        } ${formatDistanceToNowStrict(
                                                          new Date(
                                                            supportLevel.end_date *
                                                              1000,
                                                          ),
                                                          { addSuffix: true },
                                                        )} `
                                                      : ''
                                                  }`}
                                                </Badge>
                                              </AtomicTooltip>
                                            )}
                                        </>
                                      );
                                    },
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        );
                      },
                    },
                    {
                      id: '2',
                      title: 'Environment',
                      titleStyle: { width: '40%' },
                      cellType: 'cell',

                      render: (row) => {
                        const clustersGroupedByEnvironment = Object.entries(
                          groupBy(
                            row.clusters,
                            (c) => c.environment || 'default',
                          ),
                        );
                        return (
                          <div
                            className={styles['stack-e81cd31a']}
                            style={{ width: '100%' }}
                          >
                            {clustersGroupedByEnvironment
                              .slice(0, 2)
                              .map(([key, value]) => {
                                const text = `${key}: ${value.length}`;
                                return (
                                  <AtomicTooltip
                                    tooltipContent={text}
                                    key={key}
                                  >
                                    <Badge
                                      data-cy="environment"
                                      variant="neutral"
                                      style={{
                                        maxWidth: '35%',
                                      }}
                                    >
                                      {text}
                                    </Badge>
                                  </AtomicTooltip>
                                );
                              })}
                            {clustersGroupedByEnvironment.length > 2 && (
                              <AtomicTooltip
                                tooltipContent={clustersGroupedByEnvironment
                                  .slice(2)
                                  .map(
                                    ([key, value]) => `${key}: ${value.length}`,
                                  )
                                  .join(', ')}
                              >
                                <span
                                  className={styles['text-6f6337cd']}
                                  data-cy="environment-more"
                                >
                                  +
                                  {Math.max(
                                    0,
                                    clustersGroupedByEnvironment.length - 2,
                                  )}{' '}
                                  more
                                </span>
                              </AtomicTooltip>
                            )}
                          </div>
                        );
                      },
                    },
                    {
                      id: '3',
                      title: 'Cluster',
                      titleStyle: { width: '40%' },
                      cellType: 'cell',
                      render: (row) => {
                        const prodClusters = row.clusters.filter(
                          (c) => c.environment?.toLowerCase() === 'prod',
                        );
                        const nonProdCluster = row.clusters.filter(
                          (c) => c.environment?.toLowerCase() !== 'prod',
                        );
                        return (
                          <div className={styles['stack-b39a15f6']}>
                            <span className={styles['bodyText']}>
                              {prodClusters.length > 0 && (
                                <AtomicTooltip
                                  tooltipContent={moreTooltipContent(
                                    prodClusters.map((c) => c.name),
                                    0,
                                  )}
                                >
                                  <span
                                    className={styles['text-dd46644c']}
                                    data-cy="cluster-col"
                                  >
                                    {prodClusters.map((c) => c.name).join(', ')}
                                  </span>
                                </AtomicTooltip>
                              )}
                              {prodClusters.length === 0 &&
                                nonProdCluster.length > 0 && (
                                  <AtomicTooltip
                                    tooltipContent={moreTooltipContent(
                                      nonProdCluster
                                        .slice(0, 2)
                                        .map((c) => c.name),
                                      0,
                                    )}
                                  >
                                    <span
                                      className={styles['text-dd46644c']}
                                      data-cy="cluster-col"
                                    >
                                      {nonProdCluster
                                        .slice(0, 2)
                                        .map((c) => c.name)
                                        .join(', ')}
                                    </span>
                                  </AtomicTooltip>
                                )}{' '}
                            </span>
                            {prodClusters.length === 0 &&
                              nonProdCluster.length > 2 && (
                                <AtomicTooltip
                                  tooltipContent={moreTooltipContent(
                                    nonProdCluster.slice(2).map((c) => c.name),
                                    0,
                                  )}
                                >
                                  <span
                                    className={classNames(
                                      styles['text-6f6337cd'],
                                      AtomicTooltipStyles[
                                        'tooltip-text-content'
                                      ],
                                    )}
                                    data-cy="cluster-col-more"
                                  >
                                    +{Math.max(0, nonProdCluster.length - 2)}{' '}
                                    more
                                  </span>
                                </AtomicTooltip>
                              )}
                            {prodClusters.length > 0 &&
                              nonProdCluster.length > 0 && (
                                <AtomicTooltip
                                  tooltipContent={moreTooltipContent(
                                    nonProdCluster.map((c) => c.name),
                                    0,
                                  )}
                                >
                                  <span
                                    className={styles['text-6f6337cd']}
                                    data-cy="cluster-col-more"
                                  >
                                    +{nonProdCluster.length} more
                                  </span>
                                </AtomicTooltip>
                              )}
                          </div>
                        );
                      },
                    },
                  ]}
                  actions={[
                    {
                      id: 'navigate',
                      render: () => {
                        return (
                          <div className={styles['stack-83b62c08']}>
                            <SubframeCore.Icon
                              className={styles['bodyText']}
                              name="FeatherChevronRight"
                            />
                          </div>
                        );
                      },
                    },
                  ]}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </Page>
  );
}
