import { createContext, ReactNode, useEffect, useState } from 'react';

// ----------------------------------------------------------------------

export type ClusterDetailsTab =
  | 'arsigs-overview'
  | 'defects'
  | 'misconfigurations'
  | 'system-requirements'
  | 'deprecations'
  | 'unsupported-versions'
  | 'version-incompatibilities'
  | 'guardrails'
  | 'properties';

type ClusterDetailsTabContextProps = {
  current: ClusterDetailsTab;
  handleChange: (t: ClusterDetailsTab) => void;
};

const initialState: ClusterDetailsTabContextProps = {
  current: 'arsigs-overview',
  handleChange: (t: ClusterDetailsTab) => {},
};

const ClusterDetailTabContext = createContext(initialState);

type ClusterDetailsTabProviderProps = {
  children: ReactNode;
};

function ClusterDetailsTabProvider({
  children,
}: ClusterDetailsTabProviderProps) {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    const handleChange = (t: ClusterDetailsTab) =>
      setState((s) => ({ ...s, current: t }));

    setState({ ...initialState, handleChange });
  }, []);

  return (
    <ClusterDetailTabContext.Provider value={state}>
      {children}
    </ClusterDetailTabContext.Provider>
  );
}

export { ClusterDetailsTabProvider };
