import { Loader } from '@subframe/core/dist/cjs';
import {
  createUpgradePlanStageStepComment,
  createUpgradeTemplateStageStepComment,
  useListUpgradePlanHistory,
  useListUpgradePlanStageStepComments,
  useListUpgradeTemplateHistory,
  useListUpgradeTemplateStageStepComments,
} from 'api/frontend';
import {
  UpgradePlan,
  UpgradeStage,
  UpgradeStageStatus,
  UpgradeStageStep,
} from 'api/models';
import { somethingWentWrong } from 'constants/toasts';
import { formatDistanceToNow } from 'date-fns';
import useUserAccountState from 'hooks/useUserAccountState';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { UPGRADES, UPGRADE_TEMPLATES } from 'src/data/upgrades';
import {
  AccordionWithUnderline,
  Avatar,
  Badge,
  ChatMessage,
  Conversation,
  DetailsList,
  Feed,
  IconWithBackground,
  LogoIcon,
  ReplyMessageBase,
} from 'subframe/index';
import { getHumanReadableDateAndTime } from 'utils/formatTime';
import { getSkipReasonHuman } from '../helpers/skip_reasons';
import styles from './StepSidebar.module.scss';
import { getHistoryAvatar, getHistoryText } from './UpgradeSidebar';

function getStatusBadgeVariant(
  status: UpgradeStageStatus,
): 'brand' | 'neutral' | 'error' | 'warning' | 'success' {
  switch (status) {
    case 'active':
    case 'blocked':
      return 'neutral';
    case 'skipped':
      return 'warning';
    case 'completed':
      return 'success';
    case 'in-progress':
      return 'neutral';
  }
}

interface UpgradeStepSidebarProps {
  upgrade: UpgradePlan;
  stage: UpgradeStage;
  step: UpgradeStageStep;
  isTemplate?: boolean;
}

export function UpgradeStepSidebar(props: UpgradeStepSidebarProps) {
  const { account } = useUserAccountState();
  const [openElement, setOpenElement] = useState<
    'activity' | 'comments' | undefined
  >('activity');
  const showExampleData =
    props.upgrade?.id &&
    (UPGRADES.map((upgr) => upgr.id).includes(props.upgrade?.id) ||
      UPGRADE_TEMPLATES.map((upgr) => upgr.id).includes(props.upgrade?.id))
      ? true
      : false;
  const { data: historyData } = props.isTemplate
    ? useListUpgradeTemplateHistory(
        props.upgrade.id,
        {},
        {
          request: {
            headers: { Authorization: `Bearer ${account.token}` },
          },
          swr: {
            enabled: !showExampleData,
          },
        },
      )
    : useListUpgradePlanHistory(
        props.upgrade.id,
        {},
        {
          request: {
            headers: { Authorization: `Bearer ${account.token}` },
          },
          swr: {
            enabled: !showExampleData,
          },
        },
      );

  const preparedData =
    historyData === undefined
      ? undefined
      : historyData.data
          .filter(
            (event) =>
              event.subject.stage?.number === props.stage.stage_number &&
              event.subject.step?.number === props.step.step_number,
          )
          // filter out comment events as they would look redundant with the comments section below
          .filter((event) => event.type !== 'step_commented')
          .sort((a, b) => b.time - a.time);

  return (
    <div className={styles['stack-d78aa573']}>
      <DetailsList>
        <DetailsList.DetailsListBadgeRow
          label="Status"
          badges={
            <Badge variant={getStatusBadgeVariant(props.step.status)}>
              {props.step.status}
            </Badge>
          }
        />
        {props.step.status === 'skipped' &&
          props.step.last_status_change_details?.reason !== undefined && (
            <DetailsList.DetailsListRow
              label="Reason"
              icon="FeatherInfo"
              value={getSkipReasonHuman(
                props.step.last_status_change_details?.reason,
              )}
            />
          )}
      </DetailsList>
      <AccordionWithUnderline
        headerText="Activity Feed"
        open={openElement === 'activity'}
        onOpenChange={(open) => setOpenElement(open ? 'activity' : undefined)}
      >
        <div
          style={{
            overflow: 'auto',
            maxHeight: 'calc(99vh - 300px)',
            width: '100%',
            paddingRight: '8px',
          }}
        >
          {!showExampleData && preparedData === undefined && <Loader />}
          {!showExampleData && preparedData !== undefined && (
            <Feed className="h-full w-full grow shrink-0 basis-0">
              {preparedData.map((item, idx) => (
                <Feed.Item
                  key={idx}
                  leftSlot={getHistoryAvatar(item)}
                  comment={
                    item.data.comment !== undefined &&
                    item.data.comment !== '' && (
                      <Feed.Comment>{item.data.comment}</Feed.Comment>
                    )
                  }
                  timestamp={formatDistanceToNow(new Date(item.time * 1000), {
                    addSuffix: true,
                  })}
                  isLast={idx === preparedData.length - 1}
                >
                  <span className="text-body-bold font-body-bold text-default-font ph-no-capture">
                    {item.actor.user?.name || 'Chkk'}
                  </span>
                  <span className="text-body font-body text-subtext-color">
                    {getHistoryText(item, props.isTemplate)}
                  </span>
                </Feed.Item>
              ))}
            </Feed>
          )}
          {showExampleData &&
            !props.isTemplate &&
            props.step.status === 'completed' && (
              <Feed>
                <Feed.Item
                  leftSlot={
                    <IconWithBackground
                      variant="success"
                      size="small"
                      icon="FeatherCheck"
                    />
                  }
                  timestamp={
                    props.upgrade.updated
                      ? formatDistanceToNow(new Date(props.upgrade.updated), {
                          addSuffix: true,
                        })
                      : ''
                  }
                  comment={null}
                  isLast={true}
                >
                  <span className="text-body-bold font-body-bold text-default-font ph-no-capture">
                    {props.upgrade.completed_by?.name || 'Jane Doe'}
                  </span>
                  <span className="text-body font-body text-subtext-color">
                    completed this step
                  </span>
                </Feed.Item>
              </Feed>
            )}
        </div>
      </AccordionWithUnderline>
      <AccordionWithUnderline
        headerText="Comments"
        open={openElement === 'comments'}
        onOpenChange={(open) => setOpenElement(open ? 'comments' : undefined)}
      >
        <StepComments
          isTemplate={props.isTemplate}
          showExampleData={showExampleData}
          upgrade={props.upgrade}
          stage={props.stage}
          step={props.step}
        />
      </AccordionWithUnderline>
    </div>
  );
}

interface StepCommentsProps {
  showExampleData: boolean;
  upgrade: UpgradePlan;
  stage: UpgradeStage;
  step: UpgradeStageStep;
  isTemplate?: boolean;
}

function StepComments(props: StepCommentsProps) {
  const { account } = useUserAccountState();
  const { enqueueSnackbar } = useSnackbar();
  const { data: commentsData, mutate: commentsMutate } = props.isTemplate
    ? useListUpgradeTemplateStageStepComments(
        props.upgrade.id,
        props.stage.stage_number.toFixed(),
        props.step.step_number.toFixed(),
        {},
        {
          request: {
            headers: { Authorization: `Bearer ${account.token}` },
          },
        },
      )
    : useListUpgradePlanStageStepComments(
        props.upgrade.id,
        props.stage.stage_number.toFixed(),
        props.step.step_number.toFixed(),
        {},
        {
          request: {
            headers: { Authorization: `Bearer ${account.token}` },
          },
          swr: {
            enabled: !props.showExampleData,
          },
        },
      );
  const [isSending, setIsSending] = useState(false);

  return (
    <div
      style={{
        overflow: 'auto',
        maxHeight: 'calc(99vh - 300px)',
        width: '100%',
        paddingRight: '8px',
      }}
    >
      {!props.showExampleData && commentsData === undefined && <Loader />}
      {!props.showExampleData && commentsData !== undefined && (
        <Conversation
          replyElements={
            <ReplyMessageBase
              uploadBadge={undefined}
              prompts={<></>}
              disableUpload={true}
              isSending={isSending}
              disableActionsString={
                props.upgrade.status === 'pending' ||
                props.upgrade.status === 'cancelled' ||
                props.upgrade.status === 'completed'
                  ? `The Upgrade ${
                      props.isTemplate ? 'Template' : 'Plan'
                    } has concluded and can no longer be commented on.`
                  : undefined
              }
              onSend={async (message) => {
                try {
                  setIsSending(true);
                  if (props.isTemplate) {
                    await createUpgradeTemplateStageStepComment(
                      props.upgrade.id,
                      props.stage.stage_number.toFixed(),
                      props.step.step_number.toFixed(),
                      { comment: message },
                      {
                        headers: {
                          Authorization: `Bearer ${account.token}`,
                        },
                      },
                    );
                  } else {
                    await createUpgradePlanStageStepComment(
                      props.upgrade.id,
                      props.stage.stage_number.toFixed(),
                      props.step.step_number.toFixed(),
                      { comment: message },
                      {
                        headers: {
                          Authorization: `Bearer ${account.token}`,
                        },
                      },
                    );
                  }
                  await commentsMutate();
                } catch (err) {
                  //Sentry.captureException(err);
                  enqueueSnackbar(
                    somethingWentWrong.replace('<action>', 'posting comment'),
                    { variant: 'error' },
                  );
                } finally {
                  setIsSending(false);
                }
              }}
            />
          }
          className="border-none p-0"
        >
          {commentsData.data.map((item, idx) => (
            <ChatMessage
              key={idx}
              name={item.user?.name || 'Chkk'}
              message={item.comment}
              time={getHumanReadableDateAndTime(item.time)} // same date time format as in Cluster Properties
              avatar={
                item.user === undefined ? (
                  <Avatar variant="brand" size="small">
                    C
                  </Avatar>
                ) : (
                  <Avatar
                    size="small"
                    image={item.user.picture}
                    className="ph-no-capture"
                  />
                )
              }
            />
          ))}
        </Conversation>
      )}
      {props.showExampleData && (
        <Conversation
          replyElements={
            <ReplyMessageBase
              uploadBadge={undefined}
              prompts={<></>}
              disableUpload={true}
              disableActionsString="This is demonstration data and cannot be commented on."
              isSending={false}
            />
          }
          className="border-none p-0"
        >
          <ChatMessage
            name="Eva Eeg"
            message="Do you have any certificates managed outside cert-manager?"
            avatar={
              <LogoIcon
                size="medium"
                image="https://res.cloudinary.com/demo/image/upload/v1674762771/Logo_ndnxzk.png"
              />
            }
          />
          <ChatMessage
            time="4:20pm"
            message="No, all our certs are in cert-manager"
            name="Katy Smith"
            avatar={<Avatar>KS</Avatar>}
          />
          <ChatMessage
            name="Eva Eeg"
            time="5:12pm"
            message="Can you take some downtime when updating cert-manager?"
            avatar={
              <LogoIcon
                size="medium"
                image="https://res.cloudinary.com/demo/image/upload/v1674762771/Logo_ndnxzk.png"
              />
            }
          />
          <ChatMessage
            time="7:55pm"
            message="Let me share our cluster locations"
            name="Katy Smith"
            avatar={<Avatar>KS</Avatar>}
          />
          <ChatMessage
            name="Eva Eeg"
            time="8:22pm"
            message="Are you ready to fix this now?"
            avatar={
              <LogoIcon
                size="medium"
                image="https://res.cloudinary.com/demo/image/upload/v1674762771/Logo_ndnxzk.png"
              />
            }
          />
          <ChatMessage
            time="8:52pm"
            message="I am seeing something suspicious in the logs. Can you have a
                look?"
            name="Katy Smith"
            avatar={<Avatar>KS</Avatar>}
          />
        </Conversation>
      )}
    </div>
  );
}
