import Page from 'components/Page';
import { KBAPage } from './kba/index';

const ScannedSignatureDetails = () => {
  return (
    <Page title="Scanned Signature Details">
      <KBAPage isFromScannedSignature />
    </Page>
  );
};

export default ScannedSignatureDetails;
