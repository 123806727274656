import { Cluster, ClusterSupportLevel } from 'api/models';
import { subDays } from 'date-fns';
import * as semver from 'semver';

export function filterClustersByVersion(
  clusters: Cluster[],
  version: string,
): Cluster[] {
  if (version === 'earlier') {
    return clusters.filter((c) =>
      semver.satisfies(semver.coerce(c.version) || '', '<1.22.0'),
    );
  }

  return clusters.filter((c) =>
    semver.satisfies(semver.coerce(c.version) || '', `${version}.x`),
  );
}

const SUPPORT_LEVEL_MAP = {
  GKE: {
    // https://cloud.google.com/kubernetes-engine/docs/release-schedule#schedule-for-release-channels
    '1.24': [
      {
        end_date: new Date(2024, 0, 8).getTime() / 1000,
        name: 'GKE Support',
        type: 'GeneralSupport',
      },
    ],
    '1.25': [
      {
        end_date: new Date(2024, 2, 30).getTime() / 1000,
        name: 'GKE Support',
        type: 'GeneralSupport',
      },
    ],
    '1.26': [
      {
        end_date: new Date(2024, 5, 30).getTime() / 1000,
        name: 'GKE Support',
        type: 'GeneralSupport',
      },
    ],
    '1.27': [
      {
        end_date: new Date(2024, 7, 31).getTime() / 1000,
        name: 'GKE Support',
        type: 'GeneralSupport',
      },
    ],
    '1.28': [
      {
        end_date: new Date(2025, 1, 4).getTime() / 1000,
        name: 'GKE Support',
        type: 'GeneralSupport',
      },
    ],
    '1.29': [
      {
        end_date: new Date(2025, 2, 21).getTime() / 1000,
        name: 'GKE Support',
        type: 'GeneralSupport',
      },
    ],
  },
  AKS: {
    // https://learn.microsoft.com/en-us/azure/aks/supported-kubernetes-versions?tabs=azure-cli#aks-kubernetes-release-calendar
    '1.25': [
      {
        end_date: new Date(2024, 0, 14).getTime() / 1000,
        name: 'AKS Standard Support',
        type: 'GeneralSupport',
      },
    ],
    '1.26': [
      {
        end_date: new Date(2024, 2, 31).getTime() / 1000,
        name: 'AKS Standard Support',
        type: 'GeneralSupport',
      },
    ],
    '1.27': [
      {
        end_date: new Date(2024, 6, 31).getTime() / 1000,
        name: 'AKS Standard Support',
        type: 'GeneralSupport',
      },
      {
        end_date: new Date(2025, 6, 31).getTime() / 1000,
        name: 'AKS Long Term Support',
        type: 'LongTermSupport',
      },
    ],
    '1.28': [
      {
        end_date: new Date(2024, 10, 30).getTime() / 1000,
        name: 'AKS Standard Support',
        type: 'GeneralSupport',
      },
    ],
    '1.29': [
      {
        end_date: new Date(2025, 2, 31).getTime() / 1000, // interpolated - exact date is not provided upstream
        name: 'AKS Standard Support',
        type: 'GeneralSupport',
      },
    ],
  },
  EKS: {
    // https://docs.aws.amazon.com/eks/latest/userguide/kubernetes-versions.html#kubernetes-release-calendar
    '1.23': [
      {
        end_date: new Date(2023, 9, 11).getTime() / 1000,
        name: 'EKS Standard Support',
        type: 'GeneralSupport',
      },
      {
        end_date: new Date(2024, 9, 11).getTime() / 1000,
        name: 'EKS Extended Support',
        type: 'LongTermSupport',
      },
    ],
    '1.24': [
      {
        end_date: new Date(2024, 0, 31).getTime() / 1000,
        name: 'EKS Standard Support',
        type: 'GeneralSupport',
      },
      {
        end_date: new Date(2025, 0, 31).getTime() / 1000,
        name: 'EKS Extended Support',
        type: 'LongTermSupport',
      },
    ],
    '1.25': [
      {
        end_date: new Date(2024, 4, 1).getTime() / 1000,
        name: 'EKS Standard Support',
        type: 'GeneralSupport',
      },
      {
        end_date: new Date(2025, 4, 1).getTime() / 1000,
        name: 'EKS Extended Support',
        type: 'LongTermSupport',
      },
    ],
    '1.26': [
      {
        end_date: new Date(2024, 5, 11).getTime() / 1000,
        name: 'EKS Standard Support',
        type: 'GeneralSupport',
      },
      {
        end_date: new Date(2025, 5, 11).getTime() / 1000,
        name: 'EKS Extended Support',
        type: 'LongTermSupport',
      },
    ],
    '1.27': [
      {
        end_date: new Date(2024, 6, 24).getTime() / 1000,
        name: 'EKS Standard Support',
        type: 'GeneralSupport',
      },
      {
        end_date: new Date(2025, 6, 24).getTime() / 1000,
        name: 'EKS Extended Support',
        type: 'LongTermSupport',
      },
    ],
    '1.28': [
      {
        end_date: new Date(2024, 10, 26).getTime() / 1000,
        name: 'EKS Standard Support',
        type: 'GeneralSupport',
      },
      {
        end_date: new Date(2025, 10, 26).getTime() / 1000,
        name: 'EKS Extended Support',
        type: 'LongTermSupport',
      },
    ],
    '1.29': [
      {
        end_date: new Date(2025, 2, 23).getTime() / 1000,
        name: 'EKS Standard Support',
        type: 'GeneralSupport',
      },
      {
        end_date: new Date(2026, 2, 23).getTime() / 1000,
        name: 'EKS Extended Support',
        type: 'LongTermSupport',
      },
    ],
  },
};

export const CLUSTERS: Cluster[] = [
  //region 1.23
  {
    id: 'id-eks-123-usw1-dev',
    internal_k8s_ref: '',
    account_id: '',
    status: 'active',
    node_count: 42,
    managed_ng_count: 1,
    self_managed_ng_count: 0,
    container_runtimes: [],
    labels: {},
    rescan: 'completed',
    chkk_metadata: {
      version: '',
      git_commit: '',
      build_date: '',
      config: {},
      namespace: '',
    },
    support_level: SUPPORT_LEVEL_MAP['EKS']['1.23'] as ClusterSupportLevel[],
    name: 'eks-123-usw1-dev',
    version: '1.23',
    environment: 'Dev',
    cloud_provider: 'EKS',
    region: 'us-west-1',
    // LastUpgrade: new Date(2022, 11, 15).getTime() / 1000,
    created: new Date(2022, 11, 15).getTime() / 1000,
    eol_date: new Date(2025, 0, 31).getTime() / 1000, // deprecated
    k8s_release_date: new Date().getTime() / 1000,
    k8s_provider_release: new Date().getTime() / 1000,
  },
  {
    id: 'id-eks-123-usw2-dev',
    internal_k8s_ref: '',
    account_id: '',
    status: 'active',
    node_count: 42,
    managed_ng_count: 1,
    self_managed_ng_count: 0,
    container_runtimes: [],
    labels: {},
    rescan: 'completed',
    chkk_metadata: {
      version: '',
      git_commit: '',
      build_date: '',
      config: {},
      namespace: '',
    },
    support_level: SUPPORT_LEVEL_MAP['EKS']['1.23'] as ClusterSupportLevel[],
    name: 'eks-123-usw2-dev',
    version: '1.23',
    environment: 'Dev',
    cloud_provider: 'EKS',
    region: 'us-west-2',
    // LastUpgrade: new Date(2022, 11, 15).getTime() / 1000,
    created: new Date(2022, 11, 15).getTime() / 1000,
    eol_date: new Date(2025, 0, 31).getTime() / 1000, // deprecated
    k8s_release_date: new Date().getTime() / 1000,
    k8s_provider_release: new Date().getTime() / 1000,
  },
  {
    id: 'id-eks-123-use1-dev',
    internal_k8s_ref: '',
    account_id: '',
    status: 'active',
    node_count: 42,
    managed_ng_count: 1,
    self_managed_ng_count: 0,
    container_runtimes: [],
    labels: {},
    rescan: 'completed',
    chkk_metadata: {
      version: '',
      git_commit: '',
      build_date: '',
      config: {},
      namespace: '',
    },
    support_level: SUPPORT_LEVEL_MAP['EKS']['1.23'] as ClusterSupportLevel[],
    name: 'eks-123-use1-dev',
    version: '1.23',
    environment: 'Dev',
    cloud_provider: 'EKS',
    region: 'us-east-1',
    // LastUpgrade: new Date(2022, 11, 15).getTime() / 1000,
    created: new Date(2022, 11, 15).getTime() / 1000,
    eol_date: new Date(2025, 0, 31).getTime() / 1000, // deprecated
    k8s_release_date: new Date().getTime() / 1000,
    k8s_provider_release: new Date().getTime() / 1000,
  },
  //endregion 1.23

  //region 1.24
  {
    id: 'id-gke-124-nane1-dev',
    internal_k8s_ref: '',
    account_id: '',
    status: 'active',
    node_count: 42,
    managed_ng_count: 1,
    self_managed_ng_count: 0,
    container_runtimes: [],
    labels: {},
    rescan: 'completed',
    chkk_metadata: {
      version: '',
      git_commit: '',
      build_date: '',
      config: {},
      namespace: '',
    },
    support_level: SUPPORT_LEVEL_MAP['GKE']['1.24'] as ClusterSupportLevel[],
    name: 'gke-124-nane1-dev',
    version: 'v1.24.4-eks-2d98532',
    environment: 'Dev',
    cloud_provider: 'GKE',
    region: 'northamerica-northeast1',
    created: new Date(2022, 11, 15).getTime() / 1000,
    // LastUpgrade: new Date(2022, 11, 15).getTime() / 1000,
    eol_date: new Date(2025, 0, 31).getTime() / 1000, // deprecated - use support levels
    k8s_release_date: new Date().getTime() / 1000,
    k8s_provider_release: new Date().getTime() / 1000,
  },
  {
    id: 'id-eks-124-usw1-prod',
    internal_k8s_ref: '',
    account_id: '',
    status: 'active',
    node_count: 42,
    managed_ng_count: 1,
    self_managed_ng_count: 0,
    container_runtimes: [],
    labels: {},
    rescan: 'completed',
    chkk_metadata: {
      version: '',
      git_commit: '',
      build_date: '',
      config: {},
      namespace: '',
    },
    support_level: SUPPORT_LEVEL_MAP['EKS']['1.24'] as ClusterSupportLevel[],
    name: 'eks-124-usw1-prod',
    version: 'v1.24.4-eks-2d98532',
    environment: 'Prod',
    cloud_provider: 'EKS',
    region: 'us-west-1',
    created: new Date(2022, 11, 15).getTime() / 1000,
    // LastUpgrade: new Date(2022, 11, 15).getTime() / 1000,
    eol_date: new Date(2025, 0, 31).getTime() / 1000, // deprecated - use support levels
    k8s_release_date: new Date().getTime() / 1000,
    k8s_provider_release: new Date().getTime() / 1000,
  },
  {
    id: 'id-eks-124-use1-prod',
    internal_k8s_ref: '',
    account_id: '',
    status: 'active',
    node_count: 42,
    managed_ng_count: 1,
    self_managed_ng_count: 0,
    container_runtimes: [],
    labels: {},
    rescan: 'completed',
    chkk_metadata: {
      version: '',
      git_commit: '',
      build_date: '',
      config: {},
      namespace: '',
    },
    support_level: SUPPORT_LEVEL_MAP['EKS']['1.24'] as ClusterSupportLevel[],
    name: 'eks-124-use1-prod',
    version: '1.24',
    environment: 'Prod',
    cloud_provider: 'EKS',
    region: 'us-east-1',
    // LastUpgrade: new Date(2022, 11, 15).getTime() / 1000,
    created: new Date(2022, 11, 15).getTime() / 1000,
    eol_date: new Date(2025, 0, 31).getTime() / 1000,
    k8s_release_date: new Date().getTime() / 1000,
    k8s_provider_release: new Date().getTime() / 1000,
  },
  //endregion 1.24

  //region 1.25
  {
    id: 'id-eks-125-euc1-staging',
    internal_k8s_ref: '',
    account_id: '',
    status: 'active',
    node_count: 52,
    managed_ng_count: 22,
    self_managed_ng_count: 0,
    container_runtimes: [],
    labels: {},
    rescan: 'completed',
    chkk_metadata: {
      version: '',
      git_commit: '',
      build_date: '',
      config: {},
      namespace: '',
    },
    support_level: SUPPORT_LEVEL_MAP['EKS']['1.25'] as ClusterSupportLevel[],
    name: 'eks-125-euc1-staging',
    version: '1.25',
    environment: 'Staging',
    cloud_provider: 'EKS',
    region: 'eu-central-1',
    // LastUpgrade: new Date(2022, 11, 15).getTime() / 1000,
    created: subDays(new Date(), 60).getTime() / 1000,
    eol_date: new Date(2024, 4, 31).getTime() / 1000,
    k8s_release_date: new Date().getTime() / 1000,
    k8s_provider_release: new Date().getTime() / 1000,
  },
  {
    id: 'id-eks-125-euw1-staging',
    internal_k8s_ref: '',
    account_id: '',
    status: 'active',
    node_count: 52,
    managed_ng_count: 22,
    self_managed_ng_count: 0,
    container_runtimes: [],
    labels: {},
    rescan: 'completed',
    chkk_metadata: {
      version: '',
      git_commit: '',
      build_date: '',
      config: {},
      namespace: '',
    },
    support_level: SUPPORT_LEVEL_MAP['EKS']['1.25'] as ClusterSupportLevel[],
    name: 'eks-125-euw1-staging',
    version: '1.25',
    environment: 'Staging',
    cloud_provider: 'EKS',
    region: 'eu-west-1',
    // LastUpgrade: new Date(2022, 11, 15).getTime() / 1000,
    created: subDays(new Date(), 40).getTime() / 1000,
    eol_date: new Date(2024, 4, 31).getTime() / 1000,
    k8s_release_date: new Date().getTime() / 1000,
    k8s_provider_release: new Date().getTime() / 1000,
  },
  {
    id: 'id-eks-125-euw2-staging',
    internal_k8s_ref: '',
    account_id: '',
    status: 'active',
    node_count: 52,
    managed_ng_count: 22,
    self_managed_ng_count: 0,
    container_runtimes: [],
    labels: {},
    rescan: 'completed',
    chkk_metadata: {
      version: '',
      git_commit: '',
      build_date: '',
      config: {},
      namespace: '',
    },
    support_level: SUPPORT_LEVEL_MAP['EKS']['1.25'] as ClusterSupportLevel[],
    name: 'eks-125-euw2-staging',
    version: '1.25',
    environment: 'Staging',
    cloud_provider: 'EKS',
    region: 'eu-west-2',
    created: subDays(new Date(), 15).getTime() / 1000,
    eol_date: new Date(2024, 4, 31).getTime() / 1000,
    k8s_release_date: new Date().getTime() / 1000,
    k8s_provider_release: new Date().getTime() / 1000,
  },
  {
    id: 'id-aks-125-wus1-dev',
    internal_k8s_ref: '',
    account_id: '',
    status: 'active',
    node_count: 42,
    managed_ng_count: 1,
    self_managed_ng_count: 0,
    container_runtimes: [],
    labels: {},
    rescan: 'completed',
    chkk_metadata: {
      version: '',
      git_commit: '',
      build_date: '',
      config: {},
      namespace: '',
    },
    support_level: SUPPORT_LEVEL_MAP['AKS']['1.25'] as ClusterSupportLevel[],
    name: 'aks-125-wus1-dev',
    version: '1.25',
    environment: 'Dev',
    cloud_provider: 'AKS',
    region: 'westus1',
    created: subDays(new Date(), 15).getTime() / 1000,
    eol_date: new Date(2023, 5, 4).getTime() / 1000,
    k8s_release_date: new Date().getTime() / 1000,
    k8s_provider_release: new Date().getTime() / 1000,
  },
  {
    id: 'id-aks-125-wus3-dev',
    internal_k8s_ref: '',
    account_id: '',
    status: 'active',
    node_count: 42,
    managed_ng_count: 1,
    self_managed_ng_count: 0,
    container_runtimes: [],
    labels: {},
    rescan: 'completed',
    chkk_metadata: {
      version: '',
      git_commit: '',
      build_date: '',
      config: {},
      namespace: '',
    },
    support_level: SUPPORT_LEVEL_MAP['AKS']['1.25'] as ClusterSupportLevel[],
    name: 'aks-125-wus3-dev',
    version: '1.25',
    environment: 'Dev',
    cloud_provider: 'AKS',
    region: 'westus3',
    created: subDays(new Date(), 40).getTime() / 1000,
    eol_date: new Date(2023, 5, 4).getTime() / 1000,
    k8s_release_date: new Date().getTime() / 1000,
    k8s_provider_release: new Date().getTime() / 1000,
  },
  //endregion

  //region 1.26
  {
    id: 'id-eks-126-caw1-staging',
    internal_k8s_ref: '',
    account_id: '',
    status: 'active',
    node_count: 52,
    managed_ng_count: 22,
    self_managed_ng_count: 0,
    container_runtimes: [],
    labels: {},
    rescan: 'completed',
    chkk_metadata: {
      version: '',
      git_commit: '',
      build_date: '',
      config: {},
      namespace: '',
    },
    support_level: SUPPORT_LEVEL_MAP['EKS']['1.26'] as ClusterSupportLevel[],
    name: 'eks-126-caw1-staging',
    version: '1.26',
    environment: 'Staging',
    cloud_provider: 'EKS',
    region: 'ca-west-1',
    // LastUpgrade: new Date(2022, 11, 15).getTime() / 1000,
    created: new Date(2022, 11, 15).getTime() / 1000,
    eol_date: new Date(2024, 5, 31).getTime() / 1000,
    k8s_release_date: new Date().getTime() / 1000,
    k8s_provider_release: new Date().getTime() / 1000,
  },
  {
    id: 'id-aks-126-eu2-staging',
    internal_k8s_ref: '',
    account_id: '',
    status: 'active',
    node_count: 52,
    managed_ng_count: 22,
    self_managed_ng_count: 0,
    container_runtimes: [],
    labels: {},
    rescan: 'completed',
    chkk_metadata: {
      version: '',
      git_commit: '',
      build_date: '',
      config: {},
      namespace: '',
    },
    support_level: SUPPORT_LEVEL_MAP['AKS']['1.26'] as ClusterSupportLevel[],
    name: 'aks-126-eus2-staging',
    version: '1.26',
    environment: 'Staging',
    cloud_provider: 'AKS',
    region: 'eastus2',
    // LastUpgrade: new Date(2022, 11, 15).getTime() / 1000,
    created: new Date(2023, 4, 15).getTime() / 1000,
    eol_date: new Date(2024, 5, 31).getTime() / 1000, // deprecated - use support levels
    k8s_release_date: new Date().getTime() / 1000,
    k8s_provider_release: new Date().getTime() / 1000,
  },
  //endregion

  //region 1.27
  {
    id: 'id-eks-127-use1-prod',
    internal_k8s_ref: '',
    account_id: '',
    status: 'active',
    node_count: 42,
    managed_ng_count: 1,
    self_managed_ng_count: 0,
    container_runtimes: [],
    labels: {},
    rescan: 'completed',
    chkk_metadata: {
      version: '',
      git_commit: '',
      build_date: '',
      config: {},
      namespace: '',
    },
    support_level: SUPPORT_LEVEL_MAP['EKS']['1.27'] as ClusterSupportLevel[],
    name: 'eks-127-use1-prod',
    version: 'v1.27.4-eks-2d98532',
    environment: 'Prod',
    cloud_provider: 'EKS',
    region: 'us-east-1',
    // LastUpgrade: new Date(2022, 11, 15).getTime() / 1000,
    created: new Date(2023, 11, 15).getTime() / 1000,
    eol_date: new Date(2024, 9, 11).getTime() / 1000,
    k8s_release_date: new Date().getTime() / 1000,
    k8s_provider_release: new Date().getTime() / 1000,
  },
  {
    id: 'id-eks-127-use2-prod',
    internal_k8s_ref: '',
    account_id: '',
    status: 'active',
    node_count: 42,
    managed_ng_count: 1,
    self_managed_ng_count: 0,
    container_runtimes: [],
    labels: {},
    rescan: 'completed',
    chkk_metadata: {
      version: '',
      git_commit: '',
      build_date: '',
      config: {},
      namespace: '',
    },
    support_level: SUPPORT_LEVEL_MAP['EKS']['1.27'] as ClusterSupportLevel[],
    name: 'eks-127-use2-prod',
    version: 'v1.27.4-eks-2d98532',
    environment: 'Prod',
    cloud_provider: 'EKS',
    region: 'us-east-2',
    // LastUpgrade: new Date(2022, 11, 15).getTime() / 1000,
    created: new Date(2023, 11, 15).getTime() / 1000,
    eol_date: new Date(2024, 9, 11).getTime() / 1000,
    k8s_release_date: new Date().getTime() / 1000,
    k8s_provider_release: new Date().getTime() / 1000,
  },
  {
    id: 'id-eks-127-usw1-prod',
    internal_k8s_ref: '',
    account_id: '',
    status: 'active',
    node_count: 42,
    managed_ng_count: 1,
    self_managed_ng_count: 0,
    container_runtimes: [],
    labels: {},
    rescan: 'completed',
    chkk_metadata: {
      version: '',
      git_commit: '',
      build_date: '',
      config: {},
      namespace: '',
    },
    support_level: SUPPORT_LEVEL_MAP['EKS']['1.27'] as ClusterSupportLevel[],
    name: 'eks-127-usw1-prod',
    version: 'v1.27.4-eks-2d98532',
    environment: 'Prod',
    cloud_provider: 'EKS',
    region: 'us-west-1',
    // LastUpgrade: new Date(2022, 11, 15).getTime() / 1000,
    created: new Date(2023, 11, 15).getTime() / 1000,
    eol_date: new Date(2024, 9, 11).getTime() / 1000,
    k8s_release_date: new Date().getTime() / 1000,
    k8s_provider_release: new Date().getTime() / 1000,
  },
  {
    id: 'id-eks-127-usw2-prod',
    internal_k8s_ref: '',
    account_id: '',
    status: 'active',
    node_count: 42,
    managed_ng_count: 1,
    self_managed_ng_count: 0,
    container_runtimes: [],
    labels: {},
    rescan: 'completed',
    chkk_metadata: {
      version: '',
      git_commit: '',
      build_date: '',
      config: {},
      namespace: '',
    },
    support_level: SUPPORT_LEVEL_MAP['EKS']['1.27'] as ClusterSupportLevel[],
    name: 'eks-127-usw2-prod',
    version: 'v1.27.4-eks-2d98532',
    environment: 'Prod',
    cloud_provider: 'EKS',
    region: 'us-west-1',
    // LastUpgrade: new Date(2022, 11, 15).getTime() / 1000,
    created: new Date(2023, 11, 15).getTime() / 1000,
    eol_date: new Date(2024, 9, 11).getTime() / 1000,
    k8s_release_date: new Date().getTime() / 1000,
    k8s_provider_release: new Date().getTime() / 1000,
  },
  {
    id: 'id-gke-127-usw4-dev',
    internal_k8s_ref: '',
    account_id: '',
    status: 'active',
    node_count: 52,
    managed_ng_count: 22,
    self_managed_ng_count: 0,
    container_runtimes: [],
    labels: {},
    rescan: 'completed',
    chkk_metadata: {
      version: '',
      git_commit: '',
      build_date: '',
      config: {},
      namespace: '',
    },
    support_level: SUPPORT_LEVEL_MAP['GKE']['1.27'] as ClusterSupportLevel[],
    name: 'gke-127-usw4-dev',
    version: '1.27',
    environment: 'Dev',
    cloud_provider: 'GKE',
    region: 'us-west4',
    // LastUpgrade: new Date(2022, 11, 15).getTime() / 1000,
    created: new Date(2023, 11, 25).getTime() / 1000,
    eol_date: new Date(2024, 6, 31).getTime() / 1000, // deprecated - use support levels
    k8s_release_date: new Date().getTime() / 1000,
    k8s_provider_release: new Date().getTime() / 1000,
  },
  //endregion

  //region 1.28
  {
    id: 'id-aks-128-wus1-dev',
    internal_k8s_ref: '',
    account_id: '',
    status: 'active',
    node_count: 42,
    managed_ng_count: 1,
    self_managed_ng_count: 0,
    container_runtimes: [],
    labels: {},
    rescan: 'completed',
    chkk_metadata: {
      version: '',
      git_commit: '',
      build_date: '',
      config: {},
      namespace: '',
    },
    support_level: SUPPORT_LEVEL_MAP['AKS']['1.28'] as ClusterSupportLevel[],
    name: 'aks-128-wus1-dev',
    version: '1.28',
    environment: 'Dev',
    cloud_provider: 'AKS',
    region: 'westus1',
    // LastUpgrade: new Date(2022, 5, 6).getTime() / 1000,
    created: new Date(2024, 1, 6).getTime() / 1000,
    eol_date: new Date(2023, 5, 4).getTime() / 1000,
    k8s_release_date: new Date().getTime() / 1000,
    k8s_provider_release: new Date().getTime() / 1000,
  },
  {
    id: 'id-gke-128-uss1-lab',
    internal_k8s_ref: '',
    account_id: '',
    status: 'active',
    node_count: 42,
    managed_ng_count: 1,
    self_managed_ng_count: 0,
    container_runtimes: [],
    labels: {},
    rescan: 'completed',
    chkk_metadata: {
      version: '',
      git_commit: '',
      build_date: '',
      config: {},
      namespace: '',
    },
    support_level: SUPPORT_LEVEL_MAP['GKE']['1.28'] as ClusterSupportLevel[],
    name: 'gke-128-uss1-lab',
    version: '1.28',
    environment: 'Lab',
    cloud_provider: 'GKE',
    region: 'us-south1',
    // LastUpgrade: new Date(2021, 8, 8).getTime() / 1000,
    created: new Date(2024, 1, 4).getTime() / 1000,
    eol_date: new Date(2022, 7, 31).getTime() / 1000, // deprecate - use support levels
    k8s_release_date: new Date().getTime() / 1000,
    k8s_provider_release: new Date().getTime() / 1000,
  },
  {
    id: 'id-gke-128-use4-sandbox',
    internal_k8s_ref: '',
    account_id: '',
    status: 'active',
    node_count: 42,
    managed_ng_count: 1,
    self_managed_ng_count: 0,
    container_runtimes: [],
    labels: {},
    rescan: 'completed',
    chkk_metadata: {
      version: '',
      git_commit: '',
      build_date: '',
      config: {},
      namespace: '',
    },
    support_level: SUPPORT_LEVEL_MAP['GKE']['1.28'] as ClusterSupportLevel[],
    name: 'gke-128-use4-sandbox',
    version: '1.28',
    environment: 'Sandbox',
    cloud_provider: 'GKE',
    region: 'us-east4',
    // LastUpgrade: new Date(2021, 8, 8).getTime() / 1000,
    created: new Date(2024, 1, 4).getTime() / 1000,
    eol_date: new Date(2022, 7, 31).getTime() / 1000, // deprecate - use support levels
    k8s_release_date: new Date().getTime() / 1000,
    k8s_provider_release: new Date().getTime() / 1000,
  },
  {
    id: 'id-gke-128-use5-sandbox',
    internal_k8s_ref: '',
    account_id: '',
    status: 'active',
    node_count: 42,
    managed_ng_count: 1,
    self_managed_ng_count: 0,
    container_runtimes: [],
    labels: {},
    rescan: 'completed',
    chkk_metadata: {
      version: '',
      git_commit: '',
      build_date: '',
      config: {},
      namespace: '',
    },
    support_level: SUPPORT_LEVEL_MAP['GKE']['1.28'] as ClusterSupportLevel[],
    name: 'gke-128-use5-sandbox',
    version: '1.28',
    environment: 'Sandbox',
    cloud_provider: 'GKE',
    region: 'us-east5',
    // LastUpgrade: new Date(2021, 8, 8).getTime() / 1000,
    created: new Date(2024, 1, 4).getTime() / 1000,
    eol_date: new Date(2022, 7, 31).getTime() / 1000, // deprecate - use support levels
    k8s_release_date: new Date().getTime() / 1000,
    k8s_provider_release: new Date().getTime() / 1000,
  },
  {
    id: 'id-gke-128-nane2-sandbox',
    internal_k8s_ref: '',
    account_id: '',
    status: 'active',
    node_count: 42,
    managed_ng_count: 1,
    self_managed_ng_count: 0,
    container_runtimes: [],
    labels: {},
    rescan: 'completed',
    chkk_metadata: {
      version: '',
      git_commit: '',
      build_date: '',
      config: {},
      namespace: '',
    },
    support_level: SUPPORT_LEVEL_MAP['GKE']['1.28'] as ClusterSupportLevel[],
    name: 'gke-128-nane2-sandbox',
    version: '1.28',
    environment: 'Sandbox',
    cloud_provider: 'GKE',
    region: 'northamerica-northeast2',
    // LastUpgrade: new Date(2021, 8, 8).getTime() / 1000,
    created: subDays(new Date(), 60).getTime() / 1000,
    eol_date: new Date(2022, 7, 31).getTime() / 1000, // deprecate - use support levels
    k8s_release_date: new Date().getTime() / 1000,
    k8s_provider_release: new Date().getTime() / 1000,
  },
  {
    id: 'id-gke-128-nane1-sandbox',
    internal_k8s_ref: '',
    account_id: '',
    status: 'active',
    node_count: 42,
    managed_ng_count: 1,
    self_managed_ng_count: 0,
    container_runtimes: [],
    labels: {},
    rescan: 'completed',
    chkk_metadata: {
      version: '',
      git_commit: '',
      build_date: '',
      config: {},
      namespace: '',
    },
    support_level: SUPPORT_LEVEL_MAP['GKE']['1.28'] as ClusterSupportLevel[],
    name: 'gke-128-nane1-sandbox',
    version: '1.28',
    environment: 'Sandbox',
    cloud_provider: 'GKE',
    region: 'northamerica-northeast1',
    // LastUpgrade: new Date(2021, 8, 8).getTime() / 1000,
    created: subDays(new Date(), 60).getTime() / 1000,
    eol_date: new Date(2022, 7, 31).getTime() / 1000, // deprecate - use support levels
    k8s_release_date: new Date().getTime() / 1000,
    k8s_provider_release: new Date().getTime() / 1000,
  },
  //endregion

  //region 1.29
  {
    id: 'id-eks-129-euw2-dev1',
    internal_k8s_ref: '',
    account_id: '',
    status: 'active',
    node_count: 42,
    managed_ng_count: 1,
    self_managed_ng_count: 0,
    container_runtimes: [],
    labels: {},
    rescan: 'completed',
    chkk_metadata: {
      version: '',
      git_commit: '',
      build_date: '',
      config: {},
      namespace: '',
    },
    support_level: SUPPORT_LEVEL_MAP['EKS']['1.29'] as ClusterSupportLevel[],
    name: 'eks-129-euw2-dev1',
    version: '1.29',
    environment: 'Dev',
    cloud_provider: 'EKS',
    region: 'eu-west-2',
    // LastUpgrade: new Date(2021, 8, 8).getTime() / 1000,
    created: new Date(2024, 1, 8).getTime() / 1000,
    eol_date: new Date(2022, 10, 1).getTime() / 1000, // deprecated - use support levels
    k8s_release_date: new Date().getTime() / 1000,
    k8s_provider_release: new Date().getTime() / 1000,
  },
  {
    id: 'id-eks-129-euw2-dev2',
    internal_k8s_ref: '',
    account_id: '',
    status: 'active',
    node_count: 42,
    managed_ng_count: 1,
    self_managed_ng_count: 0,
    container_runtimes: [],
    labels: {},
    rescan: 'completed',
    chkk_metadata: {
      version: '',
      git_commit: '',
      build_date: '',
      config: {},
      namespace: '',
    },
    support_level: SUPPORT_LEVEL_MAP['EKS']['1.29'] as ClusterSupportLevel[],
    name: 'eks-129-euw2-dev2',
    version: '1.29',
    environment: 'Dev',
    cloud_provider: 'EKS',
    region: 'eu-west-2',
    // LastUpgrade: new Date(2021, 8, 8).getTime() / 1000,
    created: new Date(2024, 1, 8).getTime() / 1000,
    eol_date: new Date(2022, 10, 1).getTime() / 1000, // deprecated - use support levels
    k8s_release_date: new Date().getTime() / 1000,
    k8s_provider_release: new Date().getTime() / 1000,
  },
  //endregion
];
