import styles from './Feedback.module.scss';
import { Stack, styled, Typography } from '@mui/material';
import Page from 'components/Page';
import { useSnackbar } from 'notistack';
import useUserAccountState from 'hooks/useUserAccountState';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { SubmitFeedbackRequest } from 'api/models';
import { submitFeedback } from 'api/frontend';
import FormProvider from 'components/FormProvider';
import { TextField } from 'subframe/index';
import { TextArea } from 'subframe/components/TextArea';
import { Button } from 'subframe/components/Button';
import React from 'react';
import { toastAutoHideDuration } from 'constants/toasts';
import * as Sentry from '@sentry/browser';

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

interface FeedbackFields {
  title: string;
  email: string;
  details: string;
}

const Feedback = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { account, user } = useUserAccountState();
  const { logEvent } = AnalyticsEventLogger();

  const FeedbackSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    email: Yup.string().email('Email is invalid').required('Email is required'),
    details: Yup.string(),
  });

  const defaultValues: FeedbackFields = {
    title: '',
    email: user?.email || '',
    details: '',
  };

  const methods = useForm<FeedbackFields>({
    resolver: yupResolver(FeedbackSchema),
    defaultValues: defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;

  const onSubmit = async (data: FeedbackFields) => {
    try {
      const reqBody: SubmitFeedbackRequest = {
        title: data.title,
        details: data.details,
        user_email: data.email,
      };
      await submitFeedback(reqBody, {
        headers: {
          Authorization: `Bearer ${account.token}`,
        },
      });
      logEvent('feedback-submitted');
      enqueueSnackbar('Feedback submitted successfully', {
        variant: 'success',
        autoHideDuration: toastAutoHideDuration,
      });
    } catch (error) {
      enqueueSnackbar(
        'Something went wrong while submitting your feedback. Please try again or escalate to ',
        {
          variant: 'error',
          autoHideDuration: toastAutoHideDuration,
        },
      );
      Sentry.captureException(error, { level: 'log' });
    }
  };

  return (
    <Page title="Feedback">
      <RootStyle>
        <Stack width="600px" maxWidth="xl">
          <Typography variant="h3" sx={{ textAlign: 'center' }}>
            Have some feedback?
          </Typography>

          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles['stack-b4d279b8']}>
              <TextField
                id={'title-field-label'}
                className="h-auto w-full flex-none"
                label="Title"
                error={methods.getFieldState('title').invalid}
                helpText={errors?.title?.message}
              >
                <TextField.Input
                  id={'title-field'}
                  placeholder="Feedback topic"
                  {...methods.register('title')}
                />
              </TextField>
              <TextField
                id={'email-field-label'}
                className="h-auto w-full flex-none"
                label="Email"
                helpText={errors?.email?.message}
                error={methods.getFieldState('email').invalid}
              >
                <TextField.Input
                  id={'email-field'}
                  placeholder="someone@domain.com"
                  {...methods.register('email')}
                />
              </TextField>
              <TextArea
                className="h-auto w-full flex-none"
                error={false}
                label="Details"
              >
                <TextArea.Input
                  className="h-24 w-full flex-none"
                  {...methods.register('details')}
                />
              </TextArea>
              <div className={styles['stack-f125ebff']}>
                <div className={styles['stack']}>
                  <Button
                    size="large"
                    variant="brand-primary"
                    icon={null}
                    loading={isSubmitting}
                    onClick={handleSubmit(onSubmit)}
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </FormProvider>
        </Stack>
      </RootStyle>
    </Page>
  );
};

export default Feedback;
