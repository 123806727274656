import { ProductPlanContactUsButton } from 'components/ProductPlanContactUsButton';
import { Accordion } from 'subframe/components/Accordion';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';

export const FAQs = () => {
  const { logEvent } = AnalyticsEventLogger();

  const faqs = [
    {
      heading: 'What is the definition of a node?',
      paragraph:
        'A node is a physical or virtual server/machine. We don’t charge you per-node. We only charge in slabs of 100 nodes (1-100, 101-200, …)',
    },
    {
      heading: `What's your pricing model if I'm using a serverless Kubernetes cluster–e.g. EKS Fargate or GKE Autopilot?`,
      paragraph:
        'For serverless Kubernetes clusters, we establish equivalence based on number of pods and/or vCPUs. You will always have full visibility on how we do the equivalence calculation.',
    },
    {
      heading: 'What happens if my nodes autoscale?',
      paragraph:
        'You are not charged for infrequent bursts of autoscaled nodes as Chkk uses the average number of nodes in every monthly measurement cycle.\n' +
        'We compute this average node count based on a monthly measurement cycle that spans from the first day to the last day of every month. To compute average node count across the fleet, at the end of each measurement cycle, we take the sum of all node count measurements taken across all the clusters and divide it by the total number of measurements.',
    },
    {
      heading: 'What if I exceed the nodes included in my subscription?',
      paragraph:
        'Chkk has a true-up forward policy, meaning that we don’t carry out chargebacks for scenarios where the consumption in a monthly measurement cycle exceeds the licensed count. If the new, steady-state number of nodes is expected to be higher, our customer success team will discuss the situation with you, and take steps to adjust your subscription for the next measurement cycles.',
    },
    {
      heading: 'Can I purchase through my cloud provider’s Marketplace?',
      paragraph: (
        <span>
          Yes, currently you can purchase Chkk through the{' '}
          <a
            href="https://aws.amazon.com/marketplace/pp/prodview-47iz6ivkaryxk?sr=0-2&ref_=beagle&applicationId=AWSMPContessa"
            target="_blank"
            rel="noopener noreferrer"
            className="hoverable-link text-body"
          >
            AWS Marketplace
          </a>
          .
        </span>
      ),
    },
  ];

  // FAQS new version
  faqs.push({
    heading: 'Do you offer monthly or quarterly payment options?',
    paragraph: (
      <span>
        No, we only offer annual payment plans as some of the product features
        (e.g. Upgrade Templates) cannot be offered on a monthly horizon.{' '}
        <ProductPlanContactUsButton
          link
          plan="MonthlyOrQuarterlyPaymentRequest"
        />{' '}
        if you have special requirements or specific questions.
      </span>
    ),
  });
  faqs.push({
    heading: 'Do you offer volume discounts?',
    paragraph: (
      <div>
        Yes, we offer volume discounts as the number of nodes and Upgrade
        Templates increases. <ProductPlanContactUsButton link /> to discuss your
        use case.
      </div>
    ),
  });

  return (
    <div className="contents">
      <div className="flex-[1_0_0] h-full w-full flex-col gap-[24px] items-start flex">
        <div className="flex-col gap-[24px] items-start flex">
          <div className="flex-col gap-[8px] items-start flex">
            <span className="text-default-font text-subheader">FAQs</span>
            <span className="text-subtext-color text-body">
              Chkk Kubernetes Availability Platform is priced on slabs of 100
              nodes (1-100, 101-200, …) and slabs of Upgrade Templates (1-3,
              4-6, …). Chkk product plans can be configured to meet the needs of
              enterprises, startups and service providers.
            </span>
          </div>
          <div className="flex-[1_0_0] h-full w-full flex-col gap-[8px] items-start flex">
            {faqs.map((faq, idx) => {
              return (
                <Accordion
                  key={'faq-' + idx}
                  trigger={
                    <div className="w-full gap-[8px] px-[12px] py-[8px] items-center flex">
                      <span className="flex-[1_0_0] w-full text-body-bold text-default-font">
                        {faq.heading}{' '}
                      </span>
                      <Accordion.Chevron />
                    </div>
                  }
                  onClick={() => {
                    logEvent('faq-click', { faq: faq.heading });
                  }}
                >
                  <div className="flex-[1_0_0] h-full w-full flex-col gap-[8px] px-[12px] py-[8px] items-start flex">
                    <span className="text-default-font text-body">
                      {faq.paragraph}
                    </span>
                  </div>
                </Accordion>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
