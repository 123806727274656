import { Cluster } from 'api/models';
import isExampleCluster from './isExampleCluster';

export const getTimeDiff = (timestamp: number) => {
  // calculate time difference between now and last timestamp
  let dateNow = new Date(Date.now());
  let lastScanDate = new Date(timestamp * 1000);
  let diff = dateNow.getTime() - lastScanDate.getTime();
  return diff / (1000 * 3600);
};

export const isClusterInactive = (
  timeDiff: number,
  cluster: Cluster,
): boolean => {
  return timeDiff > 48 && !isExampleCluster(cluster);
};
