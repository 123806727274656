import { Box, Container, Grid, styled, Typography } from '@mui/material';
import Page from 'components/Page';
import Logo from 'components/Logo';

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: theme.spacing(12),
  paddingBottom: theme.spacing(10),
}));
const BoxStyle = {
  width: '120px',
  height: '120px',
  backgroundColor: '#F4F6F8',
  borderRadius: '15px',
  marginBottom: 4,
};
const TextStyle = {
  textAlign: 'center',
  fontSize: '24px',
  fontWeight: 'bold',
};
const UnsupportedDevice = () => {
  return (
    <Page title="Unsupported Device" sx={{ height: 1 }}>
      <RootStyle>
        <Container>
          <Grid container item xs={12}>
            <Grid item xs={4} sx={{ marginLeft: -5, marginRight: 5 }}>
              <Box sx={BoxStyle}></Box>
              <Box sx={BoxStyle}></Box>
            </Grid>
            <Grid item xs={4} sx={{ marginLeft: -3, marginRight: 2 }}>
              <Box sx={BoxStyle}></Box>
              <Box sx={BoxStyle} style={{ backgroundColor: '#FFAB00' }}>
                <Logo
                  style={{
                    position: 'absolute',
                    width: 50,
                    height: 50,
                    marginTop: '35px',
                    marginLeft: '35px',
                  }}
                  showText={false}
                ></Logo>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box sx={BoxStyle}></Box>
              <Box sx={BoxStyle}></Box>
            </Grid>
            <Grid item xs={12}>
              <br />
              <Typography variant="h3" sx={TextStyle}>
                This device is not supported
              </Typography>
              <br />
              <Typography variant="h3" sx={TextStyle}>
                Login from
              </Typography>
              <Typography variant="h3" sx={TextStyle}>
                <span style={{ color: '#FFAB00' }}>Desktop</span> or{' '}
                <span style={{ color: '#FFAB00' }}>Laptop</span>
              </Typography>
              <br />
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box
                  style={{
                    width: '150px',
                    borderBottom: '5px #F4F6F8 solid',
                    borderRadius: '5px',
                  }}
                ></Box>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </RootStyle>
    </Page>
  );
};

export default UnsupportedDevice;
