import { useState } from 'react';
import { IconButton } from 'subframe/components/IconButton';
import { AtomicTooltip } from 'components/design-system';
import { InviteTeamMemberDialog } from 'components/dialogs/InviteTeamMemberDialog';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { Button } from 'subframe/index';

export function ReinviteMemberDialog({
  email,
  refetchPendingMembers,
}: {
  email: string;
  refetchPendingMembers: VoidFunction;
}): JSX.Element {
  const [open, setOpen] = useState(false);
  const { logEvent } = AnalyticsEventLogger();

  const handleOpenChange = (open = false) => {
    setOpen(open);
    logEvent('resend-invite', { email });
    refetchPendingMembers();
  };

  return (
    <div
      style={{
        display: 'contents',
      }}
    >
      <Button
        variant="brand-secondary"
        icon="FeatherPlus"
        iconRight={null}
        size="medium"
        onContextMenu={(event) => {
          event.preventDefault();
        }}
        onClick={() => handleOpenChange(true)}
        data-cy="reinvite-member-button"
      >
        Resend Invite
      </Button>
      <InviteTeamMemberDialog
        handleOpenChange={(openStatus) => handleOpenChange(openStatus)}
        open={open}
        memberEmail={email}
      />
    </div>
  );
}
