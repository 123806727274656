import { Cluster } from 'api/models';
import { ClusterFilters } from '../models';

export const getRealAndExampleClusters = (
  clustersList: Cluster[] | null,
  isLoadingClustersList: boolean,
): { realClusterData: Cluster[]; exampleClusterData: Cluster[] } => {
  return clustersList && !isLoadingClustersList
    ? clustersList.reduce<{
        realClusterData: Cluster[];
        exampleClusterData: Cluster[];
      }>(
        (acc, cluster) => {
          if (cluster.internal_k8s_ref !== 'example-cluster-ref') {
            acc.realClusterData.push(cluster);
          } else {
            acc.exampleClusterData.push(cluster);
          }
          return acc;
        },
        { realClusterData: [], exampleClusterData: [] },
      ) // Ensuring the initial value has the correct type
    : { realClusterData: [], exampleClusterData: [] };
};

export function objectToKeyValueArray(obj: ClusterFilters): string[] {
  if (!obj) return [];
  return Object.entries(obj)
    .map(([key, values]) =>
      values.length ? `${key}:${values.join(',')}` : null,
    )
    .filter((entry): entry is string => entry !== null);
}
