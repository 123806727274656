import { UpgradeStageStepStatusDetailsReason } from 'api/models';

export function getSkipReasonHuman(
  reason: UpgradeStageStepStatusDetailsReason,
): string {
  switch (reason) {
    case 'OwnedByAnotherTeam':
      return 'Owned by another team';
    case 'IncorrectRecommendation':
      return 'Incorrect recommendation';
    case 'WillUpgradeLater':
      return 'Will upgrade later';
    case 'Other':
      return 'Other';
  }
}
