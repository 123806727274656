import React, { useEffect, useState } from 'react';

import { updateIngestionToken } from 'api/frontend';
import { IngestionToken, UpdateIngestionTokenRequest } from 'api/models';

import { useSnackbar } from 'notistack';
import useUserAccountState from 'hooks/useUserAccountState';

import { Dialog, IconButton, Button, Alert } from 'subframe/index';

import * as Sentry from '@sentry/browser';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { somethingWentWrong, toastAutoHideDuration } from 'constants/toasts';
import { differenceInSeconds, fromUnixTime } from 'date-fns';

interface RevokeTokenProps {
  open?: boolean;
  token: IngestionToken;
  onClose: () => void;
  callRefetch: VoidFunction;
}

// Check if token was last used in 24 hours
function checkTokenLastUsed(lastUsed: number) {
  return differenceInSeconds(new Date(), fromUnixTime(lastUsed)) < 86400;
}

export default function RevokeTokenDialog({
  open,
  onClose,
  token,
  callRefetch,
}: RevokeTokenProps) {
  const { logEvent } = AnalyticsEventLogger();
  const { enqueueSnackbar } = useSnackbar();
  const { account, currentOrganization } = useUserAccountState();
  const [loading, setLoading] = useState(false);
  const RevokeTokenSuccess = 'Token revoked successfully';
  const RevokeTokenAWSRequestError = somethingWentWrong.replace(
    '<action>',
    'revoking the token',
  );

  // Revoke Handler
  const revokeTokenHandler = async function (
    updateRequest: UpdateIngestionTokenRequest,
  ) {
    setLoading(true);
    logEvent('revoke-token');
    try {
      const revokedToken = await updateIngestionToken(
        'default',
        token.token_id,
        updateRequest,
        {
          headers: { Authorization: `Bearer ${account?.token}` },
        },
      );
      logEvent('token_revoked', {
        token_id: token.token_id,
        status: revokedToken.__status,
      });
      enqueueSnackbar(RevokeTokenSuccess, {
        variant: 'success',
        autoHideDuration: toastAutoHideDuration,
      });
      callRefetch();
    } catch ({ response }) {
      enqueueSnackbar(RevokeTokenAWSRequestError, {
        variant: 'error',
        autoHideDuration: toastAutoHideDuration,
      });
      Sentry.captureException(response?.data || RevokeTokenAWSRequestError, {
        level: 'error',
      });
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <div className="contents">
      <Dialog open={open} onOpenChange={onClose}>
        <Dialog.Content className="h-auto w-96 flex-none">
          <div className="flex w-full flex-col items-start gap-6 pt-6 pr-6 pb-6 pl-6">
            <div className="flex w-full items-start justify-between">
              <span className="text-subheader font-subheader text-default-font">
                Revoke Token?
              </span>
              <IconButton size="medium" icon="FeatherX" onClick={onClose} />
            </div>
            <div className="flex flex-col items-start gap-4">
              {checkTokenLastUsed(token.last_used || 0) && (
                <Alert
                  variant="warning"
                  title="Token is still being used."
                  description="Revoking a token will make it unusable for all future
                operations."
                />
              )}
              <span className="text-body font-body text-default-font">
                Are you sure you want to revoke &quot;
                {token.token_name ||
                  `token-${currentOrganization.name.toLowerCase()}`}
                &quot; token?
              </span>
              <span className="text-body font-body text-default-font">
                After revocation of this token, you cannot initiate cluster
                scans, onboard new clusters, or perform any other cluster
                management operation using this token.
              </span>
              <span className="text-body font-body text-default-font">
                To proceed, please ensure at least one token is available. If
                not, you should create a new token first.
              </span>
              <div className="flex w-full items-end justify-end gap-4">
                <Button
                  variant="neutral-tertiary"
                  size="large"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="destructive-primary"
                  size="large"
                  icon="FeatherX"
                  onClick={() => revokeTokenHandler({ revoked: true })}
                  disabled={loading}
                  loading={loading}
                >
                  Revoke Token
                </Button>
              </div>
            </div>
          </div>
        </Dialog.Content>
      </Dialog>
    </div>
  );
}
