export interface ICluster {
  cloud_provider: string;
  created: number;
  id: string;
  internal_k8s_ref: string;
  name: string;
  updated: number;
  version: string;
}

export enum EnumEolState {
  Default = 'default',
  Warning = 'warning',
  Error = 'error',
}
