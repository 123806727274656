'use client';

import * as Sentry from '@sentry/browser';
import { useEffect, useState } from 'react';
import { submitFeedback } from 'api/frontend';
import { IconButton } from 'subframe/components/IconButton';
import { TextField } from 'subframe/components/TextField';
import { Button } from 'subframe/components/Button';
import { Dialog } from 'subframe/components/Dialog';
import { CardCtaIntegration } from 'subframe/components/CardCtaIntegration';
import useUserAccountState from 'hooks/useUserAccountState';
import { useSnackbar } from 'notistack';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { Text } from '@subframe/core';
import { toastAutoHideDuration } from 'constants/toasts';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import FormProvider from 'components/FormProvider';
import { TextArea } from 'subframe/components/TextArea';

interface RequestFields {
  query: string;
  url: string;
  additional_details: string;
}
export interface RequestSupportDialogProps {
  requestResource: string;
  text?: string;
  requestedResourceName?: string;
  eventTitle:
    | 'RequestSupportCloud'
    | 'RequestSupportAddon'
    | 'RequestSupportIntegration';
  showRequestedResourceNameToast?: boolean;
}

export default function RequestSupportDialog(props: RequestSupportDialogProps) {
  const { account, user } = useUserAccountState();
  const { enqueueSnackbar } = useSnackbar();
  const { logEvent } = AnalyticsEventLogger();
  const [open, setOpen] = useState(false);
  const [isSubmitting, setisSubmitting] = useState(false);

  const FormSchema = Yup.object().shape({
    query: Yup.string().required(
      `Please enter the name of the ${props.requestResource}`,
    ),
    url: Yup.string().optional().url('URL is invalid'),
    additional_details: Yup.string().optional(),
  });

  const defaultValues: RequestFields = {
    query: '',
    url: '',
    additional_details: '',
  };

  const methods = useForm<RequestFields>({
    resolver: yupResolver(FormSchema),
    defaultValues: defaultValues,
  });
  const {
    handleSubmit,
    formState: { errors, isValid },
  } = methods;

  const handleClose = () => {
    setOpen(false);
    methods.reset();
    methods.setValue('query', props.requestedResourceName || '');
  };
  useEffect(() => {
    if (props.requestedResourceName) {
      methods.setValue('query', props.requestedResourceName);
    }
  }, [props.requestedResourceName]);

  const onSubmit = async (data: RequestFields) => {
    try {
      setisSubmitting(true);
      await submitFeedback(
        {
          title: props.eventTitle,
          details: JSON.stringify(
            props.eventTitle === 'RequestSupportAddon'
              ? {
                  resource: props.requestResource,
                  query: data.query,
                  url: data.url,
                  additional_details: data.additional_details,
                }
              : {
                  resource: props.requestResource,
                  query: data.query,
                },
          ),
          user_email: user.email || user.userId,
        },
        {
          headers: {
            Authorization: `Bearer ${account.token}`,
          },
        },
      );
      logEvent('latest-supported-clouds-addons-requested', {
        resource: props.requestResource,
        name: methods.getValues('query'),
      });
      enqueueSnackbar(
        props.showRequestedResourceNameToast
          ? `We have received your request to support ${methods.getValues(
              'query',
            )}. Our team will get in touch to understand your use case`
          : `Thank you for your request to cover a new ${props.requestResource}. We'll get back to you shortly`,
        {
          variant: 'success',
          autoHideDuration: toastAutoHideDuration,
        },
      );
    } catch (err) {
      Sentry.captureException(err);
      enqueueSnackbar(
        `Something went wrong while requesting ${props.requestResource}. Please try again or escalate to Chkk`,
        {
          variant: 'error',
          autoHideDuration: toastAutoHideDuration,
        },
      );
    } finally {
      handleClose();
      setisSubmitting(false);
    }
  };
  return (
    <>
      {props.text ? (
        <Text
          variant="subheader"
          className="text-brand-primary cursor-pointer"
          onClick={() => {
            logEvent(
              'latest-supported-clouds-addons-request-cloud-or-addon-dialog-click',
              {
                resource: props.requestResource,
              },
            );
            setOpen(true);
          }}
        >
          {props.text}
        </Text>
      ) : (
        <CardCtaIntegration
          cta={`Request ${props.requestResource}`}
          onClick={() => {
            logEvent(
              'latest-supported-clouds-addons-request-cloud-or-addon-dialog-click',
              {
                resource: props.requestResource,
              },
            );
            setOpen(true);
          }}
        />
      )}
      <Dialog open={open} onOpenChange={handleClose}>
        <Dialog.Content className="h-auto w-144 flex-none">
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <div className="w-[576px] flex-col gap-6 items-start flex pl-6 pr-3 pt-3 pb-6">
              <div className="w-full justify-between items-center flex">
                <span className="flex-[1_0_0] w-full text-default-font text-subheader">
                  Request {props.requestResource}
                </span>
                <IconButton
                  size="medium"
                  icon="FeatherX"
                  onClick={() => handleClose()}
                />
              </div>
              <div className="flex-col gap-4 items-start flex w-full pl-0 pr-3 py-0">
                <span className="text-default-font text-body">
                  Are we missing coverage for your favorite{' '}
                  {props.requestResource}?
                </span>
                <TextField
                  className="h-auto w-full flex-none"
                  label={`${props.requestResource} Name`}
                  helpText={errors.query?.message}
                  error={methods.getFieldState('query').invalid}
                >
                  <TextField.Input
                    placeholder={`Provide ${props.requestResource} Name`}
                    {...methods.register('query')}
                  />
                </TextField>

                {props.eventTitle === 'RequestSupportAddon' && (
                  <>
                    <TextField
                      className="h-auto w-full flex-none"
                      label={`${props.requestResource} URL (Optional)`}
                      helpText={errors.url?.message}
                      error={methods.getFieldState('url').invalid}
                    >
                      <TextField.Input
                        placeholder={`Provide ${props.requestResource} URL`}
                        {...methods.register('url')}
                      />
                    </TextField>
                    <TextArea
                      className="h-full w-full grow shrink-0 basis-0"
                      label="Additional Details (Optional)"
                      helpText={errors?.additional_details?.message}
                      error={
                        methods.getFieldState('additional_details').invalid
                      }
                    >
                      <TextArea.Input
                        className="min-h-16"
                        placeholder="Are there any additional details that you would like to share?"
                        {...methods.register('additional_details')}
                      />
                    </TextArea>
                  </>
                )}
                <div className="w-full gap-4 items-end justify-end flex">
                  <Button
                    size="large"
                    variant="brand-primary"
                    icon={null}
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    onClick={handleSubmit(onSubmit)}
                  >
                    {`Submit Request`}
                  </Button>
                </div>
              </div>
            </div>
          </FormProvider>
        </Dialog.Content>
      </Dialog>
    </>
  );
}
