import React, { useState } from 'react';

import { useListIngestionTokens } from 'api/frontend';
import { useSnackbar } from 'notistack';
import { IngestionToken } from 'api/models';
import useUserAccountState from 'hooks/useUserAccountState';
import useAccountIdRoute from 'hooks/useAccountIdRoute';

import { Badge, Button, IconButton } from 'subframe/index';
import { AtomicTooltip } from 'components/design-system';
import { ConsoleLoader } from 'components/Loader';
import { RouterLink } from 'components/RouterLink';
import BaseTable from 'components/design-system/Table/BaseTable';
import RevokeTokenDialog from '../components/RevokeTokenDialog';

import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { getHumanReadableDate } from 'utils/subscription_plan';
import { toastAutoHideDuration } from 'constants/toasts';
import { EditTokenNameDialog } from '../components/EditTokenNameDialog';
import DeleteTokenDialog from '../components/DeleteTokenDialog';

function checkRevokeStatus(unixTimeStamp: number) {
  return unixTimeStamp == 0 || unixTimeStamp > Math.floor(Date.now() / 1000)
    ? 'Active'
    : 'Revoked';
}

function tokenStatusToStepVariant(
  state: 'Revoked' | 'Active' | 'Expired',
): 'brand' | 'success' | 'error' {
  if (state === 'Active') {
    return 'success';
  }
  if (state === 'Revoked') {
    return 'error';
  }
  if (state === 'Expired') {
    return 'brand';
  }

  return 'brand';
}

export default function Tokens() {
  const { account, currentOrganization } = useUserAccountState();
  const { enqueueSnackbar } = useSnackbar();
  const { logEvent } = AnalyticsEventLogger();
  const [selectedToken, setSelectedToken] = useState<IngestionToken | null>();
  // Token Action Dialogs
  const [openRevokeDialog, setOpenRevokeDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const basePath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/settings',
  );
  // Tokens
  const { data: tokens, mutate: refetchTokens } = useListIngestionTokens(
    'default',
    {
      request: { headers: { Authorization: `Bearer ${account?.token}` } },
    },
  );
  return (
    <div className="flex w-full flex-col items-center justify-center gap-6">
      <div className="flex w-full max-w-[1280px] flex-col gap-6">
        <div className="flex w-full items-center justify-between py-1">
          <span className="text-subheader font-subheader text-default-font">
            Your Tokens
          </span>
          <RouterLink to={`${basePath}/tokens/new`}>
            <Button
              icon="FeatherPlus"
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {}}
            >
              Add Token
            </Button>
          </RouterLink>
        </div>
        {!tokens ? (
          <ConsoleLoader />
        ) : (
          <BaseTable
            data={tokens ? tokens?.data : []}
            noMatchingData={tokens?.data.length === 0}
            columns={[
              {
                id: 'name',
                title: 'Name',
                titleStyle: { width: '40%' },
                cellStyle: {
                  height: '90px',
                  flex: '0 0 auto',
                },
                cellType: 'cell',
                render: (row) => {
                  return (
                    <div className="flex grow shrink-0 basis-0 flex-col items-start justify-center gap-1">
                      <div className="flex items-center gap-2">
                        <span className="whitespace-nowrap text-body-bold font-body-bold text-neutral-700">
                          {row?.token_name ||
                            `token-${currentOrganization.name.toLowerCase()}`}
                        </span>
                        <AtomicTooltip
                          tooltipContent={'Copy token to clipboard'}
                        >
                          <IconButton
                            icon="FeatherClipboard"
                            className="text-subtext-color"
                            onClick={() => {
                              navigator.clipboard.writeText(row?.token);
                              enqueueSnackbar('Token copied to clipboard', {
                                variant: 'success',
                                autoHideDuration: toastAutoHideDuration,
                              });
                            }}
                          />
                        </AtomicTooltip>
                      </div>
                      <div className="flex flex-col items-start justify-center">
                        {getHumanReadableDate(row?.last_used) === '-' ? (
                          <span className="text-label font-label text-subtext-color">
                            Never Used
                          </span>
                        ) : (
                          <span className="text-label font-label text-subtext-color">
                            Last Used: {getHumanReadableDate(row?.last_used)}
                          </span>
                        )}

                        {getHumanReadableDate(
                          row?.last_used || row?.revoked,
                        ) !== '-' && (
                          <span className="text-label font-label text-subtext-color">
                            Last Updated:{' '}
                            {getHumanReadableDate(
                              row?.last_used || row?.revoked,
                            )}
                          </span>
                        )}
                      </div>
                    </div>
                  );
                },
              },
              {
                id: 'created',
                title: 'Created',
                titleStyle: { width: '30%' },
                cellType: 'text-cell',
                render: (row) => {
                  return `${getHumanReadableDate(row?.created)}`;
                },
              },
              {
                id: 'status',
                title: 'Status',
                titleStyle: { width: '30%' },
                cellType: 'cell',
                render: (row) => {
                  return (
                    <AtomicTooltip
                      tooltipContent={
                        row?.revoked_by?.name &&
                        `Revoked By: ${row?.revoked_by?.name}`
                      }
                      className="ph-no-capture"
                    >
                      <Badge
                        variant={tokenStatusToStepVariant(
                          checkRevokeStatus(row?.revoked || 0),
                        )}
                      >
                        {checkRevokeStatus(row?.revoked || 0)}
                      </Badge>
                    </AtomicTooltip>
                  );
                },
              },
            ]}
            actions={[
              {
                id: 'edit',
                render: (row) => {
                  const disableActions =
                    checkRevokeStatus(row?.revoked || 0) !== 'Active';
                  return (
                    <AtomicTooltip tooltipContent="Edit Token Name">
                      <IconButton
                        icon="FeatherPencil"
                        className={disableActions ? 'hidden' : ''}
                        onClick={() => {
                          setSelectedToken(row);
                          setOpenEditDialog(true);
                        }}
                      />
                    </AtomicTooltip>
                  );
                },
              },
              {
                id: 'revoke',
                render: (row) => {
                  const disableActions =
                    checkRevokeStatus(row?.revoked || 0) !== 'Active';
                  return (
                    <AtomicTooltip tooltipContent="Click to Revoke token. Revoked tokens can no longer be used to onboard clusters. Please ensure all connectors using this token are updated before revocation to avoid disrupting ingestion workflows.">
                      <IconButton
                        icon="FeatherX"
                        className={disableActions ? 'hidden' : ''}
                        onClick={() => {
                          setSelectedToken(row);
                          setOpenRevokeDialog(true);
                        }}
                      />
                    </AtomicTooltip>
                  );
                },
              },
              {
                id: 'delete',
                render: (row) => {
                  const disableActions =
                    checkRevokeStatus(row?.revoked || 0) === 'Active';
                  return (
                    <AtomicTooltip tooltipContent="Click to Delete token. Deleted tokens will be permanently removed from Chkk.">
                      <IconButton
                        icon="FeatherTrash"
                        className={disableActions ? 'hidden' : ''}
                        onClick={() => {
                          setSelectedToken(row);
                          setOpenDeleteDialog(true);
                        }}
                      />
                    </AtomicTooltip>
                  );
                },
              },
            ]}
          />
        )}
      </div>
      {selectedToken && (
        <>
          <RevokeTokenDialog
            open={openRevokeDialog}
            token={selectedToken!}
            onClose={() => {
              setOpenRevokeDialog(false);
            }}
            callRefetch={refetchTokens}
          />
          <DeleteTokenDialog
            open={openDeleteDialog}
            token={selectedToken!}
            onClose={() => {
              setOpenDeleteDialog(false);
            }}
            callRefetch={refetchTokens}
          />
          <EditTokenNameDialog
            open={openEditDialog}
            token={selectedToken!}
            onClose={() => {
              setOpenEditDialog(false);
            }}
            callRefetch={refetchTokens}
          />
        </>
      )}
    </div>
  );
}
