import { Account } from 'models/account';

export interface IIntegration {
  name: string;
  icon: string;
  docLink?: string;
  popupText?: string[];
  installState?: 'installed' | 'requested';
  id: string;
  text: string;
  gate_id?: string;
}

export interface IntegrationCardProps {
  account: Account;
  integration: IIntegration;
  clickable?: boolean;
  id?: string;
  text: string;
}

const supportedIntegrations: IIntegration[] = [
  {
    name: 'Slack',
    icon: 'logos:slack-icon',
    id: 'slack',
    text: `Receive notifications in your existing Slack workspace. `,
    docLink: '/orgs/:orgId/accounts/:accountId/integrations/slack',
  },
  {
    name: 'GitHub',
    icon: 'akar-icons:github-fill',
    id: 'github',
    text: `Connect your IaC repos to ensure that upgrade steps in Upgrade Plans are contextualized to your existing infrastructure code.`,
  },
  {
    name: 'Jira',
    icon: 'logos:jira',
    id: 'jira',
    text: `Assign Owners and manage Availability Risks in your Jira workspace. Only supports default fields.`,
  },
  {
    name: 'Okta SSO',
    icon: 'devicon:okta-wordmark',
    id: 'okta-sso',
    text: 'Login with your existing Okta accounts so you don’t have to maintain another set of credentials.',
    docLink: '/orgs/:orgId/accounts/:accountId/settings?page=security-tab',
  },
];

export const allIntegrations: IIntegration[] = [
  {
    name: 'requestIntegration',
    icon: 'logos:request',
    id: 'request-integration',
    text: '',
  },
  ...supportedIntegrations,
];
