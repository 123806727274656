import { useEffect, useState } from 'react';
import { clustersApi } from 'api/constants';
import useFetch, { CachePolicies } from 'use-http';
import { useInterval } from 'react-use';
import { Cluster, RescanClusterRequest } from 'api/models';

const RESCAN_INTERVAL = 10 * 1000;

export const useRescan = (clusterId: string | undefined, token: string) => {
  const rescanRequest: RescanClusterRequest = { rescan_status: 'pending' };

  const [getRescanInterval, setGetRescanInterval] = useState<number | null>(
    null,
  );
  const [rescanComplete, setRescanComplete] = useState<boolean | null>(null);

  const { get: getRescan } = useFetch<Cluster>(`${clustersApi}/${clusterId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: 'Bearer ' + token,
    } as any,
    credentials: 'omit',
    cachePolicy: CachePolicies.NO_CACHE,
  });

  const { post: postRescan } = useFetch<Cluster>(
    `${clustersApi}/${clusterId}/rescan`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + token,
      } as any,
      body: rescanRequest,
      credentials: 'omit',
      cachePolicy: CachePolicies.NO_CACHE,
    },
  );

  useEffect(() => {
    const fetch = async () => {
      try {
        const r = await getRescan();
        if (r.rescan === 'completed' || r.rescan === '') {
          setGetRescanInterval(null);
          setRescanComplete(true);
        } else {
          setGetRescanInterval(RESCAN_INTERVAL);
          setRescanComplete(false);
        }
      } catch {
        setGetRescanInterval(null);
        setRescanComplete(true);
      }
    };

    fetch();
  }, [getRescan]);

  useInterval(async () => {
    try {
      const r = await getRescan();
      if (r.rescan === 'completed' || r.rescan === '') {
        setGetRescanInterval(null);
        setRescanComplete(true);
      } else {
        setRescanComplete(false);
      }
    } catch {
      setGetRescanInterval(null);
    }
  }, getRescanInterval);

  const triggerRescan = async () => {
    try {
      setRescanComplete(false);
      await postRescan();
      setGetRescanInterval(RESCAN_INTERVAL);
    } catch {
      setRescanComplete(true);
      setGetRescanInterval(null);
    }
  };

  return { triggerRescan, rescanComplete };
};
