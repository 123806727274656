import { UpgradePlan, UpgradeStage } from 'api/models';

export const GRAFANA_LOKI_PLAN: UpgradePlan = {
  id: 'upgr_e9e674d1-6ad5-4a47-a300-6b3f515179f5',
  name: 'grafana-loki -- eks-125-euw1-staging -- in-place',
  resources: {
    addon_instances: [
      {
        id: 'id-grafana-loki-upgrade-1',
      },
    ],
  },
  deployment_strategy: 'in-place',
  status: 'completed',
  created: new Date(2024, 1, 1).getTime(),
  updated: new Date(2024, 1, 8).getTime(),
  last_validated: new Date(2024, 1, 6).getTime(),
  requested_by: {
    email: 'roger@chkk.io',
    id: '',
    name: 'Roger Walter',
    picture: '',
  },
  completed_by: {
    email: 'katy@chkk.io',
    id: '',
    name: 'Katy Smith',
    picture: '',
  },
  header: {
    items: [
      {
        title: 'Version',
        value: 'v2.6.1',
      },
      {
        title: 'Recommended',
        value: 'v2.8.3',
      },
      {
        title: 'Deployment Tool',
        value: 'Terraform Helm',
      },
      {
        title: 'Helm Chart Version',
        value: '1.8.5',
      },
      {
        title: 'Recommended Helm Chart Version',
        value: '5.10.0',
      },
      {
        title: 'Upgrade Type',
        value: 'In-Place',
      },
    ],
  },
};

export const GRAFANA_LOKI_PLAN_STAGES: UpgradeStage[] = [
  {
    allowed_action_types: [],
    created: 42,
    footer_content_blocks: [
      {
        text: "Let's start preparing for your upgrade",
        title:
          "🎉 You are all caught up on what's changing in Grafana Loki v2.8.3",
        type: 'completionCtaCard',
      },
    ],

    header_content_blocks: [],
    name: 'Add-On Changes',
    stage_number: 1,
    status: 'completed',
    steps: [
      {
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        content_blocks: [
          {
            column_definitions: [
              {
                title: 'Add-On Component',
              },
              {
                title: 'Namespace',
              },
              {
                title: 'Current Version : (Kind)',
              },
              {
                title: 'Recommended Version : (Kind)',
              },
              {
                title: 'Current Kind',
              },
              {
                title: 'Recommended Kind',
              },
            ],
            data: [
              [
                {
                  value: 'write',
                },
                {
                  value: 'logging',
                },
                {
                  value: 'v2.6.1 : (StatefulSet)',
                },
                {
                  value: 'v2.8.3 : (StatefulSet)',
                },
                {
                  value: 'StatefulSet',
                },
                {
                  value: 'StatefulSet',
                },
              ],
              [
                {
                  value: 'read',
                },
                {
                  value: 'logging',
                },
                {
                  value: 'v2.6.1 : (StatefulSet)',
                },
                {
                  value: 'v2.8.3 : (Deployment)',
                },
                {
                  value: 'StatefulSet',
                },
                {
                  value: 'Deployment',
                },
              ],
              [
                {
                  value: 'backend',
                },
                {
                  value: 'logging',
                },
                {
                  value: '-',
                },
                {
                  value: 'v2.8.3 : (StatefulSet)',
                },
                {
                  value: '-',
                },
                {
                  value: 'StatefulSet',
                },
              ],
              [
                {
                  value: 'gateway',
                },
                {
                  value: 'logging',
                },
                {
                  value: 'v2.6.1 : (Deployment)',
                },
                {
                  value: 'v2.8.3 : (Deployment)',
                },
                {
                  value: 'Deployment',
                },
                {
                  value: 'Deployment',
                },
              ],
              [
                {
                  value: 'canary',
                },
                {
                  value: 'logging',
                },
                {
                  value: '-',
                },
                {
                  value: 'v2.8.3 : (Daemonset)',
                },
                {
                  value: '-',
                },
                {
                  value: 'Daemonset',
                },
              ],
            ],
            type: 'table',
          },
          {
            type: 'banner',
            title: 'Your current Helm Chart for Grafana Loki is deprecated',
            description:
              'Loki Simple Scalable Chart has been deprecated now. This chart was moved to https://github.com/grafana/loki/tree/main/production/helm/loki. The new chart is now published to grafana/loki.',
          },
          {
            style: 'header',
            text: 'New Components Introduced',
            type: 'text',
          },
          {
            entries: [
              {
                text: 'A new target "backend" has been added to Loki’s scalable configuration.\nThis allows Loki to be run as 3 targets (read, write, and backend) and makes the "read" target stateless. The "read" target will now run as a Kubernetes deployment that can be scaled automatically.',
              },
              {
                text: 'Loki Canary is installed as a DaemonSet to monitor the health of the Loki cluster.',
              },
            ],
            type: 'bullet-list',
          },
          {
            style: 'header',
            text: 'Notable Changes',
            type: 'text',
          },
          {
            entries: [
              {
                text: 'Querier/Ruler can now block queries via a per-tenant runtime configuration.',
              },
              {
                text: 'engine.timeout and querier.query_timeout are deprecated. Move to limits_config.query_timeout with the same default values.',
              },
              {
                text: 'The in-memory fifocache has been renamed to embedded-cache.',
              },
              {
                text: 'Metric name for loki_log_messages_total has been changed to loki_internal_log_messages_total to reduce ambiguity. The previous name is still present but is deprecated.',
              },
              {
                text: 'TLS cipher_suites and tls_min_version have moved. These were previously configurable under server.http_tls_config and server.grpc_tls_config separately. They are now under server.tls_cipher_suites and server.tls_min_version.',
              },
              {
                text: 'ruler.storage.configdb has been removed.',
              },
              {
                text: 'ruler.remote_write.client has been removed.',
              },
              {
                text: 'The base alpine image used in loki image has been updated to 3.16.5',
              },
              {
                text: 'Default value for server.http-listen-port has been changed from 80 to 3100.',
              },
            ],
            type: 'bullet-list',
          },
          {
            links: [
              {
                text: 'Grafana Loki upgrade guide',
                url: 'https://grafana.com/docs/loki/v2.8.x/upgrading/#upgrading',
              },
              {
                text: 'Grafana Loki releases',
                url: 'https://grafana.com/docs/loki/latest/release-notes/v2-8',
              },
            ],
            type: 'learn-more',
          },
        ],
        created: 42,
        description: '',
        name: 'Grafana Loki v2.6.1 -> v2.8.3',
        status: 'completed',
        step_number: 1,
        updated: 42,
        comments: {},
      },
      {
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        content_blocks: [
          {
            column_definitions: [
              {
                title: 'Add-On Component',
              },
              {
                title: 'Namespace',
              },
              {
                title: 'Helm Chart Version | App Version',
              },
              {
                title: 'Recommended Helm Chart Version | App Version',
              },
            ],
            data: [
              [
                {
                  value: 'promtail',
                },
                {
                  value: 'logging',
                },
                {
                  value: '6.2.2 | v2.6.1',
                },
                {
                  value: '6.14.1 | v2.8.3',
                },
              ],
            ],
            type: 'table',
          },
          {
            style: 'header',
            text: 'Notable Changes',
            type: 'text',
          },
          {
            entries: [
              {
                text: 'Fix - Prevent logging errors on normal shutdown.',
              },
              {
                text: 'Fix - journald support in amd64 binary build.',
              },
              {
                text: 'Fix - "cri" tags treating different streams as the same.',
              },
              {
                text: 'Fix - Add go build tag "promtail_journal_enabled" to include/exclude Promtail journald code from binary.',
              },
              {
                text: 'Support exponential back-off when polling unchanged files for logs.',
              },
              {
                text: 'Configuration added to drop rate limited batches in Loki client and new metric label for drop reason.',
              },
            ],
            type: 'bullet-list',
          },
          {
            links: [
              {
                text: 'Grafana Loki and Promtail changelog',
                url: 'https://github.com/grafana/loki/blob/main/CHANGELOG.md',
              },
              {
                text: 'Grafana Loki releases',
                url: 'https://grafana.com/docs/loki/latest/release-notes/v2-8',
              },
            ],
            type: 'learn-more',
          },
        ],
        created: 42,
        description:
          'As part of moving to the recommended version of Grafana Loki, we also recommend upgrading your Promtail version to v2.8.3. This will ensure you have the latest features and fixes available.',
        name: 'Promtail v2.6.1 -> v2.8.3',
        status: 'completed',
        step_number: 2,
        updated: 42,
        comments: {},
      },
      {
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        content_blocks: [
          {
            column_definitions: [
              {
                title: 'Add-On Component',
              },
              {
                title: 'Namespace',
              },
              {
                title: 'Helm Chart Version | App Version',
              },
              {
                title: 'Recommended Helm Chart Version | App Version',
              },
            ],
            data: [
              [
                {
                  value: 'operator',
                },
                {
                  value: 'logging',
                },
                {
                  value: '0.2.2 | v0.25.1',
                },
                {
                  value: '0.2.16 | v0.34.1',
                },
              ],
            ],
            type: 'table',
          },
          {
            style: 'header',
            text: 'Notable Changes',
            type: 'text',
          },
          {
            entries: [
              {
                text: 'Symbolic links to "/bin/agent*" in Docker containers have been removed.\nIn case you’re setting a custom entrypoint, use the new binaries that are prefixed with "/bin/grafana*"',
              },
            ],
            type: 'bullet-list',
          },
          {
            links: [
              {
                text: 'Grafana Agent Operator Release Notes',
                url: 'https://grafana.com/docs/agent/v0.34/operator/release-notes/',
              },
            ],
            type: 'learn-more',
          },
        ],
        created: 42,
        description:
          'This upgrade will also update the grafana-agent-operator sub-chart to v0.34.1.',
        name: 'Grafana Agent Operator v0.25.1 -> v0.34.1',
        status: 'completed',
        step_number: 3,
        updated: 42,
        comments: {},
      },
    ],
    updated: 42,
  },
  {
    allowed_action_types: [],
    created: 42,
    header_content_blocks: [],
    name: 'Preflight Checks',
    stage_number: 2,
    status: 'completed',
    footer_content_blocks: [
      {
        text: "Let's proceed to upgrade Grafana Loki",
        title: '🎉 All Preflight checks are passing',
        type: 'completionCtaCard',
      },
    ],
    steps: [
      {
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        requirement_level: 'required',
        content_blocks: [
          {
            column_definitions: [
              {
                title: 'Name',
              },
              {
                title: 'Status',
              },
            ],
            data: [
              [
                {
                  value:
                    'Ensure recommended Helm chart for Grafana Loki is compatible with Kubernetes API server version (GKE v1.24)',
                },
                {
                  value: '',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value:
                    'Ensure recommended Helm chart version for Loki is compatible with current version of Promtail',
                },
                {
                  value: '',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'Ensure no changes are required in Network Policies',
                },
                {
                  value: '',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
            ],
            type: 'table',
          },
        ],
        created: 42,
        description: '',
        name: 'Grafana Loki Upgrade Requirements Check',
        status: 'completed',
        step_number: 1,
        updated: 42,
        comments: {},
      },
      {
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        requirement_level: 'required',
        content_blocks: [
          {
            style: 'header',
            text: 'Spin up a temporary test Pod to check the current state from Grafana Loki HTTP API',
            type: 'text',
          },
          {
            code: 'kubectl --namespace logging run loki-health-check --image=curlimages/curl -i --tty -- sh',
            type: 'code',
          },
          {
            style: 'normal',
            text: 'Inside the test Pod - `loki-health-check`:',
            type: 'text',
          },
          {
            style: 'normal',
            text: 'Check if Loki service is ready:',
            type: 'text',
          },
          {
            code: 'curl -X GET http://loki-logging-read.logging.svc.cluster.local:3100/ready',
            type: 'code',
          },
          {
            style: 'normal',
            text: 'Check if Loki services are in Running state:',
            type: 'text',
          },
          {
            code: 'curl -X GET http://loki-logging-read.logging.svc.cluster.local:3100/services',
            type: 'code',
          },
          {
            style: 'normal',
            text: "Here's a sample output showing the expected response:",
            type: 'text',
          },
          {
            code: '# sample output\n\nring => Running\ncompactor => Running\nquery-scheduler => Running\nquerier => Running\nusage-report => Running\nserver => Running\nmemberlist-kv => Running\nquery-frontend-tripperware => Running\ningester-querier => Running\nstore => Running\nruler => Running\nquery-frontend => Running\n',
            type: 'code',
          },
          {
            type: 'spacer',
          },
          {
            style: 'normal',
            text: 'Note: In case the local DNS fails to resolve the hostname of the Loki service, you can use the Cluster IP instead. Get the Cluster IP of the Loki Service from:',
            type: 'text',
          },
          {
            code: 'kubectl --namespace logging get service loki-logging-read',
            type: 'code',
          },
          {
            style: 'normal',
            text: 'Once the health has been verified, remove the temporary Pod: loki-health-check:',
            type: 'text',
          },
          {
            code: 'kubectl --namespace logging delete pod loki-health-check',
            type: 'code',
          },
          {
            type: 'spacer',
          },
          {
            style: 'header',
            text: 'Run the following command to examine the status of all resources owned by Grafana Loki that are running in your cluster',
            type: 'text',
          },
          {
            code: 'kubectl get all --namespace logging',
            type: 'code',
          },
        ],
        created: 42,
        description:
          'Verify the current state of Grafana Loki is healthy and active.',
        name: '[Manual] Verify Grafana Loki Health',
        status: 'completed',
        step_number: 2,
        updated: 42,
        comments: {},
      },
      {
        requirement_level: 'required',
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        content_blocks: [
          {
            column_definitions: [
              {
                title: 'Name',
              },
              {
                title: 'Status',
              },
            ],
            data: [
              [
                {
                  value:
                    'Ensure recommended Helm chart for Promtail is compatible with Kubernetes API server version (GKE v1.24)',
                },
                {
                  value: '',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value:
                    'Ensure recommended Helm chart version for Promtail is compatible with recommended version of Grafana Loki',
                },
                {
                  value: '',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'Ensure no changes are required in Network Policies',
                },
                {
                  value: '',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
            ],
            type: 'table',
          },
        ],
        created: 42,
        description: '',
        name: 'Promtail Upgrade Requirements Check',
        status: 'completed',
        step_number: 3,
        updated: 42,
        comments: {},
      },
      {
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        requirement_level: 'required',
        content_blocks: [
          {
            style: 'header',
            text: 'Verify Promtail is working by accessing the Promtail Daemonset',
            type: 'text',
          },
          {
            code: 'kubectl --namespace logging port-forward daemonset/promtail 3101',
            type: 'code',
          },
          {
            style: 'normal',
            text: 'Check if Promtail is ready:',
            type: 'text',
          },
          {
            code: 'curl -X GET http://127.0.0.1:3101/ready',
            type: 'code',
          },
          {
            code: 'curl -X GET http://127.0.0.1:3101/metrics',
            type: 'code',
          },
          {
            type: 'spacer',
          },
          {
            style: 'header',
            text: 'Run the following command to examine the status of all resources owned by Promtail that are running in your cluster',
            type: 'text',
          },
          {
            code: 'kubectl get all --namespace logging',
            type: 'code',
          },
        ],
        created: 42,
        description:
          'Verify the current state of Promtail is healthy and active.',
        name: '[Manual] Verify Promtail Health',
        status: 'completed',
        step_number: 4,
        updated: 42,
        comments: {},
      },
      {
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        requirement_level: 'required',
        content_blocks: [
          {
            style: 'header',
            text: 'Verify Grafana Agent Operator is up and running',
            type: 'text',
          },
          {
            style: 'normal',
            text: 'Check Agent Operator Pod is in "RUNNING" state:',
            type: 'text',
          },
          {
            code: 'kubectl --namespace logging get pod | grep "grafana-agent-operator"',
            type: 'code',
          },
          {
            type: 'spacer',
          },
          {
            style: 'normal',
            text: 'Check "kubelet" service is available:',
            type: 'text',
          },
          {
            code: 'kubectl get service kubelet -o yaml',
            type: 'code',
          },
        ],
        created: 42,
        description:
          'Verify the current state of Grafana Agent Operator is healthy and active.',
        name: '[Manual] Verify Grafana Agent Operator Health',
        status: 'completed',
        step_number: 5,
        updated: 42,
        comments: {},
      },
      {
        allowed_action_types: [
          'mark-completed',
          'undo-mark-completed',
          'skip',
          'undo-skip',
        ],
        requirement_level: 'optional',
        content_blocks: [
          {
            style: 'normal',
            text: 'Take a backup of the installed Grafana Loki Add-On using Velero or another tool. Taking a backup will ensure smooth rollbacks to last-known-stable-config in case of hitting an upgrade failure.',
            type: 'text',
          },
          {
            style: 'normal',
            text: 'Alternatively, you can also roll-back the change by restoring from the last working state backup file (default: terraform.tfstate.backup) managed by Terraform.',
            type: 'text',
          },
        ],
        last_status_change_details: {
          changed: new Date(2024, 1, 8).getTime() / 1000,
          comment:
            "We have scheduled backups we can rely on. Let's skip this step",
          reason: 'Other',
          user: {
            email: '',
            id: '',
            name: 'Roger Walter',
            picture: '',
          },
        },
        created: 42,
        description: '',
        name: 'Take a Backup',
        status: 'skipped',
        step_number: 6,
        updated: 42,
        comments: {},
      },
      {
        allowed_action_types: [
          'mark-completed',
          'undo-mark-completed',
          'skip',
          'undo-skip',
        ],
        requirement_level: 'optional',
        content_blocks: [
          {
            style: 'normal',
            text: 'Notify relevant stakeholders (business, product and application owners) about the upcoming\n upgrade and any expected impact or downtime. Communicate the timeline, expected duration, \nand potential risks associated with the upgrade to ensure everyone is well-informed.',
            type: 'text',
          },
        ],
        created: 42,
        description: '',
        name: 'Notify Stakeholders',
        status: 'completed',
        step_number: 7,
        updated: 42,
        comments: {},
      },
    ],
    updated: 42,
  },
  {
    allowed_action_types: [],
    created: 42,
    footer_content_blocks: [
      {
        text: "Now let's make sure all the components are healthy",
        title:
          '🎉 Grafana Loki, Promtail and Grafana Agent Operator have been updated',
        type: 'completionCtaCard',
      },
    ],
    header_content_blocks: [],
    name: 'Upgrade',
    stage_number: 3,
    status: 'completed',
    steps: [
      {
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        requirement_level: 'required',
        content_blocks: [
          {
            style: 'header',
            text: 'Note: If you are using a custom values.yaml file, please specify it during the upgrade step.',
            type: 'text',
          },
          {
            style: 'normal',
            text: 'Use the following Terraform module diff for reference to update your Grafana Loki resource to use the new chart and version:',
            type: 'text',
          },
          {
            code: '{\n...\n\n- chart = "loki-simple-scalable"\n- version = "1.8.5"\n+ chart = "loki"\n+ version = "5.10.0"\n\n...\n}',
            type: 'code',
          },
          {
            type: 'spacer',
          },
        ],
        created: 42,
        description: '',
        name: 'Update Terraform module for Grafana Loki',
        status: 'completed',
        step_number: 1,
        updated: 42,
        comments: {},
      },
      {
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        requirement_level: 'required',
        content_blocks: [
          {
            style: 'header',
            text: 'Note: If you are using a custom values.yaml file, please specify it during the upgrade step.',
            type: 'text',
          },
          {
            style: 'normal',
            text: 'Use the following Terraform module diff for reference to update your Promtail resource to use the new chart and version:',
            type: 'text',
          },
          {
            code: '{\n...\n\n- version = "6.2.2"\n+ version = "6.14.1"\n\n...\n}',
            type: 'code',
          },
          {
            type: 'spacer',
          },
        ],
        created: 42,
        description: '',
        name: 'Update Terraform module for Promtail',
        status: 'completed',
        step_number: 2,
        updated: 42,
        comments: {},
      },
      {
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        requirement_level: 'required',
        content_blocks: [
          {
            style: 'header',
            text: 'You will need to update the GrafanaAgent custom resource in your Helm chart to use the new version.',
            type: 'text',
          },
          {
            style: 'normal',
            text: 'Use the following manifest diff for reference to update your GrafanaAgent custom resource to use the new version:',
            type: 'text',
          },
          {
            code: 'apiVersion: monitoring.grafana.com/v1alpha1\nkind: GrafanaAgent\n...\n\n- image: grafana/agent:v0.25.1\n+ image: grafana/agent:v0.34.1\n\n...',
            type: 'code',
          },
          {
            type: 'spacer',
          },
        ],
        created: 42,
        description: '',
        name: 'Update GrafanaAgent resource',
        status: 'completed',
        step_number: 3,
        updated: 42,
        comments: {},
      },
      {
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        requirement_level: 'required',
        content_blocks: [
          {
            style: 'normal',
            text: "Check the Terraform file's syntax is correct and does not have errors:",
            type: 'text',
          },
          {
            code: 'terraform validate',
            type: 'code',
          },
          {
            style: 'normal',
            text: 'Preview what will change when you run terraform apply and confirm the results are as expected:',
            type: 'text',
          },
          {
            code: 'terraform plan',
            type: 'code',
          },
          {
            style: 'normal',
            text: 'Apply changes:',
            type: 'text',
          },
          {
            code: 'terraform apply',
            type: 'code',
          },
        ],
        created: 42,
        description:
          'You are using Terraform Infrastructure as Code(IaC) to deploy Grafana Loki. Please update your IaC using the instructions below.',
        name: 'Run Terraform Update',
        status: 'completed',
        step_number: 4,
        updated: 42,
        comments: {},
      },
      {
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        requirement_level: 'required',
        content_blocks: [
          {
            style: 'header',
            text: 'Check the status of the installed Helm chart for Grafana Loki',
            type: 'text',
          },
          {
            code: 'helm --namespace logging status logging-stack',
            type: 'code',
          },
          {
            style: 'normal',
            text: "Here's a sample output showing the expected response:",
            type: 'text',
          },
          {
            code: 'NAME: logging-stack\nNAMESPACE: logging\nSTATUS: deployed\n***********************************************************************\nWelcome to Grafana Loki\nChart version: 5.10.0\nLoki version: 2.8.3\n***********************************************************************\n\nInstalled components:\n* grafana-agent-operator\n* gateway\n* read\n* write\n* backend\n',
            type: 'code',
          },

          {
            type: 'spacer',
          },
          {
            style: 'header',
            text: 'Run the following command to examine the status of all resources owned by Grafana Loki that are running in your cluster',
            type: 'text',
          },
          {
            code: 'kubectl get all --namespace logging',
            type: 'code',
          },
          {
            style: 'normal',
            text: 'Verify the Grafana Loki image has been updated to the new tag "2.8.3"',
            type: 'text',
          },
          {
            code: "kubectl --namespace logging get statefulset loki-logging-write -o jsonpath='{.spec.template.spec.containers[0].image}'\nkubectl --namespace logging get deployment loki-logging-read -o jsonpath='{.spec.template.spec.containers[0].image}'",
            type: 'code',
          },
          {
            code: '# sample output\ndocker.io/grafana/loki:2.8.3',
            type: 'code',
          },
        ],
        created: 42,
        description: 'Verify Grafana Loki has been successfully updated.',
        name: 'Verify Grafana Loki Update',
        status: 'completed',
        step_number: 5,
        updated: 42,
        comments: {},
      },
      {
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        requirement_level: 'required',
        content_blocks: [
          {
            style: 'header',
            text: 'Check the status of the installed Helm chart for Promtail',
            type: 'text',
          },
          {
            code: 'helm --namespace logging status logging-stack-promtail',
            type: 'code',
          },
          {
            style: 'normal',
            text: "Here's a sample output showing the expected response:",
            type: 'text',
          },
          {
            code: 'NAME: logging-stack-promtail\nNAMESPACE: logging\nSTATUS: deployed\n***********************************************************************\nWelcome to Grafana Promtail\nChart version: 6.14.1\nPromtail version: 2.8.3\n***********************************************************************\n',
            type: 'code',
          },
          {
            type: 'spacer',
          },
          {
            style: 'header',
            text: 'Run the following command to examine the status of all resources owned by Promtail that are running in your cluster',
            type: 'text',
          },
          {
            code: 'kubectl get all --namespace logging',
            type: 'code',
          },
          {
            style: 'normal',
            text: 'Verify the Promtail image has been updated to the new tag "2.8.3"',
            type: 'text',
          },
          {
            code: "kubectl --namespace logging get daemonset logging-stack-promtail -o jsonpath='{.spec.template.spec.containers[0].image}'",
            type: 'code',
          },
          {
            code: '# sample output\ndocker.io/grafana/promtail:2.8.3',
            type: 'code',
          },
        ],
        created: 42,
        description: 'Verify Promtail has been successfully updated.',
        name: 'Verify Promtail Update',
        status: 'completed',
        step_number: 6,
        updated: 42,
        comments: {},
      },
      {
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        requirement_level: 'required',
        content_blocks: [
          {
            style: 'header',
            text: 'Verify Grafana Agent Operator is up and running',
            type: 'text',
          },
          {
            style: 'normal',
            text: 'Check Agent Operator Pod is in "RUNNING" state:',
            type: 'text',
          },
          {
            code: 'kubectl --namespace logging get pod | grep "grafana-agent-operator"',
            type: 'code',
          },
          {
            type: 'spacer',
          },
          {
            style: 'normal',
            text: 'Check "kubelet" service is available:',
            type: 'text',
          },
          {
            code: 'kubectl get service kubelet -o yaml',
            type: 'code',
          },
          {
            type: 'spacer',
          },
          {
            style: 'normal',
            text: 'Verify the Grafana Agent Operator image has been updated to the new tag "v0.34.1":',
            type: 'text',
          },
          {
            code: "kubectl --namespace logging get deployment logging-stack-grafana-agent-operator -o jsonpath='{.spec.template.spec.containers[0].image}'",
            type: 'code',
          },
          {
            code: '# sample output\ndocker.io/grafana/agent-operator:v0.34.1',
            type: 'code',
          },
        ],
        created: 42,
        description:
          'Verify Grafana Agent Operator has been successfully updated.',
        name: 'Verify Grafana Agent Operator Update',
        status: 'completed',
        step_number: 7,
        updated: 42,
        comments: {},
      },
    ],
    updated: 42,
  },
  {
    allowed_action_types: [],
    created: 42,
    footer_content_blocks: [
      {
        text: 'You can always refer back to completed Upgrade Plans',
        title: '🎉 Grafana Loki has been successfully upgraded',
        type: 'completionCtaCard',
      },
    ],
    header_content_blocks: [],
    name: 'Postflight Checks',
    stage_number: 4,
    status: 'completed',
    steps: [
      {
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        requirement_level: 'required',
        content_blocks: [
          {
            style: 'header',
            text: 'Spin up a temporary test Pod to check the current state from Grafana Loki HTTP API',
            type: 'text',
          },
          {
            code: 'kubectl --namespace logging run loki-health-check --image=curlimages/curl -i --tty -- sh',
            type: 'code',
          },
          {
            style: 'normal',
            text: 'Inside the test Pod: `loki-health-check`:',
            type: 'text',
          },
          {
            style: 'normal',
            text: 'Check if Loki service is ready:',
            type: 'text',
          },
          {
            code: 'curl -X GET http://loki-logging-read.logging.svc.cluster.local:3100/ready',
            type: 'code',
          },
          {
            style: 'normal',
            text: 'Check if Loki services are in Running state:',
            type: 'text',
          },
          {
            code: 'curl -X GET http://loki-logging-read.logging.svc.cluster.local:3100/services',
            type: 'code',
          },
          {
            style: 'normal',
            text: "Here's a sample output showing the expected response:",
            type: 'text',
          },
          {
            code: '# sample output\n\nserver => Running\nring => Running\nstore => Running\ncache-generation-loader => Running\nusage-report => Running\nquery-frontend-tripperware => Running\nindex-gateway-ring => Running\ningester-querier => Running\nquery-frontend => Running\nquerier => Running\nruntime-config => Running\nmemberlist-kv => Running',
            type: 'code',
          },
          {
            type: 'spacer',
          },
          {
            style: 'normal',
            text: 'Note: In case the local DNS fails to resolve the hostname of the Loki service, you can use the Cluster IP instead. Get the Cluster IP of the Loki Service from:',
            type: 'text',
          },
          {
            code: 'kubectl --namespace logging get service loki-logging-read',
            type: 'code',
          },
          {
            style: 'normal',
            text: 'Once the health has been verified, remove the temporary Pod: loki-health-check',
            type: 'text',
          },
          {
            code: 'kubectl --namespace logging delete pod loki-health-check',
            type: 'code',
          },
          {
            type: 'spacer',
          },
        ],
        created: 42,
        description:
          'Verify the current state of Grafana Loki is healthy and active.',
        name: '[Manual] Verify Grafana Loki Health',
        status: 'completed',
        step_number: 1,
        updated: 42,
        comments: {},
      },
      {
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        requirement_level: 'required',
        content_blocks: [
          {
            style: 'header',
            text: 'Verify Promtail is working by accessing the Promtail Daemonset',
            type: 'text',
          },
          {
            code: 'kubectl --namespace logging port-forward daemonset/promtail 3101',
            type: 'code',
          },
          {
            style: 'normal',
            text: 'Check if Promtail is ready:',
            type: 'text',
          },
          {
            code: 'curl -X GET http://127.0.0.1:3101/ready',
            type: 'code',
          },
          {
            code: 'curl -X GET http://127.0.0.1:3101/metrics',
            type: 'code',
          },
        ],
        created: 42,
        description:
          'Verify the current state of Promtail is healthy and active.',
        name: '[Manual] Verify Promtail Health',
        status: 'completed',
        step_number: 2,
        updated: 42,
        comments: {},
      },
      {
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        requirement_level: 'required',
        content_blocks: [
          {
            style: 'normal',
            text: 'You can verify Grafana Agent Operator and GrafanaAgent resources are working correctly by navigating to your Grafana instance and querying your Loki data source.',
            type: 'text',
          },
        ],
        created: 42,
        description:
          'Verify the current state of Grafana Agent Operator is healthy and active.',
        name: '[Manual] Verify Grafana Agent Operator Health',
        status: 'completed',
        step_number: 3,
        updated: 42,
        comments: {},
      },
    ],
    updated: 42,
  },
];
