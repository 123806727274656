import styles from '../AddClusterTerraformTab.module.scss';

export const ChkkOperatorInfo = () => {
  return (
    <div
      className={styles['stack-ab3fc6a6']}
      data-cy="chkk-operator-info-panel"
    >
      <span className={styles['text-d8841bb3']}>
        The Chkk Operator is a Kubernetes Operator that enables you to deploy
        and configure the K8s Connector in a Kubernetes cluster. By using the
        Operator, you can use a single Custom Resource Definition (CRD) to
        deploy the K8s Connector and its configurations. The Operator reports
        deployment status, health, and errors in the Operator&#39;s CRD status.
        Since the Operator uses higher-level configuration options, it limits
        the risk of potential misconfiguration. Once you have deployed the Chkk
        K8s Connector, the Chkk Operator provides the following benefits:
      </span>
      <span className={styles['text']}>
        - Validation for your Chkk K8s Connector configurations
      </span>
      <span className={styles['text-9b8c2f2e']}>
        - Ensuring the K8s Connector is up to date with your configuration
      </span>
      <span className={styles['text-5a0ca548']}>
        - Orchestration for creating and updating the K8s Connector resources
        and filters
      </span>
      <span className={styles['text-226af90e']}>
        - Reporting of the K8s Connector configuration status in the
        Operator&#39;s CRD status
      </span>
      <span className={styles['text-d8841bb3']}>
        The Chkk Operator uses the following API endpoints for ingesting cluster
        metadata: amazon.aws.com, chkk.io.
      </span>
    </div>
  );
};
